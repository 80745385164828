import React, { Fragment, PureComponent, useContext, useEffect, useRef, useState } from 'react'
import {
	H5,
	Label as BsLabel,
	InputGroup as BsInputGroup,
	InputGroupButton,
	InputGroupAddon,
	Option,
	Row,
	Img,
	P,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Tooltip,
	FormGroup as BsFormGroup,
	FormCustom,
	Small,
	Alert,
	ButtonGroup
} from '@bootstrap-styled/v4'
import ReactSelect, { components } from 'react-select'
import AsyncReactSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import styled, { css, withTheme, ThemeContext } from 'styled-components'
import BsInput from './src/Input'
import { SVGFillColor } from '~/services/SVG'
//import PropTypes from 'prop-types'
import { Col, Button, CenteredModal } from '~/components/bootstrap'
import { useField, FieldArray, useFormikContext } from 'formik'
import InputMask from 'react-input-mask'
import { SketchPicker } from 'react-color'
import { useOutsideClick, useTheme } from '~/utils/hooks'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DateTimePicker as MuDateTimePicker, DatePicker as MuDatePicker } from '@material-ui/pickers'
import { createTheme } from '@material-ui/core'
import { ThemeProvider, makeStyles, withStyles, styled as muStyled } from '@material-ui/styles'
import { checkPropTypes } from 'prop-types'

import { DropzoneArea as MuDropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'

import calendarIcon from '~/images/calendar-icon.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint } from '@fortawesome/pro-solid-svg-icons'
import { faPlus, faMinus, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import faArrowDown from '@fortawesome/fontawesome-pro/svgs/light/angle-down.svg'
import faArrowUp from '@fortawesome/fontawesome-pro/svgs/light/angle-up.svg'
import plusIcon from '~/images/plus.svg'
import minusIcon from '~/images/minus.svg'
import { ConfirmationButton } from '../Button'

export const SectionTitle = styled(H5)`
	margin-top: ${({ theme }) => theme['$spacers'][4]};
	padding-bottom: ${({ theme }) => theme['$spacers'][2]};
	margin-bottom: ${({ theme }) => theme['$spacers'][3]};
	border-bottom: 1px solid ${({ theme }) => theme['$gray-medium']};
`

export const StyledInput = styled(BsInput)`
	appearance: none;
	&.form-control {
		/* border-top: none !important;
		border-left: none;
		border-right: none; */
		/* padding-left: 0;
		padding-right: 0; */

		&::placeholder {
			//text-transform: uppercase;
		}

		&.form-control-danger {
			border-color: ${({ theme }) => theme['$brand-danger']};
		}
	}
`

export const useSelfRegisteredField = (propsOrFieldName, initialValue = '') => {
	const formContext = useFormikContext()
	const [field, meta] = useField(propsOrFieldName)
	useEffect(() => {
		if (field.value === undefined) {
			formContext.setFieldValue(field.name, initialValue, false)
		}
	}, [])
	return [field, meta]
}

export const Input = React.forwardRef((props, ref) => {
	const [field, meta] = useField(props)
	return (
		<React.Fragment>
			<StyledInput className={props.className} ref={ref} id={field.name} {...field} {...props} state={meta.touched && meta.error ? 'danger' : ''} />
			{meta.touched && meta.error && (
				<Tooltip isOpen={meta.touched && meta.error} target={field.name} placement="bottom">
					{meta.error}
				</Tooltip>
			)}
		</React.Fragment>
	)
})

export const InputGroup = ({ addon, ...props }) => (
	<BsInputGroup>
		<Input {...props} />
		<StyledInputGroupAddon>{addon}</StyledInputGroupAddon>
	</BsInputGroup>
)

const StyledInputGroupAddon = styled(InputGroupAddon)`
	&.input-group-addon {
		border-top: none;
		border-right: none;
		font-size: 90%;
		color: ${({ theme }) => theme['$gray']};
	}
`

export const Textarea = ({ innerRef, ...rest }) => {
	const [field, meta] = useField(rest)
	return (
		<React.Fragment>
			<StyledTextarea type="textarea" id={field.name} {...field} {...rest} ref={innerRef} state={meta.touched && meta.error ? 'danger' : ''} />
			{meta.touched && meta.error && (
				<Tooltip isOpen={meta.touched && meta.error} target={field.name} placement="top">
					{meta.error}
				</Tooltip>
			)}
		</React.Fragment>
	)
}

const StyledTextarea = styled(BsInput)`
	max-width: 100%;

	&::placeholder {
		text-transform: uppercase;
	}
`

export const Label = styled(BsLabel)`
	text-transform: uppercase;
`

export const FormGroup = styled(BsFormGroup)`
	${({ row, theme }) =>
		row &&
		css`
			label {
				margin-top: ${theme['$label-margin-bottom']};
			}
		`}
`

export const Select = React.forwardRef(({ name, handleOnChange, creatable, defaultValue, value, CustomOption, ...rest }, ref) => {
	const [field, meta, helpers] = useField(name)

	const theme = useContext(ThemeContext)

	const customTheme = (existing) => ({
		...existing,
		borderRadius: 0,
		colors: {
			...existing.colors,
			primary: theme['$brand-primary'],
			primary25: theme['$gray-medium']
		}
	})

	const onChange = (option, action) => {
		const { setValue, setTouched } = helpers
		switch (action.action) {
			case 'clear':
				setValue('')
				break
			case 'select-option':
				if (rest.isMulti) {
					setValue(option.map((o) => o.value))
				} else {
					setValue(option.value) //was option.value after we changed it
				}
				break
			case 'remove-value':
				setValue(option ? option.map((o) => o.value) : [])
				break
		}
		setTouched(true)

		if (handleOnChange) {
			handleOnChange(option, action)
		}
	}

	const SelectElement = creatable ? CreatableSelect : ReactSelect

	const valueToUse = value ?? field.value

	const selectedOptions = rest.options.filter((option) => (Array.isArray(valueToUse) ? valueToUse.includes(option.value) : valueToUse === option.value))

	const conditionalProps = {}
	if (defaultValue) {
		conditionalProps.defaultValue = rest.options.filter((option) =>
			Array.isArray(defaultValue) ? defaultValue.includes(option.value) : defaultValue === option.value
		)
	}

	const components = { Input: ReactSelectInput }
	if (CustomOption) components.Option = CustomOption

	return (
		<React.Fragment>
			<SelectElement
				styles={{
					container: (baseStyles, state) => ({
						...baseStyles,
						flexGrow: 1
					}),
					menu: (baseStyles) => ({
						...baseStyles,
						zIndex: 1050
					})
				}}
				ref={ref}
				onChange={onChange}
				theme={customTheme}
				{...conditionalProps}
				components={components}
				{...rest}
				value={selectedOptions}
			/>
		</React.Fragment>
	)
})

const ReactSelectInput = (props) => {
	const { autoCapitalize = 'none' } = props.selectProps
	return <components.Input {...props} autoCapitalize={autoCapitalize} />
}

export const UncontrolledSelect = React.forwardRef(({ creatable, ...rest }, ref) => {
	const SelectElement = creatable ? CreatableSelect : ReactSelect
	const theme = useContext(ThemeContext)
	const customTheme = (existing) => ({
		...existing,
		borderRadius: 0,
		colors: {
			...existing.colors,
			primary: theme['$brand-primary'],
			primary25: theme['$gray-medium']
		}
	})

	return <SelectElement ref={ref} theme={customTheme} {...rest} />
})

export const AsyncSelect = React.forwardRef(({ name, handleOnChange, CustomOption, ...rest }, ref) => {
	const [field, meta, helpers] = useField(name)
	const theme = useContext(ThemeContext)

	const customTheme = (existing) => ({
		...existing,
		borderRadius: 0,
		colors: {
			...existing.colors,
			primary: theme['$brand-primary'],
			primary25: theme['$gray-medium']
		}
	})

	const onChange = (option, action) => {
		const { setValue, setTouched } = helpers

		switch (action.action) {
			case 'clear':
				setValue('')
				break
			case 'select-option':
				setValue(option.value)
				break
		}
		setTouched(true)

		if (handleOnChange) {
			handleOnChange(option, action)
		}
	}

	const components = { Input: ReactSelectInput }
	if (CustomOption) components.Option = CustomOption

	return (
		<React.Fragment>
			<AsyncReactSelect ref={ref} onChange={onChange} theme={customTheme} components={components} {...rest} />
		</React.Fragment>
	)
})

const StyledSelect = styled(StyledInput)`
	background-image: url(${({ theme }) => SVGFillColor(faArrowDown, theme['$gray-medium'])}) !important;
	background-position: 98% 50% !important;
	background-size: 1rem !important;
	background-repeat: no-repeat;

	&:focus {
		background-image: url(${({ theme }) => SVGFillColor(faArrowUp, theme['$gray-dark'])}) !important;
	}
`

export const MultipleInputArray = ({ children, ...props }) => {
	const { name, values, showIndex, initialValues, customButtons, confirmRemoval, removalDescription, onAdd, onRemove, disabled } = props
	const [field, meta] = useField(name)
	const ref = useRef(null)
	const prevValuesCount = useRef(0)
	const [hasFocused, setHasFocused] = useState(false)

	const setElRef = (node, index) => {
		if (index === 0) {
			ref.current = node
		}
	}

	const setFocused = () => {
		setHasFocused(true)
	}

	useEffect(() => {
		if (prevValuesCount.current === 0 && ref.current && hasFocused) {
			ref.current.focus()
		}
		prevValuesCount.current = values.length
	}, [prevValuesCount.current, ref.current, hasFocused])

	return (
		<FieldArray name={name}>
			{(arrayHelpers) => {
				const renderButtons = (i, size = null) => {
					if (values.length < 2) {
						return (
							<ButtonGroup size={size}>
								<Button
									type="button"
									color="success"
									onClick={() => {
										for (i = values.length; i < 2; i++) {
											arrayHelpers.push(initialValues)
										}
										if (onAdd) onAdd(1)
									}}
									disabled={disabled}
								>
									<FontAwesomeIcon icon={faPlus} fixedWidth />
								</Button>
								<Button type="button" color="danger" disabled>
									<FontAwesomeIcon icon={faMinus} fixedWidth />
								</Button>
							</ButtonGroup>
						)
					} else {
						return (
							<ButtonGroup size={size}>
								<Button
									type="button"
									color="success"
									onClick={() => {
										if (i + 1 === values.length) {
											arrayHelpers.push(initialValues)
										} else {
											arrayHelpers.insert(i + 1, initialValues)
										}
										if (onAdd) onAdd(i + 1)
									}}
									disabled={disabled}
								>
									<FontAwesomeIcon icon={faPlus} fixedWidth />
								</Button>
								{confirmRemoval ? (
									<ConfirmationButton
										color="danger"
										handleConfirmation={(closeModal) => {
											arrayHelpers.remove(i)
											if (onRemove) onRemove(i)
											closeModal()
										}}
										description={removalDescription ?? `Are you sure you want to remove this row?`}
										icon={faMinus}
										disabled={disabled}
									/>
								) : (
									<Button
										type="button"
										color="danger"
										onClick={() => {
											arrayHelpers.remove(i)
											if (onRemove) onRemove(i)
										}}
										disabled={disabled}
									>
										<FontAwesomeIcon icon={faMinus} fixedWidth />
									</Button>
								)}

								{/*  */}
							</ButtonGroup>
						)
					}
				}

				return values.length > 0 ? (
					values.map((row, i) => {
						if (customButtons) {
							return children({ i, setElRef, setFocused, renderButtons })
						} else {
							return (
								<FormGroup id={`${field.name}-tooltip`} row key={i} className="align-items-xxxs-center">
									<Col xxxs>{children({ i, setElRef })}</Col>
									<Col xxxs="auto">{renderButtons(i)}</Col>
									{/* {meta.touched && meta.error && (
										<Tooltip isOpen={meta.touched && meta.error} target={`#${field.name}-tooltip`} placement="top">
											{Array.isArray(meta.error) ? (
												<Fragment>
													{meta.error.map((error, e) => (
														<P key={e}>{error[Object.keys(error)[0]]}</P>
													))}
												</Fragment>
											) : (
												<Fragment>{meta.error}</Fragment>
											)}
										</Tooltip>
									)} */}
								</FormGroup>
							)
						}
					})
				) : customButtons ? (
					children({ i: 0, setElRef, setFocused, renderButtons })
				) : (
					<FormGroup row className="align-items-xxxs-center">
						<Col
							xxxs
							onClick={() => {
								if (!disabled) arrayHelpers.push(initialValues)
							}}
						>
							{children({ i: 0, setFocused, setElRef })}
						</Col>
						<Col xxxs="auto">{renderButtons()}</Col>
					</FormGroup>
				)
			}}
		</FieldArray>
	)
}

export class InputArray extends PureComponent {
	constructor(props) {
		super(props)

		this.ref = React.createRef()
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.values.length == 0 && this.ref.current) {
			this.ref.current.focus()
		}
	}

	setElRef = (node, index) => {
		//console.log(node)
		if (index === 0) {
			this.ref.current = node
		}
	}

	render() {
		const { name, values, placeholder, showIndex, ...rest } = this.props

		return (
			<FieldArray name={name}>
				{(arrayHelpers) => {
					return values.length > 0 ? (
						values.map((row, i) => (
							<FormGroup row key={i} className="align-items-xxxs-center">
								<Col xxxs>
									<Input
										type="text"
										ref={(n) => this.setElRef(n, i)}
										name={`${name}.${i}`}
										placeholder={placeholder ? placeholder.replace('%i', i + 1) : ''}
										{...rest}
									/>
								</Col>
								<Col xxxs="auto">
									{i + 1 === values.length ? (
										<Button color="success" onClick={() => arrayHelpers.push('')}>
											<FontAwesomeIcon icon={faPlus} />
										</Button>
									) : i !== 0 || (i === 0 && values.length > 1) ? (
										<Button color="danger" onClick={() => arrayHelpers.remove(i)}>
											<FontAwesomeIcon icon={faMinus} />
										</Button>
									) : (
										''
									)}
								</Col>
							</FormGroup>
						))
					) : (
						<FormGroup row className="align-items-xxxs-center">
							<Col xxxs onClick={() => arrayHelpers.push(initialValues)}>
								<Input
									type="text"
									name={name}
									placeholder={placeholder ? placeholder.replace('%i', 1) : ''}
									onFocus={() => arrayHelpers.push('')}
								/>
							</Col>
							<Col xxxs="auto">
								<Button color="success">
									<FontAwesomeIcon icon={faPlus} />
								</Button>
							</Col>
						</FormGroup>
					)
				}}
			</FieldArray>
		)
	}
}

export const Typeahead = ({ children, ...props }) => {
	const [field, meta, helpers] = useField(props.name)
	const [options, setOptions] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const ref = useRef()

	const { multiple, ...rest } = props

	useOutsideClick(ref, () => {
		if (isOpen) {
			setIsOpen(false)
		}
	})

	const handleOnChange = (e) => {
		setSearchString(e.target.value)
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			getOptions()
		}, 500)

		return () => clearTimeout(timeout)
	}, [searchString])

	const showOptions = () => {
		if (options.length > 0) {
			setIsOpen(true)
		}
	}

	const selectOption = (id) => {
		if (multiple) {
			helpers.setValue(field.value.concat([id]))
		} else {
			helpers.setValue(id)
		}

		setIsOpen(false)
	}

	const getOptions = () => {
		if (searchString.length > 0) {
			setIsLoading(true)
			props.fetchOptions(searchString).then((options) => {
				setOptions(options)
				if (options.length > 0) {
					setIsOpen(true)
				}
				setIsLoading(false)
			})
		}
	}

	return (
		<TypeaheadWrapper ref={ref}>
			<StyledInput
				id="typeahead"
				type="search"
				value={searchString}
				onChange={handleOnChange}
				onClick={() => showOptions()}
				placeholder={props.placeholder}
			/>
			{isOpen && (
				<OptionsWrapper>
					{options.length > 0 &&
						options.map((option, i) => <OptionWrapper key={i}>{children({ option, selectOption, current: meta.value })}</OptionWrapper>)}
				</OptionsWrapper>
			)}
			{isLoading && (
				<LoadingWrapper>
					<FontAwesomeIcon icon={faSpinner} size="lg" spin />
				</LoadingWrapper>
			)}
			{meta.touched && meta.error && (
				<Tooltip isOpen={meta.touched && meta.error} target={typeahead} placement="bottom">
					{meta.error}
				</Tooltip>
			)}
		</TypeaheadWrapper>
	)
}

const TypeaheadWrapper = styled.div`
	position: relative;
	flex: 1 0 auto;
	display: flex;
`
const LoadingWrapper = styled.div`
	position: absolute;
	right: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
`
const OptionWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme['$brand-pimary']};
	padding: 0.5rem 1rem;
	cursor: pointer;

	&:hover {
		background: ${({ theme }) => theme['$brand-primary']};
		color: ${({ theme }) => theme['$white']};
	}
`

const OptionsWrapper = styled.div`
	max-height: 60vh;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	background: ${({ theme }) => theme['$white']};
	border: 1px solid ${({ theme }) => theme['$brand-primary']};
	top: 100%;
	width: 100%;
	z-index: 2000;

	${OptionWrapper} {
		&:last-child {
			border-bottom: none;
		}
	}
`

export const ArrayFormGroup = styled(FormGroup)`
	position: relative;

	.form-control {
		padding-right: ${({ theme }) => theme['$spacers'][5]};
	}
`
export const IconButton = styled(Img)`
	position: absolute;
	right: 0%;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
`

export const MaskedInput = React.forwardRef(({ mask, ...props }, ref) => {
	const [field, meta] = useField(props)

	return (
		<React.Fragment>
			<InputMask ref={ref} id={field.name} mask={mask} maskChar={null} {...field} {...props}>
				{(inputProps) => {
					return <StyledInput {...inputProps} state={meta.touched && meta.error ? 'danger' : ''} />
				}}
			</InputMask>
			{meta.touched && meta.error && (
				<Tooltip isOpen={meta.touched && meta.error} target={field.name} placement="bottom">
					{meta.error}
				</Tooltip>
			)}
		</React.Fragment>
	)
})

export const MaskedInputGroup = ({ addon, ...props }) => (
	<BsInputGroup>
		<MaskedInput {...props} />
		<StyledInputGroupAddon>{addon}</StyledInputGroupAddon>
	</BsInputGroup>
)

export class MaskedInputArray extends PureComponent {
	constructor(props) {
		super(props)

		this.ref = React.createRef()
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.values.length == 0 && this.ref.current) {
			this.ref.current.focus()
		}
	}

	setElRef = (node, index) => {
		//console.log(node)
		if (index === 0) {
			this.ref.current = node
		}
	}

	render() {
		const { name, values, placeholder, showIndex, ...rest } = this.props

		return (
			<FieldArray name={name}>
				{(arrayHelpers) => {
					return values.length > 0 ? (
						values.map((row, i) => (
							<ArrayFormGroup key={i}>
								<MaskedInput
									type="text"
									ref={(n) => this.setElRef(n, i)}
									name={`${name}.${i}`}
									placeholder={placeholder ? placeholder.replace('%i', i + 1) : ''}
									{...rest}
								/>
								{i + 1 === values.length ? (
									<IconButton src={plusIcon} onClick={() => arrayHelpers.push('')} />
								) : i !== 0 || (i === 0 && values.length > 1) ? (
									<IconButton src={minusIcon} onClick={() => arrayHelpers.remove(i)} />
								) : (
									''
								)}
							</ArrayFormGroup>
						))
					) : (
						<ArrayFormGroup>
							<Input
								type="text"
								name={name}
								placeholder={placeholder ? placeholder.replace('%i', 1) : ''}
								onFocus={() => arrayHelpers.push('')}
							/>
							<IconButton src={plusIcon} />
						</ArrayFormGroup>
					)
				}}
			</FieldArray>
		)
	}
}

export const Radio = ({ children, boolean, ...props }) => {
	const [{ name, value, onChange, ...field }, meta, { setValue }] = useField(props)

	const val = boolean ? String(props.id) == 'true' : props.id
	const checked = boolean ? String(value) === String(val) : value == props.id
	const handleOnChange = boolean ? () => setValue(val) : onChange

	return (
		<React.Fragment>
			<StyledCheckbox radio={{ id: props.id, name: field.name }} value={val} checked={checked} onChange={handleOnChange} {...field} {...props}>
				{children}
			</StyledCheckbox>
		</React.Fragment>
	)
}

export const RadioGroup = ({ children, ...props }) => {
	const { inline, tag, ...rest } = props
	const [{ name, ...field }, meta] = useField(rest)
	const ContainerTag = tag ? tag : inline ? FormGroup : 'div'

	return (
		<ContainerTag id={name} color={meta.touched && meta.error ? 'danger' : ''}>
			{children}
			{meta.touched && meta.error && (
				<Tooltip isOpen={meta.touched && meta.error} target={name} placement="bottom">
					{meta.error}
				</Tooltip>
			)}
		</ContainerTag>
	)
}

const StyledCheckbox = styled(FormCustom)`
	margin: 0.6rem 0;

	.custom-control-indicator {
		border: 1px solid ${({ theme }) => theme['$gray-light']};
	}
`

export const Checkbox = ({ children, ...props }) => {
	const [field, meta] = useField({ ...props, type: 'checkbox' })
	return (
		<React.Fragment>
			<StyledCheckbox {...field} {...props}>
				<span id={field.name}>{children}</span>
			</StyledCheckbox>
			{meta.touched && meta.error && (
				<Tooltip isOpen={meta.touched && meta.error} target={field.name} placement="bottom">
					{meta.error}
				</Tooltip>
			)}
		</React.Fragment>
	)
}

export const CheckboxGroup = ({ name, options, values, className }) => (
	<FieldArray name={name}>
		{(arrayHelpers) => {
			return (
				<FormGroup row className={className}>
					{options.length > 0 &&
						options.map((row, i) => {
							const { value, label, ...props } = row
							return (
								<Col key={i} xxxs={12} md={6} lg={4}>
									<StyledCheckbox
										name={name}
										onChange={(e) => {
											if (e.target.checked) {
												arrayHelpers.push(value)
											} else {
												const index = values.indexOf(value)
												arrayHelpers.remove(index)
											}
										}}
										{...props}
										checked={values.indexOf(value) > -1}
									>
										{label && <Small>{label}</Small>}
									</StyledCheckbox>
								</Col>
							)
						})}
				</FormGroup>
			)
		}}
	</FieldArray>
)

export class ErrorAlert extends PureComponent {
	state = {
		isOpen: false
	}

	timeout = null

	componentDidUpdate = (prevProps) => {
		const { isSubmitting, isValid } = this.props
		const setState = this.setState.bind(this)

		if (isSubmitting && !isValid) {
			clearTimeout(this.timeout)
			setState({ isOpen: true })

			this.timeout = setTimeout(function () {
				setState({ isOpen: false })
			}, 4000)
		}
	}

	render() {
		const { isOpen } = this.state
		const { children } = this.props
		return (
			<StyledAlert isOpen={isOpen} color="danger">
				{children}
			</StyledAlert>
		)
	}
}

export const StyledAlert = styled(Alert)`
	margin-top: ${({ theme }) => theme['$spacers'][4]};
`

class ColorPickerInput extends PureComponent {
	state = {
		isOpen: false
	}

	onChangeComplete = (color, event) => {
		const { presetColors } = this.props
		const { setValue } = this.props.helpers
		//console.log(color)
		setValue(color.hex)
		if (presetColors && presetColors.indexOf(color.hex) > -1) {
			this.setState({ isOpen: false })
		}
	}

	handleOpen = () => {
		const { setTouched } = this.props.helpers

		this.setState({ isOpen: true })
		setTouched(true)
	}

	render() {
		const { placeholder, field, meta, helpers, ...rest } = this.props
		const { value, touched } = meta
		const { isOpen } = this.state
		return (
			<React.Fragment>
				<Row noGutters>
					<ColorSwatch xxxs color={value} />
					<Col xxxs="auto">
						<Button color="secondary" onClick={() => this.handleOpen(true)}>
							<FontAwesomeIcon icon={faTint} fixedWidth />
						</Button>
					</Col>
				</Row>
				<CenteredModal isOpen={isOpen} size="sm" toggle={() => this.setState({ isOpen: false })}>
					<ModalHeader toggle={() => this.setState({ isOpen: false })}>Choose a Color</ModalHeader>
					<ModalBody>
						<StyledSketchPicker color={value} width="100%" disableAlpha onChangeComplete={this.onChangeComplete} {...rest} />
					</ModalBody>
				</CenteredModal>
			</React.Fragment>
		)
	}
}

const StyledSketchPicker = styled(SketchPicker)`
	box-sizing: border-box !important;
`

const ColorSwatch = styled(({ color, children, ...rest }) => <Col {...rest}>{children}</Col>)`
	background: ${({ color }) => color} !important;
	border: 1px solid ${({ theme }) => theme['$gray-medium']};
	border-right: none;
`

export const withFieldHOC =
	(Component) =>
	({ name, ...props }) => {
		const [field, meta, helpers] = useField(name)

		return <Component {...props} helpers={helpers} meta={meta} field={field} />
	}
export const ColorPicker = withFieldHOC(ColorPickerInput)

class MuiDateTimePicker extends PureComponent {
	static defaultProps = {
		format: 'MMMM d, yyyy h:mm a',
		placeholder: 'Select a Time',
		minutesStep: 15
	}

	state = {
		isOpen: false
	}

	constructor(props) {
		super(props)

		const { theme } = props

		this.materialTheme = createTheme({
			palette: {
				primary: {
					main: theme['$brand-primary']
				},
				secondary: {
					main: theme['$brand-secondary']
				},
				text: {
					primary: theme['$gray-dark']
				},
				tonalOffset: 0.1
			},
			typography: {
				fontFamily: '"Proxima Nova",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
				h3: {
					fontSize: theme['$font-size-h2']
				},
				h4: {
					fontSize: theme['$font-size-h4']
				}
			},
			spacing: 4,
			overrides: {
				MuiDialog: {
					root: {
						zIndex: '2000 !important'
					}
				}
			}
		})
	}

	handleOpen = () => {
		const { setTouched } = this.props.helpers

		this.setState({ isOpen: true })
		setTouched(true)
	}

	onChange = (time) => {
		const { setValue, setError } = this.props.helpers

		setValue(time)
		setError(undefined)
	}

	render() {
		const { placeholder, field, meta, helpers, ...rest } = this.props

		const { value, touched } = meta
		const { setTouched } = helpers
		const { isOpen } = this.state

		console.log(rest)

		return (
			<Row className="align-items-end">
				<Col xxxs="auto">
					<CalendarIcon src={calendarIcon} onClick={() => this.setState({ isOpen: true })} />
				</Col>
				<Col xxxs id={field.name}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ThemeProvider theme={this.materialTheme}>
							<StyledDateTimePicker
								value={value}
								onChange={this.onChange}
								emptyLabel={placeholder}
								open={isOpen}
								onClose={() => this.setState({ isOpen: false })}
								onOpen={this.handleOpen}
								{...rest}
							/>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</Col>
				{meta.touched && meta.error && (
					<Tooltip isOpen={meta.touched && meta.error} target={field.name} placement="bottom">
						{meta.error}
					</Tooltip>
				)}
			</Row>
		)
	}
}

const StyledDateTimePicker = styled(MuDateTimePicker)`
	width: 100%;

	.MuiInput-formControl {
		&:before {
			border-color: ${({ theme }) => theme['$gray-medium']};
		}
	}

	.MuiInput-input {
		height: auto;
		line-height: 1.25;
		padding: ${({ theme }) => theme['$spacers'][2]} 0;
		font-size: ${({ theme }) => theme['$font-size-sm']};
		text-transform: uppercase;
	}
`

class MuiDatePicker extends PureComponent {
	static defaultProps = {
		format: 'MMMM d, yyyy',
		placeholder: 'Select a Date'
	}

	state = {
		isOpen: false
	}

	constructor(props) {
		super(props)

		const { theme } = props

		this.materialTheme = createTheme({
			palette: {
				primary: {
					main: theme['$brand-primary']
				},
				secondary: {
					main: theme['$brand-secondary']
				},
				text: {
					primary: theme['$gray-dark']
				},
				tonalOffset: 0.1
			},
			typography: {
				fontFamily: '"Proxima Nova",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
				h3: {
					fontSize: theme['$font-size-h2']
				},
				h4: {
					fontSize: theme['$font-size-h4']
				}
			},
			spacing: 4,
			overrides: {
				MuiDialog: {
					root: {
						zIndex: '2000 !important'
					}
				}
			}
		})
	}

	handleOpen = () => {
		const { setTouched } = this.props.helpers

		this.setState({ isOpen: true })
		setTouched(true)
	}

	onChange = (time) => {
		const { setValue, setError } = this.props.helpers

		setValue(time)
		setError(undefined)
	}

	render() {
		const { placeholder, field, meta, helpers, disabled, ...rest } = this.props

		const { value, touched } = meta
		const { setTouched } = helpers
		const { isOpen } = this.state

		return (
			<Row className="align-items-end">
				<Col xxxs="auto">
					<CalendarIcon
						src={calendarIcon}
						onClick={() => {
							if (!disabled) this.setState({ isOpen: true })
						}}
						disabled={disabled}
					/>
				</Col>
				<Col xxxs>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ThemeProvider theme={this.materialTheme}>
							<StyledDatePicker
								value={value}
								onChange={this.onChange}
								emptyLabel={placeholder}
								open={isOpen}
								onClose={() => this.setState({ isOpen: false })}
								onOpen={this.handleOpen}
								disabled={disabled}
								{...rest}
							/>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</Col>
			</Row>
		)
	}
}

const StyledDatePicker = styled(MuDatePicker)`
	width: 100%;

	.MuiInput-formControl {
		&:before {
			border-color: ${({ theme }) => theme['$gray-light']};
		}
	}

	.MuiInput-input {
		height: auto;
		line-height: 1.25;
		padding: ${({ theme }) => theme['$spacers'][2]} 0;
		font-size: ${({ theme }) => theme['$font-size-sm']};
		text-transform: uppercase;
	}
`

export const DateTimePicker = withFieldHOC(withTheme(MuiDateTimePicker))
export const DatePicker = withFieldHOC(withTheme(MuiDatePicker))

const useStyles = makeStyles({
	dropZone: {
		borderColor: (props) => props['$gray'],
		backgroundColor: (props) => props['$gray-light'],
		padding: (props) => props['$spacers'][3]
	},
	dropzoneParagraph: {
		fontSize: (props) => props['$font-size-h6'],
		textTransform: 'uppercase'
	}
})

const StyledDropzoneArea = (props) => {
	const { theme, ...rest } = props
	const classes = useStyles(theme)

	return <MuDropzoneArea classes={classes} {...rest} />
}

const DropzoneArea = withTheme(StyledDropzoneArea)

export const DropzoneAreaModal = (props) => {
	const theme = useTheme()
	const [files, setFiles] = useState([])
	const { isOpen, onClose, onSave, onPaste, onDelete, onChange, submitting, ...rest } = props
	const defaultTheme = createTheme()
	const materialTheme = createTheme({
		palette: {
			primary: {
				main: theme['$brand-primary']
			},
			secondary: {
				main: theme['$brand-secondary']
			},
			text: {
				primary: theme['$gray-dark']
			},
			tonalOffset: 0.1
		},
		typography: {
			fontFamily: '"Proxima Nova",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
			h3: {
				fontSize: theme['$font-size-h2']
			},
			h4: {
				fontSize: theme['$font-size-h4']
			}
		},
		spacing: 4,
		overrides: {
			MuiSnackbar: {
				anchorOriginBottomLeft: {
					[defaultTheme.breakpoints.up('md')]: {
						left: '50%',
						transform: 'translateX(-50%)',
						minWidth: '100%'
					}
				}
			},
			MuiSnackbarContent: {
				root: {
					flexWrap: 'no-wrap',
					width: '100%'
				}
			}
		}
	})

	useEffect(() => {
		const pasteListener = (e) => {
			const items = e.clipboardData.items
			if (items.length > 0) {
				const item = items[0]
				if (item.type.indexOf('image') !== -1) {
					const file = item.getAsFile()
					if (file) {
						const reader = new FileReader()

						reader.onloadend = function (event) {
							var imgBase64 = event.target.result

							setFiles((files) => [...files, { data: imgBase64, file: file }])
							onChange(files)
						}
						reader.readAsDataURL(file)
					}
				}
			}
		}
		window.addEventListener('paste', pasteListener)

		return () => {
			window.removeEventListener('paste', pasteListener)
		}
	}, [])

	useEffect(() => {
		const fileData = files.map((file) => file.file)
		onChange(fileData)
	}, [files])

	const handleOnAdd = (newFiles) => {
		setFiles((currentFiles) => [...currentFiles, ...newFiles])
	}

	const handleOnDelete = (deletedFileIndex, index) => {
		setFiles((currentFiles) => {
			currentFiles.splice(index, 1)
			return currentFiles
		})
	}

	return (
		<CenteredModal isOpen={isOpen} toggle={onClose} size="lg">
			<ModalHeader toggle={onClose}>Upload Files</ModalHeader>
			<ModalBody>
				<ThemeProvider theme={materialTheme}>
					<DropzoneAreaBase onAdd={handleOnAdd} onDelete={handleOnDelete} fileObjects={files} {...rest} />
				</ThemeProvider>
			</ModalBody>
			<ModalFooter>
				<Button color="light" noBorders onClick={onClose}>
					Cancel
				</Button>
				<Button color="primary" noBorders onClick={onSave} submitting={submitting}>
					Save
				</Button>
			</ModalFooter>
		</CenteredModal>
	)
}

const CalendarIcon = styled(Img)`
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	max-height: 100%;
	height: 100%;
`

export class FormConfirmatonModal extends PureComponent {
	render() {
		const { isOpen, title, children, handleModalClose } = this.props

		return (
			<CenteredModal isOpen={isOpen} toggle={handleModalClose}>
				<ModalHeader toggle={handleModalClose}>{title}</ModalHeader>
				<ModalBody>{children}</ModalBody>
				<ModalFooter>
					<Button color="light" noBorders onClick={handleModalClose}>
						Close
					</Button>
				</ModalFooter>
			</CenteredModal>
		)
	}
}

export const BooleanButtons = ({ name, trueLabel, falseLabel, onTrueClick, onFalseClick, onClick }) => {
	const [field, meta, { setValue, ...helpers }] = useField(name)

	const trueClicked = () => {
		setValue(true)
		if (onTrueClick) {
			onTrueClick()
		}
		if (onClick) {
			onClick()
		}
	}

	const falseClicked = () => {
		setValue(false)
		if (onFalseClick) {
			onFalseClick()
		}
		if (onClick) {
			onClick()
		}
	}

	return (
		<ButtonGroup>
			<Button color="success" onClick={trueClicked} active={field.value === true}>
				{trueLabel ?? 'Yes'}
			</Button>
			<Button color="danger" onClick={falseClicked} active={field.value === false}>
				{falseLabel ?? 'No'}
			</Button>
		</ButtonGroup>
	)
}
