import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, CardHeader, CardFooter, Collapse } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as HeaderComponents from './components/header'
import * as TimestampComponents from './components/timestamp'
import * as BodyComponents from './components/body'
import * as FooterComponents from './components/footer'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'

const ActivityItem = ({ id, className, opportunityId, isChild }) => {
	const { selectors, theme, dispatch, entityThunks } = useLumenox()
	const [isOpen, setIsOpen] = useState(false)

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	useEffect(() => {
		if (isChild) dispatch(entityThunks.fetchOpportunityActivityById(id))
	}, [])

	if (!activity) {
		return null
	}

	const ActivityTypeHeader = HeaderComponents[activity.__t]
	const ActivityTypeTimestamp = TimestampComponents[activity.__t]
	const ActivityTypeBody = BodyComponents[activity.__t]
	const ActivityTypeFooter = FooterComponents[activity.__t]

	if (!ActivityTypeHeader) {
		return null
	}

	let color
	switch (activity.__t) {
		case 'FollowUp':
			color = theme['$brand-primary']
			break
		default:
			color = null
			break
	}

	//make sure this matches up with selector!
	let displayDate
	switch (activity.__t) {
		case 'Email':
		case 'FollowUp':
		case 'Call':
		case 'SMS':
		case 'InstallDetailsActivity':
			displayDate = activity.createdAt
			break
		default:
			displayDate = activity.updatedAt
	}

	const ToggleChevron = () => (
		<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} fixedWidth onClick={() => setIsOpen(!isOpen)} className="pointer ml-xxxs-1" size="lg" />
	)

	const renderTimestamp = () => {
		return (
			<React.Fragment>
				{ActivityTypeTimestamp ? (
					<ActivityTypeTimestamp id={id} opportunityId={opportunityId} />
				) : (
					<React.Fragment>{format(new Date(displayDate), 'MMMM d, y - h:mma')}</React.Fragment>
				)}
				{isChild && <ToggleChevron />}
			</React.Fragment>
		)
	}

	const BodyAndFooter = () => (
		<React.Fragment>
			{ActivityTypeBody && (
				<ActivityTypeBody
					id={id}
					opportunityId={opportunityId}
					renderChildren={() =>
						activity.children?.map((child) => (
							<React.Fragment key={child}>
								<Hr />
								<ActivityItem isChild id={child} opportunityId={opportunityId} className={className} />
							</React.Fragment>
						))
					}
				/>
			)}
			{ActivityTypeFooter && <ActivityTypeFooter id={id} opportunityId={opportunityId} />}
		</React.Fragment>
	)

	return (
		<Card className={className} style={color ? { borderColor: color } : {}}>
			<ActivityTypeHeader id={id} opportunityId={opportunityId} renderTimestamp={renderTimestamp} />
			{isChild ? (
				<Collapse isOpen={isOpen}>
					<BodyAndFooter />
				</Collapse>
			) : (
				<BodyAndFooter />
			)}
		</Card>
	)
}

export default ActivityItem
