import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ConfirmationButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Small, Strong } from '@bootstrap-styled/v4'

const AutoAssignFromFieldStageButton = ({ opportunity, currentStage, nextStage, field }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const fieldValue = useSelector((state) => selectors.selectUserById(state, opportunity[field]))

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	const updateStage = () => {
		//auto assign if it isn't already on who we would auto assign to (leaving this check since there isn't a message with it)
		if (opportunity[field] && opportunity.assignedTo !== opportunity[field]) {
			dispatch(entityThunks.updateOpportunityAssignedTo({ id: opportunity.id, userId: opportunity[field] }))
		}
		dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			//don't need anything, can't fail since dependencies are already checked
		})
	}

	if (!dependenciesMet) return null

	return (
		<ConfirmationButton
			color={nextStage.color}
			icon={solid[nextStage.icon]}
			handleConfirmation={updateStage}
			button={nextStage.name}
			description={
				<React.Fragment>
					{`Are you sure you want to push this lead to ${nextStage.name}?`}
					{fieldValue && (
						<React.Fragment>
							<br />
							<Small>
								<Strong>{`Will auto assign to ${fieldValue.name}`}</Strong>
							</Small>
						</React.Fragment>
					)}
				</React.Fragment>
			}
			title="Confirm Stage Change"
		/>
	)
}

export default AutoAssignFromFieldStageButton
