export const SVGFillColor = (base64, hex) => {
	const str = new Buffer(base64.replace(/(data\:image\/svg\+xml\;base64\,)/, ''), 'base64')

	const decoded = str.toString()

	let foundFills = 0
	const matches = decoded.matchAll(/fill=".*?"/g)

	for (const match of matches) {
		foundFills++
	}

	if (foundFills === 0) {
		const match = decoded.match(/(>)/)
		const fill = ` fill="${hex}"`

		if (match) {
			return [
				'data:image/svg+xml;base64,',
				new Buffer([decoded.slice(0, match.index), fill, decoded.slice(match.index)].join('')).toString('base64')
			].join('')
		}
	}

	return base64
}
