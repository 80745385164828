import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import { primitiveSelectors as userRoleSelectors } from '~/state/entities/userRole'
import { createCachedParamSelector } from '../../adapter'

export const selectUserRoleBySlug = createCachedParamSelector(
	(state) => userRoleSelectors.selectAllUserRoles(state),
	(_, slug) => slug,
	(userRoles, slug) => {
		return userRoles.find((userRole) => userRole.slug === slug)
	}
)

export const selectUserRolesBySlugs = createCachedParamSelector(
	(state) => userRoleSelectors.selectAllUserRoles(state),
	(_, slugs) => slugs,
	(userRoles, slugs) => {
		return userRoles.filter((userRole) => slugs.includes(userRole.slug)).map((role) => role.id)
	}
)
