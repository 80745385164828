import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { H3, H5, P, Hr } from '@bootstrap-styled/v4'
import { Link, useParams } from 'react-router-dom'
import { Button } from '~/components/bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { add } from 'date-fns'
import Cookies from 'js-cookie'

import { useLumenox } from '~/services/lumenox'

const ClaimInvite = () => {
	const { dispatch, selectors, entityThunks } = useLumenox()
	const params = useParams()
	const [invite, setInvite] = useState(Cookies.getJSON('invite'))

	useEffect(() => {
		dispatch(entityThunks.fetchUserInvite(params.id)).then((response) => {
			const { payload } = response
			Cookies.set('invite', payload)
			setInvite(Cookies.getJSON('invite'))
			console.log(invite)
		})
	}, [])

	return (
		<React.Fragment>
			{invite && (
				<div className="text-xxxs-center">
					<Name>{invite.name}</Name>
					<H5>You have been invited to the Lumenox CRM</H5>

					<P>To claim your invitation register below.</P>

					<Hr />
					<div className="d-xxxs-flex justify-content-xxxs-around align-items-xxxs-center">
						<Button color="primary" tag={Link} to="/auth/register">
							Register
						</Button>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export default ClaimInvite

const Name = styled(H3)`
	color: ${({ theme }) => theme['$brand-warning']};
`

const TastingName = styled(H3)`
	color: ${({ theme }) => theme['$brand-danger']};
`
