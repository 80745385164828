import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, ButtonToolbar, ButtonGroup, CardHeader, H4, H5 } from '@bootstrap-styled/v4'
import { Button } from '~/components/bootstrap'

import { BackLink } from '~/components/common/Text'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Select } from '~/components/bootstrap/components/Form'
import { startCase, camelCase } from 'lodash'
import * as Components from './components'

const Stage = ({ id }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))
	const stage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, opportunity.stage))

	if (!opportunity) {
		return null
	}

	const StageType =
		Components[
			startCase(
				stage.slug.replace(/-([a-z])/g, function (g) {
					return g[1].toUpperCase()
				})
			).replace(/ /g, '')
		]

	if (!StageType) {
		return null
	}

	return <StageType opportunityId={id} />
}

export default Stage
