import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Badge, ButtonGroup } from '@bootstrap-styled/v4'
import { ActionButton, Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import UserForm from '../../components/UserForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareNodes } from '@fortawesome/pro-solid-svg-icons'
import UserCompensationProgramsForm from '../../components/UserCompensationProgramsForm'

const UserListingTable = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const users = useSelector((state) => selectors.selectAllUsers(state))

	useEffect(() => {
		dispatch(entityThunks.fetchUsers())
	}, [])

	const handleEditUserSubmit = ({ id, ...values }, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.updateUser({ id, values })).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			resetForm()
			onSuccess()
		})
	}

	return (
		<Table bordered striped responsive>
			<Thead>
				<Tr>
					<Th width="50%">Name</Th>
					<Th>Roles</Th>
					<Th>Compensation Programs</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{users
					.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
					.map((user, i) => {
						return (
							<Tr key={i}>
								<Td>
									<A tag={Link} to={`/users/${user.id}`}>
										{user.name}
									</A>
								</Td>
								<Td>
									<Roles roles={user.roles} />
								</Td>
								<Td>
									{user.compensationPrograms.map((cp) => (
										<React.Fragment key={cp}>
											<CompensationProgram id={cp} />
											<br />
										</React.Fragment>
									))}
								</Td>
								<Td>
									<ButtonGroup size="sm">
										<ActionButton color="info" button={<FontAwesomeIcon icon={faEdit} />} title="Edit User Roles">
											{(closeModal) => <UserForm onSubmit={handleEditUserSubmit} initialValues={user} onSuccess={closeModal} />}
										</ActionButton>
										<ActionButton color="success" button={<FontAwesomeIcon icon={faShareNodes} />} title="Add Compensation Program">
											{(closeModal) => <UserCompensationProgramsForm userId={user.id} closeModal={closeModal} />}
										</ActionButton>
									</ButtonGroup>
								</Td>
							</Tr>
						)
					})}
			</Tbody>
		</Table>
	)
}

const Roles = ({ roles }) => {
	return (
		<Fragment>
			{roles.map((role) => (
				<RoleTag key={role} roleId={role} />
			))}
		</Fragment>
	)
}

const RoleTag = ({ roleId }) => {
	const { selectors } = useLumenox()
	const role = useSelector((state) => selectors.selectUserRoleById(state, roleId))

	if (!role) {
		return null
	}

	return (
		<Badge color="primary" className="mr-xxxs-1">
			{role.name}
		</Badge>
	)
}

const CompensationProgram = ({ id }) => {
	const { selectors } = useLumenox()
	const enrollment = useSelector((state) => selectors.selectCompensationProgramEnrollmentById(state, id))
	const compensationProgram = useSelector((state) => selectors.selectCompensationProgramByEnrollmentId(state, id))
	return <React.Fragment>{compensationProgram.name + ' (' + enrollment.code + ')'}</React.Fragment>
}

export default UserListingTable
