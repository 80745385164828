import { SvgHelper, FontFamilyPanel, ColorPickerPanel, RectangularBoxMarkerBase } from 'markerjs2'
import { colorSet } from './colors'

export class StartMarker extends RectangularBoxMarkerBase {
	/**
	 * String type name of the marker type.
	 *
	 * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
	 */
	static typeName = 'StartMarker'

	/**
	 * Marker type title (display name) used for accessibility and other attributes.
	 */
	static title = 'Start marker'
	/**
	 * SVG icon markup displayed on toolbar buttons.
	 */
	static icon =
		'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24px" height="24px"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M131.1 105.4c-20.1 8.6-30.8 21.8-33.9 39.4c-2.4 14.1-.7 23.2 2 29.4c2.8 6.3 7.9 12.4 16.7 18.6c19.2 13.4 48.3 22.1 84.9 32.5c1 .3 1.9 .6 2.9 .8c32.7 9.3 72 20.6 100.9 40.7c15.7 10.9 29.9 25.5 38.6 45.1c8.8 19.8 10.8 42 6.6 66.3c-7.3 42.5-35.3 71.7-71.8 87.3c-35.4 15.2-79.1 17.9-123.7 10.9l-.2 0 0 0c-24-3.9-62.7-17.1-87.6-25.6c-4.8-1.7-9.2-3.1-12.8-4.3c-16.8-5.6-25.8-23.7-20.3-40.5s23.7-25.8 40.5-20.3c4.9 1.6 10.2 3.4 15.9 5.4c25.4 8.6 56.4 19.2 74.4 22.1c36.8 5.7 67.5 2.5 88.5-6.5c20.1-8.6 30.8-21.8 33.9-39.4c2.4-14.1 .7-23.2-2-29.4c-2.8-6.3-7.9-12.4-16.7-18.6c-19.2-13.4-48.3-22.1-84.9-32.5c-1-.3-1.9-.6-2.9-.8c-32.7-9.3-72-20.6-100.9-40.7c-15.7-10.9-29.9-25.5-38.6-45.1c-8.8-19.8-10.8-42-6.6-66.3l31.5 5.5-31.5-5.5c7.3-42.5 35.3-71.7 71.8-87.3c35.4-15.2 79.1-17.9 123.7-10.9c13 2 52.4 9.6 66.6 13.4c17.1 4.5 27.2 22.1 22.7 39.2s-22.1 27.2-39.1 22.7c-11.2-3-48.2-10.2-60.1-12l4.9-31.5-4.9 31.5c-36.9-5.8-67.5-2.5-88.6 6.5z"/></svg>'

	/**
	 * Text color.
	 */
	color = 'transparent'
	/**
	 * Text's font family.
	 */
	fontFamily
	/**
	 * Padding inside of the marker's bounding box in percents.
	 */
	padding = 5

	/**
	 * Text color picker toolbox panel.
	 */
	colorPanel
	/**
	 * Text font family toolbox panel.
	 */
	fontFamilyPanel

	DEFAULT_TEXT = 'S'
	text = this.DEFAULT_TEXT
	/**
	 * Visual text element.
	 */
	textElement
	/**
	 * Text background rectangle.
	 */
	bgRectangle
	/**
	 * Div element for the text editor container.
	 */
	textEditDiv
	/**
	 * Editable text element.
	 */
	textEditor

	isMoved = false
	pointerDownPoint
	pointerDownTimestamp

	/**
	 * Creates a new marker.
	 *
	 * @param container - SVG container to hold marker's visual.
	 * @param overlayContainer - overlay HTML container to hold additional overlay elements while editing.
	 * @param settings - settings object containing default markers settings.
	 */
	constructor(container, overlayContainer, settings) {
		super(container, overlayContainer, settings)

		this.color = settings.defaultColor
		this.fontFamily = settings.defaultFontFamily

		this.defaultSize = { x: 75, y: 100 }

		this.setColor = this.setColor.bind(this)
		this.setFont = this.setFont.bind(this)
		this.renderText = this.renderText.bind(this)
		this.sizeText = this.sizeText.bind(this)
		this.textEditDivClicked = this.textEditDivClicked.bind(this)
		this.showTextEditor = this.showTextEditor.bind(this)
		this.setSize = this.setSize.bind(this)
		this.positionTextEditor = this.positionTextEditor.bind(this)

		this.colorPanel = new ColorPickerPanel('Color', colorSet, settings.defaultColor)
		this.colorPanel.onColorChanged = this.setColor

		this.fontFamilyPanel = new FontFamilyPanel('Font', settings.defaultFontFamilies, settings.defaultFontFamily)
		this.fontFamilyPanel.onFontChanged = this.setFont
	}

	/**
	 * Returns true if passed SVG element belongs to the marker. False otherwise.
	 *
	 * @param el - target element.
	 */
	ownsTarget(el) {
		if (super.ownsTarget(el) || el === this.visual || el === this.textElement || el === this.bgRectangle) {
			return true
		} else {
			let found = false
			this.textElement.childNodes.forEach((span) => {
				if (span === el) {
					found = true
				}
			})
			return found
		}
	}

	/**
	 * Creates text marker visual.
	 */
	createVisual() {
		this.visual = SvgHelper.createGroup()

		this.bgRectangle = SvgHelper.createRect(1, 1, [['fill', 'transparent']])
		this.visual.appendChild(this.bgRectangle)

		this.textElement = SvgHelper.createText([
			['fill', this.color],
			['font-family', this.fontFamily],
			['font-size', '16px'],
			['x', '0'],
			['y', '0']
		])
		this.textElement.transform.baseVal.appendItem(SvgHelper.createTransform()) // translate transorm
		this.textElement.transform.baseVal.appendItem(SvgHelper.createTransform()) // scale transorm

		this.visual.appendChild(this.textElement)

		this.addMarkerVisualToContainer(this.visual)
		this.renderText()
	}

	/**
	 * Handles pointer (mouse, touch, stylus, etc.) down event.
	 *
	 * @param point - event coordinates.
	 * @param target - direct event target element.
	 */
	pointerDown(point, target) {
		super.pointerDown(point, target)

		this.isMoved = false
		this.pointerDownPoint = point
		this.pointerDownTimestamp = Date.now()

		if (this.state === 'new') {
			this.createVisual()
			this.moveVisual(point)
			this._state = 'creating'
		}
	}

	renderText() {
		const LINE_SIZE = '1.2em'

		if (this.textElement) {
			while (this.textElement.lastChild) {
				this.textElement.removeChild(this.textElement.lastChild)
			}

			const lines = this.text.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/)
			lines.forEach((line) => {
				this.textElement.appendChild(
					SvgHelper.createTSpan(
						// workaround for swallowed empty lines
						line.trim() === '' ? ' ' : line.trim(),
						[
							['x', '0'],
							['dy', LINE_SIZE]
						]
					)
				)
			})

			setTimeout(this.sizeText, 10)
		}
	}

	getTextScale() {
		const textSize = this.textElement.getBBox()
		let scale = 1.0
		if (textSize.width > 0 && textSize.height > 0) {
			const xScale = (this.width * 1.0 - (this.width * this.padding * 2) / 100) / textSize.width
			const yScale = (this.height * 1.0 - (this.height * this.padding * 2) / 100) / textSize.height
			scale = Math.min(xScale, yScale)
		}
		return scale
	}

	getTextPosition(scale) {
		const textSize = this.textElement.getBBox()
		let x = 0
		let y = 0
		if (textSize.width > 0 && textSize.height > 0) {
			x = (this.width - textSize.width * scale) / 2
			y = this.height / 2 - (textSize.height * scale) / 2
		}
		return { x: x, y: y }
	}

	sizeText() {
		const textBBox = this.textElement.getBBox()
		const scale = this.getTextScale()
		const position = this.getTextPosition(scale)
		position.y -= textBBox.y * scale // workaround adjustment for text not being placed at y=0

		if (navigator.userAgent.indexOf('Edge/') > -1) {
			// workaround for legacy Edge as transforms don't work otherwise but this way it doesn't work in Safari
			this.textElement.style.transform = `translate(${position.x}px, ${position.y}px) scale(${scale}, ${scale})`
		} else {
			this.textElement.transform.baseVal.getItem(0).setTranslate(position.x, position.y)
			this.textElement.transform.baseVal.getItem(1).setScale(scale, scale)
		}
	}

	/**
	 * Handles marker manipulation (move, resize, rotate, etc.).
	 *
	 * @param point - event coordinates.
	 */
	manipulate(point) {
		super.manipulate(point)
		if (this.pointerDownPoint !== undefined) {
			this.isMoved = Math.abs(point.x - this.pointerDownPoint.x) > 5 || Math.abs(point.y - this.pointerDownPoint.y) > 5
		}
	}

	/**
	 * Resize marker based on current pointer coordinates and context.
	 * @param point
	 */
	resize(point) {
		super.resize(point)
		this.isMoved = true
		this.setSize()
		this.sizeText()
	}

	/**
	 * Sets size of marker elements after manipulation.
	 */
	setSize() {
		super.setSize()
		if (this.visual && this.bgRectangle) {
			SvgHelper.setAttributes(this.visual, [
				['width', this.width.toString()],
				['height', this.height.toString()]
			])
			SvgHelper.setAttributes(this.bgRectangle, [
				['width', this.width.toString()],
				['height', this.height.toString()]
			])
		}
	}

	/**
	 * Handles pointer (mouse, touch, stylus, etc.) up event.
	 *
	 * @param point - event coordinates.
	 */
	pointerUp(point) {
		const inState = this.state
		if (inState === 'creating') {
			//this._suppressMarkerCreateEvent = true
		}
		super.pointerUp(point)
		this.setSize()
		if (inState === 'creating' || (!this.isMoved && Date.now() - this.pointerDownTimestamp > 500)) {
			//this.showTextEditor();
			this.sizeText()
		}
		this.pointerDownPoint = undefined
	}

	showTextEditor() {
		this._state = 'edit'
		this.overlayContainer.innerHTML = ''

		this.textEditDiv = document.createElement('div')
		// textEditDiv.style.display = 'flex';
		this.textEditDiv.style.flexGrow = '2'
		//textEditDiv.style.backgroundColor = 'rgb(0,0,0,0.7)';
		this.textEditDiv.style.alignItems = 'center'
		this.textEditDiv.style.justifyContent = 'center'
		this.textEditDiv.style.pointerEvents = 'auto'
		this.textEditDiv.style.overflow = 'hidden'

		this.textEditor = document.createElement('div')
		this.textEditor.style.position = 'absolute'
		this.textEditor.style.fontFamily = this.fontFamily
		this.textEditor.style.lineHeight = '1em'
		this.textEditor.innerText = this.text
		this.textEditor.contentEditable = 'true'
		this.textEditor.style.color = this.color
		this.textEditor.style.whiteSpace = 'pre'
		this.positionTextEditor()
		this.textEditor.addEventListener('pointerup', (ev) => {
			ev.stopPropagation()
		})
		this.textEditor.addEventListener('input', () => {
			let fontSize = Number.parseFloat(this.textEditor.style.fontSize)
			while (this.textEditor.clientWidth >= Number.parseInt(this.textEditor.style.maxWidth) && fontSize > 0.9) {
				fontSize -= 0.1
				this.textEditor.style.fontSize = `${Math.max(fontSize, 0.9)}em`
			}
		})
		this.textEditor.addEventListener('keyup', (ev) => {
			ev.cancelBubble = true
		})
		this.textEditor.addEventListener('paste', (ev) => {
			if (ev.clipboardData) {
				// paste plain text
				const content = ev.clipboardData.getData('text')
				const selection = window.getSelection()
				if (!selection.rangeCount) return false
				selection.deleteFromDocument()
				selection.getRangeAt(0).insertNode(document.createTextNode(content))
				ev.preventDefault()
			}
		})

		this.textEditDiv.addEventListener('pointerup', () => {
			this.textEditDivClicked(this.textEditor.innerText)
		})
		this.textEditDiv.appendChild(this.textEditor)
		this.overlayContainer.appendChild(this.textEditDiv)

		this.hideVisual()

		this.textEditor.focus()
		document.execCommand('selectAll')
	}

	positionTextEditor() {
		if (this.state === 'edit') {
			if (this.textEditor === undefined) {
				this.showTextEditor()
			} else {
				this.textElement.style.display = ''
				const textScale = this.getTextScale()
				// const textPosition = this.getTextPosition(textScale);
				const rPosition = this.rotatePoint({
					x: this.left + this.width / 2,
					y: this.top + this.height / 2
				})
				const textSize = this.textElement.getBBox()
				const rWH = {
					x: textSize.width * textScale,
					y: textSize.height * textScale
				}
				rPosition.x -= rWH.x / 2
				rPosition.y -= rWH.y / 2

				this.textEditor.style.top = `${rPosition.y}px`
				this.textEditor.style.left = `${rPosition.x}px`
				this.textEditor.style.maxWidth = `${this.overlayContainer.offsetWidth - rPosition.x}px`
				this.textEditor.style.fontSize = `${Math.max(16 * textScale, 12)}px`
				this.textElement.style.display = 'none'
			}
		}
	}

	textEditDivClicked(text) {
		this.text = text.trim()
		this.overlayContainer.innerHTML = ''
		this.renderText()
		this.showVisual()
		if (this._suppressMarkerCreateEvent) {
			this._suppressMarkerCreateEvent = false
			if (this.onMarkerCreated) {
				this.onMarkerCreated(this)
			}
		}
		this.stateChanged()
	}

	select() {
		super.select()
		if (this.state === 'edit') {
			this.textEditDivClicked(this.textEditor.innerText)
		}
	}

	/**
	 * Deselects this marker, renders text (if necessary), and hides selected marker UI.
	 */
	deselect() {
		if (this.state === 'edit') {
			this.textEditDivClicked(this.textEditor.innerText)
		}
		super.deselect()
	}

	/**
	 * Opens text editor on double-click.
	 * @param point
	 * @param target
	 */
	dblClick(point, target) {
		super.dblClick(point, target)

		this.showTextEditor()
	}

	/**
	 * Sets text color.
	 * @param color - new text color.
	 */
	setColor(color) {
		if (this.textElement) {
			SvgHelper.setAttributes(this.textElement, [['fill', color]])
		}
		this.color = color
		if (this.textEditor) {
			this.textEditor.style.color = this.color
		}
		this.colorChanged(color)
	}

	/**
	 * Sets font family.
	 * @param font - new font family.
	 */
	setFont(font) {
		if (this.textElement) {
			SvgHelper.setAttributes(this.textElement, [['font-family', font]])
		}
		this.fontFamily = font
		if (this.textEditor) {
			this.textEditor.style.fontFamily = this.fontFamily
		}
		this.renderText()
		this.stateChanged()
	}

	/**
	 * Hides marker visual.
	 */
	hideVisual() {
		console.log('hiding visual')
		this.textElement.style.display = 'none'
		this.hideControlBox()
	}
	/**
	 * Shows marker visual.
	 */
	showVisual() {
		console.log('showing visual')
		if (this.state === 'edit') {
			this._state = 'select'
		}
		this.textElement.style.display = ''
		this.showControlBox()
	}

	/**
	 * Returns the list of toolbox panels for this marker type.
	 */
	get toolboxPanels() {
		return [this.colorPanel, this.fontFamilyPanel]
	}

	/**
	 * Returns current marker state that can be restored in the future.
	 */
	getState() {
		const result = Object.assign(
			{
				color: this.color,
				fontFamily: this.fontFamily,
				padding: this.padding,
				text: this.text
			},
			super.getState()
		)
		result.typeName = StartMarker.typeName

		return result
	}

	/**
	 * Restores previously saved marker state.
	 *
	 * @param state - previously saved state.
	 */
	restoreState(state) {
		const textState = state
		this.color = textState.color
		this.fontFamily = textState.fontFamily
		this.padding = textState.padding
		this.text = textState.text

		this.createVisual()
		super.restoreState(state)
		this.setSize()
	}

	/**
	 * Scales marker. Used after the image resize.
	 *
	 * @param scaleX - horizontal scale
	 * @param scaleY - vertical scale
	 */
	scale(scaleX, scaleY) {
		super.scale(scaleX, scaleY)

		this.setSize()
		this.sizeText()
		this.positionTextEditor()
	}
}
