import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchOpportunityStageTypes = createAsyncThunk('opportunityStageTypes/fetch', (includeArchived = false) => {
	return callApi({
		endpoint: `opportunities/stage-types`,
		params: { includeArchived }
	}).then((response) => {
		return response.data
	})
})

export const addOpportunityStageType = createAsyncThunk('opportunityStageTypes/add', ({ data }) => {
	return callApi({
		endpoint: 'opportunities/stages',
		method: 'POST',
		data
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityStageType = createAsyncThunk('opportunityStageTypes/update', ({ data, id }) => {
	return callApi({
		endpoint: `opportunities/stages/${id}`,
		method: 'PUT',
		data
	}).then((response) => {
		return response.data
	})
})

/* export const addOpportunity = createAsyncThunk('opportunitys/add', ({ values }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunitys`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})
 */
