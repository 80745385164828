import React, { PureComponent, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, Form, H1, H3, Hr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Textarea, Label } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { Formik, Form as FormikForm } from 'formik'
import { Checkbox } from '~/components/bootstrap/components/Form'
import * as Yup from 'yup'
import { callApi } from '~/services/api'

const TermForm = ({ setIsOpen, currentValues }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const descriptionRef = useRef(null)
	const [descriptionEditable, setDescriptionEditable] = useState(currentValues === undefined)

	useEffect(() => {
		if (currentValues !== undefined) {
			callApi({
				endpoint: `quotes/terms/editable/${currentValues.id}`,
				method: 'GET'
			}).then((response) => {
				setDescriptionEditable(response.data)
			})
		}
		if (descriptionRef.current) descriptionRef.current.focus()
	}, [descriptionRef])

	const onSubmit = (values) => {
		if (values.id) {
			dispatch(entityThunks.updateTerm({ values, termId: values.id })).then((response) => {
				setIsOpen(false)
			})
		} else {
			dispatch(entityThunks.addTerm({ values })).then((response) => {
				setIsOpen(false)
			})
		}
	}

	const defaultValues = {
		description: '',
		includeByDefault: false,
		archived: false
	}

	const initialValues = {
		...defaultValues,
		...currentValues
	}

	const validationSchema = Yup.object({
		description: Yup.string().required('Required'),
		includeByDefault: Yup.bool().required('Required'),
		archived: Yup.bool().required('Required')
	})

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				return (
					<Form tag={FormikForm}>
						<Row className="align-items-xxxs-center">
							<Col xxxs>
								<Checkbox name="includeByDefault" />
								<Label>Include By Default</Label>
							</Col>
							<Col xxxs>
								<Checkbox name="archived" />
								<Label>Archive</Label>
							</Col>
						</Row>
						<Row>
							<Col xxxs>
								<Label>Description</Label>
								<Textarea name="description" innerRef={descriptionRef} disabled={!descriptionEditable} />
							</Col>
						</Row>
						<Hr />
						<Row className="justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit">Save</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default TermForm
