import React, { Fragment, useEffect, useState } from 'react'
import { H4, Hr, Tbody, Tr, Th, Td, Card, CardHeader, H5, Row, CardBlock } from '@bootstrap-styled/v4'
import { Table, Col, ActionButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import ContactForm from './ContactForm'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { callApi } from '~/services/api'

const ContactCard = ({ id, ExtraHeaderButton }) => {
	const { selectors, entityThunks, dispatch, userNotAdminHasRole } = useLumenox()
	const [place, setPlace] = useState(null)
	const contact = useSelector((state) => selectors.selectContactById(state, id))
	const installer = userNotAdminHasRole('installer')

	if (!contact) {
		return null
	}

	useEffect(() => {
		if (contact.address && contact.address.placeId) {
			var request = {
				placeId: contact.address.placeId
				//fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
			}
			//may switch this over to be stored in state eventually
			callApi({
				endpoint: `google/place/${contact.address.placeId}`
			}).then((response) => {
				console.log('place response:', response)
				setPlace(response.data.data)
			})
			/* const service = new window.google.maps.places.PlacesService(document.createElement('div'))
			service.getDetails(request, (placeResult) => {
				setPlace(placeResult)
			}) */
		}
	}, [contact])

	const { id: contactId, updatedAt, createdAt, __v, ...contactFormValues } = contact

	const handleUpdateContactSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.updateContact({ id, values })).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	return (
		<Fragment>
			<Hr />
			<Card>
				<CardHeader>
					<Row>
						<Col xxxs>
							<H5 className="mb-xxxs-0">
								{contact.name.first} {contact.name.last}
							</H5>
						</Col>
						{!installer && (
							<Col xxxs="auto">
								<ActionButton color="info" size="sm" button="Edit" title="Edit Contact">
									{(closeModal) => (
										<ContactForm onSuccess={() => closeModal()} onSubmit={handleUpdateContactSubmit} initialValues={contactFormValues} />
									)}
								</ActionButton>
							</Col>
						)}
						{ExtraHeaderButton && (
							<Col xxxs="auto">
								<ExtraHeaderButton />
							</Col>
						)}
					</Row>
				</CardHeader>

				<Table className="mb-xxxs-0">
					<Tbody>
						{contact.email && (
							<Tr>
								<Th>Email:</Th>
								<Td>{contact.email}</Td>
							</Tr>
						)}
						{contact.phoneNumbers && (
							<Tr>
								<Th>Phone Number{contact.phoneNumbers.length > 1 ? 's' : ''}:</Th>
								<Td>
									{contact.phoneNumbers.map((phoneNumber, i) => (
										<Fragment key={i}>
											{phoneNumber.number}
											{phoneNumber.extension && <Fragment>x{phoneNumber.extension}</Fragment>}
											<br />
										</Fragment>
									))}
								</Td>
							</Tr>
						)}
						{contact.address && (
							<Tr>
								<Th>Address:</Th>
								<Td>
									{!place ? (
										<Fragment>
											{contact.address.lines.map((line, i) => (
												<Fragment key={i}>
													{line}
													<br />
												</Fragment>
											))}
											{contact.address.city}, {contact.address.state}, {contact.address.country} {contact.address.postalCode}
										</Fragment>
									) : (
										<Fragment>{place.formatted_address}</Fragment>
									)}
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
				{place?.geometry?.location && (
					<GoogleMap
						mapContainerStyle={{
							height: '400px',
							width: '100%'
						}}
						zoom={16}
						center={{
							lat: place.geometry.location.lat,
							lng: place.geometry.location.lng
						}}
						tilt={0}
						options={{
							mapTypeId: 'hybrid',
							styles: [
								{
									featureType: 'poi',
									elementType: 'all',
									stylers: [
										{
											visibility: 'off'
										}
									]
								}
							]
						}}
					>
						<Marker position={{ lat: place.geometry.location.lat, lng: place.geometry.location.lng }} />
					</GoogleMap>
				)}
			</Card>
		</Fragment>
	)
}

export default ContactCard
