import React, { PureComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row, Form, FormGroup, H5, Hr, H6, Input as BsInput, Label } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray, DatePicker, Select } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import RichTextEditor from 'react-rte'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FollowUpForm = ({ initialValues, onSubmit, onSuccess }) => {
	const { entityThunks, dispatch } = useLumenox()
	if (initialValues.createdAt === null) initialValues.createdAt = new Date() //so that the time is set to when you open the modal, not when you open the lead page
	//const [ value, setValue ] = useState(RichTextEditor.createValueFromString(initialValues.details, 'markdown'))
	const initialValue = RichTextEditor.createValueFromString(initialValues.details, 'markdown') //need to do it like this for some reason
	const [value, setValue] = useState(initialValue)

	const validationSchema = Yup.object().shape({
		details: Yup.string().required('Required')
	})

	const removeWhitespaceAfterBold = (s) => {
		let opened = false
		for (let i = 0; i < s.length - 1; i++) {
			if (!opened) {
				if (s.charAt(i) === '*' && s.charAt(i + 1) == '*') {
					opened = true
				}
			} else {
				if (s.charAt(i) === '*' && s.charAt(i + 1) == '*') {
					if (s.charAt(i - 1) === ' ') {
						//move space to after **
						console.log('doing something!')
						s = s.slice(0, i - 1) + '** ' + s.slice(i + 2)
					}
					opened = false
				}
			}
		}
		return s
	}

	const handleSubmit = (values, helpers) => {
		helpers.setSubmitting(true)
		values.details = removeWhitespaceAfterBold(values.details)
		onSubmit(values, helpers, (successArgs) => {
			helpers.setSubmitting(false)
			if (onSuccess) onSuccess(successArgs)
		})
	}

	const handleOnChange = (value, setFieldValue) => {
		setValue(value)
		setFieldValue('details', value.toString('markdown'))
	}

	const toolbarConfig = {
		// Optionally specify the groups to display (displayed in the order listed).
		display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
		INLINE_STYLE_BUTTONS: [
			{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
			{ label: 'Italic', style: 'ITALIC' },
			{ label: 'Underline', style: 'UNDERLINE' }
		],
		BLOCK_TYPE_DROPDOWN: [
			{ label: 'Normal', style: 'unstyled' },
			{ label: 'Heading Large', style: 'header-one' },
			{ label: 'Heading Medium', style: 'header-two' },
			{ label: 'Heading Small', style: 'header-three' }
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: 'UL', style: 'unordered-list-item' },
			{ label: 'OL', style: 'ordered-list-item' }
		]
	}

	const typeOptions = [
		{
			label: 'Call',
			value: 'Call'
		},
		{
			label: 'Text',
			value: 'Text'
		},
		{
			label: 'Email',
			value: 'Email'
		},
		{
			label: 'In Person',
			value: 'In Person'
		}
	]

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log('values', values)
				return (
					<Form tag={FormikForm}>
						<Row>
							<Col xxxs={12} md={6}>
								<FormGroup>
									<Label>Date:</Label>
									<DatePicker name="createdAt" />
								</FormGroup>
							</Col>
							<Col xxxs={12} md={6}>
								<FormGroup>
									<Label>Type:</Label>
									<Select name="type" options={typeOptions} />
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label>Details:</Label>
							<RichTextEditor
								toolbarConfig={toolbarConfig}
								value={value}
								onChange={(value) => handleOnChange(value, setFieldValue)}
								editorClassName="note-form"
								autoFocus
							/>
						</FormGroup>
						<Row className="align-items-xxxs-center">
							<Col xxxs>
								<Button type="submit" color="success" block submitting={isSubmitting}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default FollowUpForm
