import React, { PureComponent } from 'react'
import styled, { withTheme } from 'styled-components'
import BootstrapCol from './source/Col'

class Col extends PureComponent {
	render() {
		const { theme, children, ...rest } = this.props
		return (
			<StyledCol theme={theme} widths={theme['$breakpoints']} {...rest}>
				{children}
			</StyledCol>
		)
	}
}

const StyledCol = styled(({ theme, children, ...rest }) => (
	<BootstrapCol widths={theme['$breakpoints']} {...rest}>
		{children}
	</BootstrapCol>
))``

export default withTheme(Col)
