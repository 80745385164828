import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Row, Hr, H4, Card, CardHeader, CardBlock, Collapse, Small, Strong } from '@bootstrap-styled/v4'
import { Col, Button, ActionButton, Badge } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import {
	faArrowRightArrowLeft,
	faArrowTurnDownLeft,
	faBinary,
	faBolt,
	faFlagCheckered,
	faImage,
	faImages,
	faLightsHoliday,
	faMeterBolt,
	faPlay,
	faReel,
	faRoute
} from '@fortawesome/pro-solid-svg-icons'
import { useRouteMatch } from 'react-router'
import Ul from '@bootstrap-styled/v4/lib/Ul'
import Li from '@bootstrap-styled/v4/lib/Li'
import * as markerjs2 from 'markerjs2'
import { PenMarker } from '~/markerjs/PenMarker'
import { StartMarker } from '~/markerjs/StartMarker'
import { EndMarker } from '~/markerjs/EndMarker'

const InstallationDiagram = (props) => {
	const [wiringDiagram, setWiringDiagram] = useState({})
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id/installation-diagram/:quoteId',
		strict: false
	})

	useEffect(() => {
		dispatch(entityThunks.getWiringDiagram(params.quoteId)).then((result) => {
			setWiringDiagram(result.payload)
		})
	}, [])

	if (Object.entries(wiringDiagram).length === 0) return null

	return (
		<Row className="mb-xxxs-3">
			<Col xxxs={12} className="order-lg-1">
				<H4>Branches:</H4>
				<Runs wiringDiagram={wiringDiagram} />
			</Col>
		</Row>
	)
}

const Runs = ({ wiringDiagram }) => {
	const runs = []

	makeRuns(wiringDiagram, runs, '', 'JB1', true)

	function makeRuns(branch, runsElements, parentLabel, jb, firstCall = false, isTopLevel = false) {
		let numJunctionBoxes = 0
		const junctionBoxNames = []

		if (firstCall) {
			branch.branches.forEach((b) => {
				if (!junctionBoxNames.includes(b.run.start.name)) {
					junctionBoxNames.push(b.run.start.name)
				}
			})
			numJunctionBoxes = junctionBoxNames.length
		}
		if (numJunctionBoxes > 1) {
			//first call and multiple junction boxes
			const junctionBoxes = []
			junctionBoxNames.forEach((name) => {
				junctionBoxes.push({ branches: branch.branches.filter((b) => b.run.start.name === name), run: { type: { name: 'JB' } }, length: 0 })
			})
			//now we have array of objects for each junction box who's branches key contains an array of the subbranches of that junction box
			junctionBoxes.forEach((junctionBox, i) => {
				const junctionBoxChildren = []
				if (junctionBox.branches.length > 1) {
					//we need to do a runStart for each branch off of the junctionBox
					junctionBox.branches.forEach((subBranch, j) => {
						const runStartChildren = []
						const runStartLabel = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.at(j)
						makeRuns(subBranch, runStartChildren, runStartLabel, `JB${i + 1}`)
						junctionBoxChildren.push(
							<RunStart key={Math.random()} label={runStartLabel} branch={subBranch} junctionBox={`JB${i + 1}`}>
								{runStartChildren}
							</RunStart>
						)
					})
				} else {
					//there is only one run off of this junction box so it doesn't need a runStart
					junctionBox.branches.forEach((subBranch) => {
						makeRuns(subBranch, junctionBoxChildren, 'A', `JB${i + 1}`)
					})
				}

				runsElements.push(
					<JunctionBox key={i} name={junctionBoxNames[i]} branch={junctionBox} isTopLevel>
						{junctionBoxChildren}
					</JunctionBox>
				)
			})
		} else {
			//could be first call or not, if it is we only have one junction box which could have any amount of runs off of it
			if (branch.branches.length > 1) {
				if (firstCall) {
					//we put them in as multiple runs, similar to junctionBox runs
					branch.branches.forEach((runBranch, i) => {
						//for each of these we want to make a RunStart since this branch will always be a connector and there will always be at least one pixel string after it
						const runStartChildren = []
						const runBranchLabel = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.at(i)
						if (runBranch.branches.length > 1) {
							//it splits after this connector, so this will be a runSplit and we need to make its children
							const runSplitChildren = []
							runBranch.branches.forEach((splitBranch, i) => {
								//add this to children
								if (splitBranch.branches.length === 1) {
									//it was > 0 but now === 1 cuz if its 0 we want the else part but if its > 1 then it will actually be a split and there's no point in having that in a runStart, at least I'm pretty sure
									const runStartChildren = []
									const runStartLabel = runBranchLabel + '-' + (runSplitChildren.length + 1)
									makeRuns(splitBranch, runStartChildren, runStartLabel, 'JB1')
									runSplitChildren.push(
										<RunStart key={Math.random()} branch={splitBranch} label={runStartLabel} junctionBox="JB1">
											{runStartChildren}
										</RunStart>
									)
								} else {
									makeRuns(splitBranch, runSplitChildren, runBranchLabel, 'JB1')
								}
							})
							//make Run(Split) with its children
							runsElements.push(
								<Run key={Math.random()} label={runBranchLabel} branch={runBranch} junctionBox="JB1" isTopLevel>
									{runSplitChildren}
								</Run>
							)
						} else {
							//this is just a normal run so add it and then make the next run
							runStartChildren.push(<Run label={runBranchLabel + '-' + (runStartChildren.length + 1)} branch={runBranch} junctionBox="JB1" />)
							makeRuns(runBranch.branches[0], runStartChildren, runBranchLabel, 'JB1')
							//we only want RunStart if we dont already have a runsplit
							runsElements.push(
								<RunStart key={Math.random()} label={runBranchLabel} branch={runBranch} junctionBox="JB1" isTopLevel>
									{runStartChildren}
								</RunStart>
							)
						}
					})
				} else {
					//branch.branches multiple elements, we want to add one RunSplit to hold each run
					const runSplitChildren = []
					const runSplitLabel = (parentLabel === '' ? 'A-' : parentLabel + '-') + (runsElements.length + 1)
					branch.branches.forEach((splitBranch, i) => {
						//add this to children

						if (splitBranch.branches.length === 1) {
							//look at note in similar code above to see why its === 1 not > 0 (I think this is right)
							const runStartChildren = []
							const runStartLabel = runSplitLabel + '-' + (runSplitChildren.length + 1)
							makeRuns(splitBranch, runStartChildren, runStartLabel, jb)
							runSplitChildren.push(
								<RunStart key={Math.random()} branch={splitBranch} label={runStartLabel} junctionBox={jb}>
									{runStartChildren}
								</RunStart>
							)
						} else {
							makeRuns(splitBranch, runSplitChildren, runSplitLabel, jb)
						}
					})
					//make Run(Split) with its children
					runsElements.push(
						<Run key={Math.random()} label={runSplitLabel} branch={branch} junctionBox={jb} isTopLevel={isTopLevel}>
							{runSplitChildren}
						</Run>
					)
				}
			} else {
				//just one run
				if (!firstCall) {
					runsElements.push(
						<Run
							key={Math.random()}
							label={(parentLabel === '' ? 'A-' : parentLabel + '-') + (runsElements.length + 1)}
							branch={branch}
							junctionBox={jb}
							isTopLevel={isTopLevel}
						/>
					)
				}
				if (branch.branches.length > 0) {
					makeRuns(branch.branches[0], runsElements, parentLabel, jb, false, firstCall)
				}
			}
		}
	}

	const printComponent = (component, indents = 0, string = '') => {
		for (let i = 0; i < indents; i++) string += '\t'
		string += `<${component.type.name} ${component.props.label}${component.props.children ? '' : '/'}>\n`
		if (component.props.children) {
			component.props.children.forEach((child) => {
				string = printComponent(child, indents + 1, string)
			})
		}
		if (component.props.children && component.props.children.length !== 0) {
			for (let i = 0; i < indents; i++) string += '\t'
			string += `</${component.type.name} ${component.props.label}>\n`
		}
		return string
	}

	const printComponents = (componentArray) => {
		let string = ''
		componentArray.forEach((component) => {
			string += printComponent(component)
		})
		return string
	}

	//console.log('runs:\n' + printComponents(runs))

	return <React.Fragment>{runs}</React.Fragment>
}

const JunctionBox = ({ children, name, branch }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Card key={name} className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs="auto">
						<FontAwesomeIcon
							icon={isOpen ? faChevronUp : faChevronDown}
							fixedWidth
							onClick={() => setIsOpen(!isOpen)}
							className="pointer"
							size="lg"
						/>
					</Col>
					<Col xxxs>
						<Row className="justify-content-xxxs-center">
							<Col xxxs="auto">
								<Strong>{name}</Strong>
							</Col>
						</Row>
						<RunSummary branch={branch} />
					</Col>
				</Row>
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<CardBlock>{children}</CardBlock>
			</Collapse>
		</Card>
	)
}

const getJbColor = (jb) => {
	switch (jb) {
		case 'JB1':
			return '#FF7700'
		case 'JB2':
			return '#0099FF'
		case 'JB3':
			return '#FF0099'
		case 'JB4':
			return '#77DD00'
		default:
			return '#000000'
	}
}

const RunStart = ({ children, label, branch, junctionBox, isTopLevel }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Card key={label} className="mb-xxxs-3">
			<CardHeader onClick={() => setIsOpen(!isOpen)} className="pointer">
				<Row className="align-items-xxxs-center">
					<Col xxxs="auto" className="pr-xxxs-0">
						<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} fixedWidth size="lg" />
					</Col>
					<Col xxxs="auto" className="pl-xxxs-1">
						<Strong>{label}</Strong>
					</Col>
					<Col xxxs className="text-xxxs-right">
						<Badge style={{ backgroundColor: getJbColor(junctionBox) }}>{junctionBox}</Badge>
					</Col>
				</Row>

				{isTopLevel && (
					<Fragment>
						<Hr className="my-xxxs-1" />
						<RunSummary branch={branch} isTopLevel={isTopLevel} />
					</Fragment>
				)}
			</CardHeader>

			<Collapse isOpen={isOpen}>
				<CardBlock>{children}</CardBlock>
			</Collapse>
		</Card>
	)
}

const Run = ({ label, branch, children, junctionBox, isTopLevel }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Card key={label} className="mb-xxxs-3" style={{ borderColor: branch.run.type.name === 'Connector' ? '#7e201b' : '#2f6a2f' }}>
			<CardHeader onClick={() => setIsOpen(!isOpen)} className="pointer">
				<Row noGutters className="align-items-xxxs-center">
					<Col xxxs="auto">
						<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} fixedWidth size="lg" />
					</Col>
					<Col xxxs className="pl-xxxs-1">
						<Badge color={branch.run.type.name === 'Connector' ? 'danger' : 'success'} className="mr-xxxs-2">
							<FontAwesomeIcon icon={branch.run.type.name === 'Connector' ? faRoute : faLightsHoliday} fixedWidth />
						</Badge>
						<Strong>{label}</Strong>
					</Col>
					<Col xxxs="auto" className=" text-xxxs-right">
						{/* <Badge style={{ backgroundColor: getJbColor(junctionBox) }}>{junctionBox}</Badge> */}
						{branch.images.length > 0 && (
							<ActionButton
								color="info"
								title={`View Image${branch.images && branch.images.length > 1 ? 's' : ''}`}
								icon={branch.images.length > 1 ? faImages : faImage}
								modalSize="xl"
								size="sm"
								className="ml-xxxs-3"
							>
								{(closeModal) => (
									<Fragment>
										{branch.images.map((image, i) => (
											<RunImage key={i} image={image} index={i} />
										))}
										<Row className="justify-content-xxxs-end">
											<Col xxxs="auto">
												<Button color="dark" onClick={() => closeModal(false)}>
													Close
												</Button>
											</Col>
										</Row>
									</Fragment>
								)}
							</ActionButton>
						)}
					</Col>
				</Row>
				<Hr className="my-xxxs-1" />
				<Row className="align-items-xxxs-center" noGutters>
					<Col xxxs>
						<Badge>
							{/* <FontAwesomeIcon icon={faPlay} fixedWidth /> */} <Small>START</Small>
						</Badge>
					</Col>

					<Col xxxs className="text-xxxs-right">
						<Badge>
							{/* <FontAwesomeIcon icon={faFlagCheckered} fixedWidth className="mr-xxxs-1" /> */}
							<Small>END</Small>
						</Badge>
					</Col>
				</Row>
				<Row className="align-items-xxxs-center" noGutters>
					<Col xxxs>
						<Small>
							<Strong>{branch.run.start.name}</Strong>
						</Small>
					</Col>
					<Col xxxs="auto">
						<FontAwesomeIcon icon={faArrowRightLong} fixedWidth className="mx-xxxs-2" />
					</Col>
					<Col xxxs className="text-xxxs-right">
						<Small>
							<Strong>{branch.run.end.name}</Strong>
						</Small>
					</Col>
				</Row>
				<Hr className="my-xxxs-1" />
				<Row className="align-items-xxxs-center">
					<Col xxxs="auto" className="">
						<Badge color="info">{branch.length}ft</Badge>
					</Col>
					<Col xxxs className="text-xxxs-right">
						{branch.powerWires && branch.run.type.name === 'Connector' && (
							<Badge color="primary" className="ml-xxxs-1 pr-xxxs-2">
								<FontAwesomeIcon icon={faBolt} fixedWidth />
								{branch.powerWires.length}
							</Badge>
						)}
						{branch.dataWires && (
							<Badge color="orange" className="ml-xxxs-1 pr-xxxs-2">
								<FontAwesomeIcon icon={faBinary} fixedWidth className="mr-xxxs-1" />
								{branch.dataWires.length}
							</Badge>
						)}
						{branch.pixelsToCut && (
							<Badge color="success" className="ml-xxxs-1 pr-xxxs-2">
								<FontAwesomeIcon icon={faLightsHoliday} fixedWidth className="mr-xxxs-1" />
								{branch.pixelsToCut}
							</Badge>
						)}
						{branch.loop && (
							<Badge color="warning" className="ml-xxxs-1">
								<FontAwesomeIcon icon={faArrowRightArrowLeft} fixedWidth className="mr-xxxs-1" />
							</Badge>
						)}
						{/* {branch.twoDataLines && (
							<Badge color="warning" className="ml-xxxs-1">
								2 Data Lines
							</Badge>
						)} */}
					</Col>
				</Row>
				{isTopLevel && (
					<React.Fragment>
						<Hr />
						<RunSummary branch={branch} />
					</React.Fragment>
				)}
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<CardBlock>
					<Segments segments={branch.segments} />
					{children && children}
				</CardBlock>
			</Collapse>
		</Card>
	)
}

const RunSummary = ({ branch }) => {
	const calculateTotalLength = (branch) => {
		let total = branch.length
		if (branch.branches.length > 0) {
			total += branch.branches.reduce((t, subBranch) => {
				return (t += calculateTotalLength(subBranch))
			}, 0)
		}
		return total
	}

	const calculateTotalPixels = (branch) => {
		let total = branch.pixelsToCut ? branch.pixelsToCut : 0
		if (branch.branches.length > 0) {
			total += branch.branches.reduce((t, subBranch) => {
				return (t += calculateTotalPixels(subBranch))
			}, 0)
		}
		return total
	}

	const calculateTotalAmperage = (branch) => {
		let total = branch.amperage ? branch.amperage : 0
		if (branch.branches.length > 0) {
			total += branch.branches.reduce((t, subBranch) => {
				return t + calculateTotalAmperage(subBranch)
			}, 0)
		}
		return total
	}

	let pixelRuns = 0
	let connectorRuns = 0

	const getRunCounts = (branch) => {
		if (branch.run.type.name === 'Pixel String') pixelRuns++
		else if (branch.run.type.name === 'Connector') connectorRuns++
		branch.branches.forEach((subBranch) => getRunCounts(subBranch))
	}

	getRunCounts(branch)

	return (
		<Fragment>
			<Row>
				<Col xxxs="auto">
					<Badge style={{ backgroundColor: '#6282f5' }}>
						<FontAwesomeIcon icon={faLightsHoliday} fixedWidth className="mr-xxxs-1" />
						{pixelRuns} Pixel Run{pixelRuns > 1 ? 's' : ''}
					</Badge>
				</Col>
				<Col xxxs className="text-xxxs-right">
					<Badge style={{ backgroundColor: '#f56262' }}>
						<FontAwesomeIcon icon={faRoute} fixedWidth className="mr-xxxs-1" />
						{connectorRuns} Connector Run{connectorRuns > 1 ? 's' : ''}
					</Badge>
				</Col>
			</Row>
			<Hr className="my-xxxs-1" />
			<Row className="justify-content-xxxs-center">
				<Col xxxs="auto">
					<Badge color="info">{calculateTotalLength(branch)}ft</Badge>
				</Col>
				<Col xxxs className="text-xxxs-right">
					<Badge color="success" className="mr-xxxs-1">
						<FontAwesomeIcon icon={faLightsHoliday} fixedWidth className="mr-xxxs-1" />
						{calculateTotalPixels(branch)}
					</Badge>
					<Badge style={{ backgroundColor: '#bf04a6' }}>
						<FontAwesomeIcon icon={faMeterBolt} fixedWidth className="mr-xxxs-1" />
						{calculateTotalAmperage(branch).toFixed(2)}A
					</Badge>
				</Col>
			</Row>
		</Fragment>
	)
}

const Segments = ({ segments }) => {
	//can make this look much better later
	const text = segments.reduce((t, segment, i) => {
		return t + segment.length + (i !== segments.length - 1 ? 'ft, ' : 'ft')
	}, 'Segments: ')

	return <p>{text}</p>
}

const RunImage = ({ image, index }) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [img, setImg] = useState(image)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (img.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [PenMarker, StartMarker, EndMarker, markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setImg({
					...img,
					annotation: state
				})
				setShouldRender(true)
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		if (img.annotation) {
			markerArea.current.restoreState(img.annotation)
		}

		setTimeout(() => {
			markerArea.current.render().then((dataUrl) => {
				imageRef.current.src = dataUrl
				markerArea.current.close()
			})
		}, 500)
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/quotes/${image.url}?c=${new Date()}`
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<RunImg src={src} ref={imageRef} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const RunImg = React.forwardRef((props, ref) => (
	<img crossOrigin="anonymous" ref={ref} {...props} style={{ maxWidth: '100%', width: '125vh', imageOrientation: 'none' }} />
))

export default InstallationDiagram
