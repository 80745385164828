import React from 'react'
import { useSelector } from 'react-redux'
import { CardHeader, Row } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInboxIn, faInboxOut } from '@fortawesome/pro-duotone-svg-icons'

const SMS = ({ id, opportunityId, renderTimestamp }) => {
	const { selectors, theme } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	const openPhoneUser = useSelector((state) => selectors.selectUserByOpenPhoneId(state, activity.createdBy))

	let icon
	let personToShow
	const phoneNumberName = activity.phoneNumber ? activity.phoneNumber.name : 'Unknown'
	if (activity.direction === 'incoming') {
		icon = faInboxIn
		personToShow = phoneNumberName
	} else {
		icon = faInboxOut
		personToShow = `${phoneNumberName}${openPhoneUser ? ` - (${openPhoneUser.name})` : ''}`
	}

	return (
		<CardHeader>
			<Row className="align-items-xxxs-center">
				<Col xxxs="auto">
					<FontAwesomeIcon icon={icon} color={theme['$brand-success']} fixedWidth />
				</Col>
				<Col xxxs>{personToShow}</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default SMS
