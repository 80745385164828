import * as Yup from 'yup'

Yup.addMethod(Yup.object, 'optional', function (isOptional = true, defaultValue = undefined) {
	return (
		this.transform(function (value) {
			// If false is passed, skip the transform
			if (!isOptional) return value

			// If any child property has a value, skip the transform
			if (
				value &&
				Object.values(value).some((v) => !(v === null || v === undefined || v === '' || (v.constructor === Array && (v.length === 0 || v[0] === ''))))
			) {
				return value
			}

			return defaultValue
		})
			// Override `Yup.object` default
			.default(defaultValue)
	)
})

Yup.addMethod(Yup.string, 'optional', function (isOptional = true, defaultValue = undefined) {
	return (
		this.transform(function (value) {
			// If false is passed, skip the transform
			if (!isOptional) return value

			// If any child property has a value, skip the transform
			if (value && !(value === null || value === '')) {
				return value
			}

			return defaultValue
		})
			// Override `Yup.object` default
			.default(defaultValue)
	)
})

Yup.addMethod(Yup.array, 'optional', function (isOptional = true, defaultValue = undefined) {
	return (
		this.transform(function (value) {
			// If false is passed, skip the transform
			if (!isOptional) return value

			// If any child property has a value, skip the transform
			if (value && value.length !== 0) {
				return value
			}

			return defaultValue
		})
			// Override `Yup.object` default
			.default(defaultValue)
	)
})
