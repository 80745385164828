import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	H1,
	Row,
	Hr,
	H3,
	Card,
	CardBlock,
	Badge,
	CardHeader,
	H5,
	Thead,
	Tr,
	Th,
	Td,
	Tbody,
	ButtonGroup,
	Form,
	Label,
	A,
	H6,
	H4,
	ModalHeader,
	ModalBody,
	P
} from '@bootstrap-styled/v4'
import { Button, Col, Table, ConfirmationButton, ActionButton, CenteredModal } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import axios from 'axios'
import { callApi } from '~/services/api'
import { CollapsableCard } from '~/components/common/CollapsableCard'
import { format } from 'date-fns'
import { Formik, Form as FormikForm } from 'formik'
import { AsyncSelect, Select, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { faCircleInfo, faEdit, faImages, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import ImageGallery from 'react-image-gallery'
import styled, { keyframes } from 'styled-components'
import ErrorModal from '~/components/common/ErrorModal'

const Hover = ({ opportunityId }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))

	return (
		<Fragment>
			<Card className="mb-xxxs-3">
				<CardHeader>
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H5 className="mb-xxxs-0">Hover</H5>
						</Col>
						<Col xxxs="auto">
							<ActionButton icon={faPlus} title="Add Hover Job" color="success" modalSize="sm">
								{(setIsOpen) => <AddModal opportunityId={opportunityId} setIsOpen={setIsOpen} />}
							</ActionButton>
						</Col>
					</Row>
				</CardHeader>
				<Table>
					<Thead>
						<Tr>
							<Th>Image</Th>
							<Th>Date Created</Th>
							<Th>State</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{opportunity?.hoverJobs?.map((job) => (
							<JobRow key={job} jobId={job} opportunityId={opportunityId} />
						))}
					</Tbody>
				</Table>
			</Card>
		</Fragment>
	)
}

const AddModal = ({ opportunityId, setIsOpen }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()
	const [addingExisting, setAddingExisting] = useState(false)
	const [selectedJob, setSelectedJob] = useState([])
	const [lastTimeout, setLastTimeout] = useState(null)
	const [errorModalOpen, setErrorModalOpen] = useState(false)

	const getOptions = (input) => {
		return callApi({
			endpoint: `hover/jobs/search/${input}`,
			params: {
				opportunityId
			}
		}).then((result) => {
			setLastTimeout(null)
			return result.data
		})
	}

	const loadOptions = (input) => {
		if (input === '') return
		return new Promise((resolve) => {
			if (lastTimeout !== null) {
				clearTimeout(lastTimeout)
			}
			setLastTimeout(
				setTimeout(() => {
					resolve(getOptions(input))
				}, 500)
			)
		})
	}

	const createCaptureRequest = (closeModal) => {
		dispatch(entityThunks.createHoverCaptureRequest({ id: opportunityId })).then((response) => {
			if (response.payload.error === 'NO_HOVER_USER') {
				setErrorModalOpen(true)
			} else {
				setIsOpen(false)
			}
			closeModal(false)
		})
	}

	const createJob = (closeModal) => {
		dispatch(entityThunks.createHoverJob({ id: opportunityId })).then(() => {
			if (response.payload.error === 'NO_HOVER_USER') {
				setErrorModalOpen(true)
			} else {
				setIsOpen(false)
			}
			closeModal(false)
		})
	}

	const addExistingJob = () => {
		dispatch(entityThunks.addHoverJobToOpportunity({ opportunityId, jobId: selectedJob.value })).then((response) => {
			setIsOpen(false)
		})
	}

	return (
		<React.Fragment>
			{addingExisting ? (
				<React.Fragment>
					<ControlledAsyncSelect
						className="mb-xxxs-3"
						value={selectedJob}
						loadOptions={loadOptions}
						handleOnChange={(option) => setSelectedJob(option)}
					/>
					<Button color="success" onClick={addExistingJob} block>
						Add
					</Button>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Row className="justify-content-xxxs-center mb-xxxs-1">
						<Col xxxs="auto">
							<ConfirmationButton
								title="Confirm New Capture Request"
								description="Make capture request?"
								button="Create New Capture Request"
								handleConfirmation={createCaptureRequest}
								color="success"
							/>
						</Col>
					</Row>
					<Row className="justify-content-xxxs-center mb-xxxs-1">
						<Col xxxs="auto">
							<ConfirmationButton
								title="Confirm New Job"
								description="Make new job? It will automatically be processed once the photos are taken."
								button="Create New Job"
								handleConfirmation={createJob}
								color="success"
							/>
						</Col>
					</Row>
					<Row className="justify-content-xxxs-center">
						<Col xxxs="auto">
							<Button color="success" onClick={() => setAddingExisting(true)}>
								Add Existing Job
							</Button>
						</Col>
					</Row>
				</React.Fragment>
			)}
			<Hr />
			<Row className="justify-content-xxxs-center">
				<Col xxxs>
					<Button color="danger" block onClick={() => setIsOpen(false)}>
						Close
					</Button>
				</Col>
			</Row>
			<NoHoverUserModal isOpen={errorModalOpen} close={() => setErrorModalOpen(false)} />
		</React.Fragment>
	)
}

const JobRow = ({ jobId, opportunityId }) => {
	const { dispatch, entityThunks } = useLumenox()
	const [details, setDetails] = useState(null)
	const [refreshDetails, setRefreshDetails] = useState(true)
	const [primaryImage, setPrimaryImage] = useState(null)

	const deleteJob = () => {
		dispatch(entityThunks.removeHoverJobFromOpportunity({ jobId, opportunityId }))
	}

	useEffect(() => {
		if (refreshDetails) {
			callApi({
				endpoint: `hover/jobs/${jobId}`
			})
				.then((response) => {
					setDetails(response.data)
				})
				.then(() => {
					callApi({
						endpoint: `hover/jobs/${jobId}/primary-image`,
						responseType: 'arraybuffer'
					}).then((response) => {
						//likely not the best way of doing this but it should work
						if (response.data.byteLength === 17) {
							//length of {success: false} response
							setPrimaryImage(null)
						} else {
							const imageData = new Blob([response.data], { type: 'image/jpeg' })
							const url = URL.createObjectURL(imageData)
							setPrimaryImage(url)
						}
					})
				})
			setRefreshDetails(false)
		}
	}, [refreshDetails])

	if (!details || details.error) return null

	return (
		<Tr>
			<Td>
				<A href={`${process.env.HOVER_BASE_URL}/ui/#/property/${jobId}`} target="_blank" style={{ textDecoration: 'none' }}>
					{primaryImage && <img src={primaryImage} width="100px" />}
				</A>
			</Td>
			<Td>{format(new Date(details.created_at), 'MMMM d, y')}</Td>
			<Td>
				<StateBadge state={details.state} />
			</Td>
			<Td>
				<ButtonGroup size="sm">
					<ActionButton color="info" title="Hover Job Details" icon={faCircleInfo} modalSize="lg">
						{(setIsOpen) => <JobModal jobId={jobId} setIsOpen={setIsOpen} details={details} refreshDetails={() => setRefreshDetails(true)} />}
					</ActionButton>
					<ConfirmationButton
						color="danger"
						icon={faTrash}
						handleConfirmation={deleteJob}
						title="Remove Hover Job?"
						description="This won't delete the job from hover, it will just remove it from this lead."
					/>
				</ButtonGroup>
			</Td>
		</Tr>
	)
}

const JobModal = ({ jobId, setIsOpen, details, refreshDetails }) => {
	console.log('details:', details)
	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const doneBuilding = details.state === 'complete'
	const readyToProcess = details.deliverable_id === 7 && details.state === 'processing_upload' //I think this is the only one we want here

	const processJob = (setIsOpen) => {
		callApi({
			endpoint: `hover/jobs/${jobId}/process`
		}).then((response) => {
			console.log('response:', response)
			if (response.data.error === 'NO_HOVER_USER') {
				setErrorModalOpen(true)
			}
			setIsOpen(false)
		})
	}

	return (
		<React.Fragment>
			<H4 className="mb-xxxs-3">Details:</H4>
			<Row>
				<Col xxxs>
					<Table className="mb-xxxs-0">
						<Tbody>
							<Tr>
								<Th>State</Th>
								<Td>
									<StateBadge state={details.state} />
								</Td>
							</Tr>
							<Tr>
								<Th>Created At</Th>
								<Td>{format(new Date(details.created_at), 'MMMM d, y')}</Td>
							</Tr>
							{details.completed_at && (
								<Tr>
									<Th>Completed At</Th>
									<Td>{format(new Date(details.completed_at), 'MMMM d, y')}</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				</Col>
				<Col xxxs>
					<H6>Hover Assigned To</H6>
					<ReassignHoverSelect jobId={jobId} currentAssignedTo={details.user_id} refreshDetails={refreshDetails} />
					<H6 className="mt-xxxs-3">Links</H6>
					<ButtonGroup>
						<Button tag={A} href={`${process.env.HOVER_BASE_URL}/ui/#/property/${jobId}`} target="_blank" color="info">
							Page
						</Button>
						{doneBuilding && (
							<Button tag={A} href={`${process.env.HOVER_BASE_URL}/3d/${jobId}`} target="_blank" color="info">
								Model
							</Button>
						)}
					</ButtonGroup>
					<br />
					{readyToProcess && (
						<ConfirmationButton handleConfirmation={processJob} button="Process Job" description="Are you sure you want to process this job?" />
					)}
				</Col>
			</Row>
			{details?.images?.length > 0 && (
				<React.Fragment>
					<Hr />
					<H4 className="mb-xxxs-3">Images:</H4>
					<JobImages jobImages={details.images} />
				</React.Fragment>
			)}
			<Hr />
			<Row className="justify-content-xxxs-end">
				<Col xxxs="auto">
					<Button onClick={() => setIsOpen(false)} color="dark">
						Close
					</Button>
				</Col>
			</Row>
			<NoHoverUserModal isOpen={errorModalOpen} close={() => setErrorModalOpen(false)} />
		</React.Fragment>
	)
}

const StateBadge = ({ state }) => {
	const colorMappings = {
		complete: 'success',
		failed: 'danger'
	}

	return <Badge color={colorMappings[state] ?? 'info'}>{state}</Badge>
}

const NoHoverUserModal = ({ isOpen, close }) => {
	return (
		<ErrorModal
			isOpen={isOpen}
			close={close}
			description="It looks like there isn't a hover user associated with your crm user. Please connect your crm account to your hover user and try again."
		/>
	)
}

const ReassignHoverSelect = ({ jobId, currentAssignedTo, refreshDetails }) => {
	const { selectors } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)

	const hoverUsers = useSelector((state) => selectors.selectAllHoverUsers(state))
	const options = hoverUsers ? hoverUsers.map((user) => ({ label: user.data.displayName, value: user.hoverId })) : []

	const onChange = (option) => {
		setIsLoading(true)
		callApi({
			endpoint: `hover/jobs/${jobId}/reassign`,
			method: 'POST',
			data: {
				assignedToId: option.value
			}
		}).then((response) => {
			console.log('response', response)
			setIsLoading(false)
			refreshDetails()
		})
	}

	const value = options.find((option) => option.value === currentAssignedTo)

	return <UncontrolledSelect options={options} value={value} onChange={onChange} isLoading={isLoading} />
}

const JobImages = ({ jobImages }) => {
	const [images, setImages] = useState([])

	const getJobImages = (jobImages) => {
		return Promise.all(
			jobImages.map((image) => {
				return callApi({
					endpoint: `hover/images/${image.id}`,
					responseType: 'arraybuffer'
				}).then(async (response) => {
					const imageData = new Blob([response.data], { type: 'image/jpeg' })
					const url = URL.createObjectURL(imageData)
					return {
						thumbnail: url,
						original: url
					}
				})
			})
		)
	}
	useEffect(() => {
		getJobImages(jobImages).then((loadedImages) => setImages(loadedImages))
	}, [])

	if (jobImages.length === 0) return null

	return images.length === 0 ? (
		<Row className="align-items-xxxs-center justify-content-xxxs-center">
			<Col xxxs="auto">
				<Spinner />
			</Col>
		</Row>
	) : (
		<ImageGallery items={images} />
	)
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerContainer = styled.div`
	display: inline-block;
	width: 80px;
	height: 80px;
`

const SpinnerInner = styled.div`
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #f3f3f3;
	border-radius: 50%;
	animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #3498db transparent transparent transparent;

	&:nth-child(1) {
		animation-delay: -0.45s;
	}

	&:nth-child(2) {
		animation-delay: -0.3s;
	}

	&:nth-child(3) {
		animation-delay: -0.15s;
	}
`

const Spinner = () => {
	return (
		<SpinnerContainer>
			<SpinnerInner />
			<SpinnerInner />
			<SpinnerInner />
		</SpinnerContainer>
	)
}

const ControlledAsyncSelect = ({ value, loadOptions, handleOnChange, CustomOption, ...rest }) => {
	return (
		<Formik initialValues={{ search: '' }}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<AsyncSelect
							value={value}
							loadOptions={loadOptions}
							handleOnChange={handleOnChange}
							name="search"
							CustomOption={CustomOption}
							{...rest}
						/>
					</Form>
				)
			}}
		</Formik>
	)
}

export default Hover
