import { createCachedSelector } from 're-reselect'
import { primitiveSelectors as compensationProgramSelectors } from '../index'
import { primitiveSelectors as compensationProgramEnrollmentSelectors } from '~/state/entities/compensationProgramEnrollment'
import { primitiveSelectors as opportunitySelectors } from '~/state/entities/opportunity'
import { primitiveSelectors as contactSelectors } from '~/state/entities/contact'
import { createCachedParamSelector } from '../../adapter'

export const selectActiveCompensationPrograms = createCachedSelector(
	[(state) => compensationProgramSelectors.selectAllCompensationPrograms(state)],
	(compensationPrograms) => {
		return compensationPrograms.filter((cp) => cp.endDate === null || new Date(cp.endDate) > new Date())
	}
)((compensationPrograms) => compensationPrograms.length || '')

// selector is wrong, but this isn't even used so I'm just commenting it all out
// export const selectCompensationProgramsByOpportunity = createCachedSelector(
// 	[
// 		(state, id) => opportunitySelectors.selectOpportunityById(state, id),
// 		(state) => contactSelectors.selectContactEntities(state),
// 		(state) => compensationProgramSelectors.selectCompensationProgramEntities(state)
// 	],
// 	(opportunity, contactEntities, compensationProgramEntities) => {
// 		if (!opportunity.contacts || opportunity.contacts.length < 1) return null
// 		const contact = contactEntities[opportunity.contacts[0]]
// 		if (!contact) return null
// 		console.log('contact:', contact)
// 		return contact.compensationPrograms.map((cp) => compensationProgramEntities[cp.program])
// 	}
// )((opportunity, contactEntities, compensationProgramEntities) => {
// 	//gotta rework cuz this is way off
// 	if (!opportunity.contacts || opportunity.contacts.length < 1) return ''
// 	const contact = contactEntities[opportunity.contacts[0]]
// 	if (!contact) return ''
// 	const key = contact.compensationPrograms
// 		.map((cp) => compensationProgramEntities[cp.program])
// 		.reduce((string, cp, i) => {
// 			if (i !== 0) string += ','
// 			if (cp && cp.id) string += cp.id
// 			return string
// 		}, '')
// 	return key
// })

export const selectOpportunityReferralCompensationProgram = createCachedParamSelector(
	[
		(state, opportunityId) => opportunitySelectors.selectOpportunityById(state, opportunityId),
		(state, _) => compensationProgramEnrollmentSelectors.selectCompensationProgramEnrollmentEntities(state),
		(state, _) => compensationProgramSelectors.selectCompensationProgramEntities(state)
	],
	(opportunity, enrollmentEntities, programEntities) => {
		return programEntities[enrollmentEntities[opportunity?.referral]?.program]
	}
)

export const selectCompensationProgramByEnrollmentId = createCachedParamSelector(
	[
		(state, _) => compensationProgramEnrollmentSelectors.selectCompensationProgramEnrollmentEntities(state),
		(state, _) => compensationProgramSelectors.selectCompensationProgramEntities(state),
		(_, enrollmentId) => enrollmentId
	],
	(enrollmentEntities, programEntities, enrollmentId) => {
		const enrollment = enrollmentEntities[enrollmentId]
		if (!enrollment) return null
		return programEntities[enrollment.program]
	}
)

// export const selectUnarchivedOpportunityStageTypesOrdered = createCachedSelector([(state) => selectOpportunityStageTypesOrdered(state)], (orderedStageTypes) =>
// 	orderedStageTypes.filter((stage) => !stage.archived)
// )((stageTypes) => stageTypes.length || '')

// export const selectOpportunityStageTypeBySlug = createCachedSelector(
// 	[(state) => opportunityStageTypeSelectors.selectAllOpportunityStageTypes(state), (_, params) => params],
// 	(stageTypes, slug) => stageTypes.find((stageType) => stageType.slug === slug)
// )((stageTypes) => stageTypes.length || '')
