import React, { PureComponent, useState } from 'react'
import withWidth, { isWidthUp, isWidthDown } from 'bootstrap-styled/lib/withWidth'
import { connect } from 'react-redux'
import { H3 } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
import LeadForm from '../components/LeadForm'

import { Redirect, useRouteMatch } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'
import { useFirebase } from '~/services/firebase'

const AddLead = () => {
	const { dispatch, entityThunks, userHasRole, user } = useLumenox()
	const [redirect, setRedirect] = useState(null)
	const userHasSalesRole = userHasRole('sales')
	const { params } = useRouteMatch({
		path: '/leads/new/:qualified?',
		strict: false
	})
	const qualified = params.qualified === 'qualified'

	const handleSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
		if (qualified) {
			if (userHasSalesRole) values.salesperson = user.uid
			values.qualified = true
			values.assignedTo = user.uid
		}

		console.log('submitting')
		console.log(values)

		dispatch(entityThunks.addOpportunity(values)).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			if (response.meta.requestStatus === 'fulfilled') {
				if (qualified) {
					setRedirect(`/leads/${response.payload.result}/quotes/new`)
				} else {
					setRedirect(response.payload.result)
				}
			}
		})
	}

	return (
		<React.Fragment>
			{redirect && <Redirect to={`${redirect}`} />}
			<BackLink to="/leads">Back to Lead Listing</BackLink>
			<H3>Add New Lead</H3>
			{user && (
				<LeadForm
					initialValues={{
						contacts: []
					}}
					onSubmit={handleSubmit}
					qualified={qualified}
				/>
			)}
		</React.Fragment>
	)
}

export default AddLead
