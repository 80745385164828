import { createAsyncThunk } from '@reduxjs/toolkit'
import { callPublicApi, callApi } from '~/services/api'

export const addUserInvite = createAsyncThunk('userInvites/add', (values, { getState, requestId }) => {
	return callApi({
		endpoint: `users/invites`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const fetchUserInvite = createAsyncThunk('userInvites/fetch', (id, { getState, requestId }) => {
	return callPublicApi({
		endpoint: `users/invites/${id}`
	}).then((response) => {
		return response.data
	})
})

export const claimUserInvite = createAsyncThunk('userInvites/claim', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `users/invites/${id}`,
		method: 'PATCH'
	}).then((response) => {
		return response.data
	})
})

export const updateUserInvite = createAsyncThunk('userInvites/update', ({ id, values }, { getState, requestId }) => {
	console.log('test')
	return callApi({
		endpoint: `users/invites/${id}`,
		method: 'PUT',
		data: values
	}).then((response) => {
		return response.data
	})
})

/* export const fetchInvite = createAsyncThunk('users/fetchInvite', (id, { getState, requestId }) => {
	return callPublicApi({
		endpoint: `users/invite/${id}`
	}).then((response) => {
		return response.data
	})
})

export const claimInvite = createAsyncThunk('users/claimInvite', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `users/invite/${id}`,
		method: 'PUT'
	}).then((response) => {
		return response.data
	})
})



export const fetchUserById = createAsyncThunk('users/fetchById', (id) => {
	return callApi({
		endpoint: `users/${id}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
}) */

export const fetchUserInvites = createAsyncThunk('userInvites/fetch', (id) => {
	return callApi({
		endpoint: `users/invites`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

/* export const fetchUsersByRole = createAsyncThunk('users/fetchByRole', (id) => {
	return callApi({
		endpoint: `users/role/${id}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
}) */

/* export const fetchCourseById = createAsyncThunk('courses/fetchById', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `courses/${id}`
	}).then((response) => {
		return response.data
	})
})

export const fetchCourses = createAsyncThunk('courses/fetch', () => {
	return callApi({
		endpoint: `courses`
	}).then((response) => {
		return response.data
	})
}) */
