import React, { useEffect, useRef, useState } from 'react'
import { H3, Hr, Row } from '@bootstrap-styled/v4'
import { ActionButton, Button, Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { DateRangePicker } from 'react-date-range'
import styled from 'styled-components'
import SalespersonStatsTable from './components/SalespersonStatsTable'

const SalespersonStatsPage = () => {
	return (
		<React.Fragment>
			<Row className="align-items-xxxs-center mb-xxxs-3">
				<Col xxxs>
					<H3>Salesperson Stats</H3>
				</Col>
			</Row>
			<SalespersonStatsTable />
		</React.Fragment>
	)
}

export default SalespersonStatsPage
