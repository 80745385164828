import React from 'react'
import { useSelector } from 'react-redux'
import { CardFooter, Row } from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import { ActionButton, Button, Col } from '~/components/bootstrap'
import FollowUpForm from '../../../FollowUpForm'

const Call = ({ id, opportunityId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	const activityEntities = useSelector((state) => selectors.selectOpportunityActivityEntities(state))

	const followUpInitialValues = {
		details: '',
		createdAt: activity.createdAt,
		type: 'Call'
	}

	const handleFollowUpFormSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.addOpportunityFollowUp({ id: opportunityId, values, parent: id })).then((response) => {
			console.log('success', response)
			// todo error handling
			setSubmitting(false)
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	//children will be populated by body component
	const alreadyHasFollowUp = activity.children?.find((child) => activityEntities[child]?.__t === 'FollowUp') !== undefined

	if (alreadyHasFollowUp || activity.missed) return null

	return (
		<CardFooter>
			<Row className="justify-content-xxxs-end">
				<Col xxxs="auto">
					<ActionButton color="info" button="Mark As Follow Up" title="Add Follow Up">
						{(setIsOpen) => {
							return <FollowUpForm initialValues={followUpInitialValues} onSubmit={handleFollowUpFormSubmit} onSuccess={() => setIsOpen(false)} />
						}}
					</ActionButton>
				</Col>
			</Row>
		</CardFooter>
	)
}

export default Call
