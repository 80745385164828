import { entityCreator } from '../adapter'
import * as otherSelectors from './selectors'

const [entitySlice, defaultSelectors] = entityCreator('Opportunity', {
	initialState: {
		activeStage: 'all'
	},
	reducers: {
		setActiveStage: (state, action) => {
			state.activeStage = action.payload
		},
		setActiveTagId: (state, action) => {
			state.activeTagId = action.payload
		}
	}
})

export const slice = entitySlice
export const primitiveSelectors = defaultSelectors
export const selectors = { ...defaultSelectors, ...otherSelectors }
export const actions = slice.actions
export default slice.reducer
