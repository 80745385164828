import { createCachedSelector } from 're-reselect'
import { createCachedParamSelector, createParamSelector } from '~/state/entities'
import { selectors as quoteRunSelectors } from '~/state/entities/quoteRun'
import { primitiveSelectors as quoteTermSelectors } from '../index'

export const selectUnarchivedTerms = createCachedParamSelector([(state) => quoteTermSelectors.selectAllQuoteTerms(state)], (terms) => {
	return terms.filter((term) => !term.archived)
})

export const selectDefaultTerms = createCachedParamSelector([(state) => selectUnarchivedTerms(state)], (unarchivedTerms) => {
	return unarchivedTerms.filter((term) => term.includeByDefault)
})

export const selectDefaultTermsSorted = createCachedParamSelector([(state) => selectDefaultTerms(state)], (defaultTerms) => {
	return defaultTerms.sort((a, b) => a.defaultIndex - b.defaultIndex)
})

export const selectTermsWithDefaultSorted = createCachedParamSelector([(state) => quoteTermSelectors.selectAllQuoteTerms(state)], (terms) => {
	return terms.sort((a, b) => {
		if (a.includeByDefault && b.includeByDefault) return a.defaultIndex - b.defaultIndex
		else if (a.includeByDefault) return -1
		else if (b.includeByDefault) return 1
		if (a.archived && !b.archived) return 1
		else if (b.archived && !a.archived) return -1
		return a.updatedAt > b.updatedAt ? -1 : 1
	})
})
