import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, H1, H2, H3, Hr, Nav, NavItem, NavLink, Row } from '@bootstrap-styled/v4'
import { Col, Button, ActionButton } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { callApi } from '~/services/api'
import { lighten, readableColor } from 'polished'
import * as Pages from './pages'

const CompensationPrograms = () => {
	const [selectedPage, setSelectedPage] = useState('Programs')

	let Page = Pages[selectedPage]

	return (
		<Wrapper className="p-xxxs-3">
			<Row>
				<Col xxxs={12} md={3}>
					<Tabs selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
				</Col>
				<Col xxxs>{Page && <Page />}</Col>
			</Row>
		</Wrapper>
	)
}

const Tabs = ({ selectedPage, setSelectedPage }) => {
	const { userHasRole } = useLumenox()
	const admin = userHasRole('admin')
	const accounting = userHasRole('accounting')

	return (
		<React.Fragment>
			<Nav vertical pills stacked className="mb-xxxs-3 flex-xxxs-column">
				<PageNavItem color="info">
					<NavLink href="#" active={selectedPage === 'Programs'} onClick={() => setSelectedPage('Programs')}>
						Programs
					</NavLink>
				</PageNavItem>
				{(admin || accounting) && (
					<PageNavItem color="info">
						<NavLink active={selectedPage === 'SalesCommissions'} onClick={() => setSelectedPage('SalesCommissions')}>
							Sales Commissions
						</NavLink>
					</PageNavItem>
				)}
				{(admin || accounting) && (
					<PageNavItem color="info">
						<NavLink active={selectedPage === 'SalespersonStats'} onClick={() => setSelectedPage('SalespersonStats')}>
							Salesperson Stats
						</NavLink>
					</PageNavItem>
				)}
			</Nav>
		</React.Fragment>
	)
}

const PageNavItem = styled(({ children, ...rest }) => {
	return <NavItem {...rest}>{children}</NavItem>
})`
	a {
		transition: color 0.15s, background-color 0.15s;
		&:hover {
			background: ${({ theme, color }) => lighten('0.29', theme[`$brand-${color}`])} !important;
			color: ${({ theme, color }) => readableColor(lighten('0.29', theme[`$brand-${color}`]), theme[`$body-color`], 'white')} !important;
		}

		&.active {
			background: ${({ theme, color }) => lighten('0.25', theme[`$brand-${color}`])} !important;
			color: ${({ theme, color }) => readableColor(lighten('0.25', theme[`$brand-${color}`]), theme[`$body-color`], 'white')} !important;
		}
	}
`

const Wrapper = styled.div`
	background: ${({ theme }) => theme['$gray-light']};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default CompensationPrograms
