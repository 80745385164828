import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

/* export const fetchOpportunityStageTypes = createAsyncThunk('opportunityStageTypes/fetch', () => {
	return callApi({
		endpoint: `opportunities/stage-types`
	}).then((response) => {
		return response.data
	})
}) */

export const addQuote = createAsyncThunk('quotes/add', ({ values, opportunityId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}/quotes`,
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data: values
	}).then((response) => {
		return response.data
	})
})

export const updateQuote = createAsyncThunk('quotes/update', ({ values, opportunityId, quoteId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}/quotes/${quoteId}`,
		method: 'PUT',
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data: values
	}).then((response) => {
		return response.data
	})
})

export const removeQuote = createAsyncThunk('quotes/remove', ({ opportunityId, quoteId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}/quotes/${quoteId}`,
		method: 'DELETE'
	}).then((response) => {
		return response.data
	})
})

export const duplicateQuote = createAsyncThunk('quotes/duplicate', ({ opportunityId, quoteId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}/quotes/${quoteId}/duplicate`,
		method: 'POST'
	}).then((response) => {
		return response.data
	})
})

export const getWiringDiagram = createAsyncThunk('quotes/wiring-diagram', (quoteId) => {
	return callApi({
		endpoint: `quotes/wiring-diagram/${quoteId}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

export const approveCommission = createAsyncThunk('quotes/approve-commission', (quoteId) => {
	return callApi({
		endpoint: `quotes/${quoteId}/approve-commission`
	}).then((response) => {
		return response.data
	})
})

export const unapproveCommission = createAsyncThunk('quotes/unapprove-commission', (quoteId) => {
	return callApi({
		endpoint: `quotes/${quoteId}/unapprove-commission`
	}).then((response) => {
		return response.data
	})
})

export const markCommissionPaid = createAsyncThunk('quotes/mark-commission-paid', (quoteId) => {
	return callApi({
		endpoint: `quotes/${quoteId}/commission-paid`
	}).then((response) => {
		return response.data
	})
})

export const markCommissionNotPaid = createAsyncThunk('quotes/mark-commission-not-paid', (quoteId) => {
	return callApi({
		endpoint: `quotes/${quoteId}/commission-not-paid`
	}).then((response) => {
		return response.data
	})
})
