import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import {
	H1,
	Hr,
	H3,
	Card,
	CardBlock,
	ButtonToolbar,
	ButtonGroup,
	CardHeader,
	H4,
	H6,
	Badge,
	P,
	Tbody,
	Tr,
	Th,
	Td,
	Form,
	Thead,
	H5,
	Small
} from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { ActionButton, Button, Col, Row, Table } from '~/components/bootstrap'
import { faImage, faImages } from '@fortawesome/pro-regular-svg-icons'
import { useRouteMatch } from 'react-router-dom'
import * as markerjs2 from 'markerjs2'
import { callApi } from '~/services/api'
import { CollapsableCard } from '~/components/common/CollapsableCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrightness, faMoon, faSunrise, faSunset } from '@fortawesome/pro-solid-svg-icons'

const SiteVisitChecklistView = () => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, params.id))
	const siteVisit = useSelector((state) => selectors.selectSiteVisitById(state, opportunity.siteVisit))

	useEffect(() => {
		dispatch(entityThunks.fetchSiteVisitById(opportunity.siteVisit))
	}, [])

	if (!siteVisit) return null

	return (
		<React.Fragment>
			<Card className="mb-xxxs-3">
				<CardHeader>
					<H5>Colour Samples</H5>
				</CardHeader>
				<Table className="mb-xxxs-0">
					<Tbody>
						<Tr>
							<Th>Name</Th>
							<Th>Colour</Th>
							<Th>Images</Th>
						</Tr>
						{siteVisit.colourSamples.map((cs) => (
							<React.Fragment key={cs.name}>
								<Tr>
									<Td>{cs.name}</Td>
									<Td>
										<Row className="align-items-xxxs-center justify-content-xxxs-center">
											<Col xxxs="auto">
												<HexDisplay hex={cs.colour} />
											</Col>
											<Col xxxs="auto" className="pl-xxxs-0">
												{(cs.colour.startsWith('#') ? cs.colour : '#' + cs.colour).toUpperCase()}
											</Col>
										</Row>
									</Td>
									<Td>{cs.images.length > 0 && <ImageButton images={cs.images} />}</Td>
								</Tr>
								{cs.notes !== '' && (
									<Tr className="text-align-xxxs-center">
										<Td colSpan="3">
											<strong>Notes: </strong>
											{cs.notes}
										</Td>
									</Tr>
								)}
								<Tr>
									<Td colSpan="3">
										<ChannelMatches colour={cs.colour} />
									</Td>
								</Tr>
							</React.Fragment>
						))}
					</Tbody>
				</Table>
			</Card>
			<Card className="mb-xxxs-3">
				<CardHeader>
					<H5>Box Locations</H5>
				</CardHeader>
				<Table>
					<Tbody>
						<Tr>
							<Th>Location</Th>
							<Th>Mounting Location</Th>
							<Th>Plug Locations</Th>
						</Tr>
						{siteVisit.boxLocations.map((bl) => (
							<React.Fragment key={bl.location}>
								<Tr>
									<Td>{bl.location}</Td>
									<Td>
										<ImageButton images={bl.mountingLocation} />
									</Td>
									{bl.plugLocations.length > 0 && (
										<Td>
											<ImageButton images={bl.plugLocations} />
										</Td>
									)}
								</Tr>
								{bl.notes !== '' && (
									<Tr className="text-align-xxxs-center">
										<Td colSpan="3">
											<strong>Notes: </strong>
											{bl.notes}
										</Td>
									</Tr>
								)}
							</React.Fragment>
						))}
					</Tbody>
				</Table>
			</Card>
			{siteVisit.specialtyChannel.length > 0 && (
				<Card className="mb-xxxs-3">
					<CardHeader>
						<H5>Specialty Channel</H5>
					</CardHeader>
					<Table>
						<Tbody>
							<Tr>
								<Th>Name</Th>
								<Th>Type</Th>
								<Th>Fascia Depth</Th>
								<Th>Images</Th>
							</Tr>
							{siteVisit.specialtyChannel.map((sc) => (
								<React.Fragment key={sc.name}>
									<Tr>
										<Td>{sc.name}</Td>
										<Td>{sc.type}</Td>
										<Td>{sc.fasciaDepth}"</Td>
										<Td>
											<ImageButton images={sc.images} />
										</Td>
									</Tr>
									{sc.notes !== '' && (
										<Tr>
											<Td colSpan="4">
												<strong>Notes: </strong>
												{sc.notes}
											</Td>
										</Tr>
									)}
								</React.Fragment>
							))}
						</Tbody>
					</Table>
				</Card>
			)}
			{siteVisit.obstacles.length > 0 && (
				<Card className="mb-xxxs-3">
					<CardHeader>
						<H5>Obstacles</H5>
					</CardHeader>
					<Table>
						<Tbody>
							<Tr>
								<Th>Name</Th>
								<Th>Location</Th>
								<Th>Amount</Th>
								<Th>Images</Th>
							</Tr>
							{siteVisit.obstacles.map((o) => (
								<React.Fragment key={o.name}>
									<Tr>
										<Td>{o.name}</Td>
										<Td>{o.location}</Td>
										<Td>{o.amount}</Td>
										<Td>
											<ImageButton images={o.images} />
										</Td>
									</Tr>
									{o.notes !== '' && (
										<Tr className="text-align-xxxs-center">
											<Td colSpan="4">
												<strong>Notes: </strong>
												{o.notes}
											</Td>
										</Tr>
									)}
								</React.Fragment>
							))}
						</Tbody>
					</Table>
				</Card>
			)}
			{siteVisit.nonStandardElevations.length > 0 && (
				<Card className="mb-xxxs-3">
					<CardHeader>
						<H5>Non Standard Elevations</H5>
					</CardHeader>
					<Table>
						<Tbody>
							<Tr>
								<Th>Name</Th>
								<Th>Measurements</Th>
								<Th>Images</Th>
							</Tr>
							{siteVisit.nonStandardElevations.map((nse) => (
								<React.Fragment key={nse.name}>
									<Tr>
										<Td>{nse.name}</Td>
										<Td>
											{nse.measurements.reduce((text, measurement, i) => {
												text += measurement + "'"
												if (i !== nse.measurements.length - 1) text += ', '
												return text
											}, '')}
										</Td>
										<Td>
											<ImageButton images={nse.images} />
										</Td>
									</Tr>
									{nse.notes !== '' && (
										<Tr className="text-align-xxxs-center">
											<Td colSpan="3">
												<strong>Notes: </strong>
												{nse.notes}
											</Td>
										</Tr>
									)}
								</React.Fragment>
							))}
						</Tbody>
					</Table>
				</Card>
			)}
			<Card className="mb-xxxs-3">
				<CardHeader>
					<H5>Hover Measurements</H5>
				</CardHeader>
				<Table>
					<Tbody>
						<Tr>
							<Th>Type</Th>
							<Th>Width</Th>
							<Th>Height</Th>
						</Tr>
						{siteVisit.hoverMeasurements.map((hm) => (
							<React.Fragment key="hm.type">
								<Tr>
									<Td>{hm.type}</Td>
									<Td>{hm.width}"</Td>
									<Td>{hm.height}"</Td>
								</Tr>
								{hm.notes !== '' && (
									<Tr className="text-align-xxxs-center">
										<Td colSpan="2">
											<strong>Notes: </strong>
											{hm.notes}
										</Td>
									</Tr>
								)}
							</React.Fragment>
						))}
					</Tbody>
				</Table>
			</Card>
			{siteVisit.notes !== '' && (
				<Card>
					<CardHeader>
						<H5>Notes</H5>
					</CardHeader>
					<CardBlock>{siteVisit.notes}</CardBlock>
				</Card>
			)}
		</React.Fragment>
	)
}

const ChannelMatches = ({ colour }) => {
	const [matches, setMatches] = useState([])

	useEffect(() => {
		callApi({
			endpoint: `quotes/channel/matches/${colour.replace(/\W/g, '')}`,
			params: {
				limit: 7
			}
		}).then((response) => {
			setMatches(response.data)
			console.log(response.data)
		})
	}, [])

	return (
		<CollapsableCard title="Channel Matches" className="mb-xxxs-0">
			<Table striped size="sm" responsive className="mb-xxxs-0">
				<Thead>
					<Tr>
						<Th>Channel</Th>
						<Th></Th>
						<Th>Match</Th>
						<Th>Lightness</Th>
					</Tr>
				</Thead>
				<Tbody>
					{matches.map((match, i) => (
						<Tr key={i}>
							<Td>
								<Small>{match.name}</Small>
							</Td>
							<Td className="d-xxxs-flex align-items-xxxs-center justify-content-xxxs-center">
								<HexDisplay hex={match.hex} />
							</Td>
							{match.error ? (
								<Td colSpan={2}>Error</Td>
							) : (
								<Fragment>
									<Td>{match.diff.toFixed(2)}</Td>
									<Td>
										<Badge color={match.lightnessDiffPercentage < 0 ? 'info' : 'warning'}>
											{Math.abs(match.lightnessDiffPercentage).toFixed(2)}%{' '}
											{match.lightnessDiffPercentage < 0 ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faBrightness} />}
										</Badge>
									</Td>
								</Fragment>
							)}
						</Tr>
					))}
				</Tbody>
			</Table>
		</CollapsableCard>
	)
}

const ImageButton = ({ images }) => {
	return (
		<ActionButton color="info" title={`View Image${images.length > 1 ? 's' : ''}`} icon={images.length > 1 ? faImages : faImage} modalSize="xl" size="sm">
			{(closeModal, isShown) => (
				<React.Fragment>
					{images.map((image, i) => (
						<ImageView key={i} image={image} index={i} />
					))}
					<Row className="justify-content-xxxs-end">
						<Col xxxs="auto">
							<Button color="dark" onClick={() => closeModal(false)}>
								Close
							</Button>
						</Col>
					</Row>
				</React.Fragment>
			)}
		</ActionButton>
	)
}

const ImageView = ({ image, index }) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [img, setImg] = useState(image)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (img.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setImg({
					...img,
					annotation: state
				})
				setShouldRender(true)
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		if (img.annotation) {
			markerArea.current.restoreState(img.annotation)
		}

		markerArea.current.render().then((dataUrl) => {
			imageRef.current.src = dataUrl
			markerArea.current.close()
		})
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/site-visit/${image.url}`
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<Img src={src} ref={imageRef} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const Img = React.forwardRef((props, ref) => (
	<img crossOrigin="anonymous" ref={ref} {...props} style={{ maxWidth: '100%', width: '125vh', imageOrientation: 'none' }} />
))

const HexDisplay = styled.span`
	background: ${(props) =>
		props.hex.startsWith('#') ? (props.hex.length === 7 ? props.hex : 'white') : props.hex.length === 6 ? '#' + props.hex : 'white'} !important;
	width: 2rem;
	height: 2rem;
	display: inline-block;
	border-radius: 0.25rem;
`

export default SiteVisitChecklistView
