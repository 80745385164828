import React, { useEffect, useState } from 'react'
import { Card, CardBlock, CardHeader, H5, Row } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { UncontrolledSelect } from '~/components/bootstrap/components/Form'

const LightsGenCard = ({ id }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [loading, setLoading] = useState(false)
	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))

	//just manually add option for new generations
	const genOptions = [
		{
			label: 'Gen 1',
			value: 'Gen 1'
		},
		{
			label: 'Gen 2',
			value: 'Gen 2'
		},
		{
			label: 'Gen 3',
			value: 'Gen 3'
		},
		{
			label: 'Gen 4',
			value: 'Gen 4'
		}
	]

	const onChange = (value) => {
		setLoading(true)
		dispatch(entityThunks.updateOpportunityLightsGen({ id, lightsGen: value.value })).then((response) => {
			setLoading(false)
		})
	}

	const value = genOptions.find((option) => option.value === opportunity?.lightsGen)

	return (
		<Card className="mb-xxxs-3">
			<CardHeader>
				<Row>
					<Col xxxs>
						<H5>Lights Generation</H5>
					</Col>
				</Row>
			</CardHeader>
			<CardBlock>
				<UncontrolledSelect options={genOptions} value={value} onChange={onChange} isLoading={loading} />
			</CardBlock>
		</Card>
	)
}

export default LightsGenCard
