import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    html {
        font-size: 16px;

        ${({ theme }) => theme.SuperQuery.minWidth.xxl.css`
            font-size: 18px;
        `};

        ${({ theme }) => theme.SuperQuery.minWidth.xxxl.css`
            //font-size: 23px;
        `};

        ${({ theme }) => theme.SuperQuery.maxWidth.md.css`
            //font-size: 16px;
        `}; 

        ${({ theme }) => theme.SuperQuery.maxWidth.xs.css`
            font-size: 13px;
        `}; 
            
        body {
            margin: 0;
            min-height: 100vh;
            background: ${({ theme }) => theme['$gray']};

            a {
                transition: color 0.25s ease;
            }

            .form-control, .form-group label, .form-text {
                font-size: ${({ theme }) => theme['$font-size-sm']} !important;
            }
        }
    }

    .pointer {
        cursor: pointer;
    }
`
