import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Ul, Li, CardBlock } from '@bootstrap-styled/v4'

import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { capitalize } from 'lodash'

const Email = ({ id, renderChildren }) => {
	const { selectors } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	return (
		<CardBlock>
			<Ul>
				{activity.events.map((ev, i) => (
					<Li key={i}>
						{capitalize(ev.type)} - {format(new Date(ev.createdAt), 'MMMM d, y - h:mma')}
					</Li>
				))}
			</Ul>
			{renderChildren()}
		</CardBlock>
	)
}

export default Email
