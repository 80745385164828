import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Badge, ButtonGroup } from '@bootstrap-styled/v4'
import { ActionButton, Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
import InviteUserForm from '../../components/InviteUserForm'

const InvitesListingTable = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const users = useSelector((state) => selectors.selectAllUserInvites(state))

	useEffect(() => {
		dispatch(entityThunks.fetchUserInvites())
	}, [])

	const handleUserInviteSubmit = ({ id, ...values }, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		console.log(values)
		dispatch(entityThunks.updateUserInvite({ id, values })).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			resetForm()
			onSuccess()
		})
	}

	return (
		<Table bordered striped responsive>
			<Thead>
				<Tr>
					<Th width="50%">Name</Th>
					<Th>Roles</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{users
					.sort((a, b) => a.name.localeCompare(b.name))
					.map((user, i) => {
						return (
							<Tr key={i}>
								<Td>
									<A tag={Link} to={`/users/${user.id}`}>
										{user.name}
									</A>
								</Td>
								<Td>
									<Roles roles={user.roles} />
								</Td>
								<Td>
									<ButtonGroup size="sm">
										<ActionButton color="warning" button={<FontAwesomeIcon icon={faPaperPlane} />} title="Re-send Invite Email">
											{(closeModal) => <InviteUserForm onSubmit={handleUserInviteSubmit} initialValues={user} onSuccess={closeModal} />}
										</ActionButton>
									</ButtonGroup>
								</Td>
							</Tr>
						)
					})}
			</Tbody>
		</Table>
	)
}

const Roles = ({ roles }) => {
	return (
		<Fragment>
			{roles.map((role) => (
				<RoleTag key={role} roleId={role} />
			))}
		</Fragment>
	)
}

const RoleTag = ({ roleId }) => {
	const { selectors } = useLumenox()
	const role = useSelector((state) => selectors.selectUserRoleById(state, roleId))

	if (!role) {
		return null
	}

	return (
		<Badge color="primary" className="mr-xxxs-1">
			{role.name}
		</Badge>
	)
}

export default InvitesListingTable
