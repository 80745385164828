import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useFirebase } from '~/services/firebase'
import { useLumenox } from '~/services/lumenox'

const PrivateRoute = ({ component: Component, path, roles = [], ...rest }) => {
	const [redirect, setRedirect] = useState(false)
	const { loading, isAuthenticated } = useFirebase()
	const { userHasRole } = useLumenox()

	useEffect(() => {
		if (loading || isAuthenticated) {
			return
		} else {
			setRedirect(true)
		}
	}, [loading, isAuthenticated, path])

	const checkRoles = () => {
		if (roles.length > 0) {
			let valid = false

			roles.forEach((slug) => {
				if (userHasRole(slug)) {
					valid = true
				}
			})

			return valid
		}
		return true
	}

	const render = (props) => {
		if (!isAuthenticated && !loading) {
			return redirect ? <Redirect to="/auth/login" /> : null
		}
		if (isAuthenticated && !loading && !checkRoles()) {
			return <Redirect to="/" />
		}
		return isAuthenticated && !loading ? <Component {...props} /> : null
	}

	return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute
