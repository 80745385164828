import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader } from '@bootstrap-styled/v4'
import { BackLink } from '~/components/common/Text'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-duotone-svg-icons'

const AssignedSalesperson = ({ id, renderTimestamp }) => {
	const { selectors } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	const user = useSelector((state) => selectors.selectUserById(state, activity.user))

	if (!activity || !user) {
		return null
	}

	return (
		<CardHeader>
			<Row>
				<Col xxxs="auto">
					<FontAwesomeIcon icon={faUser} fixedWidth />
				</Col>
				<Col xxxs>Salesperson Assigned - {user.name}</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default AssignedSalesperson
