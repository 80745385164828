import React from 'react'
import { Row, H2, Form, FormGroup, P, Alert, A, Hr } from '@bootstrap-styled/v4'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'
import { Button, Col, Input } from '~/components/bootstrap'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { useCookies } from 'react-cookie'
import { useFirebase } from '~/services/firebase'

const Login = (props) => {
	const validationSchema = Yup.object({
		password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
		email: Yup.string().email('Invalid email address').required('Required')
	})

	const { signInWithEmailAndPassword, signInWithGoogle, user } = useFirebase()

	const onUserLogin = ({ email, password }, { setSubmitting, setStatus, resetForm }) => {
		setStatus(null)

		signInWithEmailAndPassword(email, password)
			.catch((err) => {
				console.log(err)
				console.log('Something went wrong: ' + err.message)
				setStatus(err)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	return user ? (
		<Redirect to="/leads" />
	) : (
		<React.Fragment>
			<H2> Login</H2>
			<P>
				Please use your credentials to login. Don't have an account?{' '}
				<A tag={Link} to="/auth/register">
					Register
				</A>
			</P>
			<Formik
				initialValues={{
					email: '',
					password: ''
				}}
				onSubmit={onUserLogin}
				validationSchema={validationSchema}
			>
				{({ isSubmitting, status }) => {
					return (
						<Form tag={FormikForm}>
							<FormGroup>
								<Input placeholder="Email Address" type="email" name="email" />
							</FormGroup>
							<FormGroup>
								<Input placeholder="Password" type="password" name="password" />
							</FormGroup>
							{status && <Alert color="danger">{status.message}</Alert>}

							<div className="d-xxxs-flex justify-content-xxxs-between align-items-xxxs-center pt-xxxs-4">
								<A tag={Link} to="/auth/forgot-password">
									Forgot Password
								</A>
								<Button type="submit" color="primary" disabled={isSubmitting} submitting={isSubmitting}>
									Login
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
			<Hr />
			<div className="d-xxxs-flex justify-content-xxxs-between align-items-xxxs-center">
				{/* <Button color="warning" onClick={() => onSocialLogin('facebook')}>
					Login with <FontAwesomeIcon icon={faFacebook} />
				</Button> */}
				<Button color="warning" onClick={() => signInWithGoogle()}>
					Login with <FontAwesomeIcon icon={faGoogle} />
				</Button>
			</div>
		</React.Fragment>
	)
}

export default Login
