import React, { useEffect } from 'react'
import { Row, Form, P, InputGroup, Hr } from '@bootstrap-styled/v4'
import { Col, Button, ActionButton } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Typeahead, FormGroup, Select, Label } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import ContactCard from '~/components/common/ContactCard'
import ContactForm from '~/components/common/ContactForm'
import { AssignedToSelect } from '~/components/common/Forms'
import { faMinus } from '@fortawesome/pro-solid-svg-icons'

const LeadForm = ({ initialValues, onSubmit, qualified }) => {
	const { dispatch, entityThunks, selectors, user, lumenoxUser, userHasRole } = useLumenox()
	const validationSchema = Yup.object({
		contacts: Yup.array().of(Yup.string()).min(1, 'Minimum one contact').required('Required'),
		assignedTo: Yup.string().when([], {
			is: () => qualified,
			then: Yup.string(),
			otherwise: Yup.string().required('Required')
		}),
		requestDetails: Yup.object({
			controlBox: Yup.string().notRequired(),
			locations: Yup.array().of(Yup.string()).notRequired()
		}).notRequired()
	})

	useEffect(() => {
		dispatch(entityThunks.fetchUsersByRole('sales'))
	}, [])

	const handleNewContactSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.addContact({ values })).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			resetForm()
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	const removeContact = (index, values, setFieldValue) => {
		setFieldValue(
			'contacts',
			values.contacts.filter((c, i) => i !== index)
		)
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				return (
					<Form tag={FormikForm}>
						<Row className="mb-xxxs-3">
							<Col xxxs={12}>
								<InputGroup>
									<Typeahead
										name="contacts"
										multiple
										placeholder="Search for existing contact..."
										fetchOptions={(searchString) =>
											dispatch(entityThunks.searchContacts(searchString)).then((response) => {
												if (response.payload.result) {
													return response.payload.result
												}
												return []
											})
										}
									>
										{({ current, selectOption, option }) => <ContactOption id={option} onClick={selectOption} />}
									</Typeahead>
									<ActionButton color="success" button="New Contact" title="Add New Contact" className="ml-xxxs-3">
										{(closeModal) => (
											<ContactForm
												onSuccess={(id) => {
													setFieldValue('contacts', values.contacts.concat([id]))
													closeModal()
												}}
												onSubmit={handleNewContactSubmit}
											/>
										)}
									</ActionButton>
								</InputGroup>
								{values.contacts &&
									values.contacts.map((id, i) => (
										<ContactCard
											key={id}
											id={id}
											ExtraHeaderButton={() => (
												<Button onClick={() => removeContact(i, values, setFieldValue)} size="sm" icon={faMinus} />
											)}
										/>
									))}
								{!qualified && (
									<React.Fragment>
										<Hr />
										<FormGroup>
											<Label>Assigned Sales Representative:</Label>
											<AssignedToSelect
												onChange={(option, action) => {
													console.log(option, action)
													switch (action.action) {
														case 'select-option':
															setFieldValue('assignedTo', option.value)
															setFieldTouched('assignedTo', true)
															break
													}
												}}
												selected={values.assignedTo}
											/>
										</FormGroup>
									</React.Fragment>
								)}
							</Col>
						</Row>
						{qualified && (
							<Row>
								<Col xxxs>
									<FormGroup>
										<Label>Preferred Box Location</Label>
										<Select
											name="requestDetails.controlBox"
											options={[
												{ label: 'Exterior', value: 'exterior' },
												{ label: 'Garage', value: 'garage' }
											]}
										/>
									</FormGroup>
								</Col>
								<Col xxxs>
									<FormGroup>
										<Label>Elevations</Label>
										<Select
											name="requestDetails.locations"
											options={[
												{ label: 'Front', value: 'front' },
												{ label: 'Left', value: 'left' },
												{ label: 'Right', value: 'right' },
												{ label: 'Back', value: 'back' }
											]}
											isMulti
										/>
									</FormGroup>
								</Col>
							</Row>
						)}
						<Hr />
						<Button type="submit" color="primary" disabled={isSubmitting} submitting={isSubmitting} noBorders>
							Save Lead
						</Button>
						<Row>
							<Col xxxs={12} lg={6}>
								{/* <ErrorAlert isSubmitting={isSubmitting} isValid={isValid}>
											<Strong>Alert!</Strong> Please fix the submission errors above.
										</ErrorAlert> */}
							</Col>
						</Row>

						{/* <FormConfirmatonModal isOpen={status !== null} title={status ? 'Success!' : 'Error!'} handleModalClose={() => setStatus(null)}>
									{status ? (
										<Fragment>
											<P>Your information has been received.</P>
											<P>We will be in contact with you shortly.</P>
										</Fragment>
									) : (
										<Fragment>
											<P>Something went wrong!</P>
											<P>There was an error submitting your request. Please try again.</P>
										</Fragment>
									)}
								</FormConfirmatonModal> */}
					</Form>
				)
			}}
		</Formik>
	)
}

const ContactOption = ({ id, onClick }) => {
	const { selectors } = useLumenox()
	const contact = useSelector((state) => selectors.selectContactById(state, id))
	return (
		<Row onClick={() => onClick(id)}>
			<Col xxxs={12}>
				<P className="mb-xxxs-0">
					{contact.name.first} {contact.name.last}
				</P>
			</Col>
		</Row>
	)
}

export default LeadForm
