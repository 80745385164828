import React from 'react'
import { useSelector } from 'react-redux'
import { Row } from '@bootstrap-styled/v4'

import { useLumenox } from '~/services/lumenox'
import { ActionButton, Col } from '~/components/bootstrap'

import NoteForm from '../../../NoteForm'
import { faEdit } from '@fortawesome/pro-solid-svg-icons' //not working for some reason
import { CardFooter } from '@bootstrap-styled/v4/lib/Cards'

const Note = (props) => {
	return (
		<CardFooter>
			<Row className="justify-content-xxxs-end">
				<Col xxxs="auto">
					<NoteButton {...props} />
				</Col>
			</Row>
		</CardFooter>
	)
}

const NoteButton = ({ id, opportunityId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	const noteFormValues = {
		content: activity.content,
		pinned: activity.pinned
	}

	const removeWhitespaceAfterBold = (s) => {
		let opened = false
		for (let i = 0; i < s.length - 1; i++) {
			if (!opened) {
				if (s.charAt(i) === '*' && s.charAt(i + 1) == '*') {
					opened = true
				}
			} else {
				if (s.charAt(i) === '*' && s.charAt(i + 1) == '*') {
					if (s.charAt(i - 1) === ' ') {
						//move space to after **
						s = s.slice(0, i - 1) + '** ' + s.slice(i + 2)
					}
					opened = false
				}
			}
		}
		return s
	}

	const handleNoteFormSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		values.content = removeWhitespaceAfterBold(values.content)
		dispatch(entityThunks.updateOpportunityActivity({ opportunityId, id, values })).then((response) => {
			console.log('success', response)
			// todo error handling
			setSubmitting(false)
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	return (
		<ActionButton size="sm" color="info" icon={faEdit} button="" title="Edit Note">
			{(closeModal) => <NoteForm onSuccess={() => closeModal()} onSubmit={handleNoteFormSubmit} initialValues={noteFormValues} />}
		</ActionButton>
	)
}

export default Note
