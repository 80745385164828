import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, H1, H2, H3, Hr, Row } from '@bootstrap-styled/v4'
import { Col, Button, ActionButton } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { callApi } from '~/services/api'
import CompensationProgramForm from './components/CompensationProgramForm'
import CompensationProgramTable from './components/CompensationProgramTable'

const ProgramsPage = () => {
	return (
		<React.Fragment>
			<Row className="justify-content-xxxs-end mb-xxxs-3">
				<Col xxxs>
					<H3>Compensation Programs</H3>
				</Col>
				<Col xxxs="auto">
					<ActionButton button="Add Program" icon={faPlus} title="Add Program">
						{(setIsOpen) => <CompensationProgramForm setIsOpen={setIsOpen} />}
					</ActionButton>
				</Col>
			</Row>
			<CompensationProgramTable />
		</React.Fragment>
	)
}

export default ProgramsPage
