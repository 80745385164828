import React from 'react'
import { useSelector } from 'react-redux'
import { CardFooter, Row } from '@bootstrap-styled/v4'

import { useLumenox } from '~/services/lumenox'
import { ActionButton, Col } from '~/components/bootstrap'

import FollowUpForm from '../../../FollowUpForm'
import { faEdit } from '@fortawesome/pro-solid-svg-icons' //not working for some reason

const FollowUp = (props) => {
	return (
		<CardFooter>
			<Row className="justify-content-xxxs-end">
				<Col xxxs="auto">
					<FollowUpButton {...props} />
				</Col>
			</Row>
		</CardFooter>
	)
}

const FollowUpButton = ({ id, opportunityId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	const followUpFormValues = {
		details: activity.details,
		createdAt: activity.createdAt,
		type: activity.type
	}

	const handleFollowUpFormSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.updateOpportunityActivity({ opportunityId, id, values })).then((response) => {
			console.log('success', response)
			// todo error handling
			setSubmitting(false)
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	return (
		<ActionButton size="sm" color="info" icon={faEdit} button="" title="Edit Follow Up">
			{(closeModal) => <FollowUpForm onSuccess={() => closeModal()} onSubmit={handleFollowUpFormSubmit} initialValues={followUpFormValues} />}
		</ActionButton>
	)
}

export default FollowUp
