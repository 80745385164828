import React from 'react'
import AssignStageButton from './components/AssignStageButton'
import AutoAssignStageButton from './components/AutoAssignStageButton'

const NeedsModificationStageButton = ({ opportunity, currentStage, nextStage, ...rest }) => {
	return currentStage.slug === 'quote-sent' ? (
		<AutoAssignStageButton opportunity={opportunity} currentStage={currentStage} nextStage={nextStage} autoAssignToSlug="quoter" />
	) : (
		<AssignStageButton
			opportunity={opportunity}
			currentStage={currentStage}
			nextStage={nextStage}
			{...rest}
			roleOptionsSlugs={['quoter']}
			assignMessage="Assign the lead to the quoter who will modify the quote:"
		/>
	)
}

export default NeedsModificationStageButton
