import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, CenteredModal } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Hr, ModalBody, ModalHeader, P, Form } from '@bootstrap-styled/v4'
import { DatePicker, Textarea, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm } from 'formik'

const InstallTimelineScheduledStageButton = ({ opportunity, currentStage, nextStage }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const [assignModalOpen, setAssignModalOpen] = useState(false)
	const installerRole = useSelector((state) => selectors.selectUserRoleBySlug(state, 'installer'))
	const productionRole = useSelector((state) => selectors.selectUserRoleBySlug(state, 'production'))
	const allUsers = useSelector((state) => selectors.selectAllUsers(state))
	const currentAssignedTo = useSelector((state) => selectors.selectUserById(state, opportunity.assignedTo))
	const currentInstaller = useSelector((state) => selectors.selectUserById(state, opportunity.installer))
	const [selectedAssignedTo, setSelectedAssignedTo] = useState(null)
	const [selectedInstaller, setSelectedInstaller] = useState(null)
	const [message, setMessage] = useState('')
	const [tentativeInstallDate, setTentativeInstallDate] = useState(null)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const installerOptions =
		installerRole && allUsers ? allUsers.filter((user) => user.roles.includes(installerRole.id)).map((user) => ({ label: user.name, value: user.id })) : []
	const assignedToOptions =
		productionRole && allUsers
			? allUsers.filter((user) => user.roles.includes(productionRole.id)).map((user) => ({ label: user.name, value: user.id }))
			: []

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	useEffect(() => {
		if (currentAssignedTo && productionRole && currentAssignedTo.roles.includes(productionRole.id)) {
			setSelectedAssignedTo({ label: currentAssignedTo.name, value: currentAssignedTo.id })
		}
	}, [currentAssignedTo, productionRole])

	useEffect(() => {
		if (currentInstaller) {
			setSelectedInstaller({ label: currentInstaller.name, value: currentInstaller.id })
		}
	}, [currentInstaller])

	const submit = () => {
		setIsSubmitting(true)
		//update assignedTo even if it's already assigned to that person so that message is sent
		dispatch(entityThunks.updateOpportunityAssignedTo({ id: opportunity.id, userId: selectedAssignedTo.value, assignMessage: message }))
		//update tentative install date
		dispatch(entityThunks.updateOpportunityInstallDate({ id: opportunity.id, installDate: { tentative: tentativeInstallDate, confirmed: null } }))
		//set installer
		if (selectedInstaller && selectedInstaller.value !== opportunity.installer) {
			dispatch(entityThunks.updateOpportunityInstaller({ id: opportunity.id, userId: selectedInstaller.value }))
		}
		dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			//don't need anything, can't fail since dependencies are already checked
			setIsSubmitting(false)
		})
	}

	const onChangeAssignedTo = (option, action) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				setSelectedAssignedTo(option)
				break
		}
	}

	const onChangeInstaller = (option, action) => {
		switch (action.action) {
			case 'clear':
				setSelectedInstaller(null)
				break
			case 'select-option':
				setSelectedInstaller(option)
				break
		}
	}

	const onChangeTentativeInstallDate = (value) => {
		setTentativeInstallDate(value)
	}

	if (!dependenciesMet) return null

	return (
		<React.Fragment>
			<Button color={nextStage.color} icon={solid[nextStage.icon]} onClick={() => setAssignModalOpen(true)}>
				{nextStage.name}
			</Button>
			<CenteredModal isOpen={assignModalOpen} size="">
				{/*Turns out there is no md, "" gives 'medium' size*/}
				<ModalHeader toggle={() => setAssignModalOpen(false)}>Assign Lead</ModalHeader>
				<ModalBody>
					<P>Tentative Install Date:</P>
					<ControlledDatePicker value={tentativeInstallDate} onChange={onChangeTentativeInstallDate} />
					<Hr />
					<P>Assign the lead to a production user:</P>
					<UncontrolledSelect value={selectedAssignedTo} options={assignedToOptions} onChange={onChangeAssignedTo} />
					<P className="mt-xxxs-3">Message to who you are assigning to:</P>
					<ControlledTextArea value={message} onChange={(e) => setMessage(e.target.value)} />
					<Hr />
					<P>Assign the installer:</P>
					<UncontrolledSelect value={selectedInstaller} options={installerOptions} onChange={onChangeInstaller} isClearable />
					<Hr />
					<Button
						onClick={submit}
						disabled={selectedAssignedTo === null || tentativeInstallDate === null}
						color="success"
						submitting={isSubmitting}
						block
					>
						Continue
					</Button>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

const ControlledTextArea = ({ value, onChange }) => {
	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={{ text: '' }} validationSchema={null} onSubmit={null}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<Textarea name="text" value={value} onChange={onChange} />
					</Form>
				)
			}}
		</Formik>
	)
}

const ControlledDatePicker = ({ value, onChange }) => {
	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={{ date: null }} validationSchema={null} onSubmit={null}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<DatePicker name="date" value={value} onChange={onChange} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default InstallTimelineScheduledStageButton
