import React, { Fragment, PureComponent, useEffect, useState } from 'react'
import withWidth, { isWidthUp, isWidthDown } from 'bootstrap-styled/lib/withWidth'
import { useSelector } from 'react-redux'
import { H3, Img } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
/* import LeadForm from '../components/LeadForm' */

import { Redirect, useRouteMatch } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'
import { useFirebase } from '~/services/firebase'
import QuoteForm from '../QuoteForm'

const View = ({ dirty, setDirty, formRef }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const [redirect, setRedirect] = useState(null)
	const { url, params } = useRouteMatch({
		path: '/leads/:id/quotes/:quoteId',
		strict: false
	})

	const quote = useSelector((state) => selectors.selectNormalizedQuoteFormValuesById(state, { quote: params.quoteId }))
	const quoteLocations = useSelector((state) => selectors.selectQuoteLocationsFormOptionsByQuoteId(state, { quote: params.quoteId }))

	const handleSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
		setSubmitting(true)

		const formData = new FormData()

		values.runs.forEach((run, r) => {
			run.segments.forEach((segment, s) => {
				if (segment.images && segment.images.length > 0) {
					segment.images.forEach((image, i) => {
						//formData.append(`runs[${r}][segments][${s}][]`, image, `runs[${r}][segments][${s}][${i}]`)
						if (typeof image.url === 'object') {
							formData.append(`runs`, image.url, `${r}.${s}.${i}`)
						}
					})
				}
			})
		})

		if (values.lineItems) {
			values.lineItems.forEach((lineItem, l) => {
				if (lineItem.images && lineItem.images.length > 0) {
					lineItem.images.forEach((image, i) => {
						if (typeof image.url === 'object') {
							formData.append(`lineItems`, image.url, `${l}.${i}`)
						}
					})
				}
			})
		}

		values.files = values.files.map((file, f) => {
			if (typeof file.url === 'object') {
				formData.append(`files`, file.url, file.url.name)
				return { url: file.url.name }
			} else {
				return file
			}
		})

		formData.append('data', JSON.stringify(values))
		//console.log(JSON.stringify(values))

		return dispatch(entityThunks.updateQuote({ values: formData, opportunityId: params.id, quoteId: params.quoteId })).then((response) => {
			// todo error handling
			setSubmitting(false)
			/* if (response.meta.requestStatus === 'fulfilled') {
				setRedirect(response.payload.result)
			} */
			return response
		})
	}

	return (
		<Fragment>
			{redirect && <Redirect to={`${redirect}`} />}
			<QuoteForm onSubmit={handleSubmit} initialValues={{ ...quote }} locations={quoteLocations} dirty={dirty} setDirty={setDirty} formRef={formRef} />
		</Fragment>
	)
}

export default View
