import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader, H5, Thead, Tr, Th, Td, Tbody, ButtonGroup } from '@bootstrap-styled/v4'
import { format } from 'date-fns'
import { BackLink } from '~/components/common/Text'
import { Button, Col, Table, ConfirmationButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faSignal1, faSignal2, faSignal3, faSignal4, faSparkles, faAward } from '@fortawesome/pro-duotone-svg-icons'
import { faPlus, faSearch, faTrash } from '@fortawesome/pro-regular-svg-icons'
import AddQuoteForm from '../AddQuoteForm'
import { Link, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '~/components/common/PrivateRoute'
import InstallationDiagramRoutes from '../../../InstallationDiagrams'

const InstallationDiagram = ({ opportunityId }) => {
	const { selectors } = useLumenox()
	const { url } = useRouteMatch()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))

	if (!opportunity) {
		return null
	}

	return (
		<Fragment>
			<Card>
				<CardHeader>
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H5 className="mb-xxxs-0">Installation Diagram</H5>
						</Col>
					</Row>
				</CardHeader>
				<Table>
					<Thead>
						<Tr>
							<Th width="40%">Location</Th>
							<Th width="30%">Date Created</Th>
							<Th width="20%">Total</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>{opportunity.quotes && opportunity.quotes.map((quoteId) => <DiagramRow key={quoteId} id={quoteId} />)}</Tbody>
				</Table>
			</Card>
			<PrivateRoute path={`${url}/installation-diagram`} component={InstallationDiagramRoutes} />
		</Fragment>
	)
}

const DiagramRow = ({ id }) => {
	const { selectors } = useLumenox()
	const { url } = useRouteMatch()

	const quote = useSelector((state) => selectors.selectQuoteById(state, id))

	if (!quote) {
		return null
	}

	return (
		<Tr>
			<Td>{quote.name}</Td>
			<Td>{format(new Date(quote.createdAt), 'MMM-d-y')}</Td>
			<Td></Td>
			<Td>
				<Button size="sm" tag={Link} color="info" to={`${url}/installation-diagram/${id}`} icon={faSearch} />
			</Td>
		</Tr>
	)
}

export default InstallationDiagram
