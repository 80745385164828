import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Nav, NavItem, NavLink, Badge } from '@bootstrap-styled/v4'
import { Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import {
	ContactName,
	AssignedName,
	StageName,
	LastActivity,
	LastQuoteSentDate,
	TagName,
	StageDateDaysSince,
	UserName,
	LastFollowUpDaysSince,
	StageDate
} from '../components/common'
import { RelativeDate } from '~/components/common/Text'
import { SortTh } from '~/components/bootstrap/components/Table'

const QuoteSent = ({ assignedOnly }) => {
	const { dispatch, entityThunks, selectors, actions, user } = useLumenox()
	const [loading, setLoading] = useState(false)
	const [sortProp, setSortProp] = useState('quote-sent')
	const [sortDir, setSortDir] = useState('desc')
	const { params } = useRouteMatch({
		path: '/:slug',
		strict: false
	})

	const skipLoad = () => {
		dispatch(actions.opportunity.skipLoading())
	}

	const leads = useSelector((state) =>
		selectors.selectQuoteSentLeadsSortedByParam(state, { direction: sortDir, prop: sortProp, assignedTo: assignedOnly ? user.uid : null })
	)

	useEffect(() => {
		setLoading(true)
		dispatch(entityThunks.fetchOpportunities({ slug: params.slug, limit: 200, assignedOnly }))
			//.then(() => dispatch(entityThunks.fetchOpportunities({ slug: params.slug, skip: 200 })))
			.then(() => {
				setLoading(false)
			})
	}, [params.slug, assignedOnly])

	const onSortHandler = (prop) => {
		if (prop === sortProp) {
			setSortDir(sortDir === 'desc' ? 'asc' : 'desc')
		} else {
			setSortProp(prop)
			setSortDir('asc')
		}
	}

	return (
		<Table bordered striped responsive loading={loading}>
			<Thead>
				<Tr>
					<Th width="50%">Name</Th>
					<Th width="15%">Tags</Th>
					<Th>Assigned To</Th>
					<SortTh active={sortProp === 'last-activity'} direction={sortDir} onClick={() => onSortHandler('last-activity')}>
						Last Activity
					</SortTh>
					<SortTh active={sortProp === 'last-follow-up'} direction={sortDir} onClick={() => onSortHandler('last-follow-up')}>
						Last Follow Up
					</SortTh>
					<SortTh active={sortProp === 'quote-sent'} direction={sortDir} onClick={() => onSortHandler('quote-sent')}>
						Quote Sent
					</SortTh>
				</Tr>
			</Thead>
			<Tbody>
				{leads
					//.sort((a, b) => a.updatedAt < b.updatedAt)
					.map((lead, i) => {
						return (
							<Tr key={i}>
								<Td>
									<A tag={Link} to={`/leads/${lead.id}`} onClick={skipLoad}>
										{lead.contacts.map((contact, c) => (
											<ContactName key={c} id={contact} />
										))}
									</A>
								</Td>
								<Td>
									{lead.tags.map((tag, t) => (
										<Badge color="info" style={{ whiteSpace: 'normal' }}>
											<TagName key={tag} id={tag} />
										</Badge>
									))}
								</Td>
								<Td>
									{lead.assignedTo && (
										<Badge color="primary">
											<UserName id={lead.assignedTo} />
										</Badge>
									)}
								</Td>
								<Td>
									<LastActivity id={lead.id} />
								</Td>
								<Td>
									<LastFollowUpDaysSince id={lead.id} />
								</Td>
								<Td className="align-xxxs-middle">
									<StageDate id={lead.id} stage="quote-sent" />
								</Td>
							</Tr>
						)
					})}
			</Tbody>
		</Table>
	)
}

export default QuoteSent
