import { createAsyncThunk } from '@reduxjs/toolkit'
import { callPublicApi, callApi } from '~/services/api'

export const fetchUserRoles = createAsyncThunk('userRoles/fetch', (id) => {
	return callApi({
		endpoint: `users/roles`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

/* export const fetchInvite = createAsyncThunk('users/fetchInvite', (id, { getState, requestId }) => {
	return callPublicApi({
		endpoint: `users/invite/${id}`
	}).then((response) => {
		return response.data
	})
})

export const claimInvite = createAsyncThunk('users/claimInvite', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `users/invite/${id}`,
		method: 'PUT'
	}).then((response) => {
		return response.data
	})
})

export const addUser = createAsyncThunk('users/add', () => {
	return callApi({
		endpoint: `users`,
		method: 'POST'
	}).then((response) => {
		return response.data
	})
})

export const addUser = createAsyncThunk('users/fetchById', (id) => {
	return callApi({
		endpoint: `users/${id}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
}) */

/* export const fetchCourseById = createAsyncThunk('courses/fetchById', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `courses/${id}`
	}).then((response) => {
		return response.data
	})
})

export const fetchCourses = createAsyncThunk('courses/fetch', () => {
	return callApi({
		endpoint: `courses`
	}).then((response) => {
		return response.data
	})
}) */
