import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import { Container, Row, H1, H2, Hr } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { useRouteMatch } from 'react-router'

import Listing from './Listing'
/* import Add from './Add'
import View from './View' */
/* import EditCourse from './EditCourse' */

const Users = () => {
	const { url } = useRouteMatch()

	return (
		<Wrapper className="p-xxxs-3">
			<Switch>
				<Route path={`${url}/`} component={Listing} />
				{/* <Route path={`${url}/new`} component={Add} />
				<Route path={`${url}/:id`} component={View} /> */}
			</Switch>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${({ theme }) => theme['$gray-light']};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default Users
