import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Row, Table as BsTable, Td, Th } from '@bootstrap-styled/v4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAsc, faSortDesc } from '@fortawesome/pro-solid-svg-icons'
import { faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons'
import Col from './Col'

export default styled(({ loading, ...rest }) => {
	return <BsTable {...rest} />
})`
	thead,
	tbody,
	tfoot {
		> tr {
			th {
				font-family: ${({ theme }) => theme['$font-family-serif']};
				font-weight: ${({ theme }) => theme['$font-weight-normal']};
			}

			th,
			td {
				&:first-child {
					text-align: left;
				}

				input {
					text-align: center;
				}
			}
		}
	}

	&.table th,
	th {
		text-align: center;
		text-transform: uppercase;
		vertical-align: middle !important;
	}

	&.table td,
	td {
		text-align: center;
		text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
		vertical-align: middle;
	}

	${({ loading }) =>
		loading &&
		css`
			position: relative;
			&:after {
				position: absolute;
				top: 0.25rem;
				right: 0.25rem;
				content: 'loading...';
				font-size: 0.5rem;
				z-index: 1000;
				animation: ${blink} 1s infinite;
			}
		`}}
`

export const ColoredTd = styled(({ color, children, ...rest }) => <Td {...rest}>{children}</Td>)`
	background: ${({ theme, color }) => theme[`$${color}`]};
`

export const blink = keyframes`
	0% { opacity: 100%; }
	50% { opacity: 0%; }
	100% { opacity: 100%; }
`

export const SortTh = styled(({ children, active, direction, ...rest }) => (
	<Th {...rest}>
		{children}
		{active && <FontAwesomeIcon icon={direction === 'desc' ? faSortDown : faSortUp} fixedWidth />}
	</Th>
))`
	cursor: pointer;
	position: relative;

	${({ active, theme }) =>
		active &&
		`
		padding-right: 1.5rem !important;
		svg {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			color: ${theme['$brand-primary']};
		}
		
	`}
`
