import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import withWidth, { isWidthUp, isWidthDown } from 'bootstrap-styled/lib/withWidth'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Badge } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'

import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { Redirect, useParams } from 'react-router'
import ContactCard from '~/components/common/ContactCard'
import ActivityStream, { ActivityFilterer } from './components/ActivityStream'
import Toolbar from './components/Toolbar'
import Details from './components/Details'
import Stage from './components/Stage'
import { Helmet } from 'react-helmet'
import { Fragment } from 'react'
import Cookies from 'js-cookie'

const View = () => {
	const { id } = useParams()
	const { selectors, dispatch, entityThunks, breakpointDown } = useLumenox()
	const [redirect, setRedirect] = useState(false)
	const [activityFilters, setActivityFilters] = useState(JSON.parse(Cookies.get('activityFilters') ?? null) ?? [{ label: 'All', value: 'All' }])

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))
	const mobile = breakpointDown('sm')

	const setActivityFiltersAndSave = (filters) => {
		Cookies.set('activityFilters', JSON.stringify(filters), { expires: 31 })
		setActivityFilters(filters)
	}

	useEffect(() => {
		//if (!opportunity) {
		dispatch(entityThunks.fetchOpportunityById(id)).then((response) => {
			// TODO handle not found
		})
		//}
	}, [id])

	if (!opportunity) {
		return null
		return <Redirect to="/leads" />
	}

	console.log(opportunity)

	return (
		<Fragment>
			{opportunity.contacts.length > 0 && <ContactHelmet contactId={opportunity.contacts[0]} />}
			{mobile ? (
				<MobileView id={id} opportunity={opportunity} activityFilters={activityFilters} setActivityFilters={setActivityFiltersAndSave} />
			) : (
				<DesktopView id={id} opportunity={opportunity} activityFilters={activityFilters} setActivityFilters={setActivityFiltersAndSave} />
			)}
		</Fragment>
	)
}

const DesktopView = ({ id, opportunity, activityFilters, setActivityFilters }) => {
	return (
		<React.Fragment>
			<BackLink to="/leads">Back to Leads</BackLink>
			<Toolbar opportunityId={id} />
			<Row>
				<Col xxxs={12} lg={7}>
					<div className="mb-xxxs-4">
						<H3>Contact{opportunity.contacts.length > 1 && 's'}</H3>
						{opportunity.contacts.map((contactId) => (
							<ContactCard key={contactId} id={contactId} />
						))}
					</div>
					<Hr />
					<Row>
						<Col xxxs="auto">
							<H3>Activity</H3>
						</Col>
						<Col xxxs>
							<ActivityFilterer activityFilters={activityFilters} setActivityFilters={setActivityFilters} />
						</Col>
					</Row>
					<Hr />
					<ActivityStream id={id} activityFilters={activityFilters} />
				</Col>
				<Col xxxs={12} lg={5}>
					<H3>Details</H3>
					<Hr />
					<Details id={id} />
					<Hr />
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H3>Stage</H3>
						</Col>
						<Col xxxs="auto">
							<StageBadge stageId={opportunity.stage} />
						</Col>
					</Row>
					<Hr />
					<Stage id={id} />
				</Col>
			</Row>
		</React.Fragment>
	)
}

const ContactHelmet = ({ contactId }) => {
	const { selectors } = useLumenox()
	const contact = useSelector((state) => selectors.selectContactById(state, contactId))

	return (
		<Helmet>
			<title>
				{contact.name.first} {contact.name.last} | Lumenox CRM
			</title>
		</Helmet>
	)
}

const MobileView = ({ id, opportunity, activityFilters, setActivityFilters }) => {
	return (
		<React.Fragment>
			<BackLink to="/leads">Back to Leads</BackLink>
			<Toolbar opportunityId={id} />
			<Row>
				<Col xxxs={12} lg={7}>
					<div className="mb-xxxs-4">
						<H3>Contact{opportunity.contacts.length > 1 && 's'}</H3>
						{opportunity.contacts.map((contactId) => (
							<ContactCard key={contactId} id={contactId} />
						))}
					</div>
					<Hr />
				</Col>
				<Col xxxs={12} lg={5}>
					<H3>Details</H3>
					<Hr />
					<Details id={id} />
					<Hr />
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H3>Stage</H3>
						</Col>
						<Col xxxs="auto">
							<StageBadge stageId={opportunity.stage} />
						</Col>
					</Row>
					<Hr />
					<Stage id={id} />
					<Hr />
				</Col>
				<Col xxxs={12}>
					<H3>Activity</H3>
					<ActivityFilterer activityFilters={activityFilters} setActivityFilters={setActivityFilters} />
					<Hr />
					<ActivityStream id={id} activityFilters={activityFilters} />
				</Col>
			</Row>
		</React.Fragment>
	)
}

const StageBadge = ({ stageId }) => {
	const { selectors } = useLumenox()
	const stage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, stageId))

	return <Badge>{stage.name}</Badge>
}

export default View
