import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, ButtonToolbar, ButtonGroup, ModalHeader, ModalBody, P, Ul, Li, Badge } from '@bootstrap-styled/v4'
import { ActionButton, Button, CenteredModal, ConfirmationButton } from '~/components/bootstrap'

import { BackLink } from '~/components/common/Text'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {} from '@fortawesome/pro-duotone-svg-icons'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { faCopy, faUndo } from '@fortawesome/pro-regular-svg-icons'
import NoteForm from './NoteForm'
import * as stageButtons from './stageButtons'
import { startCase } from 'lodash'
import FollowUpForm from './FollowUpForm'
import { Redirect } from 'react-router-dom'

const Toolbar = styled(({ opportunityId, className }) => {
	const { selectors, dispatch, entityThunks, userNotAdminHasRole } = useLumenox()
	const installer = userNotAdminHasRole('installer')

	return (
		<ButtonToolbar className={className}>
			{!installer && <StageButtonGroup opportunityId={opportunityId} />}
			<ButtonGroup>
				<NoteButton opportunityId={opportunityId} />
				<FollowUpButton opportunityId={opportunityId} />
				<DuplicateButton opportunityId={opportunityId} />
			</ButtonGroup>
		</ButtonToolbar>
	)
})`
	margin-bottom: 1rem;
	${ButtonGroup} {
		&:not(:first-child) {
			margin-left: 1rem;
		}
	}
`

const StageButtonGroup = ({ opportunityId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	useEffect(() => {
		dispatch(entityThunks.fetchOpportunityStageTypes())
	}, [])

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))
	const stage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, opportunity.stage))
	const stages = useSelector((state) => selectors.selectOpportunityStageTypeEntities(state))

	const undoStage = (closeModal) => {
		dispatch(entityThunks.undoOpportunityStage({ id: opportunityId })).then((response) => {
			closeModal()
		})
	}

	if (!stage.nextV2 || stage.nextV2.length === 0) {
		return <ConfirmationButton color="dark" icon={faUndo} handleConfirmation={undoStage} description="Are you sure you want to undo the last stage?" />
	}

	return (
		<React.Fragment>
			<ButtonGroup>
				{stage.nextV2
					.slice()
					.sort((a, b) => {
						if (!stages[a.stage] || !stages[b.stage]) {
							return 0
						}
						return stages[a.stage].percentage - stages[b.stage].percentage
					})
					.map((next) => {
						const nextStageId = next.stage
						const nextStage = stages[nextStageId]

						if (!nextStage) {
							return null
						}

						let StageButton =
							stageButtons[
								startCase(
									nextStage.name.replace(/-([a-z])/g, function (g) {
										return g[1].toUpperCase()
									})
								).replace(/ /g, '')
							]

						if (!StageButton) {
							StageButton = stageButtons.DefaultStageButton
						}

						return <StageButton key={nextStageId} opportunity={opportunity} currentStage={stage} nextStage={nextStage} />
					})}
				<ConfirmationButton color="dark" icon={faUndo} handleConfirmation={undoStage} description="Are you sure you want to undo the last stage?" />
			</ButtonGroup>
		</React.Fragment>
	)
}

const NoteButton = ({ opportunityId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const noteFormValues = {
		content: ''
	}

	const removeWhitespaceAfterBold = (s) => {
		let opened = false
		for (let i = 0; i < s.length - 1; i++) {
			if (!opened) {
				if (s.charAt(i) === '*' && s.charAt(i + 1) == '*') {
					opened = true
				}
			} else {
				if (s.charAt(i) === '*' && s.charAt(i + 1) == '*') {
					if (s.charAt(i - 1) === ' ') {
						//move space to after **
						s = s.slice(0, i - 1) + '** ' + s.slice(i + 2)
					}
					opened = false
				}
			}
		}
		return s
	}

	const handleNoteFormSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		values.content = removeWhitespaceAfterBold(values.content)
		dispatch(entityThunks.addOpportunityActivityNote({ id: opportunityId, values })).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	return (
		<ActionButton color="secondary" icon={solid.faStickyNote} button="Add Note" title="Add Note">
			{(closeModal) => <NoteForm onSuccess={() => closeModal()} onSubmit={handleNoteFormSubmit} initialValues={noteFormValues} />}
		</ActionButton>
	)
}

const FollowUpButton = ({ opportunityId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const followUpFormValues = {
		details: '',
		createdAt: null,
		type: ''
	}

	const handleFollowUpFormSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.addOpportunityFollowUp({ id: opportunityId, values })).then((response) => {
			console.log('success', response)
			// todo error handling
			setSubmitting(false)
			if (onSuccess && response.meta.requestStatus === 'fulfilled') {
				onSuccess(response.payload.result)
			}
		})
	}

	return (
		<ActionButton button="Add Follow Up" title="Add Follow Up" color="secondary" icon={solid.faClipboardListCheck}>
			{(setIsOpen) => (
				<FollowUpForm
					opportunityId={opportunityId}
					initialValues={followUpFormValues}
					onSubmit={handleFollowUpFormSubmit}
					onSuccess={() => setIsOpen(false)}
				/>
			)}
		</ActionButton>
	)
}

const DuplicateButton = ({ opportunityId }) => {
	const { dispatch, entityThunks } = useLumenox()
	const [submitting, setSubmitting] = useState(false)
	const [redirect, setRedirect] = useState(null)

	const duplicateLead = (setIsOpen) => {
		setSubmitting(true)
		dispatch(entityThunks.duplicateOpportunity(opportunityId)).then((response) => {
			if (response.meta.requestStatus === 'fulfilled') {
				setRedirect(`/leads/${response.payload.result}`)
				setSubmitting(false)
				setIsOpen(false)
			}
		})
	}

	return (
		<React.Fragment>
			{redirect && <Redirect to={`${redirect}`} />}
			<ConfirmationButton
				button="Duplicate Lead"
				icon={solid.faCopy}
				color="secondary"
				handleConfirmation={(setIsOpen) => duplicateLead(setIsOpen)}
				description={`Are you sure you want to duplicate this lead?`}
				confirmButtonSubmitting={submitting}
			/>
		</React.Fragment>
	)
}

export default Toolbar
