import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, H1, H2, H3, Hr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import StageForm from './components/StageForm'
import StageTable from './components/StageTable'
import { callApi } from '~/services/api'

const Stages = () => {
	return (
		<Wrapper className="p-xxxs-3">
			<Row className="justify-content-xxxs-end mb-xxxs-3">
				<Col xxxs>
					<H3>Stages</H3>
				</Col>
				<Col xxxs="auto">
					<ActionButton button="Add Stage" icon={faPlus} title="Add Stage">
						{(setIsOpen) => <StageForm setIsOpen={setIsOpen} />}
					</ActionButton>
				</Col>
			</Row>
			<StageTable />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${({ theme }) => theme['$gray-light']};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default Stages
