import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'
import { fetchOpportunityById } from '~/state/entities/opportunity/thunks'

export const fetchSiteVisitById = createAsyncThunk('siteVisit/fetchById', (id) => {
	return callApi({
		endpoint: `opportunities/site-visit/${id}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})
/*
//not sure we need this
export const fetchControllersByOpportunityId = createAsyncThunk('controllers/getControllers', (id, { getState, requestId }) => {
	//return fetchOpportunityById(id).then((opportunity) => opportunity.controllers)//didn't work
	return callApi({
		endpoint: `opportunities/${id}`
	})
		.then((response) => {
			console.log('response.data:', response.data)
			return response.data
		})
		.then((opportunity) => {
			console.log(opportunity)
			return opportunity.controllers
		})
})

export const addControllerToOpportunity = createAsyncThunk('controllers/addController', ({ id, deviceID, verificationCode }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}/controllers`,
		method: 'POST',
		data: { deviceID, verificationCode }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityController = createAsyncThunk(
	'controllers/updateController',
	({ opportunityId, controllerId, verificationCode }, { getState, requestId }) => {
		//id here is controller id
		return callApi({
			endpoint: `opportunities/${opportunityId}/controllers/${controllerId}`,
			method: 'PUT',
			data: { verificationCode }
		}).then((response) => {
			return response.data
		})
	}
)

export const removeOpportunityController = createAsyncThunk('controllers/removeController', ({ opportunityId, controllerId }, { getState, requestId }) => {
	//id here is controller id
	return callApi({
		endpoint: `opportunities/${opportunityId}/controllers/${controllerId}`,
		method: 'DELETE'
	}).then((response) => {
		return response.data
	})
})
*/
