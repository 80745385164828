import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchCompensationPrograms = createAsyncThunk('compensationPrograms/fetch', () => {
	return callApi({
		endpoint: `compensation-programs`,
		params: { activeOnly: false }
	}).then((response) => {
		return response.data
	})
})

export const fetchActiveCompensationPrograms = createAsyncThunk('compensationPrograms/fetchActive', () => {
	return callApi({
		endpoint: `compensation-programs`,
		params: { activeOnly: true }
	}).then((response) => {
		return response.data
	})
})

export const fetchCompensationProgramsByOpportunity = createAsyncThunk('compensationPrograms/fetchByOpportunity', ({ id }) => {
	return callApi({
		endpoint: `compensation-programs/by-opportunity/${id}`,
		method: 'GET'
	}).then((response) => {
		console.log('by opportunity response:', response)
		return response.data
	})
})

export const addCompensationProgram = createAsyncThunk('compensationPrograms/add', ({ data }) => {
	return callApi({
		endpoint: `compensation-programs`,
		method: 'POST',
		data
	}).then((response) => {
		return response.data
	})
})

export const updateCompensationProgram = createAsyncThunk('compensationPrograms/update', ({ id, data }) => {
	return callApi({
		endpoint: `compensation-programs/${id}`,
		method: 'PUT',
		data
	}).then((response) => {
		return response.data
	})
})
