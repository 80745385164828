import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarLinesPen } from '@fortawesome/pro-duotone-svg-icons'
import { format } from 'date-fns'

const InstallDate = ({ id, renderTimestamp }) => {
	const { selectors } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	if (!activity) {
		return null
	}

	return (
		<CardHeader>
			<Row>
				<Col xxxs="auto">
					<FontAwesomeIcon icon={faCalendarLinesPen} fixedWidth />
				</Col>
				<Col xxxs>
					{activity.type.charAt(0).toUpperCase() + activity.type.slice(1)} Install Date Updated -{' '}
					{activity.date ? format(new Date(activity.date), 'MMMM d, y') : 'Unscheduled'}
				</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default InstallDate
