import { createCachedSelector } from 're-reselect'
import { createCachedParamSelector, createParamSelector } from '~/state/entities'
import { primitiveSelectors as opportunityStageTypeSelectors } from '../index'
import { isBefore, sub } from 'date-fns'

// should be fine since we pretty much only load in stage types once
export const selectOpportunityStageTypesOrdered = createCachedSelector(
	[(state) => opportunityStageTypeSelectors.selectAllOpportunityStageTypes(state)],
	(stageTypes) => {
		return stageTypes.slice().sort((a, b) => {
			if (a.slug.startsWith('lost')) {
				return 1
			}
			if (b.slug.startsWith('lost')) {
				return -1
			}
			return a.percentage > b.percentage ? 1 : -1
		})
	}
)((state) => state.entities.OpportunityStageType?.ids?.length || '')

// same as ^
export const selectUnarchivedOpportunityStageTypesOrdered = createCachedSelector([(state) => selectOpportunityStageTypesOrdered(state)], (orderedStageTypes) =>
	orderedStageTypes.filter((stage) => !stage.archived)
)((state) => state.entities.OpportunityStageType?.ids?.length || '')

// same as ^
export const selectOpportunityStageTypeBySlug = createCachedSelector(
	[(state) => opportunityStageTypeSelectors.selectAllOpportunityStageTypes(state), (_, params) => params],
	(stageTypes, slug) => stageTypes.find((stageType) => stageType.slug === slug)
)((state) => state.entities.OpportunityStageType?.ids?.length || '')

/* 

const selectFlightRoundsWithGroups = createCachedParamSelector([selectFlightRounds, groupSelectors.selectGroupEntities], (rounds, groups) => {
	const tripRounds = {}
	rounds.map((round) => {
		tripRounds[round.id] = {
			...round,
			groups: round.groups.map((group) => groups[group]).sort((a, b) => new Date(a.teeTime) - new Date(b.teeTime))
		}
	})
	return tripRounds
})

const selectFlightRoundsWithMatches = createCachedParamSelector([selectFlightRoundsWithGroups, matchSelectors.selectMatchEntities], (rounds, matches) => {
	const tripRounds = {}
	console.log(rounds)
	if (rounds) {
		Object.entries(rounds).map(([key, round]) => {
			tripRounds[key] = {
				...round,
				groups: round.groups
					.map((group) => ({
						...group,
						matches: group.matches.map((match) => matches[match.id])
					}))
					.sort((a, b) => new Date(a.teeTime) - new Date(b.teeTime))
			}
		})
	}
	console.log(tripRounds)
	return tripRounds
})

const getCurrentMemberParam = createParamSelector((params) => params.currentMember)

export const selectCurrentGroup = createCachedParamSelector([selectFlightRoundsWithMatches, getCurrentMemberParam], (rounds, currentMember) => {
	let currentGroup = false
	if (rounds && currentMember) {
		const currentTime = new Date()
		Object.entries(rounds).every(([key, round]) => {
			return round.groups.every((group) => {
				const foundMatch = group.matches.find(
					(match) => match.teams.find((team) => team.players.find((player) => player.player === currentMember) !== undefined) !== undefined
				)

				if (foundMatch) {
					let completed = true
					group.matches.map((match) => {
						if (match.scorecard.holes.length < 18) {
							completed = false
						}
					})

					if (!completed && isBefore(sub(new Date(group.teeTime), { minutes: 15 }), currentTime)) {
						currentGroup = group
						return false
					}
				}
				return true
			})
		})
	}

	return currentGroup
})

export const calculateFlightPoints = createCachedParamSelector(
	[
		//(state, params) => selectFlightRoundsWithGroups(state, params),
		(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params),
		(state, params) => tripSelectors.selectFlightByParam(state, params)
	],
	(matches, trip) => {
		const points = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = {
				points: 0,
				front: 0,
				back: 0,
				eighteen: 0
			}
			return obj
		}, {})
		matches.map((match) => {
			const { points: matchPoints } = matchSelectors.calculateMatch(match)

			Object.entries(matchPoints).map(([team, teamPoints]) => {
				points[team].points += teamPoints.points
				points[team].front += teamPoints.front.point ?? 0
				points[team].back += teamPoints.back.point ?? 0
				points[team].eighteen += teamPoints.eighteen.point ?? 0
			})
		})
		return points
	}
)

export const calculateFlightWinningTeam = createCachedParamSelector([calculateFlightPoints], (points) => {
	const teams = Object.entries(points)

	return teams[0][1].points > teams[1][1].points ? teams[0][0] : teams[1][0]
})

export const calculateFlightPointsByPlayer = createCachedParamSelector(
	[(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params), (state, params) => tripSelectors.selectFlightByParam(state, params)],
	(matches, trip) => {
		const points = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = team.players.reduce((playersObj, player) => {
				playersObj[player.player.id] = {
					points: 0,
					front: 0,
					back: 0,
					eighteen: 0
				}
				return playersObj
			}, {})
			return obj
		}, {})

		matches.map((match) => {
			const { points: matchPoints } = matchSelectors.calculateMatch(match)
			match.teams.map((team) => {
				team.players.map((player) => {
					points[team.team][player.player].points += matchPoints[team.team].points
					points[team.team][player.player].front += matchPoints[team.team].front.point ?? 0
					points[team.team][player.player].back += matchPoints[team.team].back.point ?? 0
					points[team.team][player.player].eighteen += matchPoints[team.team].eighteen.point ?? 0
				})
			})
		})
		return points
	}
)

export const calculateFlightScoringAverageByPlayer = createCachedParamSelector(
	[(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params), (state, params) => tripSelectors.selectFlightByParam(state, params)],
	(matches, trip) => {
		const scores = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = team.players.reduce((playersObj, player) => {
				playersObj[player.player.id] = {
					gross: {
						total: 0,
						front: 0,
						back: 0
					},
					net: {
						total: 0,
						front: 0,
						back: 0
					},
					rounds: 0
				}
				return playersObj
			}, {})
			return obj
		}, {})

		matches.map((match) => {
			if (match.__t !== 'TwoPersonScramble') {
				const { scores: matchScores } = matchSelectors.calculateMatch(match)
				match.teams.map((team) => {
					team.players.map((player) => {
						scores[team.team][player.player].gross.front += matchScores[team.team][player.player].gross.front
						scores[team.team][player.player].gross.back += matchScores[team.team][player.player].gross.back
						scores[team.team][player.player].gross.total += matchScores[team.team][player.player].gross.total
						scores[team.team][player.player].net.front += matchScores[team.team][player.player].net.front
						scores[team.team][player.player].net.back += matchScores[team.team][player.player].net.back
						scores[team.team][player.player].net.total += matchScores[team.team][player.player].net.total
						scores[team.team][player.player].rounds++
					})
				})
			}
		})
		Object.entries(scores).map(([team, players]) => {
			Object.entries(players).map(([player, scoring]) => {
				const rounds = scoring.rounds
				scoring.gross.front = rounds > 0 ? scoring.gross.front / rounds : scoring.gross.front
				scoring.gross.back = rounds > 0 ? scoring.gross.back / rounds : scoring.gross.back
				scoring.gross.total = rounds > 0 ? scoring.gross.total / rounds : scoring.gross.total
				scoring.net.front = rounds > 0 ? scoring.net.front / rounds : scoring.net.front
				scoring.net.back = rounds > 0 ? scoring.net.back / rounds : scoring.net.back
				scoring.net.total = rounds > 0 ? scoring.net.total / rounds : scoring.net.total
			})
		})

		return scores
	}
)

export const calculateFlightDate = createCachedParamSelector(
	(state, params) => selectFlightRoundsWithGroups(state, params),
	(rounds) => {
		console.log(rounds)
		const roundsArr = Object.entries(rounds)
		if (roundsArr.length > 0) {
			const [key, round] = roundsArr[0]

			if (round.groups.length > 0) {
				return round.groups[0].teeTime
			}
		}
		return false
	}
)

export const calculateFlightStarted = createCachedParamSelector([(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params)], (matches) => {
	let started = false
	matches.every((match) => {
		if (match.scorecard.holes.length > 0) {
			started = true
			return false
		}
		return true
	})
	return started
})

export const calculateFlightFinished = createCachedParamSelector([(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params)], (matches) => {
	let finished = true
	matches.every((match) => {
		if (match.scorecard.holes.length !== 18) {
			finished = false
			return false
		}
		return true
	})
	return finished
})

export const calculateFlightMVP = createCachedParamSelector(
	[(state, params) => tripSelectors.selectFlightByParam(state, params), calculateFlightPointsByPlayer, calculateFlightFinished],
	(trip, points, finished) => {
		return trip.teams.reduce((obj, team) => {
			obj[team.team.id] = finished
				? Object.entries(points[team.team.id]).reduce(
						(max, [player, points]) => (points.points > max ? points.points : max),
						Object.entries(points[team.team.id])[0][1].points
				  )
				: null
			return obj
		}, {})
	}
)

const getHoleIndexParam = createParamSelector((params) => params.holeIndex)

const selectMatchHole = createCachedParamSelector(
	[(match, params) => match.nines, getHoleIndexParam],
	(nines, holeIndex) => match.nines[Math.floor(holeIndex / 9)].holes[holeIndex % 9]
)

const selectMatchNineHole = createCachedParamSelector([(match, params) => match.scorecard.holes, getHoleIndexParam], (holes, holeIndex) => holes[holeIndex]) */
