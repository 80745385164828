import React, { PureComponent, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row, Form, FormGroup, H5, Hr, H6, Input as BsInput } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'

const ContactForm = ({ initialValues, onSubmit, onSuccess }) => {
	const { entityThunks, dispatch } = useLumenox()

	const defaultInitValues = {
		name: {
			first: '',
			last: ''
		},
		email: '',
		phoneNumbers: [],
		address: {
			lines: [],
			city: '',
			state: '',
			country: '',
			postalCode: '',
			placeId: ''
		}
	}

	const hasPlaceId = initialValues && initialValues.address && initialValues.address.placeId

	const formInitialValues = {
		...defaultInitValues,
		...initialValues
	}

	const validationSchema = Yup.object().shape(
		{
			name: Yup.object().shape({
				first: Yup.string().required('Required'),
				last: Yup.string().required('Required')
			}),
			email: Yup.string()
				.email('Invalid email')
				.ensure()
				.when('phoneNumbers', {
					is: undefined,
					then: Yup.string().required('Required')
				})
				.optional(),
			phoneNumbers: Yup.array()
				.of(
					Yup.object().shape({
						number: Yup.string().required('Required'),
						extension: Yup.string().notRequired()
					})
				)
				.ensure()
				.when('email', {
					is: undefined,
					then: Yup.array().min(1, 'Minimum one')
				})
				.optional(),
			address: Yup.object({
				lines: hasPlaceId ? Yup.array().notRequired() : Yup.array().of(Yup.string()).min(1, 'Minimum one line').required('Required'),
				city: hasPlaceId ? Yup.string().notRequired() : Yup.string().required('Required'),
				state: hasPlaceId ? Yup.string().notRequired() : Yup.string().required('Required'),
				country: hasPlaceId ? Yup.string().notRequired() : Yup.string().required('Required'),
				postalCode: hasPlaceId ? Yup.string().notRequired() : Yup.string().required('Required'),
				placeId: Yup.string().notRequired()
			}).optional()
		},
		[['email', 'phoneNumbers']]
	)

	const handleAddressSearch = (placeResult, setFieldValue) => {
		geocodeByPlaceId(placeResult.value.place_id).then((result) => {
			if (result.length > 0) {
				const { address_components } = result[0]

				if (address_components) {
					const parts = address_components.reduce((obj, part) => {
						obj[part.types[0]] = part
						return obj
					}, {})

					setFieldValue('address.lines[0]', `${parts.street_number ? parts.street_number.short_name + ' ' : ''}${parts.route.short_name ?? ''}`)
					setFieldValue('address.city', parts.locality.short_name ?? '')
					setFieldValue('address.state', parts.administrative_area_level_1.short_name ?? '')
					setFieldValue('address.postalCode', parts.postal_code.short_name ?? '')
					setFieldValue('address.country', parts.country.long_name ?? '')
					setFieldValue('address.placeId', placeResult.value.place_id)
				}
			}
		})
	}

	const handleSubmit = (values, helpers) => {
		validationSchema.validate(values).then((values) => {
			onSubmit(values, helpers, onSuccess)
		})
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<FormGroup row>
							<Col xxxs={12} md={6} className="mb-xxxs-3 mb-lg-0">
								<Input type="text" name={`name.first`} placeholder="First Name" />
							</Col>
							<Col xxxs={12} md={6} className="mb-xxxs-3 mb-lg-0">
								<Input type="text" name={`name.last`} placeholder="Last Name" />
							</Col>
						</FormGroup>
						<FormGroup>
							<Input type="email" name={`email`} placeholder="Email" />
						</FormGroup>
						<Hr />
						<H6>Phone Numbers:</H6>
						<MultipleInputArray name="phoneNumbers" values={values.phoneNumbers} initialValues={{ number: '', extension: '' }}>
							{({ i, setElRef, setFocused }) => (
								<Row>
									<Col xxxs={12} md={9} className="mb-xxxs-3 mb-lg-0">
										<Input
											type="tel"
											name={`phoneNumbers.${i}.number`}
											ref={(n) => setElRef && setElRef(n, i)}
											placeholder="Phone Number"
											onClick={setFocused}
										/>
									</Col>
									<Col xxxs={12} md={3} className="mb-xxxs-3 mb-lg-0">
										<Input type="tel" name={`phoneNumbers.${i}.extension`} placeholder="Ext" />
									</Col>
								</Row>
							)}
						</MultipleInputArray>
						<Hr />
						<H6>Address:</H6>
						<GooglePlacesAutocomplete
							apiKey="AIzaSyDsnBd4lG7iFlJqpfsesNMEU7dNfZRZbOY"
							autocompletionRequest={{
								componentRestrictions: {
									country: ['us', 'ca']
								},
								types: ['address']
							}}
							selectProps={{
								onChange: (result) => handleAddressSearch(result, setFieldValue)
							}}
						/>
						{/* <ReactGoogleAutocomplete
							apiKey="AIzaSyAXImPs035Fmf7ESy8l1l_Y6qaywsXXAIQ"
							onPlaceSelected={(place) => {
								console.log(place)
							}}
						/> */}
						<Hr />
						<InputArray name="address.lines" placeholder="Address Line #%i" values={values.address.lines} />
						<FormGroup row>
							<Col xxxs={12} md={8} className="mb-xxxs-3 mb-lg-0">
								<Input type="text" name={`address.city`} placeholder="City" />
							</Col>
							<Col xxxs={12} md={4} className="mb-xxxs-3 mb-lg-0">
								<Input type="text" name={`address.postalCode`} placeholder="Zip/Postal Code" />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs={12} md={6} className="mb-xxxs-3 mb-lg-0">
								<Input type="text" name={`address.state`} placeholder="Province/State" />
							</Col>
							<Col xxxs={12} md={6} className="mb-xxxs-3 mb-lg-0">
								<Input type="text" name={`address.country`} placeholder="Country" />
							</Col>
						</FormGroup>
						<Button type="submit" color="success" block>
							Save
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

export default ContactForm
