import React, { Fragment, useEffect, useState, useRef } from 'react'
import {
	Row,
	Form,
	P,
	InputGroup,
	Hr,
	H4,
	Card,
	CardHeader,
	CardBlock,
	H6,
	Collapse,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Badge,
	H3,
	H5,
	Small,
	ButtonGroup,
	Img
} from '@bootstrap-styled/v4'
import { Col, Button, ActionButton, Table, ConfirmationButton } from '~/components/bootstrap'
import { Formik, Form as FormikForm, useField } from 'formik'
import * as Yup from 'yup'
import { round } from 'lodash'
import { Typeahead, FormGroup, Select, Label, Input, MultipleInputArray, DropzoneAreaModal } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faChevronDown, faChevronRight, faChevronUp, faPencilPaintbrush, faSearch, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons'
import { useTheme } from 'styled-components'
import { faTrash, faImage, faImages } from '@fortawesome/pro-solid-svg-icons'
import { useRouteMatch } from 'react-router'
import Ul from '@bootstrap-styled/v4/lib/Ul'
import Li from '@bootstrap-styled/v4/lib/Li'
import * as markerjs2 from 'markerjs2'
import { PenMarker } from '~/markerjs/PenMarker'
import { StartMarker } from '~/markerjs/StartMarker'
import { EndMarker } from '~/markerjs/EndMarker'

const WiringDiagramForm = (props) => {
	const { values, wiringDiagram } = props
	const { dispatch, entityThunks, selectors, user, lumenoxUser, userHasRole } = useLumenox()

	const { url, params } = useRouteMatch({
		path: '/leads/:id/wiring-diagram/:quoteId',
		strict: false
	})

	return (
		<React.Fragment>
			<Row>
				<Col xxxs={12} lg={9}>
					<Label>Location Name:</Label>
					<Label value={values.name} />
				</Col>
				<Col xxxs={12} lg={3}>
					<Label>Units Per Foot:</Label>
					<Label value={values.unitsPerFoot} className="text-xxxs-center" />
				</Col>
			</Row>
			<Hr />
			<Row className="mb-xxxs-3">
				<Col xxxs={12} lg={9}>
					<H4>Runs:</H4>
					<Runs wiringDiagram={wiringDiagram} />
				</Col>
				<Col xxxs={12} lg={3}>
					<H4>Legend:</H4>
					<Legend />

					<H4>Summary:</H4>
					<Summary wiringDiagram={wiringDiagram} />
				</Col>
			</Row>
		</React.Fragment>
	)
}

const Legend = () => {
	return (
		<React.Fragment>
			<Ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
				<Li>
					<Badge color="primary">Connector/Power Wire Length</Badge>
				</Li>
				<Li>
					<Badge color="info">Pixels to cut</Badge>
				</Li>
				<Li>
					<Badge color="success">Amperage</Badge>
				</Li>
				<Li>
					<Badge color="warning">Extra Data Wire</Badge>
				</Li>
				{/* <Li>
					<Badge className="badge-dark">Power Rails</Badge>
				</Li> */}
			</Ul>
		</React.Fragment>
	)
}

const Summary = ({ wiringDiagram }) => {
	const items = {
		...wiringDiagram.totalProducts
	}

	return (
		<Fragment>
			<Table size="sm" bordered>
				<Thead>
					<Tr>
						<Th>Type</Th>
						<Th>Amount</Th>
					</Tr>
				</Thead>
				<Tbody>
					{Object.entries(items).map(([item, amount]) => {
						return (
							<Tr key={item}>
								<Td>{item}</Td>
								<Td>{amount}</Td>
							</Tr>
						)
					})}
					<Tr>
						<Td>Total Amperage:</Td>
						<Td>{wiringDiagram.totalAmperage.toFixed(3)}</Td>
					</Tr>
				</Tbody>
			</Table>
		</Fragment>
	)
}

const Runs = ({ wiringDiagram }) => {
	//console.log('wiringDiagram in runs:', wiringDiagram)

	const runs = []

	makeRuns(wiringDiagram, runs, '', true)

	function makeRuns(branch, runsElements, parentLabel, firstCall = false) {
		let numJunctionBoxes = 0
		const junctionBoxNames = []

		if (firstCall) {
			branch.branches.forEach((b) => {
				if (!junctionBoxNames.includes(b.run.start.name)) {
					junctionBoxNames.push(b.run.start.name)
				}
			})
			numJunctionBoxes = junctionBoxNames.length
		}
		if (numJunctionBoxes > 1) {
			//first call and multiple junction boxes
			const junctionBoxes = []
			junctionBoxNames.forEach((name) => {
				junctionBoxes.push({ branches: branch.branches.filter((b) => b.run.start.name === name) })
			})
			//now we have array of objects for each junction box who's branches key contains an array of the subbranches of that junction box
			junctionBoxes.forEach((junctionBox, i) => {
				const junctionBoxChildren = []
				const junctionBoxLabel = 'JB' + (i + 1)
				if (junctionBox.branches.length > 1) {
					//we need to do a runStart for each branch off of the junctionBox
					junctionBox.branches.forEach((subBranch, j) => {
						const runStartChildren = []
						const runStartLabel = junctionBoxLabel + '-' + (j + 1)
						makeRuns(subBranch, runStartChildren, runStartLabel)
						junctionBoxChildren.push(
							<RunStart label={runStartLabel} branch={subBranch}>
								{runStartChildren}
							</RunStart>
						)
					})
				} else {
					//there is only one run off of this junction box so it doesn't need a runStart
					junctionBox.branches.forEach((subBranch) => {
						makeRuns(subBranch, junctionBoxChildren, junctionBoxLabel)
					})
				}

				runsElements.push(
					<JunctionBox key={i} label={junctionBoxLabel} name={junctionBoxNames[i]} totalAmperage={junctionBox.totalAmperage}>
						{junctionBoxChildren}
					</JunctionBox>
				)
			})
		} else {
			//could be first call or not, if it is we only have one junction box which could have any amount of runs off of it
			if (branch.branches.length > 1) {
				if (firstCall) {
					//we put them in as multiple runs, similar to junctionBox runs
					branch.branches.forEach((runBranch, i) => {
						//for each of these we want to make a RunStart since this branch will always be a connector and there will always be at least one pixel string after it
						const runStartChildren = []
						const runBranchLabel = '' + (i + 1)
						if (runBranch.branches.length > 1) {
							//it splits after this connector, so this will be a runSplit and we need to make its children
							const runSplitChildren = []
							runBranch.branches.forEach((splitBranch, i) => {
								//add this to children

								if (splitBranch.branches.length === 1) {
									//it was > 0 but now === 1 cuz if its 0 we want the else part but if its > 1 then it will actually be a split and there's no point in having that in a runStart, at least I'm pretty sure
									const runStartChildren = []
									const runStartLabel = runBranchLabel + '-' + (runSplitChildren.length + 1)
									makeRuns(splitBranch, runStartChildren, runStartLabel)
									runSplitChildren.push(
										<RunStart branch={splitBranch} label={runStartLabel}>
											{runStartChildren}
										</RunStart>
									)
								} else {
									makeRuns(splitBranch, runSplitChildren, runBranchLabel)
								}
							})
							//make Run(Split) with its children
							runsElements.push(
								<Run label={runBranchLabel} branch={runBranch}>
									{runSplitChildren}
								</Run>
							)
						} else {
							//this is just a normal run so add it and then make the next run
							runStartChildren.push(<Run label={runBranchLabel + '-' + (runStartChildren.length + 1)} branch={runBranch} />)
							makeRuns(runBranch.branches[0], runStartChildren, runBranchLabel)
							//we only want RunStart if we dont already have a runsplit
							runsElements.push(
								<RunStart label={runBranchLabel} branch={runBranch}>
									{runStartChildren}
								</RunStart>
							)
						}
					})
				} else {
					//branch.branches multiple elements, we want to add one RunSplit to hold each run
					const runSplitChildren = []
					const runSplitLabel = (parentLabel === '' ? '' : parentLabel + '-') + (runsElements.length + 1)
					branch.branches.forEach((splitBranch, i) => {
						//add this to children

						if (splitBranch.branches.length === 1) {
							//look at note in similar code above to see why its === 1 not > 0 (I think this is right)
							const runStartChildren = []
							const runStartLabel = runSplitLabel + '-' + (runSplitChildren.length + 1)
							makeRuns(splitBranch, runStartChildren, runStartLabel)
							runSplitChildren.push(
								<RunStart branch={splitBranch} label={runStartLabel}>
									{runStartChildren}
								</RunStart>
							)
						} else {
							makeRuns(splitBranch, runSplitChildren, runSplitLabel)
						}
					})
					//make Run(Split) with its children
					runsElements.push(
						<Run label={runSplitLabel} branch={branch}>
							{runSplitChildren}
						</Run>
					)
				}
			} else {
				//just one run
				if (!firstCall) {
					runsElements.push(<Run label={(parentLabel === '' ? '' : parentLabel + '-') + (runsElements.length + 1)} branch={branch} />)
				}
				if (branch.branches.length > 0) {
					makeRuns(branch.branches[0], runsElements, parentLabel)
				}
			}
		}
	}

	return <React.Fragment>{runs}</React.Fragment>
}

const JunctionBox = ({ children, label, name, totalAmperage }) => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<Card key={label} className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs={12} lg className="mb-xxxs-3 mb-lg-0">
						<Row className="align-items-xxxs-center">
							<Col xxxs="auto">
								<FontAwesomeIcon
									icon={isOpen ? faChevronUp : faChevronDown}
									fixedWidth
									onClick={() => setIsOpen(!isOpen)}
									className="pointer"
									size="lg"
								/>
								{label}
							</Col>
							<Col xxxs="auto">{name}</Col>
						</Row>
					</Col>
				</Row>
			</CardHeader>

			<Collapse isOpen={isOpen}>
				<CardBlock>{children}</CardBlock>
			</Collapse>
		</Card>
	)
}

const RunStart = ({ children, label, branch }) => {
	const [isOpen, setIsOpen] = useState(false)

	// console.log('RunStart ' + label + ':', branch)

	const calculateTotalLength = (branch) => {
		let total = branch.length
		if (branch.branches.length > 0) {
			total += branch.branches.reduce((t, subBranch) => {
				return (t += calculateTotalLength(subBranch))
			}, 0)
		}
		return total
	}

	const totalLength = Math.round(calculateTotalLength(branch)) //just rounding normally for now

	return (
		<Card key={label} className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs={12} lg className="mb-xxxs-3 mb-lg-0">
						<Row className="align-items-xxxs-center">
							<Col xxxs="auto">
								<FontAwesomeIcon
									icon={isOpen ? faChevronUp : faChevronDown}
									fixedWidth
									onClick={() => setIsOpen(!isOpen)}
									className="pointer"
									size="lg"
								/>
								{label}
							</Col>
							<Col xxxs="auto">Total Length: {totalLength}</Col>
						</Row>
					</Col>
				</Row>
			</CardHeader>

			<Collapse isOpen={isOpen}>
				<CardBlock>{children}</CardBlock>
			</Collapse>
		</Card>
	)
}

const Run = ({ label, branch, children }) => {
	//console.log('run ' + label + ':', branch)

	const [isOpen, setIsOpen] = useState(false)
	//const totalCost = branch.run && branch.run && branch.run.type ? calculateRunCost(totalLength, runTypeEntities[branch.run.type.value]) : 0
	const stepdownLocations = branch.stepdownLocations
		? branch.stepdownLocations.reduce((string, location, i) => {
				if (i == 0) {
					string += location
				} else {
					string += ', ' + location
				}
				return string
		  }, '')
		: null

	// const lengths = branch.connectorLength
	// 	? branch.connectorLength + 'ft'
	// 	: branch.powerWires
	// 	? branch.powerWires.reduce((string, wire, i) => {
	// 			if (i == 0) {
	// 				string += wire + 'ft'
	// 			} else {
	// 				string += ', ' + wire + 'ft'
	// 			}
	// 			return string
	// 	  }, '')
	// 	: null

	//this should always exist but I'll keep it conditional just to be safe
	const powerWires = branch.powerWires
		? branch.powerWires.reduce((string, wire, i) => {
				if (i == 0) {
					string += wire + 'ft'
				} else {
					string += ', ' + wire + 'ft'
				}
				return string
		  }, '')
		: null

	const dataWires = branch.dataWires
		? branch.dataWires.reduce((string, wire, i) => {
				if (i == 0) {
					string += wire + 'ft'
				} else {
					string += ', ' + wire + 'ft'
				}
				return string
		  }, '')
		: null

	const rails = branch.rails.reduce((string, rail, i) => {
		if (i === 0) {
			string += rail
		} else {
			string += ', ' + rail
		}
		return string
	}, '')

	const type = branch.run.type.name === 'Connector' ? 'Connector Run' : branch.run.type.name === 'Pixel String' ? 'Pixel Run' : 'Undefined Run Type'

	return (
		<Card key={label} className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs className="mb-xxxs-3 mb-lg-0">
						<Row className="align-items-xxxs-center">
							<Col xxxs="auto">
								<FontAwesomeIcon
									icon={isOpen ? faChevronUp : faChevronDown}
									fixedWidth
									onClick={() => setIsOpen(!isOpen)}
									className="pointer"
									size="lg"
								/>
								{label}
							</Col>
							<Col xxxs="auto">
								<Small>
									{branch.run.start.name}
									<FontAwesomeIcon icon={faArrowRight} fixedWidth className="mx-xxxs-2" />
									{branch.run.end.name}
								</Small>
							</Col>
							<Col xxxs="auto">
								<Small>(Rounded) Length: {Math.round(branch.length)}ft</Small>
							</Col>
							<Col xxxs="auto">{type}</Col>
							{branch.hadLoop && (
								<Col xxxs="auto">
									<Badge color="warning">Run causes infinite loop!</Badge>
								</Col>
							)}
						</Row>
						<Row className="align-items-xxxs-center">
							{/* {lengths && (
								<Col xxxs="auto">
									<Badge color="primary">Wire(s): {lengths} </Badge>
								</Col>
							)} */}
							{powerWires && (
								<Col xxxs="auto">
									<Badge color="primary">Power Wire(s): {powerWires}</Badge>
								</Col>
							)}
							{dataWires && (
								<Col xxxs="auto">
									<Badge color="primary">Data Wire(s): {dataWires}</Badge>
								</Col>
							)}
							{branch.pixelsToCut && (
								<Col xxxs="auto">
									<Badge color="info">{branch.pixelsToCut} pixels </Badge>
								</Col>
							)}
							{branch.amperage && (
								<Col xxxs="auto">
									<Badge color="success">{branch.amperage.toFixed(3)} amps</Badge>
								</Col>
							)}
							{branch.totalAmperage && (
								<Col xxxs="auto">
									<Badge color="success">Total amperage: {branch.totalAmperage.toFixed(3)}</Badge>
								</Col>
							)}

							{branch.loop && (
								<Col xxxs="auto">
									<Badge color="warning">Data Return</Badge>
								</Col>
							)}
							{branch.twoDataLines && (
								<Col xxxs="auto">
									<Badge color="warning">2 Data Lines</Badge>
								</Col>
							)}
						</Row>
					</Col>
					{branch.images.length > 0 && (
						<Col xxxs="auto">
							<ActionButton
								color="info"
								title={`View Image${branch.images && branch.images.length > 1 ? 's' : ''}`}
								icon={branch.images.length > 1 ? faImages : faImage}
								modalSize="xl"
								size="sm"
							>
								{(closeModal, isShown) => (
									<Fragment>
										{branch.images.map((image, i) => (
											<RunImage key={i} image={image} index={i} />
										))}
										<Row className="justify-content-xxxs-end">
											<Col xxxs="auto">
												<Button color="dark" onClick={() => closeModal(false)}>
													Close
												</Button>
											</Col>
										</Row>
									</Fragment>
								)}
							</ActionButton>
						</Col>
					)}
				</Row>
			</CardHeader>

			<Collapse isOpen={isOpen}>
				<CardBlock>
					<Segments segments={branch.segments} />
					{children && children}
				</CardBlock>
			</Collapse>
		</Card>
	)
}

const Segments = ({ segments }) => {
	//can make this look much better later
	const text = segments.reduce((t, segment, i) => {
		return t + segment.length + (i !== segments.length - 1 ? 'ft, ' : 'ft')
	}, 'Segments: ')

	return <p>{text}</p>
}

const RunImage = ({ image, index }) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [img, setImg] = useState(image)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (img.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [PenMarker, StartMarker, EndMarker, markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setImg({
					...img,
					annotation: state
				})
				setShouldRender(true)
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		if (img.annotation) {
			markerArea.current.restoreState(img.annotation)
		}

		setTimeout(() => {
			markerArea.current.render().then((dataUrl) => {
				imageRef.current.src = dataUrl
				markerArea.current.close()
			})
		}, 500)
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/quotes/${image.url}?c=${new Date()}`
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<RunImg src={src} ref={imageRef} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const RunImg = React.forwardRef((props, ref) => (
	<img crossOrigin="anonymous" ref={ref} {...props} style={{ maxWidth: '100%', width: '125vh', imageOrientation: 'none' }} />
))

export default WiringDiagramForm
