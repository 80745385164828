import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { A, Row, Thead, Tbody, Tr, Td, Th, Nav, NavItem, NavLink, Collapse, Button } from '@bootstrap-styled/v4'
import { Col, Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { startCase, camelCase } from 'lodash'
import { lighten, readableColor } from 'polished'

import * as stages from './stages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/pro-duotone-svg-icons'
import Badge from '@bootstrap-styled/v4/lib/Badge'
import { callApi } from '~/services/api'

const ListingTable = ({ assignedOnly }) => {
	const { dispatch, entityThunks, selectors, actions, theme, breakpointDown } = useLumenox()
	const { params } = useRouteMatch({
		path: '/:slug',
		strict: false
	})
	const [tabsOpen, setTabsOpen] = useState(true)

	const currentStage = useSelector((state) => selectors.selectActiveStage(state))
	const currentTagId = useSelector((state) => selectors.selectActiveTagId(state)) //not used here now but if we ever want to make tables for tags it will be
	const leads = useSelector((state) => selectors.selectLeadsBySlug(state, { direction: 'asc', slug: params.slug }))

	let StageTable

	if (currentStage) {
		StageTable =
			stages[
				startCase(
					currentStage.replace(/-([a-z])/g, function (g) {
						return g[1].toUpperCase()
					})
				).replace(/ /g, '')
			]
	}
	if (!StageTable) {
		StageTable = stages.DefaultTable
	}

	return (
		<Row>
			<Col xxxs={12} md={3}>
				{breakpointDown('sm') ? (
					<React.Fragment>
						<Button onClick={() => setTabsOpen(!tabsOpen)} block color="info">
							{tabsOpen ? 'Collapse Tabs' : 'Expand Tabs'}
						</Button>
						<Collapse isOpen={tabsOpen}>
							<Tabs currentStage={currentStage} currentTagId={currentTagId} />
						</Collapse>
					</React.Fragment>
				) : (
					<Tabs currentStage={currentStage} currentTagId={currentTagId} assignedOnly={assignedOnly} />
				)}
			</Col>
			<Col xxxs={12} md>
				<StageTable leads={leads} assignedOnly={assignedOnly} />
			</Col>
		</Row>
	)
}

const Tabs = ({ currentStage, currentTagId, assignedOnly }) => {
	const { dispatch, entityThunks, selectors, actions, theme, breakpointDown } = useLumenox()
	const [loading, setLoading] = useState(false)
	const [stageCounts, setStageCounts] = useState([])
	const [tagCounts, setTagCounts] = useState([])
	const scrollRef = useRef(null)
	const mobile = breakpointDown('sm')

	const skipLoad = () => {
		dispatch(actions.opportunity.skipLoading())
	}

	const stageTypes = useSelector((state) => selectors.selectUnarchivedOpportunityStageTypesOrdered(state))

	const tags = useSelector((state) => selectors.selectOpportunityTagsOrdered(state))

	useEffect(() => {
		dispatch(entityThunks.fetchUsers())
		dispatch(entityThunks.fetchOpportunityTags())
		dispatch(entityThunks.fetchOpportunityStageTypes())
	}, [])

	useEffect(() => {
		callApi({
			endpoint: '/opportunities/stage-counts',
			params: {
				assignedOnly
			}
		}).then((response) => {
			console.log('stage count response:', response)
			// doesn't get updated if someone else changes stage of lead while you're on leads page
			setStageCounts(response.data)
		})
		callApi({
			endpoint: '/opportunities/tag-counts',
			params: {
				assignedOnly
			}
		}).then((response) => {
			console.log('tag count response:', response)
			setTagCounts(response.data)
		})
	}, [assignedOnly])

	const scrollToTable = () => {
		if (mobile) scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	const setActive = (stage, tagId) => {
		skipLoad()
		if (stage === 'all') {
			dispatch(actions.opportunity.setActiveStage(stage))
			scrollToTable()
		} else if (stage !== null) {
			dispatch(actions.opportunity.setActiveStage(stage.slug))
			dispatch(actions.opportunity.setActiveTagId(null))
			dispatch(entityThunks.fetchOpportunitiesByStage({ stageId: stage.id, assignedOnly })).then(() => {
				scrollToTable()
			})
		} else if (tagId !== null) {
			dispatch(actions.opportunity.setActiveStage(null))
			dispatch(actions.opportunity.setActiveTagId(tagId))
			dispatch(entityThunks.fetchOpportunitiesByTag({ tagId, assignedOnly })).then(() => {
				scrollToTable()
			})
		}
		//this should eventually be moved into the stage table itself but this works for now
	}

	return (
		<React.Fragment>
			<Nav vertical pills stacked className="mb-xxxs-3 flex-xxxs-column">
				<NavItem>
					<NavLink href="#" active={currentStage === 'all'} onClick={() => setActive('all', null)}>
						All
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink disabled>Stages:</NavLink>
				</NavItem>
				{stageTypes.map((st, i) => {
					return (
						<StageNavItem key={i} color={st.color} stage={st.id}>
							<NavLink active={currentStage === st.slug} href="#" onClick={() => setActive(st, null)}>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										{icons[st.icon] && (
											<FontAwesomeIcon icon={icons[st.icon]} color={theme[`$brand-${st.color}`]} fixedWidth className="mr-xxxs-1" />
										)}
										{st.name}
									</Col>
									<Col xxxs="auto">
										<StageCount color={st.color} count={stageCounts[st.id]} />
									</Col>
								</Row>
							</NavLink>
						</StageNavItem>
					)
				})}
				<NavItem>
					<NavLink disabled>Tags:</NavLink>
				</NavItem>
				{tags.map((t, i) =>
					tagCounts[t.id] ? (
						<TagNavItem key={i} color="info" tagId={t.id}>
							<NavLink active={currentTagId === t.id} href="#" onClick={() => setActive(null, t.id)}>
								<Row className="align-items-xxxs-center">
									<Col xxxs>{t.name}</Col>
									<Col xxxs="auto">
										<StageCount count={tagCounts[t.id]} color="info" />
									</Col>
								</Row>
							</NavLink>
						</TagNavItem>
					) : null
				)}
			</Nav>
			<div ref={scrollRef} />
		</React.Fragment>
	)
}

export default ListingTable

const StageCount = ({ count, ...rest }) => {
	return count === 0 ? null : <Badge {...rest}>{count}</Badge>
}

const StageNavItem = styled(({ children, stage, ...rest }) => {
	const { dispatch, entityThunks, selectors, userNotAdminHasRole } = useLumenox()
	const installer = userNotAdminHasRole('installer')
	const count = useSelector((state) => selectors.selectOpportunityCountByStageId(state, { opportunityStage: stage }))

	if (installer && count === 0) {
		return null
	}

	return <NavItem {...rest}>{children}</NavItem>
})`
	a {
		transition: color 0.15s, background-color 0.15s;
		&:hover {
			background: ${({ theme, color }) => lighten('0.29', theme[`$brand-${color}`])} !important;
			color: ${({ theme, color }) => readableColor(lighten('0.29', theme[`$brand-${color}`]), theme[`$body-color`], 'white')} !important;
		}

		&.active {
			background: ${({ theme, color }) => lighten('0.25', theme[`$brand-${color}`])} !important;
			color: ${({ theme, color }) => readableColor(lighten('0.25', theme[`$brand-${color}`]), theme[`$body-color`], 'white')} !important;
		}
	}
`

const TagNavItem = styled(({ children, tagId, ...rest }) => {
	const { dispatch, entityThunks, selectors, userNotAdminHasRole } = useLumenox()
	const installer = userNotAdminHasRole('installer')
	const count = useSelector((state) => selectors.selectOpportunityCountByStageId(state, { opportunityTag: tagId }))

	if (installer && count === 0) {
		return null
	}

	return <NavItem {...rest}>{children}</NavItem>
})`
	a {
		transition: color 0.15s, background-color 0.15s;
		&:hover {
			background: ${({ theme, color }) => lighten('0.29', theme[`$brand-${color}`])} !important;
			color: ${({ theme, color }) => readableColor(lighten('0.29', theme[`$brand-${color}`]), theme[`$body-color`], 'white')} !important;
		}

		&.active {
			background: ${({ theme, color }) => lighten('0.25', theme[`$brand-${color}`])} !important;
			color: ${({ theme, color }) => readableColor(lighten('0.25', theme[`$brand-${color}`]), theme[`$body-color`], 'white')} !important;
		}
	}
`
