import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Badge, Container, Form, H1, H3, Hr, P, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Textarea, Label, Table } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faCheck, faX, faEdit } from '@fortawesome/pro-solid-svg-icons'
import StageForm from './StageForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solid from '@fortawesome/pro-solid-svg-icons'

const StageTable = () => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const stages = useSelector((state) => selectors.selectAllOpportunityStageTypes(state))
	const stageEntities = useSelector((state) => selectors.selectOpportunityStageTypeEntities(state))

	useEffect(() => {
		dispatch(entityThunks.fetchOpportunityStageTypes(true)).then((response) => {
			// nothing
		})
	}, [])

	return (
		<Table>
			<Thead>
				<Tr>
					<Th width="50%">Stage</Th>
					<Th>Color</Th>
					<Th>Icon</Th>
					<Th>Percentage</Th>
					<Th>Next</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{stages
					.slice()
					.sort((a, b) => {
						if (a.archived && !b.archived) return 1
						if (b.archived && !a.archived) return -1
						return a.percentage - b.percentage
					})
					.map((stage) => (
						<Tr key={stage.id} style={{ background: stage.archived ? '#eeeeee' : null }}>
							<Td>{stage.name}</Td>
							<Td>
								<Badge color={stage.color}>{stage.color}</Badge>
							</Td>
							<Td>
								{solid[stage.icon] && (
									<Badge color={stage.color}>
										<FontAwesomeIcon icon={solid[stage.icon]} />
									</Badge>
								)}
							</Td>
							<Td>{stage.percentage}</Td>
							<Td>
								{stage.nextV2.map((next) => {
									const nextStage = stageEntities[next.stage]
									if (!nextStage) {
										console.log('next stage (' + next.stage + ') must have been removed, remove it from stage: ', stage)
										return null
									}
									return (
										<React.Fragment key={next.stage}>
											<Badge color={nextStage.color}>{nextStage.name}</Badge>
											{/* {next.dependencies.length > 0 && (
												//Idk how to make this look good but we want some kind of visual for the dependencies
												<React.Fragment>
													(dependencies:
													{next.dependencies.map((dependencyId) => (
														<Badge>
															<StageName id={dependencyId} />
														</Badge>
													))}
													)
												</React.Fragment>
											)} */}
											<br />
										</React.Fragment>
									)
								})}
							</Td>
							<Td>
								<ActionButton icon={faEdit} title="Update Stage">
									{(setIsOpen) => <StageForm setIsOpen={setIsOpen} currentValues={stage} />}
								</ActionButton>
							</Td>
						</Tr>
					))}
			</Tbody>
		</Table>
	)
}

export default StageTable
