import { A, H1, H2, H3, H4, H5, H6, Hr, Img, Li, Ol, P, Row, Small, Strong, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Button, Col, Table } from '~/components/bootstrap'
import { callApi } from '~/services/api'
import { useLumenox } from '~/services/lumenox'
import { utcToZonedTime, format } from 'date-fns-tz'
import logo from '~/assets/images/logo.svg'
import { useTheme } from '~/utils/hooks'
import { Fragment } from 'react'
import styled from 'styled-components'
import { FormatCurrency } from '~/components/common/Text'
import { Term } from '~/views/Terms'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const PublicQuotePDF = () => {
	//still need to make this redicrect back to quote view
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/public/leads/:id/quotes/:quoteId',
		strict: false
	})

	useEffect(() => {
		// needed since puppeteer skips all the pages you normally go through that load this stuff in
		dispatch(entityThunks.fetchOpportunityById(params.id)).then((response) => {
			//nothing
		})
	}, [])

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, params.id))
	const quote = useSelector((state) => selectors.selectNormalizedQuoteFormValuesById(state, { quote: params.quoteId }))

	return opportunity && quote ? <QuotePDF opportunity={opportunity} quote={quote} /> : null
}

const PrivateQuotePDF = ({ setIsOpen }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id/quotes/:quoteId',
		strict: false
	})
	const [downloadLoading, setDownloadLoading] = useState(false)

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, params.id))
	const quote = useSelector((state) => selectors.selectNormalizedQuoteFormValuesById(state, { quote: params.quoteId }))
	const contact = useSelector((state) => selectors.selectContactById(state, opportunity.contacts[0])) //can improve later

	const slugify = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, '')
			.replace(/[\s_-]+/g, '-')
			.replace(/^-+|-+$/g, '')

	const downloadPdf = () => {
		setDownloadLoading(true)
		callApi({
			endpoint: `quotes/pdf/${opportunity.id}/${quote.id}`,
			headers: {
				'Content-Type': 'application/pdf'
			}
		})
			.then((result) => {
				return result.data
			})
			.then((data) => {
				const linkSource = `data:application/pdf;base64,${data}`
				const link = document.createElement('a')
				link.href = linkSource
				link.setAttribute(
					'download',
					`lumenox-quote-${slugify(contact.name.first + ' ' + contact.name.last)}${quote.quotationNumber ? `-${quote.quotationNumber}` : ''}.pdf`
				)

				// Append to html link element pag
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
			.then(() => {
				setDownloadLoading(false)
			})
	}

	const close = () => {
		setIsOpen(false)
	}

	return (
		<React.Fragment>
			<QuotePDF opportunity={opportunity} quote={quote} />
			<React.Fragment>
				<Hr />
				<Row className="justify-content-xxxs-end">
					<Col xxxs="auto">
						<Button className="mr-xxxs-3" onClick={downloadPdf} color="info" submitting={downloadLoading}>
							<FontAwesomeIcon icon={faDownload} />
						</Button>
						<Button color="dark" onClick={close}>
							Close
						</Button>
					</Col>
				</Row>
			</React.Fragment>
		</React.Fragment>
	)
}

const roundTo2 = (number) => Math.round((number + Number.EPSILON) * 100) / 100

const QuotePDF = ({ opportunity, quote, setIsOpen }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const theme = useTheme()
	const contact = useSelector((state) => selectors.selectContactById(state, opportunity.contacts[0])) //can improve later
	const [googleAddress, setGoogleAddress] = useState(null)

	useEffect(() => {
		if (contact.address && contact.address.placeId) {
			// var request = {
			// 	placeId: contact.address.placeId
			// 	//fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
			// }
			callApi({
				endpoint: `google/place/${contact.address.placeId}`
			}).then((response) => {
				console.log(response)
				setGoogleAddress(response.data.data)
			})
			/* const service = new window.google.maps.places.PlacesService(document.createElement('div'))
			service.getDetails(request, (placeResult) => {
				setPlace(placeResult)
			}) */
		}
	}, [contact])

	// console.log('opportuntiy:', opportunity)
	// console.log('contact:', contact)
	// console.log('quote:', quote)

	const totalTaxRate = quote.taxRate ? (quote.taxRate.GST ? quote.taxRate.GST : 0) + (quote.taxRate.HST ? quote.taxRate.HST : 0) : 0
	const { subTotal, discountTotal } = quote.lineItems.reduce(
		({ subTotal, discountTotal }, lineItem) => {
			if (lineItem.type === 'Sales Discount') {
				return { subTotal: subTotal, discountTotal: discountTotal - lineItem.quantity * lineItem.rate } // - since rate is -ve
			}
			const qty =
				lineItem.type === 'Pixels'
					? Math.floor(lineItem.quantity) + (lineItem.quantity % 1 !== 0 ? (lineItem.quantity % 1 > 0.5 ? 1 : 0.5) : 0)
					: lineItem.quantity
			const itemTotal = roundTo2(qty * lineItem.rate)
			const st = subTotal + itemTotal
			const dt = discountTotal + (lineItem.discount > 0 ? roundTo2((itemTotal * lineItem.discount) / 100) : 0)

			return { subTotal: st, discountTotal: dt }
		},
		{ subTotal: 0, discountTotal: 0 }
	)
	const preTaxTotal = roundTo2(subTotal - discountTotal)
	const taxTotal = roundTo2((preTaxTotal * totalTaxRate) / 100)
	const overallTotal = roundTo2(preTaxTotal + taxTotal)

	return (
		<React.Fragment>
			<Row id="pdf-container" className="justify-content-xxxs-center">
				<Col xxxs="auto" className="text-xxxs-center">
					<Img src={logo} className="mb-xxxs-3" width="200px" />
					<br />
					<H5>Quotation</H5>
				</Col>
			</Row>
			<Row className="mb-xxxs-3">
				<Col xxxs>
					<Small>{format(utcToZonedTime(quote.createdAt, 'Canada/Mountain'), 'MMMM d, yyyy')}</Small>
				</Col>
				{quote.quotationNumber && (
					<Col xxxs="auto">
						<Small>#{quote.quotationNumber}</Small>
					</Col>
				)}
			</Row>
			<Row className="mb-xxxs-3">
				<Col xxxs>
					<H6 className="mb-xxxs-0">Lumenox</H6>
					<P>
						<Small>
							2322 49 Ave SE Unit 103
							<br />
							Calgary, AB, Canada T2B 3E3
							<br />
							sales@lumenox.ca
							<br />
							1 (844) 586-3669
							<br />
							<A href="https://lumenox.ca">lumenox.ca</A>
						</Small>
					</P>
				</Col>
				<Col xxxs="auto" className="text-xxxs-right">
					<H6 className="mb-xxxs-0">
						{contact.name.first} {contact.name.last}
					</H6>
					<P>
						{contact.address.lines.length > 0 ? (
							<Small>
								{contact.address.lines.map((line) => line)}
								<br />
								{contact.address.city}, {contact.address.state}, {contact.address.country} {contact.address.postalCode}
								<br />
								{contact.email}
							</Small>
						) : googleAddress?.address_components ? (
							(() => {
								const addressComponents = googleAddress.address_components.reduce((obj, cur) => {
									obj[cur.types[0]] = cur.short_name

									return obj
								}, {})

								return (
									<Small>
										{`${addressComponents['street_number']} ${addressComponents['route']}`}
										<br />
										{`${addressComponents['locality']}, ${addressComponents['administrative_area_level_1']}, ${
											addressComponents['country']
										} ${addressComponents['postal_code'] ? addressComponents['postal_code'] : ''}`}
										<br />
										{contact.email}
									</Small>
								)
							})()
						) : (
							<Small>Error loading address, try refreshing and if that doesn't work talk to Jacob or Taylor</Small>
						)}
					</P>
				</Col>
			</Row>

			<Table size="sm" className="mb-xxxs-5">
				<Thead>
					<Tr>
						<Th className="pl-xxxs-0">Description</Th>
						<Th width="10%">Quantity</Th>
						<Th width="10%">Rate</Th>
						<Th width="10%">Discount</Th>
						<Th className="pr-xxxs-0 text-xxxs-right" width="10%">
							Total
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{quote.lineItems.map((lineItem, i) => {
						const { name, description, quantity, rate, discount, type } = lineItem
						const qty = type === 'Pixels' ? Math.floor(quantity) + (quantity % 1 !== 0 ? (quantity % 1 > 0.5 ? 1 : 0.5) : 0) : quantity
						const taxlessTotal = roundTo2(qty * rate * (1 - discount / 100))
						return (
							<React.Fragment key={lineItem.id}>
								<Tr style={{ borderBottom: '1px light-gray' }}>
									<Td className="pl-xxxs-0">
										{type === 'Specialty Channel' ? type : name}
										<br />
										<Small>{description}</Small>
									</Td>
									<Td style={{ verticalAlign: 'top' }}>
										{qty * 1 !== 1 && discount !== 100 && (type === 'Pixels' || type === 'Specialty Channel' ? `${qty} ft` : qty)}
									</Td>
									<Td style={{ verticalAlign: 'top' }}>
										<FormatCurrency amount={rate} decimalPlaces={2} />
									</Td>
									<Td style={{ verticalAlign: 'top' }}>{discount == 0 ? '' : discount + '%'}</Td>
									<Td className="pr-xxxs-0 text-xxxs-right" style={{ verticalAlign: 'top' }}>
										{taxlessTotal === 0 ? 'FREE' : <FormatCurrency amount={taxlessTotal} decimalPlaces={2} />}
									</Td>
								</Tr>
							</React.Fragment>
						)
					})}
				</Tbody>
			</Table>
			<Row className="justify-content-xxxs-end">
				<Col xxxs={7}>
					{quote.additionalNotes !== '' && (
						<React.Fragment>
							<H6>Additional Notes:</H6>
							<Small>
								<ReactMarkdown>{quote.additionalNotes}</ReactMarkdown>
							</Small>
						</React.Fragment>
					)}
					<H6>Terms and Conditions</H6>
					<Ol className="pl-xxxs-3" type="1">
						{quote.terms.map((term) => {
							return (
								<Small key={term}>
									<Li>
										<Term id={term} />
									</Li>
								</Small>
							)
						})}
					</Ol>
				</Col>
				<Col xxxs={5}>
					<Table size="sm" bordered className="mb-xxxs-0">
						<Tbody>
							<Tr>
								<Td>Sub Total</Td>
								<Td className="text-xxxs-right">
									<FormatCurrency amount={subTotal} decimalPlaces={2} />
								</Td>
							</Tr>
							{discountTotal !== 0 && (
								<React.Fragment>
									<Tr>
										<Td>Discount</Td>
										<Td className="text-xxxs-right" style={{ color: theme['$brand-primary'] }}>
											<FormatCurrency amount={discountTotal} decimalPlaces={2} />
										</Td>
									</Tr>
									<Tr>
										<Td>Pre-tax Total</Td>
										<Td className="text-xxxs-right">
											<FormatCurrency amount={preTaxTotal} decimalPlaces={2} />
										</Td>
									</Tr>
								</React.Fragment>
							)}
							<Tr>
								<Td>Tax</Td>
								<Td className="text-xxxs-right">
									<FormatCurrency amount={taxTotal} decimalPlaces={2} />
								</Td>
							</Tr>
							<Tr>
								<Th>Total</Th>
								<Th className="text-xxxs-right">
									<FormatCurrency amount={overallTotal} decimalPlaces={2} />
								</Th>
							</Tr>
						</Tbody>
					</Table>
				</Col>
			</Row>
		</React.Fragment>
	)
}

export default PrivateQuotePDF
