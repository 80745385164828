import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Nav, NavItem, NavLink, Badge } from '@bootstrap-styled/v4'
import { Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { ContactName, AssignedName, StageName, LastActivity, TagName } from '../components/common'

const DefaultTable = ({ assignedOnly }) => {
	const { dispatch, entityThunks, selectors, actions, user } = useLumenox()
	const [loading, setLoading] = useState(false)
	const { params } = useRouteMatch({
		path: '/:slug',
		strict: false
	})

	const skipLoad = () => {
		dispatch(actions.opportunity.skipLoading())
	}
	const stageType = new URLSearchParams(useLocation().search).get('st')

	const leads = useSelector((state) =>
		selectors.selectLeadsBySlug(state, { direction: 'asc', slug: params.slug, assignedTo: assignedOnly ? user.uid : null })
	)

	const stageTypes = useSelector((state) => selectors.selectOpportunityStageTypesOrdered(state))

	useEffect(() => {
		setLoading(true)
		dispatch(entityThunks.fetchOpportunities({ slug: params.slug, limit: 100, assignedOnly }))
			//.then(() => dispatch(entityThunks.fetchOpportunities({ slug: params.slug, skip: 200 })))
			.then((result) => {
				setLoading(false)
			})
	}, [params.slug, assignedOnly])

	return (
		<Table bordered striped responsive loading={loading}>
			<Thead>
				<Tr>
					<Th width="50%">Name</Th>
					<Th>Stage</Th>
					<Th width="15%">Tags</Th>
					<Th>Assigned To</Th>
					<Th>Last Activity</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{leads
					//.sort((a, b) => a.updatedAt < b.updatedAt)
					.map((lead, i) => {
						return (
							<Tr key={i}>
								<Td>
									<A tag={Link} to={`/leads/${lead.id}`} onClick={skipLoad}>
										{lead.contacts.map((contact, c) => (
											<ContactName key={c} id={contact} />
										))}
									</A>
								</Td>
								<Td>
									<StageName id={lead.stage} />
								</Td>
								<Td>
									{lead.tags.map((tag, t) => (
										<Badge key={tag} color="info" style={{ whiteSpace: 'normal' }}>
											<TagName id={tag} />
										</Badge>
									))}
								</Td>
								<Td>
									<AssignedName id={lead.assignedTo} />
								</Td>
								<Td>
									<LastActivity id={lead.id} />
								</Td>
								{/* <Td>{tasting.flights.length}</Td>
								<Td>0</Td>
								<Td>{tasting.users.length}</Td> */}
							</Tr>
						)
					})}
			</Tbody>
		</Table>
	)
}

export default DefaultTable
