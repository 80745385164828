import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchOpenPhoneNumbers = createAsyncThunk('openPhoneNumbers/fetch', () => {
	return callApi({
		endpoint: `open-phone`
	}).then((response) => {
		return response.data
	})
})

//will add when needed
// export const fetchProductById = createAsyncThunk('products/fetchById', (id, { getState, requestId }) => {
// 	return callApi({
// 		endpoint: `products/${id}`
// 	}).then((response) => {
// 		return response.data
// 	})
// })

// export const addProduct = createAsyncThunk('products/add', (values, { getState, requestId }) => {
// 	return callApi({
// 		endpoint: `products`,
// 		method: 'POST',
// 		data: values
// 	}).then((response) => {
// 		return response.data
// 	})
// })
