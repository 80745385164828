import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Badge, ButtonGroup, Container, Form, H1, H3, Hr, P, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Textarea, Label, Table } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faCheck, faX, faEdit, faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { format } from 'date-fns'
import CompensationProgramForm from './CompensationProgramForm'
import { callApi } from '~/services/api'

const CompensationProgramTable = () => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const compensationPrograms = useSelector((state) => selectors.selectAllCompensationPrograms(state))
	const [enrollmentCounts, setEnrollmentCounts] = useState(null)

	useEffect(() => {
		dispatch(entityThunks.fetchCompensationPrograms()).then((response) => {
			// nothing
		})
		//hit endpoint to grab counts for each program
		callApi({
			endpoint: `compensation-programs/counts`,
			method: 'GET'
		}).then((response) => {
			console.log('setting enrollment counts to:', response.data)
			setEnrollmentCounts(response.data)
		})
	}, [])

	return (
		<Table>
			<Thead>
				<Tr>
					<Th>Name</Th>
					<Th>Start Date</Th>
					<Th>End Date</Th>
					<Th>Referrer Compensation</Th>
					<Th>referee Compensation</Th>
					<Th>Enrolled users</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{compensationPrograms.map((cp) => (
					<Tr key={cp.id}>
						<Td>{cp.name}</Td>
						<Td>{format(new Date(cp.startDate), 'MMMM d, y')}</Td>
						<Td>{cp.endDate ? format(new Date(cp.endDate), 'MMMM d, y') : 'TBD'}</Td>
						<Td>
							{cp.referrerCompensation.map((rc) => {
								return (
									<React.Fragment key={rc._id}>
										{rc.type === 'Fixed' && '$'}
										{rc.value}
										{rc.type === 'Percentage' && '%'}
										{' (' + rc.method + ')'}
										<br />
									</React.Fragment>
								)
							})}
						</Td>
						<Td>
							{cp.refereeCompensation.map((rc) => {
								return (
									<React.Fragment key={rc._id}>
										{rc.type === 'Fixed' && '$'}
										{rc.value}
										{rc.type === 'Percentage' && '%'}
										{' (' + rc.method + ')'}
										<br />
									</React.Fragment>
								)
							})}
						</Td>
						<Td>{enrollmentCounts && enrollmentCounts[cp.id]}</Td>
						<Td>
							<ButtonGroup size="sm">
								<ActionButton color="warning" icon={faEdit} title="Update Program">
									{(setIsOpen) => <CompensationProgramForm setIsOpen={setIsOpen} currentValues={cp} />}
								</ActionButton>
								<ActionButton color="info" icon={faCircleInfo} title="Program Stats">
									{(setIsOpen) => <StatsModal setIsOpen={setIsOpen} id={cp.id} />}
								</ActionButton>
							</ButtonGroup>
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	)
}

const StatsModal = ({ id, setIsOpen }) => {
	const [stats, setStats] = useState([])

	useEffect(() => {
		callApi({
			endpoint: `compensation-programs/${id}/stats`
		}).then((response) => {
			setStats(response.data)
		})
	}, [])

	return (
		<Table>
			<Thead>
				<Tr>
					<Th>Name</Th>
					<Th>Referred Leads</Th>
					<Th>Paid Leads</Th>
					<Th>Total Compensation</Th>
				</Tr>
			</Thead>
			<Tbody>
				{stats.map((referrer) => (
					<Tr key={referrer.fullName}>
						<Td>{referrer.fullName}</Td>
						<Td>{referrer.numNewLeads}</Td>
						<Td>{referrer.numPaidLeads}</Td>
						<Td>
							{referrer.totalCompensation.cash ? <React.Fragment>${referrer.totalCompensation.cash.toFixed(2)} (Cash)</React.Fragment> : null}
							{referrer.totalCompensation.giftCard ? (
								<React.Fragment>
									{referrer.totalCompensation.cash ? <br /> : null}${referrer.totalCompensation.giftCard.toFixed(2)} (Gift Cards)
								</React.Fragment>
							) : null}
							{referrer.totalCompensation.cash + referrer.totalCompensation.giftCard === 0 && '$0'}
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	)
}

export default CompensationProgramTable
