import { configureStore, combineReducers } from '@reduxjs/toolkit'

import contactReducer from './entities/contact'
import dealerReducer from './entities/dealer'
import userReducer from './entities/user'
import userRoleReducer from './entities/userRole'
import userInviteReducer from './entities/userInvite'
import opportunityReducer from './entities/opportunity'
import opportunityStageTypeReducer from './entities/opportunityStageType'
import opportunityActivityReducer from './entities/opportunityActivity'
import quoteReducer from './entities/quote'
import quoteRunReducer from './entities/quoteRun'
import quoteRunSegmentReducer from './entities/quoteRunSegment'
import quoteLocationReducer from './entities/quoteLocation'
import runTypeReducer from './entities/runType'
import productReducer from './entities/product'
import rateReducer from './entities/rate'
import controllerReducer from './entities/controller'
import installDetailsReducer from './entities/installDetails'
import openPhoneNumberReducer from './entities/openPhoneNumber'
import siteVisitReducer from './entities/siteVisit'
import quoteTermReducer from './entities/quoteTerm'
import quoteLineItemReducer from './entities/quoteLineItem'
import opportunityTagReducer from './entities/opportunityTag'
import compensationProgramReducer from './entities/compensationProgram'
import compensationProgramEnrollmentReducer from './entities/compensationProgramEnrollment'
/* import tastingTestReducer from './entities/tasting'
import tastingUserReducer from './entities/tastingUser'
import flightReducer from './entities/flight'
import flightRankingReducer from './entities/flightRanking'
import userReducer from './entities/user'
import breweryReducer from './entities/brewery' */
import authReducer from '~/views/Auth/slice'

const rootReducer = combineReducers({
	entities: combineReducers({
		contact: contactReducer,
		dealer: dealerReducer,
		user: userReducer,
		userRole: userRoleReducer,
		userInvite: userInviteReducer,
		opportunity: opportunityReducer,
		opportunityStageType: opportunityStageTypeReducer,
		opportunityActivity: opportunityActivityReducer,
		quote: quoteReducer,
		quoteLocation: quoteLocationReducer,
		quoteRun: quoteRunReducer,
		quoteRunSegment: quoteRunSegmentReducer,
		runType: runTypeReducer,
		product: productReducer,
		rate: rateReducer,
		controller: controllerReducer,
		installDetails: installDetailsReducer,
		openPhoneNumber: openPhoneNumberReducer,
		siteVisit: siteVisitReducer,
		quoteTerm: quoteTermReducer,
		quoteLineItem: quoteLineItemReducer,
		opportunityTag: opportunityTagReducer,
		compensationProgram: compensationProgramReducer,
		compensationProgramEnrollment: compensationProgramEnrollmentReducer
		/* tasting: tastingTestReducer,
		tastingUser: tastingUserReducer,
		flight: flightReducer,
		flightRanking: flightRankingReducer,
		user: userReducer,
		brewery: breweryReducer */
	}),
	auth: authReducer
})

export default configureStore({
	reducer: rootReducer
})
