import React from 'react'
import { useSelector } from 'react-redux'
import { CardHeader, Row } from '@bootstrap-styled/v4'

import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote } from '@fortawesome/pro-duotone-svg-icons'
import { faThumbtack as faThumbtackSolid } from '@fortawesome/pro-solid-svg-icons'
import { faThumbtack as faThumbtackRegular } from '@fortawesome/pro-regular-svg-icons'

const Note = ({ id, opportunityId, renderTimestamp }) => {
	const { selectors, theme, dispatch, entityThunks } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	const user = useSelector((state) => selectors.selectUserById(state, activity.user))

	const togglePinned = (pinned) => {
		dispatch(entityThunks.updateOpportunityActivity({ opportunityId, id, values: { ...activity, pinned } })).then((response) => {})
	}

	return (
		<CardHeader>
			<Row className="align-items-xxxs-center">
				<Col xxxs="auto">
					<FontAwesomeIcon icon={faStickyNote} color={theme['$brand-warning']} fixedWidth />
				</Col>
				<Col xxxs>{user && user.name}</Col>
				<Col xxxs="auto">
					<FontAwesomeIcon
						icon={activity.pinned ? faThumbtackSolid : faThumbtackRegular}
						className="fa-rotate-by"
						style={{ '--fa-rotate-angle': '45deg', cursor: 'pointer' }}
						onClick={() => togglePinned(!activity.pinned)}
					/>
				</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default Note
