import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Nav, NavItem, NavLink } from '@bootstrap-styled/v4'
import { Table, Badge } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { ContactName, AssignedName, StageName, LastActivity, StageDate, SourceInfo, UserName, TagName } from '../components/common'
import { capitalize } from 'lodash'
import { SortTh } from '~/components/bootstrap/components/Table'
import { RelativeDate } from '~/components/common/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollar } from '@fortawesome/pro-solid-svg-icons'

const SiteVisitScheduled = ({ assignedOnly }) => {
	const { dispatch, entityThunks, selectors, actions, user } = useLumenox()
	const [loading, setLoading] = useState(false)
	const [sortProp, setSortProp] = useState('last-activity')
	const [sortDir, setSortDir] = useState('desc')
	const { params } = useRouteMatch({
		path: '/:slug',
		strict: false
	})

	const skipLoad = () => {
		dispatch(actions.opportunity.skipLoading())
	}
	const stageType = new URLSearchParams(useLocation().search).get('st')

	const leads = useSelector((state) =>
		selectors.selectLeadsSortedByParam(state, { direction: sortDir, prop: sortProp, assignedTo: assignedOnly ? user.uid : null })
	)

	const stageTypes = useSelector((state) => selectors.selectOpportunityStageTypesOrdered(state))

	useEffect(() => {
		setLoading(true)
		dispatch(entityThunks.fetchOpportunities({ slug: params.slug, limit: 200, assignedOnly }))
			//.then(() => dispatch(entityThunks.fetchOpportunities({ slug: params.slug, skip: 200 })))
			.then(() => {
				setLoading(false)
			})
	}, [params.slug])

	const onSortHandler = (prop) => {
		if (prop === sortProp) {
			setSortDir(sortDir === 'desc' ? 'asc' : 'desc')
		} else {
			setSortProp(prop)
			setSortDir('asc')
		}
	}

	return (
		<Table bordered striped responsive loading={loading}>
			<Thead>
				<Tr>
					<SortTh width="40%" active={sortProp === 'name'} direction={sortDir} onClick={() => onSortHandler('name')}>
						Name
					</SortTh>
					<Th width="15%">Tags</Th>
					<SortTh active={sortProp === 'assigned-to'} direction={sortDir} onClick={() => onSortHandler('assigned-to')}>
						Assigned To
					</SortTh>
					<Th>CSR</Th>
					<SortTh active={sortProp === 'last-activity'} direction={sortDir} onClick={() => onSortHandler('last-activity')}>
						Last Activity
					</SortTh>
					<SortTh active={sortProp === 'created-at'} direction={sortDir} onClick={() => onSortHandler('created-at')}>
						Created
					</SortTh>
				</Tr>
			</Thead>
			<Tbody>
				{leads.map((lead, i) => {
					return (
						<Tr key={i}>
							<Td>
								<A tag={Link} to={`/leads/${lead.id}`} onClick={skipLoad}>
									{lead.contacts.map((contact, c) => (
										<ContactName key={c} id={contact} />
									))}
								</A>
							</Td>
							<Td>
								{lead.tags.map((tag, t) => (
									<Badge key={tag} color="info" style={{ whiteSpace: 'normal' }}>
										<TagName id={tag} />
									</Badge>
								))}
							</Td>
							<Td>
								{lead.assignedTo && (
									<Badge color="primary">
										<UserName id={lead.assignedTo} />
									</Badge>
								)}
								{lead.salesperson && lead.salesperson !== lead.assignedTo && (
									<Badge color="dark">
										<UserName id={lead.salesperson} />
										<FontAwesomeIcon icon={faDollar} fixedWidth />
									</Badge>
								)}
							</Td>
							<Td>
								{lead.csr && (
									<Badge color="orange">
										<UserName id={lead.csr} />
									</Badge>
								)}
							</Td>
							<Td>
								<LastActivity id={lead.id} />
							</Td>
							<Td>
								<RelativeDate date={new Date(lead.createdAt)} />
							</Td>
						</Tr>
					)
				})}
			</Tbody>
		</Table>
	)
}

export default SiteVisitScheduled
