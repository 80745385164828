import { createCachedSelector } from 're-reselect'
import { createCachedParamSelector, createParamSelector } from '~/state/entities'
import { primitiveSelectors as opportunitySelectors } from '../index'
import { primitiveSelectors as contactSelectors } from '~/state/entities/contact'
import { primitiveSelectors as userSelectors } from '~/state/entities/user'
import { primitiveSelectors as opportunityStageTypeSelectors } from '~/state/entities/opportunityStageType'
import { primitiveSelectors as opportunityActivitySelectors, selectors as opportunityActivityOtherSelectors } from '~/state/entities/opportunityActivity'
import { isBefore, sub, differenceInBusinessDays } from 'date-fns'

export const selectActiveStage = createCachedSelector(
	(state) => state.entities.opportunity,
	(opportunity) => {
		return opportunity.activeStage
	}
)((state) => state.entities.opportunity.activeStage || '')

export const selectActiveTagId = createCachedSelector(
	(state) => state.entities.opportunity,
	(opportunity) => {
		return opportunity.activeTagId
	}
)((state) => state.entities.opportunity.activeTagId || '')

export const selectOpportunitiesSortedByName = createCachedParamSelector(
	[
		(state, _) => opportunitySelectors.selectAllOpportunities(state),
		(state, _) => contactSelectors.selectContactEntities(state),
		(_, params) => params.direction
	],
	(opportunities, contacts, direction) => {
		return opportunities.sort((a, b) => {
			const aContact = a.contacts.length > 0 ? contacts[a.contacts[0]] : null
			const bContact = b.contacts.length > 0 ? contacts[b.contacts[0]] : null

			return (aContact ? aContact.name.first + aContact.name.last : '').localeCompare(bContact ? bContact.name.first + bContact.name.last : '')
		})
	}
)

export const selectOpportunitiesSortedByStage = createCachedParamSelector(
	[
		(state, params) => selectOpportunitiesSortedByName(state, params),
		(state, _) => opportunityStageTypeSelectors.selectOpportunityStageTypeEntities(state),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(_, params) => params,
		(state) => state.entities.opportunity
	],
	(opportunities, stages, activities, params) => {
		return opportunities.sort((a, b) => {
			const aStage = stages[a.stage]
			const bStage = stages[b.stage]

			if (!aStage || !bStage) return 0

			if (aStage.slug === 'lost') {
				return 1
			}
			if (bStage.slug === 'lost') {
				return -1
			}

			if (['quoted-accurate', 'new'].includes(aStage.slug)) {
				const aLastActivity = activities[a.activity[a.activity.length - 1]]
				if (aLastActivity && isBefore(new Date(aLastActivity.createdAt), sub(new Date(), { weeks: 2 }))) {
					return 1
				}
			}

			if (['quoted-accurate', 'new'].includes(bStage.slug)) {
				const bLastActivity = activities[b.activity[b.activity.length - 1]]
				if (bLastActivity && isBefore(new Date(bLastActivity.createdAt), sub(new Date(), { weeks: 2 }))) {
					return -1
				}
			}

			if (aStage.percentage === bStage.percentage) {
				return 0
			}

			return aStage.percentage < bStage.percentage ? -1 : 1
		})
	}
)

export const selectOpportunityCountByTagId = createCachedParamSelector(
	[(state, _) => opportunitySelectors.selectAllOpportunities(state), (_, params) => params.opportunityTag],
	(opportunities, tagId) => {
		return opportunities.filter((op) => op.tags.includes(tagId)).length
	}
)

export const selectOpportunityCountByStageId = createCachedParamSelector(
	[(state, _) => opportunitySelectors.selectAllOpportunities(state), (_, params) => params.opportunityStage],
	(opportunities, stageId) => {
		return opportunities.filter((op) => op.stage === stageId).length
	}
)

export const selectLeadsBySlug = createCachedParamSelector(
	[
		(state, params) => selectOpportunitiesSortedByStage(state, params),
		(state, _) => opportunityStageTypeSelectors.selectOpportunityStageTypeEntities(state),
		(_, params) => params.slug,
		(state) => state.entities.opportunity.activeStage,
		(state) => state.entities.opportunity.activeTagId,
		(_, params) => params.assignedTo,
		(state) => state
	],
	(opportunities, stageTypes, slug, activeStage, activeTagId, assignedTo, state) => {
		switch (slug) {
			/* case 'quotes':
				return opportunities.filter((opportunity) =>
					['quote-scheduled', 'quoted-rough', 'quoted-accurate', 'hover-requested', 'site-visit'].includes(stageTypes[opportunity.stage].slug)
				)
			case 'production':
				return opportunities.filter((opportunity) =>
					['routing-completed', 'approved-for-production', 'produced'].includes(stageTypes[opportunity.stage].slug)
				)
			case 'installs':
				return opportunities.filter((opportunity) =>
					['shipped-to-installer', 'produced', 'install-scheduled', 'installed'].includes(stageTypes[opportunity.stage].slug)
				) */
			case 'leads':
			default:
				const activeStageId =
					activeStage !== 'all' && activeStage !== undefined && activeStage !== null
						? Object.entries(stageTypes).find(([id, st]) => st.slug === activeStage)[0]
						: false
				return opportunities.filter(
					(opportunity) =>
						(activeStageId !== false
							? opportunity.stage === activeStageId
							: activeTagId !== null && activeTagId !== undefined
							? opportunity.tags.includes(activeTagId)
							: true) &&
						(assignedTo
							? opportunity.assignedTo === assignedTo || opportunity.installer === assignedTo || opportunity.salesperson === assignedTo //doesn't include csr since Tineke says we don't use that so Angie is csr for pretty much everyone
							: true)
				)
		}
	}
)

export const selectLeadsSortedByParam = createCachedParamSelector(
	[
		(state, params) => selectLeadsBySlug(state, params),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(state, _) => userSelectors.selectUserEntities(state),
		(state, _) => contactSelectors.selectContactEntities(state),
		(_, params) => params,
		(state) => state
	],
	(opportunities, opportunityActivities, users, contacts, params, state) => {
		//console.log(opportunities)
		switch (params.prop) {
			case 'last-activity':
				return opportunities.sort((a, b) => {
					return a.updatedAt < b.updatedAt ? (params.direction === 'desc' ? 1 : -1) : params.direction === 'desc' ? -1 : 1
				})
			case 'created-at':
				return opportunities.sort((a, b) => {
					return a.createdAt < b.createdAt ? (params.direction === 'desc' ? 1 : -1) : params.direction === 'desc' ? -1 : 1
				})
			case 'assigned-to':
				return opportunities.sort((a, b) => {
					const aUser = users[a.assignedTo] ?? null
					const bUser = users[b.assignedTo] ?? null
					return params.direction === 'desc'
						? (bUser ? bUser.name : '').localeCompare(aUser ? aUser.name : '')
						: (aUser ? aUser.name : '').localeCompare(bUser ? bUser.name : '')
				})
			case 'qualified-at':
				return opportunities.sort((a, b) => {
					const aQualified = opportunityActivityOtherSelectors.selectOpportunityActivityStage(state, {
						opportunity: a.id,
						stage: 'qualified'
					})

					const bQualified = opportunityActivityOtherSelectors.selectOpportunityActivityStage(state, {
						opportunity: b.id,
						stage: 'qualified'
					})

					if (!aQualified || !bQualified) {
						return 0
					}

					return aQualified.createdAt < bQualified.createdAt ? (params.direction === 'desc' ? 1 : -1) : params.direction === 'desc' ? -1 : 1
				})

			case 'name':
				return opportunities.sort((a, b) => {
					const aContact = a.contacts.length > 0 ? contacts[a.contacts[0]] : null
					const bContact = b.contacts.length > 0 ? contacts[b.contacts[0]] : null

					return params.direction === 'desc'
						? (bContact ? bContact.name.first + bContact.name.last : '').localeCompare(aContact ? aContact.name.first + aContact.name.last : '')
						: (aContact ? aContact.name.first + aContact.name.last : '').localeCompare(bContact ? bContact.name.first + bContact.name.last : '')
				})
			case 'install-date':
				//will always put leads with no install date on the bottom (all leads should have a date though)
				return opportunities.sort((a, b) => {
					const aInstallDate = a.installDate ? a.installDate.confirmed ?? a.installDate.tentative ?? null : null
					const bInstallDate = b.installDate ? b.installDate.confirmed ?? b.installDate.tentative ?? null : null
					if (aInstallDate) {
						if (bInstallDate) {
							return (params.direction === 'desc' ? 1 : -1) * (new Date(bInstallDate).getTime() - new Date(aInstallDate).getTime())
						}
						return -1
					}
					if (bInstallDate) return 1
					return 0
				})
			default:
				return opportunities
		}
	}
)

export const selectNewLeadsDeliveredNotOpenedSortedByParam = createCachedParamSelector(
	[
		(state, params) => selectLeadsBySlug(state, params),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(_, params) => params,
		(state) => state
	],
	(opportunities, opportunityActivities, params, state) => {
		opportunities = opportunities.filter((opp) => {
			const lastActivity = opportunityActivities[opp.activity[opp.activity.length - 1]]

			return (
				lastActivity &&
				lastActivity.__t === 'Email' &&
				lastActivity.events.length > 0 &&
				lastActivity.events[lastActivity.events.length - 1].type === 'delivered' &&
				isAfter(sub(new Date(), { days: 3 }), new Date(lastActivity.createdAt))
			)
		})
		switch (params.prop) {
			case 'last-activity':
				return opportunities.sort((a, b) => {
					const aLastActivity = opportunityActivityOtherSelectors.selectOpportunityLastActivity(state, {
						opportunity: a.id,
						direction: params.direction
					})

					const bLastActivity = opportunityActivityOtherSelectors.selectOpportunityLastActivity(state, {
						opportunity: b.id,
						direction: params.direction
					})

					return aLastActivity.createdAt < bLastActivity.createdAt ? (params.direction ? 1 : -1) : params.direction ? -1 : 1
				})
			default:
				return opportunities
		}
	}
)

export const selectQuoteSentLeadsSortedByParam = createCachedParamSelector(
	[
		(state, params) => selectLeadsBySlug(state, params),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(_, params) => params,
		(state) => state
	],
	(opportunities, opportunityActivities, params, state) => {
		switch (params.prop) {
			case 'quote-sent':
				return opportunities.sort((a, b) => {
					/* const aLastActivity = a.activity.slice().sort((aa, bb) => {
						const aActivity = opportunityActivities[aa]
						const bActivity = opportunityActivities[bb]

						return aActivity.createdAt < bActivity.createdAt ? 1 : -1
					})[0] */

					const aQuoteActivity = opportunityActivityOtherSelectors.selectOpportunityQuoteSentActivity(state, {
						opportunity: a.id,
						direction: params.direction
					})
					const bQuoteActivity = opportunityActivityOtherSelectors.selectOpportunityQuoteSentActivity(state, {
						opportunity: b.id,
						direction: params.direction
					})

					if (!aQuoteActivity || !bQuoteActivity) {
						return 0
					}

					const aDays = Math.abs(differenceInBusinessDays(new Date(aQuoteActivity.createdAt), new Date()))
					const bDays = Math.abs(differenceInBusinessDays(new Date(bQuoteActivity.createdAt), new Date()))

					if (aDays == bDays) {
						const aLastActivity = opportunityActivityOtherSelectors.selectOpportunityLastActivity(state, {
							opportunity: a.id
						})
						const bLastActivity = opportunityActivityOtherSelectors.selectOpportunityLastActivity(state, {
							opportunity: b.id
						})

						if (!aLastActivity || !bLastActivity) return 0
						return aLastActivity.createdAt < bLastActivity.createdAt ? (params.direction === 'desc' ? 1 : -1) : params.direction === 'desc' ? -1 : 1
					}

					return aDays < bDays ? (params.direction === 'desc' ? -1 : 1) : params.direction === 'desc' ? 1 : -1
				})
			case 'last-follow-up':
				return opportunities.sort((a, b) => {
					const aLastFollowUp = selectOpportunityLastFollowUp(state, { opportunity: a.id })
					const bLastFollowUp = selectOpportunityLastFollowUp(state, { opportunity: b.id })

					if (!aLastFollowUp && !bLastFollowUp) return 0
					if (aLastFollowUp && bLastFollowUp) {
						return (
							(new Date(aLastFollowUp.createdAt).getTime() - new Date(bLastFollowUp.createdAt).getTime()) * (params.direction === 'desc' ? -1 : 1)
						)
					}
					//always have leads with follow ups at the top
					if (aLastFollowUp) return -1
					if (bLastFollowUp) return 1
				})
			case 'last-activity':
				return opportunities.sort((a, b) => {
					const aLastActivity = opportunityActivityOtherSelectors.selectOpportunityLastActivity(state, {
						opportunity: a.id
					})

					const bLastActivity = opportunityActivityOtherSelectors.selectOpportunityLastActivity(state, {
						opportunity: b.id
					})

					return aLastActivity.createdAt < bLastActivity.createdAt ? (params.direction === 'desc' ? 1 : -1) : params.direction === 'desc' ? -1 : 1
				})
			default:
				return opportunities
		}
	}
)

export const selectOpportunityLastFollowUp = createCachedParamSelector(
	[
		(state, params) => opportunityActivityOtherSelectors.selectOpportunityActivitiesSorted(state, { ...params, direction: 'desc' }),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state)
	],
	(opportunityActivities, entities) => {
		for (const activityId of opportunityActivities) {
			const activity = entities[activityId]
			if (!activity) continue
			if (activity.__t === 'FollowUp') return activity
			if (activity.children?.length > 0) {
				//as of right now follow ups can only be one layer deep (no children of children are follow ups)
				for (const childId of activity.children) {
					const childActivity = entities[childId]
					if (!childActivity) continue
					if (childActivity.__t === 'FollowUp') return childActivity
				}
			}
		}
		return null
	}
)

/* const selectFlightRoundsWithGroups = createCachedParamSelector([selectFlightRounds, groupSelectors.selectGroupEntities], (rounds, groups) => {
	const tripRounds = {}
	rounds.map((round) => {
		tripRounds[round.id] = {
			...round,
			groups: round.groups.map((group) => groups[group]).sort((a, b) => new Date(a.teeTime) - new Date(b.teeTime))
		}
	})
	return tripRounds
})

const selectFlightRoundsWithMatches = createCachedParamSelector([selectFlightRoundsWithGroups, matchSelectors.selectMatchEntities], (rounds, matches) => {
	const tripRounds = {}
	console.log(rounds)
	if (rounds) {
		Object.entries(rounds).map(([key, round]) => {
			tripRounds[key] = {
				...round,
				groups: round.groups
					.map((group) => ({
						...group,
						matches: group.matches.map((match) => matches[match.id])
					}))
					.sort((a, b) => new Date(a.teeTime) - new Date(b.teeTime))
			}
		})
	}
	console.log(tripRounds)
	return tripRounds
})

const getCurrentMemberParam = createParamSelector((params) => params.currentMember)

export const selectCurrentGroup = createCachedParamSelector([selectFlightRoundsWithMatches, getCurrentMemberParam], (rounds, currentMember) => {
	let currentGroup = false
	if (rounds && currentMember) {
		const currentTime = new Date()
		Object.entries(rounds).every(([key, round]) => {
			return round.groups.every((group) => {
				const foundMatch = group.matches.find(
					(match) => match.teams.find((team) => team.players.find((player) => player.player === currentMember) !== undefined) !== undefined
				)

				if (foundMatch) {
					let completed = true
					group.matches.map((match) => {
						if (match.scorecard.holes.length < 18) {
							completed = false
						}
					})

					if (!completed && isBefore(sub(new Date(group.teeTime), { minutes: 15 }), currentTime)) {
						currentGroup = group
						return false
					}
				}
				return true
			})
		})
	}

	return currentGroup
})

export const calculateFlightPoints = createCachedParamSelector(
	[
		//(state, params) => selectFlightRoundsWithGroups(state, params),
		(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params),
		(state, params) => tripSelectors.selectFlightByParam(state, params)
	],
	(matches, trip) => {
		const points = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = {
				points: 0,
				front: 0,
				back: 0,
				eighteen: 0
			}
			return obj
		}, {})
		matches.map((match) => {
			const { points: matchPoints } = matchSelectors.calculateMatch(match)

			Object.entries(matchPoints).map(([team, teamPoints]) => {
				points[team].points += teamPoints.points
				points[team].front += teamPoints.front.point ?? 0
				points[team].back += teamPoints.back.point ?? 0
				points[team].eighteen += teamPoints.eighteen.point ?? 0
			})
		})
		return points
	}
)

export const calculateFlightWinningTeam = createCachedParamSelector([calculateFlightPoints], (points) => {
	const teams = Object.entries(points)

	return teams[0][1].points > teams[1][1].points ? teams[0][0] : teams[1][0]
})

export const calculateFlightPointsByPlayer = createCachedParamSelector(
	[(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params), (state, params) => tripSelectors.selectFlightByParam(state, params)],
	(matches, trip) => {
		const points = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = team.players.reduce((playersObj, player) => {
				playersObj[player.player.id] = {
					points: 0,
					front: 0,
					back: 0,
					eighteen: 0
				}
				return playersObj
			}, {})
			return obj
		}, {})

		matches.map((match) => {
			const { points: matchPoints } = matchSelectors.calculateMatch(match)
			match.teams.map((team) => {
				team.players.map((player) => {
					points[team.team][player.player].points += matchPoints[team.team].points
					points[team.team][player.player].front += matchPoints[team.team].front.point ?? 0
					points[team.team][player.player].back += matchPoints[team.team].back.point ?? 0
					points[team.team][player.player].eighteen += matchPoints[team.team].eighteen.point ?? 0
				})
			})
		})
		return points
	}
)

export const calculateFlightScoringAverageByPlayer = createCachedParamSelector(
	[(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params), (state, params) => tripSelectors.selectFlightByParam(state, params)],
	(matches, trip) => {
		const scores = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = team.players.reduce((playersObj, player) => {
				playersObj[player.player.id] = {
					gross: {
						total: 0,
						front: 0,
						back: 0
					},
					net: {
						total: 0,
						front: 0,
						back: 0
					},
					rounds: 0
				}
				return playersObj
			}, {})
			return obj
		}, {})

		matches.map((match) => {
			if (match.__t !== 'TwoPersonScramble') {
				const { scores: matchScores } = matchSelectors.calculateMatch(match)
				match.teams.map((team) => {
					team.players.map((player) => {
						scores[team.team][player.player].gross.front += matchScores[team.team][player.player].gross.front
						scores[team.team][player.player].gross.back += matchScores[team.team][player.player].gross.back
						scores[team.team][player.player].gross.total += matchScores[team.team][player.player].gross.total
						scores[team.team][player.player].net.front += matchScores[team.team][player.player].net.front
						scores[team.team][player.player].net.back += matchScores[team.team][player.player].net.back
						scores[team.team][player.player].net.total += matchScores[team.team][player.player].net.total
						scores[team.team][player.player].rounds++
					})
				})
			}
		})
		Object.entries(scores).map(([team, players]) => {
			Object.entries(players).map(([player, scoring]) => {
				const rounds = scoring.rounds
				scoring.gross.front = rounds > 0 ? scoring.gross.front / rounds : scoring.gross.front
				scoring.gross.back = rounds > 0 ? scoring.gross.back / rounds : scoring.gross.back
				scoring.gross.total = rounds > 0 ? scoring.gross.total / rounds : scoring.gross.total
				scoring.net.front = rounds > 0 ? scoring.net.front / rounds : scoring.net.front
				scoring.net.back = rounds > 0 ? scoring.net.back / rounds : scoring.net.back
				scoring.net.total = rounds > 0 ? scoring.net.total / rounds : scoring.net.total
			})
		})

		return scores
	}
)

export const calculateFlightDate = createCachedParamSelector(
	(state, params) => selectFlightRoundsWithGroups(state, params),
	(rounds) => {
		console.log(rounds)
		const roundsArr = Object.entries(rounds)
		if (roundsArr.length > 0) {
			const [key, round] = roundsArr[0]

			if (round.groups.length > 0) {
				return round.groups[0].teeTime
			}
		}
		return false
	}
)

export const calculateFlightStarted = createCachedParamSelector([(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params)], (matches) => {
	let started = false
	matches.every((match) => {
		if (match.scorecard.holes.length > 0) {
			started = true
			return false
		}
		return true
	})
	return started
})

export const calculateFlightFinished = createCachedParamSelector([(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params)], (matches) => {
	let finished = true
	matches.every((match) => {
		if (match.scorecard.holes.length !== 18) {
			finished = false
			return false
		}
		return true
	})
	return finished
})

export const calculateFlightMVP = createCachedParamSelector(
	[(state, params) => tripSelectors.selectFlightByParam(state, params), calculateFlightPointsByPlayer, calculateFlightFinished],
	(trip, points, finished) => {
		return trip.teams.reduce((obj, team) => {
			obj[team.team.id] = finished
				? Object.entries(points[team.team.id]).reduce(
						(max, [player, points]) => (points.points > max ? points.points : max),
						Object.entries(points[team.team.id])[0][1].points
				  )
				: null
			return obj
		}, {})
	}
)

const getHoleIndexParam = createParamSelector((params) => params.holeIndex)

const selectMatchHole = createCachedParamSelector(
	[(match, params) => match.nines, getHoleIndexParam],
	(nines, holeIndex) => match.nines[Math.floor(holeIndex / 9)].holes[holeIndex % 9]
)

const selectMatchNineHole = createCachedParamSelector([(match, params) => match.scorecard.holes, getHoleIndexParam], (holes, holeIndex) => holes[holeIndex]) */
