import React from 'react'
import { Container, Row, Card, CardBlock } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { Route, Switch, Redirect } from 'react-router-dom'

import Login from './components/Login'
import Register from './components/Register'
//import ForgotPassword from './components/ForgotPassword'
import ClaimInvite from './components/ClaimInvite'

export default (props) => {
	const { match } = props
	return (
		<Container className="py-xxxs-5 py-lg-7" style={{ minHeight: '100vh', display: 'flex' }}>
			<Row className="justify-content-xxxs-center align-items-xxxs-center" style={{ flexBasis: '1', width: '100%' }}>
				<Col xxxs={12} md={8} lg={4}>
					<Card>
						<CardBlock>
							<Switch>
								<Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
								<Route path={`${match.url}/login`} component={Login} />
								<Route path={`${match.url}/register`} component={Register} />
								{/* <Route path={`${match.url}/forgot-password`} component={ForgotPassword} /> */}
								<Route path={`${match.url}/invite/:id`} component={ClaimInvite} />
							</Switch>
						</CardBlock>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}
