import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import { camelCase, upperFirst } from 'lodash'
import pluralize from 'pluralize'
import deepEqual from 'deep-equal'

import * as contactThunks from '~/state/entities/contact/thunks'
import * as dealerThunks from '~/state/entities/dealer/thunks'
import * as userThunks from '~/state/entities/user/thunks'
import * as userRoleThunks from '~/state/entities/userRole/thunks'
import * as userInviteThunks from '~/state/entities/userInvite/thunks'
import * as opportunityThunks from '~/state/entities/opportunity/thunks'
import * as opportunityStageTypeThunks from '~/state/entities/opportunityStageType/thunks'
import * as opportunityActivityThunks from '~/state/entities/opportunityActivity/thunks'
import * as quoteThunks from '~/state/entities/quote/thunks'
import * as quoteRunThunks from '~/state/entities/quoteRun/thunks'
import * as quoteRunSegmentThunks from '~/state/entities/quoteRunSegment/thunks'
import * as quoteLocationThunks from '~/state/entities/quoteLocation/thunks'
import * as runTypeThunks from '~/state/entities/runType/thunks'
import * as productThunks from '~/state/entities/product/thunks'
import * as rateThunks from '~/state/entities/rate/thunks'
import * as controllerThunks from '~/state/entities/controller/thunks'
import * as installDetailsThunks from '~/state/entities/installDetails/thunks'
import * as openPhoneNumberThunks from '~/state/entities/openPhoneNumber/thunks'
import * as siteVisitThunks from '~/state/entities/siteVisit/thunks'
import * as quoteTermThunks from '~/state/entities/quoteTerm/thunks'
import * as quoteLineItemThunks from '~/state/entities/quoteLineItem/thunks'
import * as opportunityTagThunks from '~/state/entities/opportunityTag/thunks'
import * as compensationProgramThunks from '~/state/entities/compensationProgram/thunks'
import * as compensationProgramEnrollmentThunks from '~/state/entities/compensationProgramEnrollment/thunks'
/* import * as tastingThunks from '~/state/entities/tasting/thunks'
import * as tastingUserThunks from '~/state/entities/tastingUser/thunks'
import * as flightThunks from '~/state/entities/flight/thunks'
import * as flightRankingThunks from '~/state/entities/flightRanking/thunks'
import * as breweryThunks from '~/state/entities/brewery/thunks'
import * as userThunks from '~/state/entities/user/thunks' */
import pusherThunk from '~/state/entities/pusher'

export const createParamSelector = (selector) => (_, params) => selector(params)

export const createCachedParamSelector = (...selector) => {
	// TODO: figure out using javascript arguments.....
	return createCachedSelector(...selector)((state, params) => {
		return params
			? typeof params === 'string'
				? params
				: Object.entries(params)
						.reduce((arr, [key, value]) => {
							if (typeof value === 'string') {
								arr.push(value)
							}
							return arr
						}, [])
						.join(':')
			: ''
	})
}

export const createCachedStateParamSelector = (stateVars, ...selector) => {
	return createCachedSelector(...selector)((state, params) => {
		return (
			stateVars.map((stateVar) => state.entities[stateVar].ids.length).join(':') +
			(params
				? Object.entries(params)
						.reduce((arr, [key, value]) => {
							if (typeof value === 'string') {
								arr.push(value)
							}
							return arr
						}, [])
						.join(':')
				: '')
		)
	})
}

export const entityThunks = {
	...contactThunks,
	...dealerThunks,
	...opportunityThunks,
	...opportunityStageTypeThunks,
	...opportunityActivityThunks,
	...userThunks,
	...userRoleThunks,
	...userInviteThunks,
	...quoteThunks,
	...quoteLocationThunks,
	...quoteRunThunks,
	...quoteRunSegmentThunks,
	...runTypeThunks,
	...productThunks,
	...rateThunks,
	...controllerThunks,
	...installDetailsThunks,
	...openPhoneNumberThunks,
	...siteVisitThunks,
	...quoteTermThunks,
	...quoteLineItemThunks,
	...opportunityTagThunks,
	...compensationProgramThunks,
	...compensationProgramEnrollmentThunks
	/* ...tastingThunks,
	...tastingUserThunks,
	...flightThunks,
	...flightRankingThunks,
	...breweryThunks,
	 */
}

const entityAdapter = createEntityAdapter()
const { sortComparer, getSelectors, getInitialState, ...rest } = entityAdapter

//console.log(entityThunks)

export const entityReducers = rest

export const entityUpdater = (key) => {
	const reducers = {}

	const entityName = /^[A-Z]/.test(key) ? key : pluralize(key)

	Object.entries(entityThunks).map((entityThunk) => {
		const thunk = entityThunk[1]
		reducers[thunk.fulfilled] = (state, action) => {
			const { entities } = action.payload
			if (entities && entities[entityName]) {
				Object.entries(entities[entityName]).map((e) => {
					const entity = e[1]
					if (!state.entities[entity.id] || !deepEqual(state.entities[entity.id], entity)) {
						entityReducers.upsertOne(state, entity)
					}
				})
			}
		}

		reducers[pusherThunk.fulfilled] = (state, action) => {
			const { entity, data } = action.payload

			if (entity === entityName) {
				if (!state.entities[data.id] || !deepEqual(state.entities[data.id], data)) {
					entityReducers.upsertOne(state, data)
				}
			}
		}
	})

	return reducers
}

export const entityCreator = (entityName, config = {}) => {
	const { reducers, extraReducers, initialState } = config

	const sliceName = camelCase(entityName)
	const selectorName = upperFirst(sliceName)

	const selectorNames = {
		[`get${selectorName}Param`]: null,
		[`select${selectorName}ById`]: null,
		[`select${selectorName}ByParam`]: null,
		[`select${selectorName}Ids`]: null,
		[`select${selectorName}Entities`]: null,
		[`selectAll${pluralize(selectorName, 0)}`]: null,
		[`selectTotal${pluralize(selectorName, 0)}`]: null
	}

	const selectors = ({
		selectIds: selectorNames[`select${selectorName}Ids`],
		selectEntities: selectorNames[`select${selectorName}Entities`],
		selectAll: selectorNames[`selectAll${pluralize(selectorName, 0)}`],
		selectTotal: selectorNames[`selectTotal${pluralize(selectorName, 0)}`]
	} = entityAdapter.getSelectors((state) => state.entities[sliceName]))

	const selectEntities = (state) => state.entities[sliceName].entities
	const getParam = createParamSelector((params) => params[sliceName])
	const selectByParam = createCachedParamSelector(
		[(state, params) => selectEntities(state, params), (state, params) => getParam(state, params)],
		(entities, id) => entities[id]
	)
	selectorNames[`get${selectorName}Param`] = getParam
	selectorNames[`select${selectorName}ByParam`] = selectByParam

	selectorNames[`select${selectorName}ById`] = createCachedSelector(
		[selectEntities, (state, id) => id],
		(entities, id) => entities[id]
	)((entities, id) => {
		return id ? id : ''
	})

	return [
		createSlice({
			name: sliceName,
			initialState: entityAdapter.getInitialState({
				loading: false,
				error: null,
				loadData: true,
				...initialState
			}),
			reducers: {
				...entityReducers,
				...reducers,
				[`skipLoading`]: (state) => {
					state.loadData = false
				},
				[`resetLoading`]: (state) => {
					state.loadData = true
				}
			},
			extraReducers: {
				...entityUpdater(entityName),
				...extraReducers
			}
		}),
		selectorNames,
		entityReducers
	]
}

export default entityAdapter
