import React, { useEffect, useRef, useState } from 'react'
import { H3, Hr, Row } from '@bootstrap-styled/v4'
import { ActionButton, Button, Col } from '~/components/bootstrap'
import SalesCommissionsListingTable from './components/SalesCommissionsListingTable'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { DateRangePicker } from 'react-date-range'
import styled from 'styled-components'
import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfWeek,
	endOfWeek,
	isSameDay,
	differenceInCalendarDays,
	format
} from 'date-fns'

const SalesCommissionsPage = () => {
	const [selectedSalesperson, setselectedSalesperson] = useState(null)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [refreshRows, setRefreshRows] = useState(true)

	return (
		<React.Fragment>
			<Row className="align-items-xxxs-center mb-xxxs-3">
				<Col xxxs>
					<H3>Sales Commissions</H3>
				</Col>
				<Col xxxs={3}>
					<SalesPersonFilter
						selectedSalesperson={selectedSalesperson}
						setselectedSalesperson={setselectedSalesperson}
						refresh={() => setRefreshRows(true)}
					/>
				</Col>
				<Col xxxs="auto" style={{ position: 'relative' }}>
					<DateRangeFilter
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						refresh={() => setRefreshRows(true)}
					/>
				</Col>
			</Row>
			<SalesCommissionsListingTable
				selectedSalesperson={selectedSalesperson}
				startDate={startDate}
				endDate={endDate}
				refreshRows={refreshRows}
				setRefreshRows={setRefreshRows}
			/>
		</React.Fragment>
	)
}

const SalesPersonFilter = ({ selectedSalesperson, setselectedSalesperson, refresh }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const salespeople = useSelector((state) => selectors.selectUsersByRole(state, 'sales'))
	const options = salespeople?.map((user) => ({ label: user.name, value: user.id })) ?? []

	useEffect(() => {
		dispatch(entityThunks.fetchUsersByRole('sales'))
	}, [])

	const onChange = (option, action) => {
		// if action is clear then option is null anyways so no need for special handling
		setselectedSalesperson(option)
		refresh()
	}

	return <UncontrolledSelect value={selectedSalesperson} options={options} onChange={onChange} placeholder="Salesperson" isClearable />
}

const DateRangeFilter = ({ startDate, setStartDate, endDate, setEndDate, refresh }) => {
	const [buttonText, setButtonText] = useState('Filter By Date')
	const [newStartDate, setNewStartDate] = useState(startDate)
	const [newEndDate, setNewEndDate] = useState(endDate)
	const setRanges = [
		{
			startDate: startOfDay(new Date()),
			endDate: endOfDay(new Date()),
			label: 'Today'
		},
		{
			startDate: startOfDay(addDays(new Date(), -1)),
			endDate: endOfDay(addDays(new Date(), -1)),
			label: 'Yesterday'
		},
		{
			startDate: startOfWeek(new Date()),
			endDate: endOfWeek(new Date()),
			label: 'This Week'
		},
		{
			startDate: startOfWeek(addDays(new Date(), -7)),
			endDate: endOfWeek(addDays(new Date(), -7)),
			label: 'Last Week'
		},
		{
			startDate: startOfMonth(new Date()),
			endDate: endOfMonth(new Date()),
			label: 'This Month'
		},
		{
			startDate: startOfMonth(addMonths(new Date(), -1)),
			endDate: endOfMonth(addMonths(new Date(), -1)),
			label: 'Last Month'
		}
	]

	const onChange = ({ selection }) => {
		setNewStartDate(selection.startDate)
		setNewEndDate(selection.endDate)
	}

	const onClose = () => {
		setStartDate(newStartDate)
		setEndDate(newEndDate ? endOfDay(newEndDate) : null)
		updateButtonText({ startDate: newStartDate, endDate: newEndDate })
		refresh()
	}

	const updateButtonText = (selection) => {
		if (!(selection.startDate || selection.endDate)) {
			setButtonText('Filter By Date')
			return
		}
		for (const range of setRanges) {
			if (isSameDay(range.startDate, selection.startDate) && isSameDay(range.endDate, selection.endDate)) {
				setButtonText(range.label)
				return
			}
		}
		setButtonText(
			`${selection.startDate ? format(selection.startDate, 'MMM d, yyyy') : 'Early'} -> ${
				selection.endDate ? format(selection.endDate, 'MMM d, yyyy') : 'Continuous'
			}`
		)
	}

	const reset = () => {
		setNewStartDate(null)
		setNewEndDate(null)
	}

	const close = (setIsOpen) => {
		onClose()
		setIsOpen(false)
	}

	return (
		<ActionButton title="Choose Date Range" button={buttonText} color="info" onClose={onClose}>
			{(setIsOpen) => {
				return (
					<React.Fragment>
						<Row className="align-items-xxxs-center justify-content-xxxs-center">
							<Col xxxs="auto">
								<DateRangePicker
									ranges={[{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }]}
									onChange={onChange}
									inputRanges={[]}
								/>
							</Col>
						</Row>
						<Hr />
						<Row>
							<Col xxxs>
								<Button block color="warning" onClick={reset}>
									Reset
								</Button>
							</Col>
							<Col xxxs>
								<Button block color="danger" onClick={() => close(setIsOpen)}>
									Set
								</Button>
							</Col>
						</Row>
					</React.Fragment>
				)
			}}
		</ActionButton>
	)
}

export default SalesCommissionsPage
