import React, { useEffect, useState } from 'react'
import { useLumenox } from '~/services/lumenox'
import { MultipleInputArray, Select, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { useSelector } from 'react-redux'

export const AssignedToSelect = ({ name, selected, roleOptionsIds, onChange, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)
	const salesUsers = useSelector((state) => selectors.selectUsersByRole(state, 'sales'))
	const adminUsers = useSelector((state) => selectors.selectUsersByRole(state, 'admin'))
	const estimatorUsers = useSelector((state) => selectors.selectUsersByRole(state, 'estimator'))
	const installerUsers = useSelector((state) => selectors.selectUsersByRole(state, 'installer'))
	let users = [...new Set([...salesUsers, ...estimatorUsers, ...installerUsers, ...adminUsers])]
	//not actually using this now but can still be useful
	if (roleOptionsIds) {
		users = users.filter((user) => user.roles.some((role) => roleOptionsIds.includes(role)))
	}

	const admin = userHasRole('admin')

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
		dispatch(entityThunks.fetchUsers())
	}, [])

	if (!users) {
		return null
	}
	const options = users
		.slice()
		.filter((user) => {
			if (!admin) {
				console.log(user, currentUser)
				return user.id === currentUser.sub
			}
			return true
		})
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((res, cur) => {
			res.push({ value: cur.id, label: cur.name })
			return res
		}, [])

	const value = selected ? options.find((option) => option.value === selected) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return (
		<SelectElement
			name={name}
			options={options}
			{...rest}
			value={value}
			onChange={(option, action) => onChange(option, action, setIsLoading)}
			isLoading={isLoading}
		/>
	)
}

export const InstallerSelect = ({ name, selected, values, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const installers = useSelector((state) => selectors.selectUsersByRole(state, 'installer'))
	const admin = userHasRole('admin')
	const sales = userHasRole('sales')

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
		dispatch(entityThunks.fetchUsers())
	}, [])

	if (!installers) {
		return null
	}
	const options = installers
		.slice()
		.filter((user) => {
			if (sales && !admin) {
				console.log(user, currentUser)
				return user.id === currentUser.sub
			}
			return true
		})
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((res, cur) => {
			res.push({ value: cur.id, label: cur.name })
			return res
		}, [])

	const SelectElement = name ? Select : UncontrolledSelect

	return <SelectElement isMulti name={name} options={options} {...rest} value={values} />
}

export const SiteVisitorSelect = ({ name, selected, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const siteVisitors = useSelector((state) => selectors.selectUsersByRole(state, 'installer'))
	const estimators = useSelector((state) => selectors.selectUsersByRole(state, 'estimator'))
	const admin = userHasRole('admin')
	const sales = userHasRole('sales')

	const users = [...new Set([...siteVisitors, ...estimators])]

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
		dispatch(entityThunks.fetchUsers())
	}, [])

	if (!siteVisitors) {
		return null
	}
	const options = users
		.slice()
		.filter((user) => {
			if (sales && !admin) {
				console.log(user, currentUser)
				return user.id === currentUser.sub
			}
			return true
		})
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((res, cur) => {
			res.push({ value: cur.id, label: cur.name })
			return res
		}, [])

	const value = selected ? options.find((option) => option.value === selected) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return <SelectElement name={name} options={options} {...rest} value={value} />
}

export const CSRField = ({ name, selected, onChange, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)
	const users = useSelector((state) => selectors.selectUsersByRole(state, 'csr'))
	const admin = userHasRole('admin')
	const sales = userHasRole('sales')

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
		dispatch(entityThunks.fetchUsers())
	}, [])

	if (!users) {
		return null
	}
	const options = users
		.slice()
		.filter((user) => {
			if (sales && !admin) {
				console.log(user, currentUser)
				return user.id === currentUser.sub
			}
			return true
		})
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((res, cur) => {
			res.push({ value: cur.id, label: cur.name })
			return res
		}, [])

	const value = selected ? options.find((option) => option.value === selected) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return (
		<SelectElement
			name={name}
			options={options}
			{...rest}
			value={value}
			onChange={(option, action) => onChange(option, action, setIsLoading)}
			isLoading={isLoading}
		/>
	)
}

export const InstallerField = ({ name, selected, onChange, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)
	const users = useSelector((state) => selectors.selectUsersByRole(state, 'installer'))
	const admin = userHasRole('admin')
	const sales = userHasRole('sales')

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
		dispatch(entityThunks.fetchUsers())
	}, [])

	if (!users) {
		return null
	}
	const options = users
		// .slice()
		// this filter lets sales only assign themselves which isn't relevant here
		// .filter((user) => {
		// 	if (sales && !admin) {
		// 		console.log(user, currentUser)
		// 		return user.id === currentUser.sub
		// 	}
		// 	return true
		// })
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((res, cur) => {
			res.push({ value: cur.id, label: cur.name })
			return res
		}, [])

	const value = selected ? options.find((option) => option.value === selected) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return (
		<SelectElement
			name={name}
			options={options}
			{...rest}
			value={value}
			onChange={(option, action) => onChange(option, action, setIsLoading)}
			isLoading={isLoading}
		/>
	)
}

export const SalespersonField = ({ name, selected, onChange, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)
	const users = useSelector((state) => selectors.selectUsersByRole(state, 'sales'))
	const admin = userHasRole('admin')
	const sales = userHasRole('sales')

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
		dispatch(entityThunks.fetchUsers())
	}, [])

	if (!users) {
		return null
	}
	const options = users
		.slice()
		//this filter lets sales only assign themselves
		.filter((user) => {
			if (sales && !admin) {
				// console.log(user, currentUser)
				return user.id === currentUser.sub
			}
			return true
		})
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((res, cur) => {
			res.push({ value: cur.id, label: cur.name })
			return res
		}, [])

	const value = selected ? options.find((option) => option.value === selected) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return (
		<SelectElement
			name={name}
			options={options}
			{...rest}
			value={value}
			onChange={(option, action) => onChange(option, action, setIsLoading)}
			isLoading={isLoading}
		/>
	)
}

export const DealerField = ({ name, selected, onChange, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole, user: currentUser } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)
	const dealers = useSelector((state) => selectors.selectAllDealers(state))
	const admin = userHasRole('admin')
	const sales = userHasRole('csr')

	useEffect(() => {
		dispatch(entityThunks.fetchDealers())
	}, [])

	if (!dealers) {
		return null
	}
	const options = dealers.slice().reduce((res, cur) => {
		res.push({ value: cur.id, label: cur.name })
		return res
	}, [])

	const value = selected ? options.find((option) => option.value === selected) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return (
		<SelectElement
			name={name}
			options={options}
			{...rest}
			value={value}
			onChange={(option, action) => onChange(option, action, setIsLoading)}
			isLoading={isLoading}
		/>
	)
}

export const TagField = ({ selected, name, handleTagCreated, onChange, ...rest }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()
	const [isLoading, setIsLoading] = useState(false)
	const tags = useSelector((state) => selectors.selectAllOpportunityTags(state))

	useEffect(() => {
		dispatch(entityThunks.fetchOpportunityTags())
	}, [])

	const options = tags.map((tag) => ({ label: tag.name, value: tag }))

	const value = selected ? options.filter((option) => selected.includes(option.value.id)) : null

	const SelectElement = name ? Select : UncontrolledSelect

	return (
		<SelectElement
			name={name}
			options={options}
			{...rest}
			value={value}
			isMulti
			creatable
			onChange={(option, action) => onChange(option, action, setIsLoading)}
			onCreateOption={(value) => handleTagCreated(value, setIsLoading)}
			isLoading={isLoading}
		/>
	)
}
