import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, CenteredModal } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Hr, ModalBody, ModalHeader, P, Form } from '@bootstrap-styled/v4'
import { DatePicker, Textarea, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm } from 'formik'

const InstallTimelineScheduledStageButton = ({ opportunity, currentStage, nextStage }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const [installDateModalOpen, setInstallDateModalOpen] = useState(false)
	const [confirmedInstallDate, setConfirmedInstallDate] = useState(null)
	const [isSubmitting, setIsSubmitting] = useState(false)

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	const submit = () => {
		setIsSubmitting(true)
		dispatch(
			entityThunks.updateOpportunityInstallDate({
				id: opportunity.id,
				installDate: { tentative: opportunity.installDate.tentative, confirmed: confirmedInstallDate }
			})
		).then(() => {
			dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
				setIsSubmitting(false)
			})
		})
	}

	const onChangeConfirmedInstallDate = (value) => {
		setConfirmedInstallDate(value)
	}

	if (!dependenciesMet) return null

	return (
		<React.Fragment>
			<Button color={nextStage.color} icon={solid[nextStage.icon]} onClick={() => setInstallDateModalOpen(true)}>
				{nextStage.name}
			</Button>
			<CenteredModal isOpen={installDateModalOpen} size="sm">
				<ModalHeader toggle={() => setInstallDateModalOpen(false)}>Set Install Date</ModalHeader>
				<ModalBody>
					<ControlledDatePicker value={confirmedInstallDate} onChange={onChangeConfirmedInstallDate} />
					<Hr />
					<Button onClick={submit} disabled={confirmedInstallDate === null} color="success" submitting={isSubmitting} block>
						Continue
					</Button>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

const ControlledDatePicker = ({ value, onChange }) => {
	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={{ date: null }} validationSchema={null} onSubmit={null}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<DatePicker name="date" value={value} onChange={onChange} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default InstallTimelineScheduledStageButton
