import { createCachedSelector } from 're-reselect'
import { createCachedParamSelector, createParamSelector } from '~/state/entities'
import { primitiveSelectors as opportunityActivitySelectors } from '../index'
import opportunity, { primitiveSelectors as opportunitySelectors } from '~/state/entities/opportunity'
import { primitiveSelectors as opportunityStageTypeSelectors } from '~/state/entities/opportunityStageType'
import { primitiveSelectors as userSelectors } from '~/state/entities/user'
import { primitiveSelectors as userRoleSelectors } from '~/state/entities/userRole'
import { isBefore, sub } from 'date-fns'
import { addOpportunityActivityNote } from '../../opportunity/thunks'
import isEqual from 'lodash/isEqual'

const entitiesByOpportunityCache = {}

// ok I should have it!!! Just change all of the selectOpportunityActivityEntities to be per activity and use that instead!!!
export const selectOpportunityActivityEntitiesByOpportunity = createCachedParamSelector(
	[
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(state, opportunityId) => opportunitySelectors.selectOpportunityById(state, opportunityId)
	],
	(allEntities, opportunity) => {
		console.log('running selectOpportunityActivityEntitiesByOpportunity')
		const entitiesByOpportunity = Object.keys(allEntities).reduce((obj, id) => {
			if (opportunity.activity.includes(id)) {
				obj[id] = allEntities[id]
			}
			return obj
		}, {})

		if (!isEqual(entitiesByOpportunity, entitiesByOpportunityCache[opportunity._id])) {
			console.log('not equal, updating cache')
			entitiesByOpportunityCache[opportunity._id] = entitiesByOpportunity
		}
		return entitiesByOpportunityCache[opportunity._id]
	}
)

export const selectOpportunityActivitiesSorted = createCachedParamSelector(
	[
		(state, params) => opportunitySelectors.selectOpportunityByParam(state, params),
		(state, params) => selectOpportunityActivityEntitiesByOpportunity(state, params.opportunity),
		(_, params) => params.direction
	],
	(opportunity, opportunityActivities, direction = 'asc') => {
		//console.log('Debugging', 'inside selectOpportunityActivitiesSorted')
		//console.trace()
		return opportunity.activity.slice().sort((a, b) => {
			const aActivity = opportunityActivities[a]
			const bActivity = opportunityActivities[b]

			if (!aActivity || !bActivity) return 0
			let aTime, bTime

			switch (aActivity.__t) {
				case 'Email':
				case 'FollowUp':
				case 'Call':
				case 'SMS':
				case 'InstallDetailsActivity':
					aTime = aActivity.createdAt
					break
				default:
					aTime = aActivity.updatedAt
			}

			switch (bActivity.__t) {
				case 'Email':
				case 'FollowUp':
				case 'Call':
				case 'SMS':
				case 'InstallDetailsActivity':
					bTime = bActivity.createdAt
					break
				default:
					bTime = bActivity.updatedAt
			}

			return aTime < bTime ? (direction === 'asc' ? -1 : 1) : direction === 'asc' ? 1 : -1
		})
	}
)

export const selectOpportunityLastActivity = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSorted(state, { ...params, direction: 'desc' }),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(state, params) => params
	],
	(opportunityActivities, entities, params) => {
		return entities[opportunityActivities[0]]
	}
)

export const selectOpportunityActivityLastStage = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSorted(state, { ...params, direction: 'desc' }),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state)
	],
	(opportunityActivities, entities) => {
		const result = opportunityActivities.find((activity) => entities[activity] && entities[activity].__t === 'Stage')
		if (result) {
			return entities[result]
		} else {
			return null
		}
	}
)

export const selectOpportunityActivityStage = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSorted(state, { ...params, direction: 'desc' }),
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(state, _) => opportunityStageTypeSelectors.selectOpportunityStageTypeEntities(state),
		(_, params) => params.stage
	],
	(opportunityActivities, entities, stageTypeEnities, stage) => {
		return entities[
			opportunityActivities.find(
				(activityId) =>
					entities[activityId] &&
					entities[activityId].__t === 'Stage' &&
					stageTypeEnities[entities[activityId].stage] &&
					stageTypeEnities[entities[activityId].stage].slug === stage
			)
		]
	}
)

export const selectOpportunityQuoteSentActivity = createCachedParamSelector(
	[
		(state, params) => {
			const { direction, ...rest } = params
			return selectOpportunityActivitiesSorted(state, { direction: 'desc', ...rest })
		},
		(state, _) => opportunityActivitySelectors.selectOpportunityActivityEntities(state),
		(state, _) => opportunityStageTypeSelectors.selectOpportunityStageTypeEntities(state)
	],
	(opportunityActivities, entities, stageTypeEnities) => {
		return entities[
			opportunityActivities.find(
				(activityId) =>
					entities[activityId] &&
					entities[activityId].__t === 'Stage' &&
					stageTypeEnities[entities[activityId].stage] &&
					stageTypeEnities[entities[activityId].stage].slug === 'quote-sent'
			)
		]
	}
)

export const selectOpportunityActivitiesSortedWithPinned = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSorted(state, params),
		(state, params) => selectOpportunityActivityEntitiesByOpportunity(state, params.opportunity)
	],
	(sortedOpportunityActivities, opportunityActivities) => {
		//console.log('Debugging', 'inside selectOpportunityActivitiesSortedWithPinned')
		return sortedOpportunityActivities.slice().sort((a, b) => {
			const aActivity = opportunityActivities[a]
			const bActivity = opportunityActivities[b]
			if (!aActivity || !bActivity) return 0
			if (aActivity.pinned) return bActivity.pinned ? 0 : -1
			else if (bActivity.pinned) return 1
			return 0
		})
	}
)

export const selectFilteredOpportunityActivitiesSorted = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSortedWithPinned(state, params),
		(state, params) => selectOpportunityActivityEntitiesByOpportunity(state, params.opportunity),
		(_, params) => params.filters
	],
	(sortedOpportunityActivities, opportunityActivities, filters) => {
		//console.log('Debugging', 'inside selectFilteredOpportunityActivitiesSorted')
		if (filters.length === 1 && filters[0] === 'All') return sortedOpportunityActivities
		return sortedOpportunityActivities.filter((activityId) => {
			const activity = opportunityActivities[activityId]
			if (!activity) return false
			if (activity.children?.length > 0) {
				//only one layer deep right now since we only have one layer of children used anywhere (follow ups)
				for (const childId of activity.children) {
					const childActivity = opportunityActivities[childId]
					if (childActivity && filters.includes(childActivity.__t)) return true
				}
			}
			return filters.includes(activity.__t)
		})
	}
)

export const selectOpportunityActivitiesSortedByType = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSorted(state, params),
		(state, params) => selectOpportunityActivityEntitiesByOpportunity(state, params.opportunity),
		(_, params) => params.type
	],
	(sortedOpportunityActivities, opportunityActivities, type) => {
		return sortedOpportunityActivities
			.filter((activityId) => {
				const activity = opportunityActivities[activityId]
				if (!activity) return false
				return activity.__t === type
			})
			.map((activityId) => opportunityActivities[activityId])
	}
)

export const selectLatestAssignedToWithRole = createCachedParamSelector(
	[
		(state, params) => selectOpportunityActivitiesSortedByType(state, { ...params, type: 'Salesperson', direction: 'desc' }),
		(state, params) => userSelectors.selectUserEntities(state),
		(state, params) => userRoleSelectors.selectUserRoleEntities(state),
		(_, params) => params.roleSlug
	],
	(sortedOpportunityActivitiesWithType, users, userRoles, roleSlug) => {
		for (const activity of sortedOpportunityActivitiesWithType) {
			const user = users[activity.user]
			if (!user) continue
			for (const roleId of user.roles) {
				const role = userRoles[roleId]
				if (!role) continue
				if (role.slug === roleSlug) return user
			}
		}
		return null
	}
)

/* const selectFlightRounds = createCachedParamSelector(
	[(state, params) => tripSelectors.selectFlightByParam(state, params), roundSelectors.selectAllRounds],
	(trip, rounds) => {
		return rounds.filter((round) => trip.rounds.includes(round.id))
	}
)

const selectFlightRoundsWithGroups = createCachedParamSelector([selectFlightRounds, groupSelectors.selectGroupEntities], (rounds, groups) => {
	const tripRounds = {}
	rounds.map((round) => {
		tripRounds[round.id] = {
			...round,
			groups: round.groups.map((group) => groups[group]).sort((a, b) => new Date(a.teeTime) - new Date(b.teeTime))
		}
	})
	return tripRounds
})

const selectFlightRoundsWithMatches = createCachedParamSelector([selectFlightRoundsWithGroups, matchSelectors.selectMatchEntities], (rounds, matches) => {
	const tripRounds = {}
	console.log(rounds)
	if (rounds) {
		Object.entries(rounds).map(([key, round]) => {
			tripRounds[key] = {
				...round,
				groups: round.groups
					.map((group) => ({
						...group,
						matches: group.matches.map((match) => matches[match.id])
					}))
					.sort((a, b) => new Date(a.teeTime) - new Date(b.teeTime))
			}
		})
	}
	console.log(tripRounds)
	return tripRounds
})

const getCurrentMemberParam = createParamSelector((params) => params.currentMember)

export const selectCurrentGroup = createCachedParamSelector([selectFlightRoundsWithMatches, getCurrentMemberParam], (rounds, currentMember) => {
	let currentGroup = false
	if (rounds && currentMember) {
		const currentTime = new Date()
		Object.entries(rounds).every(([key, round]) => {
			return round.groups.every((group) => {
				const foundMatch = group.matches.find(
					(match) => match.teams.find((team) => team.players.find((player) => player.player === currentMember) !== undefined) !== undefined
				)

				if (foundMatch) {
					let completed = true
					group.matches.map((match) => {
						if (match.scorecard.holes.length < 18) {
							completed = false
						}
					})

					if (!completed && isBefore(sub(new Date(group.teeTime), { minutes: 15 }), currentTime)) {
						currentGroup = group
						return false
					}
				}
				return true
			})
		})
	}

	return currentGroup
})

export const calculateFlightPoints = createCachedParamSelector(
	[
		//(state, params) => selectFlightRoundsWithGroups(state, params),
		(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params),
		(state, params) => tripSelectors.selectFlightByParam(state, params)
	],
	(matches, trip) => {
		const points = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = {
				points: 0,
				front: 0,
				back: 0,
				eighteen: 0
			}
			return obj
		}, {})
		matches.map((match) => {
			const { points: matchPoints } = matchSelectors.calculateMatch(match)

			Object.entries(matchPoints).map(([team, teamPoints]) => {
				points[team].points += teamPoints.points
				points[team].front += teamPoints.front.point ?? 0
				points[team].back += teamPoints.back.point ?? 0
				points[team].eighteen += teamPoints.eighteen.point ?? 0
			})
		})
		return points
	}
)

export const calculateFlightWinningTeam = createCachedParamSelector([calculateFlightPoints], (points) => {
	const teams = Object.entries(points)

	return teams[0][1].points > teams[1][1].points ? teams[0][0] : teams[1][0]
})

export const calculateFlightPointsByPlayer = createCachedParamSelector(
	[(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params), (state, params) => tripSelectors.selectFlightByParam(state, params)],
	(matches, trip) => {
		const points = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = team.players.reduce((playersObj, player) => {
				playersObj[player.player.id] = {
					points: 0,
					front: 0,
					back: 0,
					eighteen: 0
				}
				return playersObj
			}, {})
			return obj
		}, {})

		matches.map((match) => {
			const { points: matchPoints } = matchSelectors.calculateMatch(match)
			match.teams.map((team) => {
				team.players.map((player) => {
					points[team.team][player.player].points += matchPoints[team.team].points
					points[team.team][player.player].front += matchPoints[team.team].front.point ?? 0
					points[team.team][player.player].back += matchPoints[team.team].back.point ?? 0
					points[team.team][player.player].eighteen += matchPoints[team.team].eighteen.point ?? 0
				})
			})
		})
		return points
	}
)

export const calculateFlightScoringAverageByPlayer = createCachedParamSelector(
	[(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params), (state, params) => tripSelectors.selectFlightByParam(state, params)],
	(matches, trip) => {
		const scores = trip.teams.reduce((obj, team) => {
			obj[team.team.id] = team.players.reduce((playersObj, player) => {
				playersObj[player.player.id] = {
					gross: {
						total: 0,
						front: 0,
						back: 0
					},
					net: {
						total: 0,
						front: 0,
						back: 0
					},
					rounds: 0
				}
				return playersObj
			}, {})
			return obj
		}, {})

		matches.map((match) => {
			if (match.__t !== 'TwoPersonScramble') {
				const { scores: matchScores } = matchSelectors.calculateMatch(match)
				match.teams.map((team) => {
					team.players.map((player) => {
						scores[team.team][player.player].gross.front += matchScores[team.team][player.player].gross.front
						scores[team.team][player.player].gross.back += matchScores[team.team][player.player].gross.back
						scores[team.team][player.player].gross.total += matchScores[team.team][player.player].gross.total
						scores[team.team][player.player].net.front += matchScores[team.team][player.player].net.front
						scores[team.team][player.player].net.back += matchScores[team.team][player.player].net.back
						scores[team.team][player.player].net.total += matchScores[team.team][player.player].net.total
						scores[team.team][player.player].rounds++
					})
				})
			}
		})
		Object.entries(scores).map(([team, players]) => {
			Object.entries(players).map(([player, scoring]) => {
				const rounds = scoring.rounds
				scoring.gross.front = rounds > 0 ? scoring.gross.front / rounds : scoring.gross.front
				scoring.gross.back = rounds > 0 ? scoring.gross.back / rounds : scoring.gross.back
				scoring.gross.total = rounds > 0 ? scoring.gross.total / rounds : scoring.gross.total
				scoring.net.front = rounds > 0 ? scoring.net.front / rounds : scoring.net.front
				scoring.net.back = rounds > 0 ? scoring.net.back / rounds : scoring.net.back
				scoring.net.total = rounds > 0 ? scoring.net.total / rounds : scoring.net.total
			})
		})

		return scores
	}
)

export const calculateFlightDate = createCachedParamSelector(
	(state, params) => selectFlightRoundsWithGroups(state, params),
	(rounds) => {
		console.log(rounds)
		const roundsArr = Object.entries(rounds)
		if (roundsArr.length > 0) {
			const [key, round] = roundsArr[0]

			if (round.groups.length > 0) {
				return round.groups[0].teeTime
			}
		}
		return false
	}
)

export const calculateFlightStarted = createCachedParamSelector([(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params)], (matches) => {
	let started = false
	matches.every((match) => {
		if (match.scorecard.holes.length > 0) {
			started = true
			return false
		}
		return true
	})
	return started
})

export const calculateFlightFinished = createCachedParamSelector([(state, params) => matchSelectors.selectFlightMatchHandicaps(state, params)], (matches) => {
	let finished = true
	matches.every((match) => {
		if (match.scorecard.holes.length !== 18) {
			finished = false
			return false
		}
		return true
	})
	return finished
})

export const calculateFlightMVP = createCachedParamSelector(
	[(state, params) => tripSelectors.selectFlightByParam(state, params), calculateFlightPointsByPlayer, calculateFlightFinished],
	(trip, points, finished) => {
		return trip.teams.reduce((obj, team) => {
			obj[team.team.id] = finished
				? Object.entries(points[team.team.id]).reduce(
						(max, [player, points]) => (points.points > max ? points.points : max),
						Object.entries(points[team.team.id])[0][1].points
				  )
				: null
			return obj
		}, {})
	}
)

const getHoleIndexParam = createParamSelector((params) => params.holeIndex)

const selectMatchHole = createCachedParamSelector(
	[(match, params) => match.nines, getHoleIndexParam],
	(nines, holeIndex) => match.nines[Math.floor(holeIndex / 9)].holes[holeIndex % 9]
)

const selectMatchNineHole = createCachedParamSelector([(match, params) => match.scorecard.holes, getHoleIndexParam], (holes, holeIndex) => holes[holeIndex]) */
