import React, { useEffect, useState } from 'react'
import { ButtonGroup, Card, CardBlock, CardHeader, Form, FormGroup, H5, Row, Small, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { ActionButton, Button, Col, Table } from '~/components/bootstrap'
import { faBell, faCircleInfo, faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { Checkbox, Label, Select } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { format } from 'date-fns'
import { callApi } from '~/services/api'

const CompensationProgramCard = ({ id }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))
	const contact = useSelector((state) => selectors.selectContactByIdCompensationProgramsPopulated(state, opportunity?.contacts?.[0]))

	useEffect(() => {
		//only need to load in programs that this lead is involved in at this point
		//this also loads in programs
		dispatch(entityThunks.fetchCompensationProgramEnrollmentsByOpportunity({ id })).then((result) => {})
	})

	return (
		<Card className="mt-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs>
						<H5 className="mb-xxxs-0">Compensation Programs</H5>
					</Col>
					<Col xxxs="auto">
						{contact && (
							<ActionButton color="success" icon={faPlus} title="Add Compensation Program">
								{(closeModal) => <AddCompensationProgramModal closeModal={closeModal} contactId={contact.id} opportunityId={id} />}
							</ActionButton>
						)}
					</Col>
				</Row>
			</CardHeader>
			<Table>
				<Thead>
					<Tr>
						<Th>Program</Th>
						<Th>Code</Th>
						<Th>End Date</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				{contact && contact.compensationPrograms && (
					<Tbody>
						{contact.compensationPrograms.map((enrollment) => (
							<Tr key={enrollment.id}>
								<Td>{enrollment.program.name}</Td>
								<Td>{enrollment.code}</Td>
								<Td>{enrollment.program.endDate ? format(new Date(enrollment.program.endDate), 'MMMM d, y') : 'TBD'}</Td>
								<Td>
									<ButtonGroup size="sm">
										<ActionButton color="info" icon={faCircleInfo} title="Program Stats">
											{(closeModal) => <CompensationStatsModal closeModal={closeModal} enrollment={enrollment} />}
										</ActionButton>
										<ActionButton color="warning" icon={faBell} title="Edit Notification Preferences">
											{(closeModal) => <UpdateNotificationsModal closeModal={closeModal} enrollment={enrollment} />}
										</ActionButton>
									</ButtonGroup>
								</Td>
							</Tr>
						))}
					</Tbody>
				)}
			</Table>
		</Card>
	)
}

const CompensationStatsModal = ({ enrollment, closeModal }) => {
	if (!enrollment || !enrollment.program) return null
	const [programStats, setProgramStats] = useState(null)

	useEffect(() => {
		callApi({
			endpoint: `compensation-programs/enrollments/${enrollment.id}/stats`
		}).then((result) => {
			setProgramStats(result.data)
		})
	}, [])

	if (!programStats) return <p>Loading...</p>

	return (
		<Table>
			<Thead>
				<Tr>
					<Th>New Leads</Th>
					<Th>Paid Leads</Th>
					<Th>Total Compensation</Th>
				</Tr>
			</Thead>
			<Tbody>
				<Tr>
					<Td>{programStats.newLeads}</Td>
					<Td>{programStats.paidLeads}</Td>
					<Td>
						{programStats.totalCompensation.cash ? <React.Fragment>${programStats.totalCompensation.cash.toFixed(2)} (Cash)</React.Fragment> : null}
						{programStats.totalCompensation.giftCard ? (
							<React.Fragment>
								{programStats.totalCompensation.cash ? <br /> : null}${programStats.totalCompensation.giftCard.toFixed(2)} (Gift Cards)
							</React.Fragment>
						) : null}
						{programStats.totalCompensation.cash + programStats.totalCompensation.giftCard === 0 && '$0'}
					</Td>
				</Tr>
			</Tbody>
		</Table>
	)
}

const UpdateNotificationsModal = ({ enrollment, closeModal }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const initialValues = {
		notifications: enrollment.notifications
	}

	const validationSchema = Yup.object().shape({
		notifications: Yup.object({
			new: Yup.bool().required(),
			paid: Yup.bool().required()
		})
	})

	const handleSubmit = (values, helpers) => {
		helpers.setSubmitting(true)
		dispatch(entityThunks.updateCompensationProgramEnrollment({ id: enrollment.id, data: values })).then((response) => {
			//console.log('response:', response)
			helpers.setSubmitting(false)
			closeModal(false)
		})
	}

	return (
		<Formik validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="notifications.new">Send New Referee Email Notifications?</Checkbox>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="notifications.paid">Send Referee Paid Email Notifications?</Checkbox>
							</Col>
						</FormGroup>
						<Row className="align-items-xxxs-center justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit" color="success" submitting={isSubmitting}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

const AddCompensationProgramModal = ({ contactId, opportunityId, closeModal }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	useEffect(() => {
		//want to load in all active programs that they could join
		dispatch(entityThunks.fetchActiveCompensationPrograms()).then((result) => {})
	})

	const contact = useSelector((state) => selectors.selectContactByIdCompensationProgramsPopulated(state, contactId))
	const activeCompensationPrograms = useSelector((state) => selectors.selectActiveCompensationPrograms(state))

	//filter down to ones they're not already enrolled in
	const availableCompensationPrograms = activeCompensationPrograms
		.filter((program) => contact.compensationPrograms.find((pgm) => pgm.program && pgm.program.id === program.id) === undefined)
		.map((program) => ({ label: program.name, value: program.id }))

	const initialValues = {
		program: '',
		sendWelcomeEmail: true,
		notifications: {
			new: true,
			paid: true
		}
	}

	const validationSchema = Yup.object().shape({
		program: Yup.string().required('Required'),
		sendWelcomeEmail: Yup.bool().required(),
		notifications: Yup.object({
			new: Yup.bool().required(),
			paid: Yup.bool().required()
		})
	})

	const handleSubmit = (values, helpers) => {
		helpers.setSubmitting(true)
		dispatch(entityThunks.addCompensationProgramToContact({ id: contactId, data: { ...values, opportunityId } })).then((response) => {
			//console.log('response:', response)
			helpers.setSubmitting(false)
			closeModal(false)
		})
	}

	const removeProgram = (programId) => {
		console.log('removing')
	}

	return (
		<Formik validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<FormGroup>
							<Select name={`program`} options={availableCompensationPrograms} isSearchable={false} />
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="sendWelcomeEmail">Send Enrollment Email?</Checkbox>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="notifications.new">Send New Referee Email Notifications?</Checkbox>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="notifications.paid">Send Referee Paid Email Notifications?</Checkbox>
							</Col>
						</FormGroup>
						<Row className="align-items-xxxs-center justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit" color="success" submitting={isSubmitting}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default CompensationProgramCard
