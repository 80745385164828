import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ConfirmationButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'

// doesn't show up if we've already been to the the stage (only works if there aren't any backwards loops after the stage)
// ex if a lead could go to paid and then back to qualified, we would wanna check if it's been in that stage since then, not just at all
// but this doesn't handle that
const PreventRepeatStageButton = ({ opportunity, currentStage, nextStage }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const [alreadyVisited, setAlreadyVisited] = useState(false)
	const previousStageActivities = useSelector((state) =>
		selectors.selectOpportunityActivitiesSortedByType(state, { opportunity: opportunity.id, type: 'Stage', direction: 'desc' })
	)

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	useEffect(() => {
		// use state variable and useEffect so we don't need to run check on every render
		setAlreadyVisited(previousStageActivities.some((activity) => activity.stage === nextStage.id))
	}, [previousStageActivities, nextStage])

	const updateStage = () => {
		dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			//don't need anything, can't fail since dependencies are already checked
		})
	}

	if (!dependenciesMet || alreadyVisited) return null

	return (
		<ConfirmationButton
			color={nextStage.color}
			icon={solid[nextStage.icon]}
			handleConfirmation={updateStage}
			button={nextStage.name}
			description={`Are you sure you want to push this lead to ${nextStage.name}?`}
			title="Confirm Stage Change"
		/>
	)
}

export default PreventRepeatStageButton
