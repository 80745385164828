import React from 'react'
import { useSelector } from 'react-redux'
import { CardBlock, Row } from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import { Col } from '~/components/bootstrap'

const SMS = ({ id, renderChildren }) => {
	const { selectors } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	return (
		<CardBlock>
			<Row>
				<Col xxxs>
					{activity.body}
					{activity.media &&
						activity.media.length > 0 &&
						activity.media.map((media) => {
							return <img key={media.url} style={{ maxWidth: '100%' }} src={media.url} />
						})}
				</Col>
			</Row>
			{renderChildren()}
		</CardBlock>
	)
}

export default SMS
