import { createCachedSelector } from 're-reselect'
import { createCachedParamSelector, createParamSelector } from '~/state/entities'
import { primitiveSelectors as opportunityTagSelectors } from '../index'
import { isBefore, sub } from 'date-fns'

// should be fine since we only load these in once
export const selectOpportunityTagsOrdered = createCachedSelector([(state) => opportunityTagSelectors.selectAllOpportunityTags(state)], (tags) => {
	return tags.slice().sort((a, b) => {
		if (!a?.name || !b?.name) return 0
		return a.name.localeCompare(b.name)
	})
})((state) => state?.entities?.opportunityTag?.ids?.length || '')
