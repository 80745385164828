import React, { useEffect, useState } from 'react'
import { Card, CardBlock, CardHeader, Form, FormGroup, H5, Row, Strong, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { ActionButton, Badge, Button, CenteredModal, Col, Table } from '~/components/bootstrap'
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { AsyncSelect, Checkbox, Input, Label, Select, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import { format } from 'date-fns'
import { callApi } from '~/services/api'
import { components } from 'react-select'

const ReferralCard = ({ id }) => {
	const { selectors, dispatch, entityThunks, userIncludesRole } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))
	const referrerObj = useSelector((state) => selectors.selectOpportunityReferrer(state, id))
	const referralProgram = useSelector((state) => selectors.selectOpportunityReferralCompensationProgram(state, id))
	const hasPermissions = userIncludesRole(['admin', 'csr'])

	let referrerName = null
	if (referrerObj) {
		const { type, referrer } = referrerObj
		if (type === 'contact') {
			referrerName = referrer.name.first + ' ' + referrer.name.last
		} else {
			//user
			referrerName = referrer.data.displayName
		}
	}

	useEffect(() => {
		if (opportunity.referral) {
			dispatch(entityThunks.fetchOpportunityReferralContact({ id }))
			dispatch(entityThunks.fetchOpportunityReferralUser({ id }))
		}
	}, [opportunity.referral])

	return (
		<Card className="mt-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs>
						<H5 className="mb-xxxs-0">Referred By</H5>
					</Col>
					{!opportunity.referral && hasPermissions && (
						<Col xxxs="auto">
							<ActionButton color="success" icon={faPlus} title="Add Referral">
								{(closeModal) => <AddReferralModal closeModal={closeModal} opportunityId={id} />}
							</ActionButton>
						</Col>
					)}
				</Row>
			</CardHeader>
			{referrerName && referralProgram && (
				<Table>
					<Thead>
						<Tr>
							<Th>Name</Th>
							<Th>Program</Th>
							<Th>Referee Compensation</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td>{referrerName}</Td>
							<Td>{referralProgram.name}</Td>
							<Td>
								{referralProgram.refereeCompensation.map((rc, i) => {
									return (
										<React.Fragment key={rc._id}>
											{rc.type === 'Fixed' && '$'}
											{rc.value}
											{rc.type === 'Percentage' && '%'}
											{' (' + rc.method + ')'}
											<br />
										</React.Fragment>
									)
								})}
							</Td>
						</Tr>
					</Tbody>
				</Table>
			)}
		</Card>
	)
}

const AddReferralModal = ({ closeModal, opportunityId }) => {
	const { dispatch, entityThunks } = useLumenox()
	const [code, setCode] = useState('')
	const [selectedReferrer, setSelectedReferrer] = useState(null)
	const [lastTimeout, setLastTimeout] = useState(null)
	const [programIds, setprogramIds] = useState([])
	const [selectedProgram, setSelectedProgram] = useState(null)

	const selectedReferrerChanged = (option) => {
		setSelectedReferrer(option)
		setprogramIds(option.value.compensationPrograms)
	}

	const getOptions = (input) => {
		//could adjust this and also pass the current contact id so that you can't refer yourself but also probably not a big deal
		return callApi({
			endpoint: `/compensation-programs/enrollments/referrer-options/${input}`
		}).then((result) => {
			setLastTimeout(null)
			return result.data
		})
	}

	const loadOptions = (input) => {
		if (input === '') return
		return new Promise((resolve) => {
			if (lastTimeout !== null) {
				clearTimeout(lastTimeout)
			}
			setLastTimeout(
				setTimeout(() => {
					resolve(getOptions(input))
				}, 500)
			)
		})
	}

	const onProgramChange = (option) => {
		setSelectedProgram(option)
	}

	const addByCode = () => {
		dispatch(entityThunks.updateOpportunityReferralByCode({ id: opportunityId, code: code.toUpperCase() })).then((response) => {
			closeModal(false)
		})
	}

	const addByName = () => {
		dispatch(entityThunks.updateOpportunityReferral({ id: opportunityId, referral: selectedProgram.value })).then((response) => {
			closeModal(false)
		})
	}

	return (
		<Row>
			<Col xxxs={4}>
				<Label>By Code:</Label>
				<ControlledInput value={code} onChange={(e) => setCode(e.target.value)} />
				<Button className="mt-xxxs-3" color="success" onClick={addByCode} block disabled={code.length !== 6}>
					Add Referral
				</Button>
			</Col>
			<Col xxxs={8}>
				<Label>By Name:</Label>
				<ControlledAsyncSelect
					value={selectedReferrer}
					loadOptions={loadOptions}
					handleOnChange={selectedReferrerChanged}
					CustomOption={ReferrerOption}
				/>
				{programIds.length > 0 && (
					<React.Fragment>
						<Label className="mt-xxxs-3">Program:</Label>
						<CompensationProgramSelect value={selectedProgram} onChange={onProgramChange} ids={programIds} />
					</React.Fragment>
				)}
				<Button className="mt-xxxs-3" color="success" onClick={addByName} block disabled={selectedProgram === null}>
					Add Referral
				</Button>
			</Col>
		</Row>
	)
}

const CompensationProgramSelect = ({ value, onChange, ids }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const enrollments = useSelector((state) => selectors.selectCompensationProgramEnrollmentsByIdsProgramsPopulated(state, ids))
	const options = enrollments ? enrollments.map((e) => ({ label: e.program.name, value: e.id })) : []

	useEffect(() => {
		dispatch(entityThunks.fetchCompensationProgramEnrollmentsByIds({ ids })).then((response) => {})
	}, [ids])

	return <UncontrolledSelect value={value} onChange={onChange} options={options} />
}

const ReferrerOption = (props) => {
	return (
		<React.Fragment>
			<components.Option {...props}>
				<Row noGutters className="align-xxxs-start">
					<Col xxxs>{props.label}</Col>
					<Col xxxs="auto">
						<Badge>{props.value.type}</Badge>
					</Col>
				</Row>
			</components.Option>
		</React.Fragment>
	)
}

const ControlledAsyncSelect = ({ value, loadOptions, handleOnChange, CustomOption }) => {
	return (
		<Formik initialValues={{ search: '' }}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<AsyncSelect value={value} loadOptions={loadOptions} handleOnChange={handleOnChange} name="search" CustomOption={CustomOption} />
					</Form>
				)
			}}
		</Formik>
	)
}

const ControlledInput = ({ value, onChange }) => {
	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={{ text: '' }} validationSchema={null} onSubmit={null}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<Input name="text" value={value} onChange={onChange} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default ReferralCard
