import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ConfirmationButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'

const DefaultStageButton = ({ opportunity, currentStage, nextStage }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	const updateStage = () => {
		dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			//don't need anything, can't fail since dependencies are already checked
		})
	}

	if (!dependenciesMet) return null

	return (
		<ConfirmationButton
			color={nextStage.color}
			icon={solid[nextStage.icon]}
			handleConfirmation={updateStage}
			button={nextStage.name}
			description={`Are you sure you want to push this lead to ${nextStage.name}?`}
			title="Confirm Stage Change"
		/>
	)
}

export default DefaultStageButton
