import { createSlice } from '@reduxjs/toolkit'
//import { ThunkAction } from 'redux-thunk'
import { callPublicApi, callApi } from '~/services/api'
import { sub, isAfter } from 'date-fns'

export const slice = createSlice({
	name: 'auth',
	initialState: {
		/* data: {
			invite: {
				data: null,
				cache: null
			}
		}, */
		ui: {
			invite: {
				loading: false,
				error: false
			},
			register: {
				loading: false,
				error: false
			}
		}
	},
	reducers: {
		/* setFetchInviteSuccess: (state, action) => {
			const { invite } = state.data

			invite.data = action.payload
		},
		setFetchInviteFailed: (state) => {
			const { invite } = state.data

			invite.data = null
		},
		clearInvite: (state) => {
			const { invite } = state.data

			invite.data = null
		}, */
		setLoading: (state, action) => {
			const { ui } = state
			const { key, value } = action.payload
			ui[key].loading = value
		},
		setError: (state, action) => {
			const { ui } = state
			const { key, value } = action.payload
			ui[key].error = value
		},
		setCache: (state, action) => {
			const { data } = state
			const key = action.payload
			data[key].cache = new Date().toString()
		}
	}
})

const { setFetchInviteSuccess, setFetchInviteFailed, setLoading, setError, setCache } = slice.actions

export const { clearInvite } = slice.actions

export const fetchInvite = (id) => (dispatch) => {
	console.log('fetching invite')
	const key = 'invite'

	dispatch(setLoading({ key: key, value: true }))
	return callPublicApi({
		endpoint: `users/invite/${id}`
	})
		.then((response) => {
			console.log(response)
			//dispatch(setFetchInviteSuccess(response.data))
			dispatch(setLoading({ key: key, value: false }))

			return response.data
		})
		.catch((err) => {
			dispatch(setError({ key: key, value: err }))
			dispatch(setLoading({ key: key, value: false }))

			return false
		})
}

export const claimInvite = (inviteId) => (dispatch) => {
	console.log('claiming invite')
	console.log(inviteId)
	const key = 'invite'

	dispatch(setLoading({ key: key, value: true }))
	return callApi({
		endpoint: `users/invite/${inviteId}`,
		method: 'PUT'
	})
		.then((response) => {
			console.log(response)
			dispatch(setLoading({ key: key, value: false }))

			return response.data
		})
		.catch((err) => {
			dispatch(setError({ key: key, value: err }))
			dispatch(setLoading({ key: key, value: false }))

			return false
		})
}

export const addUser = () => (dispatch) => {
	console.log('add user')
	const key = 'register'

	dispatch(setLoading({ key: key, value: true }))
	return callApi({
		endpoint: `users`,
		method: 'POST'
	})
		.then((response) => {
			console.log(response)
			dispatch(setLoading({ key: key, value: false }))

			return response.data
		})
		.catch((err) => {
			dispatch(setError({ key: key, value: err }))
			dispatch(setLoading({ key: key, value: false }))

			return false
		})
}

export default slice.reducer
