import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchCompensationProgramEnrollments = createAsyncThunk('compensationProgramEnrollments/fetch', () => {
	return callApi({
		endpoint: `compensation-programs/enrollments`
	}).then((response) => {
		return response.data
	})
})

export const fetchCompensationProgramEnrollmentsByOpportunity = createAsyncThunk('compensationProgramEnrollments/fetchByOpportunity', ({ id }) => {
	return callApi({
		endpoint: `compensation-programs/enrollments/by-opportunity/${id}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

export const fetchCompensationProgramEnrollmentsByIds = createAsyncThunk('compensationProgramEnrollments/fetchByIds', ({ ids }) => {
	return callApi({
		endpoint: 'compensation-programs/enrollments/by-ids',
		method: 'GET',
		params: { ids }
	}).then((response) => {
		return response.data
	})
})

export const updateCompensationProgramEnrollment = createAsyncThunk('compensationProgramEnrollmentss/update', ({ id, data }) => {
	return callApi({
		endpoint: `compensation-programs/enrollments/${id}`,
		method: 'PUT',
		data
	}).then((response) => {
		return response.data
	})
})
