import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Nav, NavItem, NavLink, Badge } from '@bootstrap-styled/v4'
import { Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { ContactName, AssignedName, StageName, LastActivity, TagName, UserName } from '../../components/common'
import { SortTh } from '~/components/bootstrap/components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBadgeCheck, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons'

const InstallDateTable = ({ assignedOnly }) => {
	const { dispatch, entityThunks, selectors, actions, user } = useLumenox()
	const [loading, setLoading] = useState(false)
	const [sortProp, setSortProp] = useState('install-date')
	const [sortDir, setSortDir] = useState('asc')
	const { params } = useRouteMatch({
		path: '/:slug',
		strict: false
	})

	const skipLoad = () => {
		dispatch(actions.opportunity.skipLoading())
	}

	const leads = useSelector((state) =>
		selectors.selectLeadsSortedByParam(state, { direction: sortDir, prop: sortProp, assignedTo: assignedOnly ? user.uid : null })
	)

	useEffect(() => {
		setLoading(true)
		dispatch(entityThunks.fetchOpportunities({ slug: params.slug, limit: 200, assignedOnly }))
			//.then(() => dispatch(entityThunks.fetchOpportunities({ slug: params.slug, skip: 200 })))
			.then(() => {
				setLoading(false)
			})
	}, [params.slug, assignedOnly])

	const onSortHandler = (prop) => {
		if (prop === sortProp) {
			setSortDir(sortDir === 'desc' ? 'asc' : 'desc')
		} else {
			setSortProp(prop)
			setSortDir('asc')
		}
	}

	return (
		<Table bordered striped responsive loading={loading}>
			<Thead>
				<Tr>
					<SortTh width="40%" active={sortProp === 'name'} direction={sortDir} onClick={() => onSortHandler('name')}>
						Name
					</SortTh>
					<Th width="15%">Tags</Th>
					<Th>Assigned To</Th>
					<SortTh active={sortProp === 'last-activity'} direction={sortDir} onClick={() => onSortHandler('last-activity')}>
						Last Activity
					</SortTh>
					<SortTh active={sortProp === 'install-date'} direction={sortDir} onClick={() => onSortHandler('install-date')}>
						Install Date
					</SortTh>
				</Tr>
			</Thead>
			<Tbody>
				{leads.map((lead, i) => {
					return (
						<Tr key={i}>
							<Td>
								<A tag={Link} to={`/leads/${lead.id}`} onClick={skipLoad}>
									{lead.contacts.map((contact, c) => (
										<ContactName key={c} id={contact} />
									))}
								</A>
							</Td>
							<Td>
								{lead.tags.map((tag, t) => (
									<Badge key={tag} color="info" style={{ whiteSpace: 'normal' }}>
										<TagName id={tag} />
									</Badge>
								))}
							</Td>
							<Td>
								{lead.assignedTo !== lead.installer && (
									<Badge color="info">
										<UserName id={lead.assignedTo} />
									</Badge>
								)}
								{lead.installer && lead.assignedTo !== lead.installer && <br />}
								{lead.installer && (
									<Badge color="success">
										<UserName id={lead.installer} />
										<FontAwesomeIcon icon={faScrewdriverWrench} className="ml-xxxs-1" />
									</Badge>
								)}
							</Td>
							<Td>
								<LastActivity id={lead.id} />
							</Td>
							<Td>
								{lead.installDate &&
									(lead.installDate.confirmed ? (
										<React.Fragment>
											{format(new Date(lead.installDate.confirmed), 'MMMM d, y')}
											<FontAwesomeIcon icon={faBadgeCheck} className="ml-xxxs-1" />
										</React.Fragment>
									) : lead.installDate.tentative ? (
										format(new Date(lead.installDate.tentative), 'MMMM d, y')
									) : null)}
							</Td>
						</Tr>
					)
				})}
			</Tbody>
		</Table>
	)
}

export default InstallDateTable
