import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, P, Ul, Li, Ol, H3, Card, CardBlock, Badge } from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import ReactMarkdown from 'react-markdown'

const FollowUp = ({ id, renderChildren }) => {
	const { selectors } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	if (activity?.details?.replace(/\u200B/g, '').trim() === '') return null

	return (
		<CardBlock>
			<MarkdownContainer>
				<ReactMarkdown children={activity?.details} />
			</MarkdownContainer>
			{renderChildren()}
		</CardBlock>
	)
}

export default FollowUp

const MarkdownContainer = styled.div`
	*:first-child {
		margin-top: 0;
	}
	*:last-child {
		margin-bottom: 0;
	}
	img {
		max-width: 100%;
	}
`
