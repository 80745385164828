import { entityCreator } from '../adapter'
import * as otherSelectors from './selectors'

const [entitySlice, defaultSelectors] = entityCreator('Dealer')

export const slice = entitySlice
export const primitiveSelectors = defaultSelectors
export const selectors = { ...defaultSelectors, ...otherSelectors }
export const actions = slice.actions
export default slice.reducer
