import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, P, Ul, Li, Ol, H3, Card, CardBlock, Badge } from '@bootstrap-styled/v4'

import { useLumenox } from '~/services/lumenox'
import { faEdit } from '@fortawesome/pro-solid-svg-icons' //not working for some reason

import ReactMarkdown from 'react-markdown'
import NoteForm from '../../../NoteForm'
import { ActionButton, Button, ConfirmationButton, Col } from '~/components/bootstrap'

const Note = ({ id, renderChildren }) => {
	const { selectors } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	return (
		<CardBlock>
			<MarkdownContainer>
				<ReactMarkdown children={activity.content} />
			</MarkdownContainer>
			{renderChildren()}
		</CardBlock>
	)
}

export default Note

const MarkdownContainer = styled.div`
	*:first-child {
		margin-top: 0;
	}
	*:last-child {
		margin-bottom: 0;
	}
	img {
		max-width: 100%;
	}
`
