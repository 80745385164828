import React, { PureComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLumenox } from '~/services/lumenox'
import styled from 'styled-components'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { CenteredModal } from '~/components/bootstrap'
import { useFirebase } from '~/services/firebase'
import { Container, ModalBody, ModalHeader } from '@bootstrap-styled/v4'
import { upperFirst } from 'lodash'
import PrivateRoute from '~/components/common/PrivateRoute'
import View from './View'
import Form from './SiteVisitChecklistForm'

const SiteVisitChecklist = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(true)
	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, params.id))
	const siteVisit = useSelector((state) => selectors.selectSiteVisitById(state, opportunity.siteVisit))

	const handleExit = () => {
		console.log('exit')
		history.push(url)
	}

	return (
		<CenteredModal isOpen={isOpen} onExit={handleExit}>
			<ModalHeader toggle={handleExit}>Site Visit Checklist</ModalHeader>
			<ModalBody>
				<Route exact path={`${url}/site-visit-checklist`} component={opportunity.siteVisit ? View : Form} />
				<Route path={`${url}/site-visit-checklist/update`} render={(props) => (siteVisit ? <Form currentSiteVisit={siteVisit} /> : null)} />
			</ModalBody>
		</CenteredModal>
	)
}

export default SiteVisitChecklist
