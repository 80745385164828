import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th } from '@bootstrap-styled/v4'
import { Table, Button, Row, Col, ActionButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import ButtonGroup from '@bootstrap-styled/v4/lib/ButtonGroup'
import UpdateProductForm from './UpdateProductForm'
import ProductHistory from './ProductHistory'
import Badge from '@bootstrap-styled/v4/lib/Badge'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faHistory } from '@fortawesome/pro-regular-svg-icons'

const ProductTable = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()

	const products = useSelector((state) => selectors.selectAllProducts(state))

	useEffect(() => {
		dispatch(entityThunks.fetchProducts())
	}, [])

	return (
		<Table bordered striped responsive>
			<Thead>
				<Tr>
					<Th width="50%">Name</Th>
					<Th>Price</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{
					/*products &&*/ products
						/* .sort((a, b) => a.updatedAt < b.updatedAt) */
						.map((product, i) => {
							return (
								<Tr key={i}>
									<Td>{product.name}</Td>
									<Td>
										<ProductRate key={i} product={product} />
									</Td>
									<Td>
										<ButtonGroup>
											<ActionButton button={<FontAwesomeIcon icon={faEdit} />} title="New Rate">
												{(closeModal) => (
													<UpdateProductForm
														onSuccess={() => {
															closeModal()
														}}
														product={product}
													/>
												)}
											</ActionButton>
											<ActionButton button={<FontAwesomeIcon icon={faHistory} />} title="History">
												{(closeModal) => (
													<ProductHistory
														onSuccess={() => {
															closeModal()
														}}
														product={product}
													/>
												)}
											</ActionButton>
										</ButtonGroup>
									</Td>
								</Tr>
							)
						})
				}
			</Tbody>
		</Table>
	)
}

const ProductRate = ({ product }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const rate = useSelector((state) => selectors.selectCurrentRateByProduct(state, product.id))

	if (!rate) {
		return null
	}
	return (
		<Fragment>
			{rate.currencies.map((currency, i) => {
				return (
					<Badge key={i} color="info">
						${currency.value} {currency.symbol}
					</Badge>
				)
			})}
		</Fragment>
	)
}

export default ProductTable
