import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, ButtonToolbar, ButtonGroup, CardHeader, H4, H5, H6, Badge, P, Tbody, Tr, Th, Td, Form } from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import * as Yup from 'yup'
import { Formik, Form as FormikForm, useField, FieldArray } from 'formik'
import { Checkbox, DatePicker, FormGroup, Input, MultipleInputArray, Select, Textarea } from '~/components/bootstrap/components/Form'
import { Button, Col } from '~/components/bootstrap'
import { InstallerSelect, SiteVisitorSelect } from '~/components/common/Forms'

const InstallDetailsForm = ({ closeModal, id, opportunityId }) => {
	//can eventually make channel quantity autofill from quote if there is an approved quote
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()

	let currentDetails = useSelector((state) => selectors.selectInstallDetailsById(state, id))

	const defaultDetails = {
		date: new Date(),
		installers: [''],
		channel: [
			{
				type: '',
				color: '',
				quantity: ''
			}
		],
		boxLocation: '',
		lightsLocation: '',
		plugLocations: '',
		siteDetails: '',
		siteVisitDate: new Date(),
		siteVisitor: '',
		homeownerPresent: false,
		confirmedDate: false
	}

	const initialValues = {
		...defaultDetails,
		...currentDetails
	}

	//nothing is required, can always just fill out whatever you know
	const validationSchema = Yup.object({
		date: Yup.date(),
		installers: Yup.array().of(Yup.string()),
		channel: Yup.array().of(
			Yup.object({
				type: Yup.string(),
				color: Yup.string(),
				quantity: Yup.number().nullable()
			})
		),
		boxLocation: Yup.string(),
		lightsLocation: Yup.string(),
		plugLocations: Yup.string(),
		siteDetails: Yup.string(),
		siteVisitDate: Yup.date(),
		siteVisitor: Yup.string().nullable(),
		homeownerPresent: Yup.bool(),
		confirmedDate: Yup.bool()
	})

	const onSubmit = (values, { setSubmitting }) => {
		values.channel = values.channel.filter((channel) => channel.quantity && channel.color && channel.type)
		console.log('submitted: ', values)
		setSubmitting(true)
		dispatch(entityThunks.updateOpportunityInstallDetails({ id: opportunityId, installDetails: values })).then((response) => {
			setSubmitting(false)
			closeModal()
		})
	}

	const channelOptions = [
		{ label: 'Regular', value: 'Regular' },
		{ label: 'J-Channel', value: 'J-Channel' },
		{ label: 'Front Fascia', value: 'Front Fascia' },
		{ label: 'Back Fascia', value: 'Back Fascia' },
		{ label: 'Deep', value: 'Deep' },
		{ label: 'Side Holes', value: 'Side Holes' },
		{ label: 'Curved - Vertical', value: 'Curved - Vertical' },
		{ label: 'Curved - Horizontal', value: 'Curved - Horizontal' },
		{ label: 'Sloped', value: 'Sloped' }
	]

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				if (Object.keys(errors).length > 0) console.log('errors:', errors)
				return (
					<Form tag={FormikForm}>
						<H5>Site Visit</H5>
						<FormGroup row className="mb-xxxs-3">
							<Col xxxs={12} md={6}>
								<H6>Date:</H6>
								<DatePicker name="siteVisitDate" />
							</Col>
							<Col xxxs={12} md={6}>
								<H6>Completed By:</H6>
								<SiteVisitorSelect name="siteVisitor" />
								{/*May need to make this a custom select  that's not just installers*/}
							</Col>
						</FormGroup>
						<FormGroup className="mb-xxxs-3">
							<H6>Details</H6>
							<Textarea name="siteDetails" />
						</FormGroup>
						<H5>Install:</H5>
						<FormGroup row className="mb-xxxs-3">
							<Col xxxs={12} md={6}>
								<H6>Date:</H6>
								<DatePicker name="date" />
							</Col>
							<Col xxxs={12} md={6}>
								<H6>Installer(s):</H6>
								<InstallerSelect name="installers" values={values.installers} />
							</Col>
						</FormGroup>
						<MultipleInputArray name="channel" values={values.channel} initialValues={{ type: '', color: '', quantity: '' }}>
							{({ i, setElRef, setFocused }) => (
								<FormGroup row className="mb-xxxs-0" key={i}>
									<Col xxxs={12} md={4}>
										<H6>Channel Type</H6>
										<Select name={`channel.${i}.type`} options={channelOptions} />
									</Col>
									<Col xxxs={12} md={4}>
										<H6>Channel Color</H6>
										<Input name={`channel.${i}.color`} />
									</Col>
									<Col xxxs={12} md={4}>
										<H6>Channel Quantity</H6>
										<Input type="number" name={`channel.${i}.quantity`} />
									</Col>
								</FormGroup>
							)}
						</MultipleInputArray>
						<FormGroup row className="mb-xxxs-3">
							<Col xxxs={12} md={6}>
								<H6>Elevations</H6>
								<Input name="lightsLocation" />
							</Col>
							<Col xxxs={12} md={6}>
								<H6>Control Box</H6>
								<Input name="boxLocation" />
							</Col>
							<Col xxxs={12} className="mt-xxxs-1">
								<H6>Plug Locations</H6>
								<Input name="plugLocations" />
							</Col>
						</FormGroup>

						<FormGroup row className="align-items-xxxs-center mb-xxxs-3">
							<Col xxxs="auto">
								<H6>Homeowner confirmed they will be present:</H6>
							</Col>
							<Col xxxs>
								<Checkbox name="homeownerPresent" />
							</Col>
						</FormGroup>
						<FormGroup row className="align-items-xxxs-center mb-xxxs-3">
							<Col xxxs="auto">
								<H6>Homeowner confirmed date:</H6>
							</Col>
							<Col xxxs>
								<Checkbox name="dateConfirmed" />
							</Col>
						</FormGroup>
						<Hr />
						<Row className="justify-content-xxxs-end">
							<Col xxxs>
								<Button type="submit" color="success" submitting={isSubmitting} block>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default InstallDetailsForm
