import React from 'react'
import { useSelector } from 'react-redux'
import { CardHeader, Row } from '@bootstrap-styled/v4'

import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMessageSms, faPeopleArrows, faPhoneVolume, faStickyNote } from '@fortawesome/pro-duotone-svg-icons'

const FollowUp = ({ id, opportunityId, renderTimestamp }) => {
	const { selectors, theme, dispatch, entityThunks } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	const user = useSelector((state) => selectors.selectUserById(state, activity?.user))

	let icon = null
	switch (activity.type) {
		case 'Text':
			icon = faMessageSms
			break
		case 'Call':
			icon = faPhoneVolume
			break
		case 'In Person':
			icon = faPeopleArrows
			break
		case 'Email':
			icon = faEnvelope
			break
	}

	return (
		<CardHeader>
			<Row className="align-items-xxxs-center">
				{icon && (
					<Col xxxs="auto">
						<FontAwesomeIcon icon={icon} color={theme['$brand-primary']} fixedWidth />
					</Col>
				)}
				<Col xxxs>{`Follow Up${user ? ` - ${user.name}` : ''}`}</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default FollowUp
