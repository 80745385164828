import React, { PureComponent, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Badge, Container, Form, FormGroup, H1, H3, H6, Hr, InputGroup, InputGroupAddon } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Textarea, Label } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { Formik, Form as FormikForm } from 'formik'
import { Checkbox, DatePicker, Input, MultipleInputArray, Select } from '~/components/bootstrap/components/Form'
import * as Yup from 'yup'
import { callApi } from '~/services/api'

const CompensationProgramForm = ({ setIsOpen, currentValues }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [fullyEditable, setFullyEditable] = useState(true)
	useEffect(() => {
		if (currentValues?.id) {
			callApi({
				endpoint: `compensation-programs/${currentValues.id}/editable`
			}).then((response) => {
				setFullyEditable(response.data)
			})
		}
	})

	const onSubmit = (values) => {
		//remove empty values multipleInputArray adds
		const toSubmit = {
			...values,
			referrerCompensation: values.referrerCompensation.filter((rc) => Object.values(rc).some((v) => !(v === null || v === undefined || v === ''))),
			refereeCompensation: values.refereeCompensation.filter((rc) => Object.values(rc).some((v) => !(v === null || v === undefined || v === '')))
		}
		if (currentValues) {
			dispatch(entityThunks.updateCompensationProgram({ id: currentValues.id, data: toSubmit })).then((result) => {
				setIsOpen(false)
			})
		} else {
			dispatch(entityThunks.addCompensationProgram({ data: toSubmit })).then((result) => {
				setIsOpen(false)
			})
		}
	}

	const defaultValues = {
		name: '',
		startDate: new Date(),
		endDate: null,
		referrerCompensation: [],
		refereeCompensation: []
	}

	const initialValues = {
		...defaultValues,
		...currentValues
	}

	const validationSchema = Yup.object({
		name: Yup.string().required('Required'),
		startDate: Yup.date().required('Required'),
		endDate: Yup.date().nullable(),
		referrerCompensation: Yup.array()
			.of(
				Yup.object({
					type: Yup.string().required(),
					value: Yup.number().required(),
					method: Yup.string().required()
				})
			)
			//remove default values that multipleInputArray adds automatically
			.transform((compensations) => {
				const newCompensations = []
				compensations.forEach((c) => {
					if (Object.values(c).some((v) => !(v === null || v === undefined || v === ''))) {
						newCompensations.push(c)
					}
				})
				return newCompensations
			}),
		refereeCompensation: Yup.array()
			.of(
				Yup.object({
					type: Yup.string().required(),
					value: Yup.number().required(),
					method: Yup.string().required()
				})
			)
			//remove default values that multipleInputArray adds automatically
			.transform((compensations) => {
				const newCompensations = []
				compensations.forEach((c) => {
					if (Object.values(c).some((v) => !(v === null || v === undefined || v === ''))) {
						newCompensations.push(c)
					}
				})
				return newCompensations
			}),
		emails: Yup.object({
			enrollment: Yup.object({
				subject: Yup.string().required('Required'),
				template: Yup.string().required('Required')
			}),
			new: Yup.object({
				subject: Yup.string().required('Required'),
				template: Yup.string().required('Required')
			}),
			paid: Yup.object({
				subject: Yup.string().required('Required'),
				template: Yup.string().required('Required')
			})
		})
	})

	const typeOptions = [
		{ label: 'Fixed', value: 'Fixed' },
		{ label: 'Percentage', value: 'Percentage' }
	]
	const methodOptions = [
		{ label: 'Gift Card', value: 'Gift Card' },
		{ label: 'Cash', value: 'Cash' }
	]

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				return (
					<Form tag={FormikForm}>
						<FormGroup className="mb-xxxs-3">
							<Label>Name:</Label>
							<Input name="name" />
						</FormGroup>
						<Row>
							<Col xxxs md={6}>
								<FormGroup>
									<Label>Start Date:</Label>
									<DatePicker name="startDate" disabled={!fullyEditable} />
								</FormGroup>
							</Col>
							<Col xxxs md={6}>
								<FormGroup>
									<Label>End Date:</Label>
									<DatePicker name="endDate" disabled={!fullyEditable && initialValues.endDate !== null} />
								</FormGroup>
							</Col>
						</Row>
						<Hr />
						<H6>Referrer Compensation:</H6>
						<MultipleInputArray
							name="referrerCompensation"
							values={values.referrerCompensation}
							initialValues={{ type: '', value: '', method: '' }}
							disabled={!fullyEditable}
						>
							{({ i }) => (
								<Row>
									<Col xxxs md={4}>
										<FormGroup className="mb-xxxs-0">
											<Label>Type:</Label>
											<Select name={`referrerCompensation.${i}.type`} options={typeOptions} isDisabled={!fullyEditable} />
										</FormGroup>
									</Col>
									<Col xxxs md={4}>
										<FormGroup className="mb-xxxs-0">
											<Label>Value:</Label>
											<InputGroup>
												{values.referrerCompensation[i] && values.referrerCompensation[i].type === 'Fixed' && (
													<InputGroupAddon>$</InputGroupAddon>
												)}
												<Input name={`referrerCompensation.${i}.value`} disabled={!fullyEditable} />
												{values.referrerCompensation[i] && values.referrerCompensation[i].type === 'Percentage' && (
													<InputGroupAddon>%</InputGroupAddon>
												)}
											</InputGroup>
										</FormGroup>
									</Col>
									<Col xxxs md={4}>
										<FormGroup className="mb-xxxs-0">
											<Label>Method:</Label>
											<InputGroup>
												<Select name={`referrerCompensation.${i}.method`} options={methodOptions} isDisabled={!fullyEditable} />
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>
							)}
						</MultipleInputArray>
						<H6>Referee Compensation:</H6>
						<MultipleInputArray
							name="refereeCompensation"
							values={values.refereeCompensation}
							initialValues={{ type: '', value: '', method: '' }}
							disabled={!fullyEditable}
						>
							{({ i }) => {
								return (
									<Row>
										<Col xxxs md={4}>
											<FormGroup className="mb-xxxs-0">
												<Label>Type:</Label>
												<Select name={`refereeCompensation.${i}.type`} options={typeOptions} isDisabled={!fullyEditable} />
											</FormGroup>
										</Col>
										<Col xxxs md={4}>
											<FormGroup className="mb-xxxs-0">
												<Label>Value:</Label>
												<InputGroup>
													{values.refereeCompensation[i] && values.refereeCompensation[i].type === 'Fixed' && (
														<InputGroupAddon>$</InputGroupAddon>
													)}
													<Input name={`refereeCompensation.${i}.value`} disabled={!fullyEditable} />
													{values.refereeCompensation[i] && values.refereeCompensation[i].type === 'Percentage' && (
														<InputGroupAddon>%</InputGroupAddon>
													)}
												</InputGroup>
											</FormGroup>
										</Col>
										<Col xxxs md={4}>
											<FormGroup className="mb-xxxs-0">
												<Label>Method:</Label>
												<InputGroup>
													<Select name={`refereeCompensation.${i}.method`} options={methodOptions} isDisabled={!fullyEditable} />
												</InputGroup>
											</FormGroup>
										</Col>
									</Row>
								)
							}}
						</MultipleInputArray>
						<Hr />
						<H6>Enrollment Email Subject:</H6>
						<Input name="emails.enrollment.subject" />
						<H6 className="mt-xxxs-3">Enrollment Email Template:</H6>
						<Textarea name="emails.enrollment.template" style={{ minHeight: '10em' }} />
						<Hr />
						<H6>New Lead Email Subject:</H6>
						<Input name="emails.new.subject" />
						<H6 className="mt-xxxs-3">New Lead Email Template:</H6>
						<Textarea name="emails.new.template" style={{ minHeight: '10em' }} />
						<Hr />
						<H6>Paid Lead Email Subject:</H6>
						<Input name="emails.paid.subject" />
						<H6 className="mt-xxxs-3">Paid Lead Email Template:</H6>
						<Textarea name="emails.paid.template" style={{ minHeight: '10em' }} />
						<Hr />
						<Row className="justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit">Save</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default CompensationProgramForm
