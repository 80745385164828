import { Tbody, Td, Th, Thead, Tr, Form, Small, Row } from '@bootstrap-styled/v4'
import React, { useEffect, useState } from 'react'
import { Badge, Col, Table } from '~/components/bootstrap'
import { Checkbox, StyledCheckbox } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm } from 'formik'
import { callApi } from '~/services/api'
import { ConfirmationCheckbox } from '~/components/bootstrap/components/Button'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { FormatCurrency } from '~/components/common/Text'

const SalesCommissionsListingTable = ({ selectedSalesperson, startDate, endDate, refreshRows, setRefreshRows }) => {
	const { dispatch, entityThunks, userHasRole } = useLumenox()
	const [rows, setRows] = useState([])

	const accounting = userHasRole('accounting')

	useEffect(() => {
		if (refreshRows) {
			callApi({
				endpoint: 'compensation-programs/sales-stats',
				params: {
					salesperson: selectedSalesperson?.value,
					startDate: startDate,
					endDate: endDate
				}
			}).then((response) => {
				setRows(response.data)
			})
			setRefreshRows(false)
		}
	}, [selectedSalesperson, startDate, endDate, refreshRows])

	const toggleApproved = async (setIsOpen, quoteId, currentlyApproved) => {
		if (currentlyApproved) {
			await dispatch(entityThunks.unapproveCommission(quoteId))
		} else {
			await dispatch(entityThunks.approveCommission(quoteId))
		}
		setRefreshRows(true)
		setIsOpen(false)
	}

	const togglePaid = async (setIsOpen, quoteId, currentlyPaid) => {
		if (currentlyPaid) {
			await dispatch(entityThunks.markCommissionNotPaid(quoteId))
		} else {
			await dispatch(entityThunks.markCommissionPaid(quoteId))
		}
		setRefreshRows(true)
		setIsOpen(false)
	}

	return (
		<React.Fragment>
			<Table striped responsive className="mb-xxxs-0">
				<Thead>
					<Tr>
						<Th>Client</Th>
						<Th>Salesperson</Th>
						<Th>Date</Th>
						<Th>Total Length</Th>
						<Th>Total Price</Th>
						<Th>Commission</Th>
						<Th>Approved</Th>
						<Th>Paid</Th>
					</Tr>
				</Thead>
				<Tbody>
					{rows.map((row) => {
						return (
							<Tr key={row.quoteId}>
								<Td>{row.name}</Td>
								<Td>{row.salesperson}</Td>
								{row.date && (
									<Td>
										<Badge>{format(new Date(row.date), 'MMM d, yyyy')}</Badge>
									</Td>
								)}
								<Td>{row.length}ft</Td>
								<Td>
									<FormatCurrency amount={row.price} decimalPlaces={2} />
								</Td>
								<Td>
									<FormatCurrency amount={row.commission.cash} decimalPlaces={2} />
									{row.takenFromCommission && '*'}
									{row.commission.cash && row.commission.giftCard ? <br /> : null}
									{row.commission.giftCard ? (
										<React.Fragment>
											<FormatCurrency amount={row.commission.giftCard} decimalPlaces={2} />
											{' (Gift Card)'}
										</React.Fragment>
									) : null}
								</Td>
								<Td>
									<ConfirmationCheckbox
										value={row.approved}
										handleConfirmation={(setIsOpen) => toggleApproved(setIsOpen, row.quoteId, row.approved)}
										titleIfTrue="Unapprove Commission"
										titleIfFalse="Approve Commission"
										descriptionIfTrue="Are you sure you want to unapprove this commission?"
										descriptionIfFalse="Are you sure you want to approve this commission?"
										disabled={!accounting}
									/>
								</Td>
								<Td>
									{row.approved && (
										<ConfirmationCheckbox
											value={row.paid}
											handleConfirmation={(setIsOpen) => togglePaid(setIsOpen, row.quoteId, row.paid)}
											titleIfTrue="Commission Not Paid"
											titleIfFalse="Commission Paid"
											descriptionIfTrue="Are you sure you want to mark this commission as not paid?"
											descriptionIfFalse="Are you sure you want to mark this commission as paid?"
											disabled={!(accounting && row.approved)}
										/>
									)}
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
			<Row className="justify-content-xxxs-end">
				<Col xxxs="auto">
					<Small>*discount taken from commission</Small>
					{/*May not keep this reminder*/}
				</Col>
			</Row>
		</React.Fragment>
	)
}

export default SalesCommissionsListingTable
