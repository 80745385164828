import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, CenteredModal } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Hr, ModalBody, ModalHeader, P, Form } from '@bootstrap-styled/v4'
import { Input, Label, Textarea, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm } from 'formik'

const AssignStageButton = ({ opportunity, currentStage, nextStage, roleOptionsSlugs, assignMessage, assignTitle, defaultAssignedToId }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const [assignModalOpen, setAssignModalOpen] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const allRoles = useSelector((state) => selectors.selectAllUserRoles(state))
	const roleOptionsIds = allRoles.filter((role) => (roleOptionsSlugs ? roleOptionsSlugs.includes(role.slug) : true)).map((role) => role.id)
	const allUsers = useSelector((state) => selectors.selectAllUsers(state))
	const assignedToOptions = allUsers
		.filter((user) => {
			for (const role of user.roles) {
				if (roleOptionsIds.includes(role)) return true
			}
			return false
		})
		.map((user) => ({ label: user.name, value: user.id }))
	const currentAssignedTo = useSelector((state) => selectors.selectUserById(state, opportunity.assignedTo))
	const [selectedAssignedTo, setSelectedAssignedTo] = useState(null)
	const [message, setMessage] = useState('')

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	useEffect(() => {
		//need it set up this way since the options won't be loaded in immediately
		if (defaultAssignedToId && selectedAssignedTo === null) {
			setSelectedAssignedTo(assignedToOptions.find((option) => option.value === defaultAssignedToId) ?? null)
		}
	}, [assignedToOptions])

	useEffect(() => {
		if (!currentAssignedTo || !currentAssignedTo.roles || defaultAssignedToId) return
		if (currentAssignedTo.roles.some((role) => roleOptionsIds.includes(role))) {
			setSelectedAssignedTo({ label: currentAssignedTo.name, value: currentAssignedTo.id })
		} else {
			setSelectedAssignedTo(null)
		}
	}, [currentAssignedTo])

	const updateStageAndAssignedTo = () => {
		setIsSubmitting(true)
		//update assignedTo even if it's already assigned to that person so that message is sent
		dispatch(entityThunks.updateOpportunityAssignedTo({ id: opportunity.id, userId: selectedAssignedTo.value, assignMessage: message })).then(() => {
			dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
				setIsSubmitting(false)
			})
		})
	}

	const onChangeAssignedTo = (option, action) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				setSelectedAssignedTo(option)
				break
		}
	}

	if (!dependenciesMet) return null

	return (
		<React.Fragment>
			<Button color={nextStage.color} icon={solid[nextStage.icon]} onClick={() => setAssignModalOpen(true)}>
				{nextStage.name}
			</Button>
			<CenteredModal isOpen={assignModalOpen} size="sm">
				<ModalHeader toggle={() => setAssignModalOpen(false)}>{assignTitle ? assignTitle : 'Assign Lead'}</ModalHeader>
				<ModalBody>
					<P>{assignMessage ? assignMessage : 'Assign the lead to a new user'}</P>
					<UncontrolledSelect value={selectedAssignedTo} options={assignedToOptions} onChange={onChangeAssignedTo} />
					<P className="mt-xxxs-3">Message to who you are assigning to:</P>
					<ControlledTextArea value={message} onChange={(e) => setMessage(e.target.value)} />
					<Hr />
					<Button onClick={updateStageAndAssignedTo} disabled={selectedAssignedTo === null} color="success" submitting={isSubmitting} block>
						Continue
					</Button>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

const ControlledTextArea = ({ value, onChange }) => {
	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={{ text: '' }} validationSchema={null} onSubmit={null}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<Textarea name="text" value={value} onChange={onChange} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default AssignStageButton
