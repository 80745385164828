import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchRates = createAsyncThunk('rates/fetch', () => {
	return callApi({
		endpoint: `product/rates` //I think, could be wrong should probably double check
	}).then((response) => {
		return response.data
	})
})
