export const colorSet = [
	'#EF4444', // red
	'#13e8a1', // bright green (not default green)
	'#2563EB', // blue
	'#FFFF00', // yellow
	'#7C3AED', // purple
	'#F472B6', // pink
	'#000000', // black
	'#FFFFFF' //white
]
