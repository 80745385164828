import React from 'react'
import { useSelector } from 'react-redux'
import { CardHeader, Row } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEnvelopeCircleCheck, faEnvelopeOpenText } from '@fortawesome/pro-duotone-svg-icons'

const Email = ({ id, opportunityId, renderTimestamp }) => {
	const { selectors, theme } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	//console.log('email activity:', activity)

	const lastEvent = activity.events.length > 0 ? activity.events[activity.events.length - 1] : null

	let icon = faEnvelope

	if (lastEvent) {
		switch (lastEvent.type) {
			case 'delivered':
				icon = faEnvelopeCircleCheck
				break
			case 'opened':
				icon = faEnvelopeOpenText
				break
		}
	}

	return (
		<CardHeader>
			<Row className="align-items-xxxs-center">
				<Col xxxs="auto">
					<FontAwesomeIcon icon={icon} color={theme['$brand-success']} fixedWidth />
				</Col>
				<Col xxxs>{activity.template ?? activity.subject}</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default Email
