import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock } from '@bootstrap-styled/v4'

import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ActivityItem from './ActivityItem'
import { UncontrolledSelect } from '~/components/bootstrap/components/Form'

const ActivityStream = styled(({ id, className, activityFilters }) => {
	const { selectors, dispatch, entityThunks, userNotAdminHasRole } = useLumenox()
	/* const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id)) */

	const activities = useSelector((state) =>
		selectors.selectFilteredOpportunityActivitiesSorted(state, {
			opportunity: id,
			direction: 'desc',
			filters: activityFilters ? activityFilters.map((af) => af.value) : ['All']
		})
	)
	console.log('Debugging', 're-rendering activity stream')
	//const installer = userNotAdminHasRole('installer')

	// used to be !activities || installer
	if (!activities) {
		return null
	}

	return (
		<Card className={className}>
			<CardBlock>
				{activities.map((activityId) => (
					<ActivityItem key={activityId} id={activityId} opportunityId={id} />
				))}
			</CardBlock>
		</Card>
	)
})`
	> div > div:not(:last-child) {
		margin-bottom: 2rem;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			width: 1px;
			height: 2.5rem;
			background: ${({ theme }) => theme['$brand-primary']};
			left: 50%;
			transform: translateX(-50%);
		}
	}
`

export const ActivityFilterer = ({ activityFilters, setActivityFilters }) => {
	const options = [
		{ label: 'All', value: 'All' },
		{ label: 'Note', value: 'Note' },
		{ label: 'Text', value: 'SMS' },
		{ label: 'Call', value: 'Call' },
		{ label: 'Follow Up', value: 'FollowUp' },
		{ label: 'Stage', value: 'Stage' },
		{ label: 'Assigned To', value: 'Salesperson' },
		{ label: 'Installer Assigned', value: 'Installer' },
		{ label: 'CSR Assigned', value: 'CSR' },
		{ label: 'Salesperson Assigned', value: 'AssignedSalesperson' },
		{ label: 'Install Details', value: 'InstallDetailsActivity' },
		{ label: 'Site Visit', value: 'SiteVisitActivity' },
		{ label: 'Email', value: 'Email' },
		{ label: 'Install Date Set', value: 'InstallDate' }
	]

	const handleChange = (values, { action, option }) => {
		switch (action) {
			case 'select-option':
				if (option.value === 'All') {
					setActivityFilters([{ label: 'All', value: 'All' }])
				} else {
					setActivityFilters([...activityFilters.filter((af) => af.value !== 'All'), option])
				}
				break
			case 'remove-value':
				setActivityFilters(values ?? [{ label: 'All', value: 'All' }]) //doesn't include removed value
				break
			case 'clear':
				setActivityFilters([{ label: 'All', value: 'All' }])
				break
			default:
			//console.log('other action')
		}
	}

	return (
		<UncontrolledSelect
			value={activityFilters}
			onChange={handleChange}
			options={options}
			isMulti
			isClearable={!(activityFilters.length === 1 && activityFilters[0].value === 'All')}
		/>
	)
}

export default ActivityStream
