import { createCachedSelector } from 're-reselect'
import { primitiveSelectors as userSelectors } from '~/state/entities/user'
import { selectors as userRoleSelectors } from '~/state/entities/userRole'
import { primitiveSelectors as compensationProgramSelectors } from '~/state/entities/compensationProgram'
import { primitiveSelectors as compensationProgramEnrollmentSelectors } from '~/state/entities/compensationProgramEnrollment'
import { createCachedParamSelector, createCachedStateParamSelector } from '../../adapter'

export const selectUsersByRole = createCachedParamSelector(
	(state) => userSelectors.selectAllUsers(state),
	(state, slug) => userRoleSelectors.selectUserRoleBySlug(state, slug),
	(users, userRole) => {
		if (!userRole) {
			return []
		}
		return users.filter((user) => user.roles.includes(userRole.id))
	}
)

export const selectUserByOpenPhoneId = createCachedParamSelector(
	(state) => userSelectors.selectAllUsers(state),
	(_, openPhoneId) => openPhoneId,
	(users, openPhoneId) => {
		if (!openPhoneId) return null
		return users.find((user) => user.openPhoneId === openPhoneId)
	}
)

export const selectUserByIdCompensationProgramsPopulated = createCachedParamSelector(
	[
		(state, id) => userSelectors.selectUserById(state, id),
		(state, _) => compensationProgramEnrollmentSelectors.selectCompensationProgramEnrollmentEntities(state),
		(state, _) => compensationProgramSelectors.selectCompensationProgramEntities(state)
	],
	(user, compensationProgramEnrollmentEntities, compensationProgramEntities) => {
		if (!user.compensationPrograms) return null //should never happen
		return {
			...user,
			compensationPrograms: user.compensationPrograms.reduce((arr, cp) => {
				const compensationProgramEnrollment = compensationProgramEnrollmentEntities[cp]
				if (!compensationProgramEnrollment || !compensationProgramEnrollment.program) return arr //should never happen
				arr.push({
					...compensationProgramEnrollment,
					program: compensationProgramEntities[compensationProgramEnrollment.program]
				})
				return arr
			}, [])
		}
	}
)

export const selectUserByHoverId = createCachedStateParamSelector(
	['user'],
	[(state, _) => userSelectors.selectAllUsers(state), (_, hoverId) => hoverId],
	(users, hoverId) => {
		if (!hoverId) return null
		console.log('all users:', users)
		return users.find((user) => user.hoverId === hoverId)
	}
)

export const selectAllHoverUsers = createCachedStateParamSelector(['user'], [(state, _) => userSelectors.selectAllUsers(state)], (users) =>
	users.filter((user) => user.hoverId !== null && user.hoverId !== undefined)
)
