import React from 'react'
import { render } from 'react-dom'
import regeneratorRuntime from 'regenerator-runtime'
import App from '~/App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import * as Yup from 'yup'
import '~/utils/yup'
import './styles.global.scss'

import firebase from 'firebase/app'

firebase.initializeApp({
	apiKey: process.env.FIREBASE_API_KEY,
	projectId: process.env.FIREBASE_PROJECT_ID,
	authDomain: process.env.FIREBASE_AUTH_DOMAIN
})

Sentry.init({
	dsn: 'https://dab345f66b7943ac8d908353dc741c65@o4504578948268032.ingest.sentry.io/4504578961506304',
	integrations: [new BrowserTracing()],
	environment: process.env.SENTRY_ENVIRONMENT,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
})

const root = document.getElementById('root')

render(<App />, root)

if (module.hot) {
	module.hot.accept('./App.js', () => {
		// eslint-disable-next-line global-require
		const NextApp = require('./App.js').default
		render(<NextApp />, root)
	})
}
