import React, { Fragment, PureComponent, useState } from 'react'
import withWidth, { isWidthUp, isWidthDown } from 'bootstrap-styled/lib/withWidth'
import { connect } from 'react-redux'
import { H3, Img } from '@bootstrap-styled/v4'
import { v4 as uuid } from 'uuid'

import { BackLink } from '~/components/common/Text'
/* import LeadForm from '../components/LeadForm' */

import { Redirect, useRouteMatch } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'
import { useFirebase } from '~/services/firebase'
import QuoteForm from '../QuoteForm'

const Add = ({ dirty, setDirty, formRef }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const [redirect, setRedirect] = useState(null)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})

	const handleSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
		setSubmitting(true)
		console.log('submitting')
		//console.log(values)

		const formData = new FormData()

		values.runs.forEach((run, r) => {
			run.segments.forEach((segment, s) => {
				if (segment.images && segment.images.length > 0) {
					segment.images.forEach((image, i) => {
						//formData.append(`runs[${r}][segments][${s}][]`, image, `runs[${r}][segments][${s}][${i}]`)
						formData.append(`runs`, image.url, `${r}.${s}.${i}`)
					})
				}
			})
		})

		if (values.lineItems) {
			values.lineItems.forEach((lineItem, l) => {
				if (lineItem.images && lineItem.images.length > 0) {
					lineItem.images.forEach((image, i) => {
						formData.append(`lineItems`, image.url, `${l}.${i}`)
					})
				}
			})
		}

		formData.append('data', JSON.stringify(values))
		//console.log(formData.getAll('runs[0][segments][0][]'))
		//console.log(formData.getAll('data'))

		return dispatch(entityThunks.addQuote({ values: formData, opportunityId: params.id })).then((response) => {
			console.log('success', response)
			setSubmitting(false)

			if (response.meta.requestStatus === 'fulfilled' && response.payload.result) {
				setRedirect(response.payload.result)
				return response
			}
		})
	}

	const initialValues = {
		name: '',
		unitsPerFoot: 1,
		taxRate: { GST: 5, HST: 0 },
		pixelsRate: 22,
		discount: 0,
		approved: false,
		runs: [
			{
				type: '',
				start: '',
				end: '',
				segments: [],
				lineItem: ''
			}
		],
		lineItems: [
			{
				name: 'Wifi Extender',
				description: 'Wifi Extender (if needed) to extend Home Wifi Network to Control Box',
				quantity: 1,
				rate: 50,
				discount: 0,
				images: [],
				takenFromCommission: null,
				type: 'Other',
				_id: uuid()
			}
		],
		terms: [],
		additionalNotes: ''
		//quotation number set in back end when saved
	}

	return (
		<Fragment>
			{redirect && <Redirect to={`/leads/${params.id}`} />}
			<QuoteForm onSubmit={handleSubmit} initialValues={initialValues} dirty={dirty} setDirty={setDirty} formRef={formRef} />
		</Fragment>
	)
}

export default Add
