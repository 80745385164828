import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, Form, H1, H3, Hr, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Textarea, Label, Table } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faCheck, faX, faEdit } from '@fortawesome/pro-solid-svg-icons'
import TermForm from './TermForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const TermTable = () => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const terms = useSelector((state) => selectors.selectTermsWithDefaultSorted(state))

	useEffect(() => {
		dispatch(entityThunks.fetchTerms({ includeArchived: true })).then((response) => {
			// nothing
		})
	}, [])

	const onDragEnd = ({ draggableId, source, destination }) => {
		if (source.index === destination.index) return
		dispatch(entityThunks.reorderTerms({ movedTermId: draggableId, oldIndex: source.index, newIndex: destination.index })).then((response) => {
			// nothing
		})
	}

	return (
		<Table>
			<Thead>
				<Tr>
					<Th width="75%">Term</Th>
					<Th>Included By Default</Th>
					<Th>Archived</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="terms">
					{(provided) => (
						<tbody {...provided.droppableProps} ref={provided.innerRef}>
							{terms.map((term, i) => (
								<Draggable draggableId={term.id} index={i} key={term.id} isDragDisabled={!term.includeByDefault}>
									{(draggableProvided, snapshot) => {
										if (snapshot.isDragging) {
											//idk why but without this it offsets while dragging
											draggableProvided.draggableProps.style.left = undefined
											draggableProvided.draggableProps.style.right = undefined
											draggableProvided.draggableProps.style.top = undefined
											draggableProvided.draggableProps.style.bottom = undefined
										}
										return (
											<tr {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps} ref={draggableProvided.innerRef}>
												<Td>{term.description}</Td>
												<Td>{term.includeByDefault ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faX} />}</Td>
												<Td>{term.archived ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faX} />}</Td>
												<Td>
													<ActionButton icon={faEdit} title="Update Term">
														{(setIsOpen) => <TermForm setIsOpen={setIsOpen} currentValues={term} />}
													</ActionButton>
												</Td>
											</tr>
										)
									}}
								</Draggable>
							))}
						</tbody>
					)}
				</Droppable>
			</DragDropContext>
		</Table>
	)
}

export default TermTable
