import { createCachedSelector } from 're-reselect'
import { primitiveSelectors as compensationProgramEnrollmentSelectors } from '../index'
import { primitiveSelectors as compensationProgramSelectors } from '~/state/entities/compensationProgram'
import { primitiveSelectors as opportunitySelectors } from '~/state/entities/opportunity'
import { primitiveSelectors as contactSelectors } from '~/state/entities/contact'
import { primitiveSelectors as userSelectors } from '~/state/entities/user'
import { createCachedParamSelector } from '../../adapter'

export const selectCompensationProgramEnrollmentsByIds = createCachedParamSelector(
	[(state) => compensationProgramEnrollmentSelectors.selectCompensationProgramEnrollmentEntities(state), (_, ids) => ids],
	(entities, ids) => {
		return ids.reduce((arr, id) => {
			if (entities[id]) arr.push(entities[id])
			return arr
		}, [])
	}
)

export const selectCompensationProgramEnrollmentsByIdsProgramsPopulated = createCachedParamSelector(
	[
		(state, ids) => selectCompensationProgramEnrollmentsByIds(state, ids),
		(state, _) => compensationProgramSelectors.selectCompensationProgramEntities(state)
	],
	(enrollments, programEntities) => {
		return enrollments.reduce((arr, enrollment) => {
			if (programEntities[enrollment.program])
				arr.push({
					...enrollment,
					program: programEntities[enrollment.program]
				})
			return arr
		}, [])
	}
)

export const selectOpportunityReferralCompensationProgramEnrollment = createCachedParamSelector(
	[
		(state, opportunityId) => opportunitySelectors.selectOpportunityById(state, opportunityId),
		(state, _) => compensationProgramEnrollmentSelectors.selectCompensationProgramEnrollmentEntities(state)
	],
	(opportunity, enrollmentEntities) => {
		if (!opportunity.referral) return null
		return enrollmentEntities[opportunity.referral]
	}
)

export const selectOpportunityReferrer = createCachedParamSelector(
	[
		(state, opportunityId) => selectOpportunityReferralCompensationProgramEnrollment(state, opportunityId),
		(state, _) => contactSelectors.selectAllContacts(state),
		(state, _) => userSelectors.selectAllUsers(state)
	],
	(enrollment, contacts, users) => {
		if (!enrollment) return null
		const contact = contacts.find((contact) => contact.compensationPrograms && contact.compensationPrograms.includes(enrollment.id))
		if (contact)
			return {
				type: 'contact',
				referrer: contact
			}
		const user = users.find((user) => user.compensationPrograms && user.compensationPrograms.includes(enrollment.id))
		if (user)
			return {
				type: 'user',
				referrer: user
			}
		return null
	}
)
