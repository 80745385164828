import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchDealers = createAsyncThunk('dealers/fetch', (id) => {
	return callApi({
		endpoint: `dealers`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})
