import { Card, CardBlock, CardHeader, Collapse, H3, H4, H5, Row } from '@bootstrap-styled/v4'
import { CardFooter } from '@bootstrap-styled/v4/lib/Cards'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {} from 'bootstrap-styled'
import React, { useState } from 'react'
import { Col } from '../bootstrap'

export const CollapsableCard = ({ openByDefault, customHeader, footer, collapseFooter, title, children, ...rest }) => {
	const [isOpen, setIsOpen] = useState(openByDefault)

	const ToggleChevron = () => (
		<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} fixedWidth onClick={() => setIsOpen(!isOpen)} className="pointer" size="lg" />
	)

	return (
		<Card {...rest}>
			<CardHeader>
				{customHeader ? (
					customHeader(ToggleChevron)
				) : (
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H5 className="mb-xxxs-0">{title}</H5>
						</Col>
						<Col xxxs="auto">
							<ToggleChevron />
						</Col>
					</Row>
				)}
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<CardBlock>{children}</CardBlock>
				{footer && collapseFooter && <CardFooter>{footer}</CardFooter>}
			</Collapse>
			{footer && !collapseFooter && <CardFooter>{footer}</CardFooter>}
		</Card>
	)
}
