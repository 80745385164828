import React, { useEffect, useState } from 'react'
import { Card, CardBlock, CardHeader, Form, FormGroup, H5, Hr, P, Row, Strong, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { ActionButton, Badge, Button, CenteredModal, Col, Table } from '~/components/bootstrap'
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { AsyncSelect, Checkbox, Input, Label, Select, UncontrolledSelect } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import * as Yup from 'yup'

const QuoteRequestCard = ({ id }) => {
	const { selectors, dispatch, entityThunks, userIncludesRole } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))

	if (!opportunity?.requestDetails) return null

	return (
		<Card className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs>
						<H5>Quote Request</H5>
					</Col>
					<Col xxxs="auto">
						<ActionButton icon={faEdit} title="Edit Quote Request" color="info">
							{(setIsOpen) => <QuoteRequestForm id={id} setIsOpen={setIsOpen} />}
						</ActionButton>
					</Col>
				</Row>
			</CardHeader>
			<Table className="mb-xxxs-0">
				<Tbody>
					<Tr>
						<Th>Elevations:</Th>
						<Td>
							{opportunity.requestDetails.locations.map((location, l) => (
								<Badge color="primary" key={l} className={l === 0 ? '' : 'ml-xxxs-1'}>
									{startCase(location)}
								</Badge>
							))}
						</Td>
					</Tr>
					{opportunity.requestDetails.controlBox && (
						<Tr>
							<Th>Control Box:</Th>
							<Td>
								<Badge color="primary">{startCase(opportunity.requestDetails.controlBox)}</Badge>
							</Td>
						</Tr>
					)}
					{opportunity.requestDetails.consent?.backyard && (
						<Tr>
							<Th>Property Access:</Th>
							<Td>
								<Badge color="info">{opportunity.requestDetails.consent.backyard?.value ? 'Yes' : 'No'}</Badge>
								{opportunity.requestDetails.consent.backyard?.message && <P>{opportunity.requestDetails.consent.backyard.message}</P>}
							</Td>
						</Tr>
					)}
					{opportunity.requestDetails.consent?.marketing && (
						<Tr>
							<Th>Marketing:</Th>
							<Td>
								<Badge color="info" className="mr-xxxs-2">
									{opportunity.requestDetails.consent.marketing ? 'Yes' : 'No'}
								</Badge>
							</Td>
						</Tr>
					)}
					{opportunity.requestDetails.message && (
						<Tr>
							<Th>Message:</Th>
							<Td>{opportunity.requestDetails.message}</Td>
						</Tr>
					)}
				</Tbody>
			</Table>
		</Card>
	)
}

/*
requestDetails: {
			locations: [{ type: String }],
			controlBox: String,
			message: String,
			consent: {
				marketing: Boolean,
				backyard: {
					value: Boolean,
					message: String
				}
			}
		}
*/

const QuoteRequestForm = ({ id, setIsOpen }) => {
	const { selectors, dispatch, entityThunks, userIncludesRole } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))

	const validationSchema = Yup.object({
		locations: Yup.array().of(Yup.string()),
		controlBox: Yup.string()
	})

	const defaultValues = {
		locations: [],
		controlBox: ''
	}

	const initialValues = {
		...defaultValues,
		...opportunity?.requestDetails
	}

	const handleSubmit = (values, { setSubmitting }) => {
		if (values.controlBox === '') values.controlBox = null //if it stays as '' we get a whitespace warning
		// console.log('submitting:', values)
		setSubmitting(true)
		dispatch(entityThunks.updateOpportunityRequestDetails({ id, requestDetails: values })).then((response) => {
			// console.log('response:', response)
			setSubmitting(false)
			setIsOpen(false)
		})
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log('values', values)
				return (
					<Form tag={FormikForm}>
						<Row>
							<Col xxxs>
								<FormGroup>
									<Label>Preferred Box Location</Label>
									<Select
										name="controlBox"
										options={[
											{ label: 'Exterior', value: 'exterior' },
											{ label: 'Garage', value: 'garage' }
										]}
										isClearable
									/>
								</FormGroup>
							</Col>
							<Col xxxs>
								<FormGroup>
									<Label>Elevations</Label>
									<Select
										name="locations"
										options={[
											{ label: 'Front', value: 'front' },
											{ label: 'Left', value: 'left' },
											{ label: 'Right', value: 'right' },
											{ label: 'Back', value: 'back' }
										]}
										isMulti
									/>
								</FormGroup>
							</Col>
						</Row>
						<Hr />
						<Row className="justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button color="success" type="submit" submitting={isSubmitting}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default QuoteRequestCard
