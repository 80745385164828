import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Container, Row, H1, H3, Hr, Nav, NavItem, NavLink } from '@bootstrap-styled/v4'
import { Col, Button, ActionButton } from '~/components/bootstrap'
import { NavLink as RouterNavLink, Link, useRouteMatch, Switch, Route } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'

import UserListingTable from './components/UserListingTable'
import InvitesListingTable from './components/InvitesListingTable'
import InviteUserForm from '../components/InviteUserForm'

const Listing = () => {
	const { entityThunks, dispatch } = useLumenox()
	const { url } = useRouteMatch()

	const handleUserInviteSubmit = (values, { setSubmitting, setStatus, resetForm }, onSuccess) => {
		dispatch(entityThunks.addUserInvite(values)).then((response) => {
			console.log('success', response)

			// todo error handling
			setSubmitting(false)
			resetForm()
			onSuccess()
		})
	}

	return (
		<React.Fragment>
			<Row className="mb-xxxs-4 align-items-xxxs-center">
				<Col xxxs={12} md>
					<H3>Users</H3>
				</Col>
				<Col xxxs={12} md="auto">
					<ActionButton color="success" button="Invite User" title="Invite New User">
						{(closeModal) => <InviteUserForm onSubmit={handleUserInviteSubmit} onSuccess={closeModal} />}
					</ActionButton>
				</Col>
			</Row>
			<Nav pills className="mb-xxxs-3">
				<NavItem>
					<NavLink tag={RouterNavLink} to={`${url}`} exact color="primary">
						Users
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={RouterNavLink} to={`${url}/invites`}>
						Invited Users
					</NavLink>
				</NavItem>
			</Nav>
			<Switch>
				<Route exact path={`${url}/`} component={UserListingTable} />
				<Route exact path={`${url}/invites`} component={InvitesListingTable} />
			</Switch>
		</React.Fragment>
	)
}

export default Listing
