import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchOpportunityTags = createAsyncThunk('opportunityTags/fetch', () => {
	return callApi({
		endpoint: `opportunities/tags`
	}).then((response) => {
		return response.data
	})
})

/* export const addOpportunity = createAsyncThunk('opportunitys/add', ({ values }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunitys`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})
 */
