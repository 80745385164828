import { hot } from 'react-hot-loader/root'
import React from 'react'
import styled from 'styled-components'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import BootstrapProvider from '@bootstrap-styled/provider'
import history, { onRedirectCallback } from '~/utils/history'
import { LumenoxProvider } from './services/lumenox'
import { FirebaseProvider } from './services/firebase'
//import Analytics from '~/utils/analytics'

import GlobalStyle from '~/globalStyle'
import theme from '~/theme'

import store from '~/state/store'

//import Favicon from '~/components/Favicon'
import Routes from '~/components/Routes'
import Navbar from '~/components/common/Navbar'
import { Helmet } from 'react-helmet'
//import Footer from '~/components/Footer/'

const App = () => {
	return (
		<Provider store={store}>
			<Helmet>
				<title>Lumenox CRM</title>
			</Helmet>
			<BootstrapProvider reset={false} theme={theme}>
				<GlobalStyle />
				<Wrapper>
					<BrowserRouter history={history}>
						<FirebaseProvider>
							<LumenoxProvider>
								<Navbar />
								<Routes />
							</LumenoxProvider>
						</FirebaseProvider>
					</BrowserRouter>
				</Wrapper>
			</BootstrapProvider>
		</Provider>
	)
}

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`

export default hot(App)
