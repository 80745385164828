import React from 'react'
import AssignStageButton from './components/AssignStageButton'

const HoverCompletedStageButton = (props) => {
	return (
		<AssignStageButton
			{...props}
			roleOptionsSlugs={['quoter']}
			assignMessage="Assign the lead to the quoter who will make the quote:"
			assignTitle="Assign to Quoter"
		/>
	)
}

export default HoverCompletedStageButton
