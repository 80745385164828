import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader, H5, Thead, Tr, Th, Td, Tbody, ButtonGroup } from '@bootstrap-styled/v4'
import { format } from 'date-fns'
import { BackLink } from '~/components/common/Text'
import { Button, Col, Table, ConfirmationButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faSignal1, faSignal2, faSignal3, faSignal4, faSparkles, faAward } from '@fortawesome/pro-duotone-svg-icons'
import { faPlus, faSearch, faTrash } from '@fortawesome/pro-regular-svg-icons'
import AddQuoteForm from '../AddQuoteForm'
import { Link, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '~/components/common/PrivateRoute'
import WiringDiagramRoutes from '../../../WiringDiagrams'

const WiringDiagram = ({ opportunityId }) => {
	const { selectors, dispatch } = useLumenox()
	const { url } = useRouteMatch()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))

	if (!opportunity) {
		return null
	}

	return (
		<Fragment>
			<Card className="mb-xxxs-3">
				<CardHeader>
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H5 className="mb-xxxs-0">Wiring Diagram</H5>
						</Col>
					</Row>
				</CardHeader>
				<Table>
					<Thead>
						<Tr>
							<Th width="40%">Location</Th>
							<Th width="30%">Date Created</Th>
							<Th width="20%">Total</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>{opportunity.quotes && opportunity.quotes.map((quoteId) => <QuoteRow key={quoteId} id={quoteId} />)}</Tbody>
				</Table>
			</Card>
			<PrivateRoute path={`${url}/wiring-diagram`} component={WiringDiagramRoutes} />
		</Fragment>
	)
}

const QuoteRow = ({ id }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const { url } = useRouteMatch()
	const { params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})

	const quote = useSelector((state) => selectors.selectQuoteById(state, id))

	//const quoteTotal = useSelector((state) => selectors.selectQuoteTotal(state, id))

	const removeQuote = () => {
		dispatch(entityThunks.removeQuote({ opportunityId: params.id, quoteId: id })).then((response) => {
			console.log('success', response)
		})
	}

	if (!quote) {
		return null
	}

	return (
		<Tr>
			<Td>{quote.name}</Td>
			<Td>{format(new Date(quote.createdAt), 'MMM-d-y')}</Td>
			<Td></Td>
			<Td>
				<Button size="sm" tag={Link} color="info" to={`${url}/wiring-diagram/${id}`} icon={faSearch} />
			</Td>
		</Tr>
	)
}

export default WiringDiagram
