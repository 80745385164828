import React from 'react'
import { useSelector } from 'react-redux'
import { CardHeader, Row } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneArrowUpRight, faPhoneArrowDownLeft, faPhoneMissed } from '@fortawesome/pro-duotone-svg-icons'

const Call = ({ id, renderTimestamp }) => {
	const { selectors, theme } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	//console.log('call activity:', activity)
	const rep = useSelector((state) => selectors.selectUserByOpenPhoneId(state, activity.createdBy ?? activity.userId))
	const icon = activity.missed ? faPhoneMissed : activity.direction === 'incoming' ? faPhoneArrowDownLeft : faPhoneArrowUpRight
	const color = activity.missed ? '$brand-danger' : '$brand-success'

	return (
		<CardHeader>
			<Row className="align-items-xxxs-center">
				<Col xxxs="auto">
					<FontAwesomeIcon icon={icon} color={theme[color]} fixedWidth />
				</Col>
				<Col xxxs>{rep ? rep.name : 'Unknown'}</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default Call
