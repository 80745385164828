import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import {
	H1,
	Hr,
	H3,
	Card,
	CardBlock,
	ButtonToolbar,
	ButtonGroup,
	CardHeader,
	H4,
	H6,
	Badge,
	P,
	Tbody,
	Tr,
	Th,
	Td,
	Form,
	Thead,
	Row,
	Small
} from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { Col, Table } from '~/components/bootstrap'

const InstallDetailsView = ({ id }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()

	useEffect(() => {
		if (id) {
			dispatch(entityThunks.fetchInstallDetailsById(id))
		}
	}, [])

	const installDetails = useSelector((state) => selectors.selectInstallDetailsPopulated(state, id))
	if (!installDetails) return null

	return (
		<Table className="mb-xxxs-0" size="sm" responsive>
			<Thead>
				<Tr>
					<Th colSpan="2" className="text-xxxs-center">
						Site Visit:
					</Th>
				</Tr>
			</Thead>
			<Tbody>
				<Tr>
					<Td colSpan="2" className="text-xxxs-center">
						{format(new Date(installDetails.siteVisitDate), 'MMMM d, y')}
						<br />
						<UserName user={installDetails.siteVisitor} />
					</Td>
				</Tr>
				<Tr>
					<Th>Details:</Th>
					<Td style={{ whiteSpace: 'break-spaces' }}>{installDetails.siteDetails}</Td>
				</Tr>
			</Tbody>
			<Thead>
				<Tr>
					<Th colSpan="2" className="text-xxxs-center">
						Install:
					</Th>
				</Tr>
			</Thead>
			<Tbody>
				<Tr>
					<Td colSpan="2" className="text-xxxs-center">
						{format(new Date(installDetails.date), 'EEEE MMMM d, y')}
						<Badge className="ml-xxxs-1" color={installDetails.dateConfirmed ? 'success' : 'danger'}>
							{installDetails.dateConfirmed ? 'Confirmed' : 'Not Confirmed'}
						</Badge>
						<br />
						{installDetails.installers.map((installer) => {
							return <UserName user={installer} key={installer.name} />
						})}
					</Td>
				</Tr>
				<Tr>
					<Th>Channel:</Th>
					<Td>
						{installDetails.channel?.length > 0 ? (
							<Small>
								<Table bordered striped size="small" responsive>
									<Thead>
										<Tr>
											<Th>Type</Th>
											<Th>Color</Th>
											<Th>Quantity</Th>
										</Tr>
									</Thead>
									<Tbody>
										{installDetails.channel.map((channel, i) => (
											<Tr key={i}>
												<Td>{channel.type}</Td>
												<Td>{channel.color}</Td>
												<Td>{channel.quantity}</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</Small>
						) : (
							<React.Fragment>
								{installDetails.channelColor}
								<Badge className="ml-xxxs-3">{installDetails.channelQuantity}</Badge>
							</React.Fragment>
						)}
					</Td>
				</Tr>

				<Tr>
					<Th>Elevations:</Th>
					<Td>{installDetails.lightsLocation}</Td>
				</Tr>
				<Tr>
					<Th>Control Box:</Th>
					<Td>{installDetails.boxLocation}</Td>
				</Tr>
				<Tr>
					<Th>Plug Locations:</Th>
					<Td>{installDetails.plugLocations}</Td>
				</Tr>

				<Tr>
					<Th>Homeowner Present:</Th>
					<Td>{installDetails.homeownerPresent ? 'Yes' : 'No'}</Td>
				</Tr>
			</Tbody>
		</Table>
	)
}

const UserName = ({ user }) => {
	if (!user) return null
	return <Badge className="ml-xxxs-3">{user.name}</Badge>
}

export default InstallDetailsView
