//selector for all rates of a product, selector for current (most recent) rate of a product, that's probably it

import { createCachedSelector } from 're-reselect'
import { createCachedParamSelector, createParamSelector } from '~/state/entities'
import { primitiveSelectors as productRateSelectors } from '../index'
import { primitiveSelectors as productSelectors } from '~/state/entities/product'

//takes state and productId as arguments
export const selectRatesDescendingOrderByProduct = createCachedParamSelector(
	[productSelectors.selectProductById, (state, _) => productRateSelectors.selectRateEntities(state)],
	(product, rates) => {
		if (product.rates.length > 0) {
			return product.rates
				.map((rateId) => rates[rateId])
				.sort((a, b) => {
					const effectiveDatea = new Date(a.effectiveDate)
					const effectiveDateb = new Date(b.effectiveDate)
					return effectiveDatea < effectiveDateb ? 1 : -1
				})
		}
		return false
	}
)

//takes state and productId as arguments
export const selectCurrentRateByProduct = createCachedParamSelector([(state, productId) => selectRatesDescendingOrderByProduct(state, productId)], (rates) => {
	return rates.length > 0 ? rates[0] : false
})

// export const selectNormalizedQuoteById = createCachedParamSelector(
// 	[
// 		(state, params) => tripSelectors.selectQuoteByParam(state, params),
// 		(state, params) => quoteRunSegmentSelectors.selectNormalizedQuoteRunSegmentsByQuoteId(state, params)
// 	],
// 	(quote, runs) => {
// 		return {
// 			...quote,
// 			runs: runs
// 		}
// 	}
// )

// export const selectNormalizedQuoteFormValuesById = createCachedParamSelector([(state, params) => selectNormalizedQuoteById(state, params)], (quote) => {
// 	return {
// 		...quote,
// 		runs: quote.runs.map((run) => {
// 			return {
// 				...run,
// 				start: {
// 					label: run.start.name,
// 					value: run.start.id
// 				},
// 				end: {
// 					label: run.end.name,
// 					value: run.end.id
// 				},
// 				type: {
// 					label: run.type.name,
// 					slug: run.type.slug,
// 					value: run.type.id
// 				}
// 			}
// 		})
// 	}
// })
