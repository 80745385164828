import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader, H5, Thead, Tr, Th } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
import { Col, Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faSignal1, faSignal2, faSignal3, faSignal4, faSparkles, faAward } from '@fortawesome/pro-duotone-svg-icons'
//import Quotes from './Quotes' //differenct branches had these 2 imports for quotes, not sure which one we need now
import Quotes from './components/Quotes'
import WiringDiagram from './components/WiringDiagram'
import InstallDetails from './components/InstallDetails'
import SiteVisitChecklist from './components/SiteVisitChecklist'
import InstallationDiagram from './components/InstallationDiagram'
import InstallDateCard from './components/InstallDateCard'
import LightsGenCard from './components/LightsGenCard'
import Hover from './components/Hover'

const ShippedToInstaller = ({ opportunityId }) => {
	const { selectors, dispatch, userNotAdminHasRole } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))
	const installer = userNotAdminHasRole('installer')

	if (!opportunity) {
		return null
	}

	return (
		<Fragment>
			<InstallDateCard id={opportunityId} />
			<LightsGenCard id={opportunityId} />
			{(!installer || opportunity.siteVisit) && <SiteVisitChecklist opportunityId={opportunityId} />}
			{!installer && <Hover opportunityId={opportunityId} />}
			{!installer && <InstallDetails opportunityId={opportunityId} />}
			<Quotes opportunityId={opportunityId} />
			<WiringDiagram opportunityId={opportunityId} />
			<InstallationDiagram opportunityId={opportunityId} />
		</Fragment>
	)
}

export default ShippedToInstaller
