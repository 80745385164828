import { ThreeSixty } from '@material-ui/icons'
import { ColorPickerPanel, LineWidthPanel, MarkerBase, RectangularBoxMarkerBase, ResizeGrip, SvgHelper } from 'markerjs2'
import { colorSet } from './colors'

export class PenMarker extends MarkerBase {
	strokeColor = 'transparent'
	strokeWidth = 5
	points = []
	activeGrip
	isDoubleClick = false
	isDoubleClickLastGrip = false
	controlBox

	constructor(container, overlayContainer, settings) {
		super(container, overlayContainer, settings)

		this.strokeColor = settings.defaultColor
		this.strokeWidth = settings.defaultStrokeWidth

		this.createVisual = this.createVisual.bind(this)
		this.setStrokeColor = this.setStrokeColor.bind(this)
		this.setStrokeWidth = this.setStrokeWidth.bind(this)

		//this.strokePanel = new ColorPickerPanel('Line color', settings.defaultColorSet, settings.defaultColor)
		this.strokePanel = new ColorPickerPanel('Line color', colorSet, settings.defaultColor)

		this.strokePanel.onColorChanged = this.setStrokeColor

		this.strokeWidthPanel = new LineWidthPanel('Line width', settings.defaultStrokeWidths, settings.defaultStrokeWidth)
		this.strokeWidthPanel.onWidthChanged = this.setStrokeWidth
		this.setupControlBox()
	}

	handleRightClick = (event) => {
		event.preventDefault()
		return false
	}

	getPoints() {
		return this.points.reduce((str, cur, i) => {
			if (i === 0) {
				return str + `M ${cur.x} ${cur.y}`
			} else {
				return str + `L ${cur.x} ${cur.y}`
			}
		}, '')

		return `0,${this.height} ${this.width / 2},0 ${this.width},${this.height}`
	}

	createVisual() {
		this.visual = SvgHelper.createPath(this.getPoints(), [
			['stroke', this.strokeColor],
			['stroke-linecap', 'round'],
			['fill', 'transparent'],
			['stroke-width', this.strokeWidth.toString()]
		])
		this.addMarkerVisualToContainer(this.visual)
	}

	setPoints() {
		//super.setSize()
		SvgHelper.setAttributes(this.visual, [['d', this.getPoints()]])
	}

	pointerDown(point, target) {
		super.pointerDown(point, target)
		console.log('pointer donw')
		if (this.state === 'new') {
			this._state = 'creating'
		}

		if (this.state !== 'new' && this.state !== 'creating') {
			this.select()

			this.activeGrip = undefined
			this.points.forEach((point, i) => {
				if (point.grip.ownsTarget(target)) {
					this.activeGrip = point.grip
				}
			})

			if (this.activeGrip) {
				const lastPoint = this.points[this.points.length - 1]

				if (this.activeGrip && lastPoint.grip && this.activeGrip === lastPoint.grip) {
					if (this.isDoubleClickLastGrip) {
						this._state = 'creating'
						this.deselect()
						const svg = this._container.closest('svg')
						svg.style.cursor = 'crosshair'
					} else {
						this._state = 'resize'
					}
					this.isDoubleClickLastGrip = true
					setTimeout(() => {
						this.isDoubleClickLastGrip = false
					}, 200)
				} else {
					this._state = 'resize'
				}
			}
		}
	}

	setupControlBox() {
		this.controlBox = SvgHelper.createGroup()
		this.container.appendChild(this.controlBox)

		this.controlBox.style.display = 'none'
	}

	pointerUp(point, event) {
		const inState = this.state
		super.pointerUp(point)
		switch (inState) {
			case 'creating':
				if (!this.isDoubleClick) {
					this._suppressMarkerCreateEvent = true
					this.points.push({
						...point,
						grip: this.createGrip()
					})
					if (this.visual) {
						this.setPoints()
					} else {
						this.createVisual()
					}
					this.isDoubleClick = true
					setTimeout(() => {
						this.isDoubleClick = false
					}, 200)
				} else {
					if (this.points.length > 1) {
						const lastPoint = this.points[this.points.length - 1]

						if (Math.pow(point.x - lastPoint.x, 2) + Math.pow(point.y - lastPoint.y, 2) < Math.pow(15, 2)) {
							this._state = 'select'
							this.onMarkerCreated(this)
						}
					}
				}

				break
			case 'resize':
				this.resize(point)
				break
			default:
				break
		}
		//
	}

	resize(point) {
		const foundPoint = this.points.find((point) => point.grip === this.activeGrip)

		if (foundPoint) {
			foundPoint.x = point.x
			foundPoint.y = point.y
			this.setPoints()
			this.positionGrips()
		}
	}

	select() {
		super.select()
		this.positionGrips()
		this.controlBox.style.display = ''
		/* this.adjustControlBox();
        ; */
	}

	deselect() {
		super.deselect()
		this.controlBox.style.display = 'none'
	}

	ownsTarget(el) {
		const gripOwns = this.points.reduce((val, cur) => {
			const test = cur.grip.ownsTarget(el)
			if (test) {
				val = true
			}
			return val
		}, false)
		if (super.ownsTarget(el) || el === this.visual || gripOwns) {
			return true
		} else {
			return false
		}
	}

	setStrokeColor(color) {
		this.strokeColor = color
		if (this.visual) {
			SvgHelper.setAttributes(this.visual, [['stroke', this.strokeColor]])
		}
		this.colorChanged(color)
	}

	setStrokeWidth(width) {
		this.strokeWidth = width
		if (this.visual) {
			SvgHelper.setAttributes(this.visual, [['stroke-width', this.strokeWidth]])
		}
	}

	get toolboxPanels() {
		return [this.strokePanel, this.strokeWidthPanel]
	}

	onStateChanged() {}

	getState() {
		const result = Object.assign(
			{
				strokeColor: this.strokeColor,
				strokeWidth: this.strokeWidth,
				points: this.points
			},
			super.getState()
		)
		result.typeName = PenMarker.typeName

		return result
	}

	restoreState(state) {
		const rectState = state
		this.strokeColor = rectState.strokeColor
		this.strokeWidth = rectState.strokeWidth || 5
		this.points = rectState.points.map((point) => {
			return { ...point, grip: this.createGrip() }
		})

		this.createVisual()
		this.positionGrips()
		super.restoreState(state)
		this.setPoints()
	}

	scale(scaleX, scaleY) {
		super.scale(scaleX, scaleY)
		this.points.forEach((point) => {
			point.x = point.x * scaleX
			point.y = point.y * scaleY
		})

		this.setPoints()
	}

	createGrip() {
		const grip = new ResizeGrip()
		grip.visual.transform.baseVal.appendItem(SvgHelper.createTransform())
		this.controlBox.appendChild(grip.visual)

		grip.visual.addEventListener('contextmenu', (e) => {
			e.preventDefault()
			if (grip === this.activeGrip) {
				const point = this.points.find((pt) => pt.grip === this.activeGrip)
				this.points.splice(this.points.indexOf(point), 1)
				grip.visual.remove()
				this.setPoints()
				this.positionGrips()
			}
		})

		return grip
	}

	positionGrips() {
		this.points.forEach((point, i) => {
			this.positionGrip(point.grip.visual, point.x - point.grip.GRIP_SIZE / 2, point.y - point.grip.GRIP_SIZE / 2)
		})
	}
	positionGrip(grip, x, y) {
		const translate = grip.transform.baseVal.getItem(0)
		translate.setTranslate(x, y)
		grip.transform.baseVal.replaceItem(translate, 0)
	}
}

PenMarker.typeName = 'PenMarker'
PenMarker.title = 'Pen marker'
PenMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24px" height="24px"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M368.4 18.3L312.7 74.1 437.9 199.3l55.7-55.7c21.9-21.9 21.9-57.3 0-79.2L447.6 18.3c-21.9-21.9-57.3-21.9-79.2 0zM288 94.6l-9.2 2.8L134.7 140.6c-19.9 6-35.7 21.2-42.3 41L3.8 445.8c-3.8 11.3-1 23.9 7.3 32.4L164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L33.7 500.9c8.6 8.3 21.1 11.2 32.4 7.3l264.3-88.6c19.7-6.6 35-22.4 41-42.3l43.2-144.1 2.8-9.2L288 94.6z"/></svg>`
