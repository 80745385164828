import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader, H5, Thead, Tr, Th } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
import { Col, Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faSignal1, faSignal2, faSignal3, faSignal4, faSparkles, faAward } from '@fortawesome/pro-duotone-svg-icons'
import Quotes from './components/Quotes'
import InstallDetails from './components/InstallDetails'
import SiteVisitChecklist from './components/SiteVisitChecklist'
import Hover from './components/Hover'

const HoverFailed = ({ opportunityId }) => {
	const { selectors, dispatch } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))

	if (!opportunity) {
		return null
	}

	return (
		<Fragment>
			<SiteVisitChecklist opportunityId={opportunityId} />
			<Hover opportunityId={opportunityId} />
			<InstallDetails opportunityId={opportunityId} />
			<Quotes opportunityId={opportunityId} />
		</Fragment>
	)
}

export default HoverFailed
