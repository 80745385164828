import { useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components'

export const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClick)

		return () => {
			document.removeEventListener('click', handleClick)
		}
	})
}

export const useTheme = () => {
	const theme = useContext(ThemeContext)

	return theme || {}
}
