import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Badge } from '@bootstrap-styled/v4'
import { Table, Button, Row, Col, ActionButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import ButtonGroup from '@bootstrap-styled/v4/lib/ButtonGroup'
import UpdateProductForm from './UpdateProductForm'

const ProductHistory = ({ product }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()

	const rates = useSelector((state) => selectors.selectRatesDescendingOrderByProduct(state, product.id))

	return (
		<Table bordered striped responsive>
			<Thead>
				<Tr>
					<Th>Price</Th>
					<Th>Effective Date</Th>
				</Tr>
			</Thead>
			<Tbody>
				{rates.map((rate, i) => {
					return (
						<Tr key={i}>
							<Td>
								{rate.currencies.map((currency, i) => (
									<Badge key={i} color="info">
										${currency.value} {currency.symbol}
									</Badge>
								))}
							</Td>
							<Td>{format(new Date(rate.effectiveDate), 'MMMM do, yyyy')}</Td>
						</Tr>
					)
				})}
			</Tbody>
		</Table>
	)
}

export default ProductHistory
