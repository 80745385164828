import React, { PureComponent, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, Row, H1, H3, Hr, ButtonGroup, Label, P } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { callApi } from '~/services/api'

import ListingTable from './components/ListingTable'
import { Link, Redirect } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'
import { Toggle } from '~/components/bootstrap/components/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-solid-svg-icons'

const Listing = () => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()
	const installer = userHasRole('installer')
	const estimator = userHasRole('estimator')
	const admin = userHasRole('admin')
	const currentStage = useSelector((state) => selectors.selectActiveStage(state))
	const currentTagId = useSelector((state) => selectors.selectActiveTagId(state))
	const currentStageId = useSelector((state) => selectors.selectOpportunityStageTypeBySlug(state, currentStage))?.id
	const [assignedOnly, setAssignedOnly] = useState(false)

	// console.log('currentStage', currentStage)
	// console.log('currentTag', currentTagId)

	let redirect
	if (!admin) {
		if (installer) {
			redirect = '/installs'
		} else if (estimator) {
			redirect = '/quotes'
		}
	}

	const exportToCSV = () => {
		callApi({
			endpoint: 'opportunities/csv',
			params: { stageType: currentStageId, tagId: currentTagId },
			headers: {
				responseType: 'blob'
			}
		})
			//.then((response) => response.blob())
			.then((blob) => {
				console.log(blob)
				const url = window.URL.createObjectURL(new Blob([blob.data]))
				console.log(url)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `leads.csv`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}

	const exportPurchasesToCSV = () => {
		callApi({
			endpoint: 'opportunities/csv/purchases',
			headers: {
				responseType: 'blob'
			}
		})
			//.then((response) => response.blob())
			.then((blob) => {
				console.log(blob)
				const url = window.URL.createObjectURL(new Blob([blob.data]))
				console.log(url)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `purchases.csv`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}

	return (
		<React.Fragment>
			{redirect && <Redirect to={redirect} />}
			<Row className="mb-xxxs-4 align-items-xxxs-center">
				<Col xxxs={12} md>
					<H3>Leads</H3>
				</Col>
				{admin && (
					<Col xxxs="auto">
						<Row className="align-items-xxxs-center">
							<Col xxxs="auto" className="pr-xxxs-0">
								Assigned To Me Only
							</Col>
							<Col xxxs="auto" className="pl-xxxs-2">
								<FontAwesomeIcon icon={assignedOnly ? faToggleOn : faToggleOff} size="2x" onClick={() => setAssignedOnly(!assignedOnly)} />
							</Col>
						</Row>
					</Col>
				)}
				<Col xxxs={6} md="auto">
					<ButtonGroup>
						<Button color="info" tag={Link} to="/leads/new">
							New Lead
						</Button>
						<Button color="warning" tag={Link} to="/leads/new/qualified">
							New Qualified Lead
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<ListingTable assignedOnly={assignedOnly} />
			{admin && (
				<Row className="mb-xxxs-4 align-items-xxxs-center justify-content-xxxs-end">
					<Col xxxs={12} md="auto">
						<Button color="info" onClick={exportToCSV}>
							Export to CSV
						</Button>

						<Button color="warning" onClick={exportPurchasesToCSV}>
							Export Purchases to CSV
						</Button>
					</Col>
				</Row>
			)}
		</React.Fragment>
	)
}

export default Listing
