import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
	Navbar as BsNavbar,
	NavbarBrand,
	NavbarToggler,
	Collapse,
	NavItem,
	Nav,
	Container,
	P,
	Img,
	NavLink as BsNavLink,
	A,
	Form,
	Small,
	Hr
} from '@bootstrap-styled/v4'
import { NavLink as RouterNavLink, Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { Col, Button, Row, Badge } from '~/components/bootstrap'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { useFirebase } from '~/services/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import { useLumenox } from '~/services/lumenox'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { AsyncSelect } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm } from 'formik'
import { callApi } from '~/services/api'
import { mediaBreakpointUp } from '@bootstrap-styled/css-mixins/lib/breakpoints'
import { useTheme } from '~/utils/hooks'
import { components } from 'react-select'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

const Navbar = () => {
	const { user, logout } = useFirebase()
	const { userHasRole, breakpointDown, dispatch, entityThunks } = useLumenox()
	const [isOpen, setIsOpen] = useState(false)
	const [searchOpen, setSearchOpen] = useState(false)
	const theme = useTheme()
	const searchInput = useRef(null)

	const admin = userHasRole('admin')
	const sales = userHasRole('sales')
	const estimator = userHasRole('estimator')
	const production = userHasRole('production')
	const installer = userHasRole('installer')

	useEffect(() => {
		dispatch(entityThunks.fetchOpportunityStageTypes())
	}, [])

	const toggleSearch = () => {
		setSearchOpen(true)
		setTimeout(() => {
			searchInput.current.focus()
		}, 50)
	}

	if (!user) return null //to not show navbar when downloading quote pdf

	return (
		<React.Fragment>
			<Wrapper toggleable="lg">
				<Container>
					<Row className="align-items-xxxs-center justify-content-xxxs-end">
						<Col xxxs>
							<NavbarBrand tag={Link} to="/">
								Lumenox
							</NavbarBrand>
						</Col>
						{breakpointDown('sm') && (
							<Col xxxs="auto" className="pr-xxxs-0">
								<Button icon={faMagnifyingGlass} onClick={toggleSearch} />
							</Col>
						)}
						<Col xxxs="auto" className="pl-xxxs-2">
							<NavbarToggler onClick={() => setIsOpen(!isOpen)}>
								<FontAwesomeIcon icon={faBars} />
							</NavbarToggler>
						</Col>
					</Row>
					<Collapse navbar isOpen={isOpen}>
						<Nav navbar className="mx-xxxs-auto">
							{user && (
								<React.Fragment>
									{(admin || sales) && (
										<NavItem>
											<NavLink tag={RouterNavLink} to="/leads" onClick={() => setIsOpen(false)}>
												Leads
											</NavLink>
										</NavItem>
									)}
									{estimator && (
										<NavItem>
											<NavLink tag={RouterNavLink} to="/quotes" onClick={() => setIsOpen(false)}>
												Quotes
											</NavLink>
										</NavItem>
									)}
									{production && (
										<NavItem>
											<NavLink tag={RouterNavLink} to="/production" onClick={() => setIsOpen(false)}>
												Production
											</NavLink>
										</NavItem>
									)}
									{installer && (
										<NavItem>
											<NavLink tag={RouterNavLink} to="/installs" onClick={() => setIsOpen(false)}>
												Installs
											</NavLink>
										</NavItem>
									)}

									<NavItem>
										<NavLink tag={RouterNavLink} to="/calendar" onClick={() => setIsOpen(false)}>
											Calendar
										</NavLink>
									</NavItem>
									{admin && (
										<Fragment>
											<NavItem>
												<NavLink tag={RouterNavLink} to="/users" onClick={() => setIsOpen(false)}>
													Users
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink tag={RouterNavLink} to="/products" onClick={() => setIsOpen(false)}>
													Products
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink tag={RouterNavLink} to="/terms" onClick={() => setIsOpen(false)}>
													Terms
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink tag={RouterNavLink} to="/stages" onClick={() => setIsOpen(false)}>
													Stages
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink tag={RouterNavLink} to="/compensation-programs" onClick={() => setIsOpen(false)}>
													Compensation Programs
												</NavLink>
											</NavItem>
										</Fragment>
									)}
								</React.Fragment>
							)}
						</Nav>
						<Nav navbar>
							{!breakpointDown('sm') && <Button className="mr-xxxs-1" icon={faMagnifyingGlass} onClick={toggleSearch} />}
							{user && (
								<React.Fragment>
									<NavItem className="mr-xxxs-2">
										<UserName>Welcome, {user.displayName}</UserName>
									</NavItem>

									{user.photoUrl && (
										<NavItem>
											<ProfilePicture thumbnail src={user.photoUrl} />
										</NavItem>
									)}

									<NavItem>
										<NavLink
											href="#"
											onClick={(e) => {
												e.preventDefault()
												logout()
											}}
										>
											Logout
										</NavLink>
									</NavItem>
								</React.Fragment>
							)}
							{!user && (
								<NavItem>
									<NavLink tag={RouterNavLink} to="/auth/login">
										Login
									</NavLink>
								</NavItem>
							)}
						</Nav>
					</Collapse>
				</Container>
			</Wrapper>
			<Collapse isOpen={searchOpen}>
				<Container className="p-xxxs-0 my-xxxs-3">
					<Search inputRef={searchInput} />
				</Container>
			</Collapse>
		</React.Fragment>
	)
}
//
const Wrapper = styled(BsNavbar)`
	${({ theme }) => `
		background: ${theme['$brand-primary']};
		color: ${theme['$gray-light']};

		a {
			color: ${theme['$gray-light']};
			
			&:hover {
				color: ${theme['$gray-dark']};
			}
		}

		.navbar-toggler {
			border-color: ${theme['$blue-dark']};
		}
	`}
`

const NavLink = styled(BsNavLink)`
	text-decoration: none;
`

const ProfilePicture = styled(Img)`
	border-radius: 100rem !important;
	max-width: 2.5rem !important;
	padding: 0.1rem !important;
`

const UserName = styled(({ children }) => <span className="navbar-text">{children}</span>)``

const LeadOption = (props) => {
	const { selectors } = useLumenox()
	const currentStage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, props.value.stage))
	return (
		<React.Fragment>
			<components.Option {...props}>
				<Row noGutters className="align-xxxs-start">
					<Col xxxs={6}>
						{props.label}
						<br />
						{/*This check shouldn't be needed but it was on dev and better safe than sorry*/}
						{currentStage && <Badge color={currentStage.color}>{currentStage.name}</Badge>}
					</Col>
					<Col xxxs={6} className="text-xxxs-right">
						{format(new Date(props.value.createdAt), 'MMMM d, y')}
						<br />
						{props.value.phoneNumbers &&
							props.value.phoneNumbers.length > 0 &&
							props.value.phoneNumbers.map((phoneNumber) => (
								<Badge key={phoneNumber} color="info" className="ml-xxxs-1">
									{phoneNumber}
								</Badge>
							))}
						<br />
						{props.value.address ? props.value.address : null}
					</Col>
				</Row>
			</components.Option>
		</React.Fragment>
	)
}

const Search = ({ inputRef }) => {
	const history = useHistory()
	const [lastTimeout, setLastTimeout] = useState(null)

	const getOptions = (input) => {
		return callApi({
			endpoint: `/opportunities/search/${input}`
		}).then((result) => {
			setLastTimeout(null)
			return result.data
		})
	}

	const loadOptions = (input) => {
		if (input === '') return
		return new Promise((resolve) => {
			if (lastTimeout !== null) {
				clearTimeout(lastTimeout)
			}
			setLastTimeout(
				setTimeout(() => {
					resolve(getOptions(input))
				}, 500)
			)
		})
	}

	const goToLead = (option) => {
		history.push(`/leads/${option.value.id}`)
	}

	return (
		<Formik initialValues={{ name: '' }}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<AsyncSelect loadOptions={loadOptions} handleOnChange={goToLead} name="name" CustomOption={LeadOption} ref={inputRef} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default Navbar
