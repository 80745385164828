import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useDispatch } from 'react-redux'
import { addUser } from '~/state/entities/user/thunks'
import { claimUserInvite } from '~/state/entities/userInvite/thunks'
import { Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

export const FirebaseContext = React.createContext()
export const useFirebase = () => useContext(FirebaseContext)
export const FirebaseProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState()
	const [user, setUser] = useState()
	//const [redirect, setRedirect] = useState(false)
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const auth = firebase.auth()

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				console.log('USER LOGGED IN', user.displayName, user.uid)
				setIsAuthenticated(true)
				user.sub = user.uid
				console.log(user)
				setUser(user)
				checkInvite()
			} else {
				setIsAuthenticated(false)
				setUser(null)
			}
			setLoading(false)
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		auth.getRedirectResult().then((result) => {
			console.log('redirect : ', result.credential)
			if (result.credential) {
				//checkInvite()
			}
		})
	}, [])

	const signInWithEmailAndPassword = (email, password) => {
		return auth.signInWithEmailAndPassword(email, password).then(() => {
			//checkInvite()
		})
	}

	const checkInvite = () => {
		const invite = Cookies.getJSON('invite')
		console.log('-------------------checking invite------------------------', invite)
		if (invite) {
			console.log('found invite, claiming')
			return dispatch(claimUserInvite(invite.id)).then((response) => {
				console.log('removing cookie')
				Cookies.remove('invite')
			})
		}
	}

	const signUpWithEmailAndPassword = ({ email, password, ...rest }) => {
		return auth.createUserWithEmailAndPassword(email, password).then((user) => {
			console.log('created user', user)
			return user.user
				.updateProfile({
					displayName: `${rest.firstName} ${rest.lastName}`
				})
				.then(() => {
					console.log('before check invite', user.displayName)
					//checkInvite()
					user.user.reload()
				})
		})
	}

	const signInWithGoogle = () => {
		const provider = new firebase.auth.GoogleAuthProvider()
		provider.addScope('profile')
		provider.addScope('email')
		console.log('provider is :', provider)
		// auth.signInWithRedirect(provider)
		auth.signInWithPopup(provider)
	}

	const logout = () => {
		return auth.signOut()
	}

	return (
		<FirebaseContext.Provider
			value={{
				loading,
				isAuthenticated,
				user,
				signInWithEmailAndPassword,
				signInWithGoogle,
				signUpWithEmailAndPassword,
				logout
			}}
		>
			{!loading ? children : null}
		</FirebaseContext.Provider>
	)
}
