import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { A, Thead, Tbody, Tr, Td, Th, Nav, NavItem, NavLink } from '@bootstrap-styled/v4'
import { Badge, Table } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { format } from 'date-fns'
import { RelativeDate } from '~/components/common/Text'
import { capitalize } from 'lodash'
import Default from './components/default'
import DeliveredNotOpened from './components/deliveredNotOpened'

const New = ({ assignedOnly }) => {
	return (
		<Fragment>
			<Nav pills className="mb-xxxs-4">
				<NavItem>
					<NavLink>All</NavLink>
				</NavItem>
				<NavItem>
					<NavLink>Delivered Not Opened</NavLink>
				</NavItem>
			</Nav>
			<Default assignedOnly={assignedOnly} />
		</Fragment>
	)
}

export default New
