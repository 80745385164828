import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, ButtonToolbar, ButtonGroup, CardHeader, H4, H5, H6, Badge, P, Tbody, Tr, Th, Td, Form } from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import { DatePicker } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useField, FieldArray } from 'formik'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { ActionButton, Button, Col } from '~/components/bootstrap'
import { format } from 'date-fns'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import SiteVisitRoutes from '../../../SiteVisitChecklist'
import { Link, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '~/components/common/PrivateRoute'

const SiteVisitChecklist = ({ opportunityId }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))

	return (
		<React.Fragment>
			<Card className="mb-xxxs-3">
				<CardHeader>
					<Row className="align-items-xxxs-center">
						<Col xxxs>
							<H5 className="mb-xxxs-0">Site Visit Checklist</H5>
						</Col>

						<Col xxxs="auto">
							<ButtonGroup>
								{opportunity.siteVisit && <Button tag={Link} to={`${url}/site-visit-checklist/update`} color="warning" icon={faEdit} />}
								<Button tag={Link} to={`${url}/site-visit-checklist`} color="info" icon={opportunity.siteVisit ? faSearch : faEdit} />
							</ButtonGroup>
						</Col>
					</Row>
				</CardHeader>
			</Card>
			<PrivateRoute path={`${url}/site-visit-checklist`} component={SiteVisitRoutes} />
		</React.Fragment>
	)
}

export default SiteVisitChecklist
