import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Container, H1, H3, Hr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton } from '~/components/bootstrap'
import styled from 'styled-components'

import ProductTable from './components/ProductTable'
import AddProductForm from './components/AddProductForm'

const Products = () => {
	return (
		<Wrapper className="p-xxxs-3">
			<Row className="mb-xxxs-4 align-items-xxxs-center">
				<Col xxxs={12} md>
					<H3>Products</H3>
				</Col>
				<Col xxxs={12} md="auto">
					<ActionButton button="New Product" title="Add New Product">
						{(closeModal) => (
							<AddProductForm
								onSuccess={() => {
									closeModal()
								}}
							/>
						)}
					</ActionButton>
				</Col>
			</Row>
			<ProductTable />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${({ theme }) => theme['$gray-light']};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default Products
