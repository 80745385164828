import React, { PureComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLumenox } from '~/services/lumenox'
import styled from 'styled-components'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { CenteredModal } from '~/components/bootstrap'
import { useFirebase } from '~/services/firebase'
import { Container, ModalBody, ModalHeader } from '@bootstrap-styled/v4'
import { upperFirst } from 'lodash'
import PrivateRoute from '~/components/common/PrivateRoute'

import View from './View'

const WiringDiagrams = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const { params: nameParams } = useRouteMatch({
		path: '/leads/:id/wiring-diagram/:name',
		strict: false
	})
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(true)
	const quote = useSelector((state) => selectors.selectQuoteById(state, nameParams.name))

	const handleExit = () => {
		console.log('exit')
		history.push(url)
	}

	return (
		<CenteredModal isOpen={isOpen} size="xl" onExit={handleExit}>
			<ModalHeader toggle={handleExit}>{upperFirst(quote ? 'View' : nameParams.name)} Wiring Diagram</ModalHeader>
			<ModalBody>
				<Switch>
					<Redirect exact from={`${url}/wiring-diagram`} to={`${url}`} />
					<Route path={`${url}/wiring-diagram/:id`} component={View} />
				</Switch>
			</ModalBody>
		</CenteredModal>
	)
}

export default WiringDiagrams
