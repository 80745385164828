import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchProducts = createAsyncThunk('products/fetch', () => {
	console.log('Fetching products')
	return callApi({
		endpoint: `products`
	}).then((response) => {
		return response.data
	})
})

export const fetchProductById = createAsyncThunk('products/fetchById', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `products/${id}`
	}).then((response) => {
		return response.data
	})
})

export const addProduct = createAsyncThunk('products/add', (values, { getState, requestId }) => {
	return callApi({
		endpoint: `products`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const updateProduct = createAsyncThunk('products/update', ({ values, productId }) => {
	console.log('product ID: ' + productId)
	return callApi({
		endpoint: `products/${productId}`,
		method: 'PATCH',
		data: values
	}).then((response) => {
		return response.data
	})
})

// Gotta add upate ones

// export const updateOpportunityStage = createAsyncThunk('opportunities/updateStage', ({ id, stageId }, { getState, requestId }) => {
// 	return callApi({
// 		endpoint: `opportunities/${id}`,
// 		method: 'PUT',
// 		data: { stage: stageId }
// 	}).then((response) => {
// 		return response.data
// 	})
// })

// export const updateOpportunityAssignedTo = createAsyncThunk('opportunities/updateAssignedTo', ({ id, userId }, { getState, requestId }) => {
// 	return callApi({
// 		endpoint: `opportunities/${id}`,
// 		method: 'PUT',
// 		data: { assignedTo: userId }
// 	}).then((response) => {
// 		return response.data
// 	})
// })
