import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { selectors, entityThunks, actions } from '~/state/entities'
import pusherThunk from '~/state/entities/pusher'
import { useFirebase } from './firebase'
import Cookies from 'js-cookie'
import Pusher from 'pusher-js'
import { useJsApiLoader } from '@react-google-maps/api'
import { useBreakpoints } from 'react-breakpoints-hook'

export const LumenoxContent = React.createContext()
export const useLumenox = () => useContext(LumenoxContent)

const useQuery = () => {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}

export const LumenoxProvider = ({ children }) => {
	const theme = useContext(ThemeContext)
	//const tastingMatch = /\/tastings\/((?!new)[^\/]*)/gm.exec(location.pathname)
	const { user } = useFirebase()
	const dispatch = useDispatch()
	const [customToken, setCustomToken] = useState(false)
	const [loading, setLoading] = useState(false)
	const { isLoaded, loadError } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDsnBd4lG7iFlJqpfsesNMEU7dNfZRZbOY',
		libraries: ['places']
	})
	const [orderedBreakpoints, setOrderedBreakpoints] = useState([])
	const [breakpointArgs, setBreakpointArgs] = useState({})
	const query = useQuery()
	console.log('isLoaded', isLoaded, loadError)
	let pusher

	console.log(user)

	useEffect(() => {
		if (query.get('token')) {
			setCustomToken(query.get('token'))
		}
	}, [query])

	useEffect(() => {
		if (user) {
			dispatch(entityThunks.fetchUserById(user.sub))
		}
	}, [user])

	useEffect(() => {
		calculateBreakpointInfo()
	}, [])

	//const currentTastingId = tastingMatch ? tastingMatch[1] : Cookies.get('currentTasting') ? Cookies.get('currentTasting') : null

	//const tasting = useSelector((state) => selectors.selectTastingById(state, currentTastingId))
	const lumenoxUser = useSelector((state) => selectors.selectUserById(state, user ? user.sub : null))
	console.log('lumenoxUser', lumenoxUser)

	const userHasRole = (slug) => {
		const role = useSelector((state) => selectors.selectUserRoleBySlug(state, slug))

		return !!(role && lumenoxUser && lumenoxUser.roles.includes(role.id))
	}

	const userNotAdminHasRole = (slug) => {
		const role = useSelector((state) => selectors.selectUserRoleBySlug(state, slug))
		const admin = useSelector((state) => selectors.selectUserRoleBySlug(state, 'admin'))

		return !!(role && admin && lumenoxUser && lumenoxUser.roles.includes(role.id) && !lumenoxUser.roles.includes(admin.id))
	}

	const userIncludesRole = (slugs) => {
		const roleIds = useSelector((state) => selectors.selectUserRolesBySlugs(state, slugs))

		//x?. returns undefined if x is undefined or null, and undefined && y returns undefined, so convert to boolean
		return Boolean(roleIds?.length > 0 && lumenoxUser?.roles.some((r) => roleIds.includes(r)))
	}

	const calculateBreakpointInfo = () => {
		const ob = Object.entries(theme['$grid-breakpoints']).sort(
			([keyA, valueA], [keyB, valueB]) => Number(valueA.slice(0, -2)) - Number(valueB.slice(0, -2))
		)
		const ba = {}
		for (let i = 0; i < ob.length; i++) {
			ba[ob[i][0]] = {}
			ba[ob[i][0]].min = Number(ob[i][1].slice(0, -2))
			if (i !== ob.length - 1) {
				ba[ob[i][0]].max = Number(ob[i + 1][1].slice(0, -2)) - 1
			} else {
				ba[ob[i][0]].max = null
			}
		}
		setOrderedBreakpoints(ob)
		setBreakpointArgs(ba)
	}

	//less than or equal to
	const breakpointDown = (size) => {
		const breakpoints = useBreakpoints(breakpointArgs)
		for (let i = 0; i < orderedBreakpoints.length; i++) {
			if (orderedBreakpoints[i][0] === size) return breakpoints[size]
			if (breakpoints[orderedBreakpoints[i][0]]) return true
		}
		//shouldn't ever get here
		return false
	}

	//greater than or equal to
	const breakpointUp = (size) => {
		const breakpoints = useBreakpoints(breakpointArgs)
		for (let i = orderedBreakpoints.length - 1; i >= 0; i--) {
			if (orderedBreakpoints[i][0] === size) return breakpoints[size]
			if (breakpoints[orderedBreakpoints[i][0]]) return true
		}
		//shouldn't ever get here
		return false
	}

	//const isOwner = tasting && user && tasting.owner === user.sub

	/* useEffect(() => {
		console.log(`Use Effect - get tasting ${currentTastingId}`)
		if (currentTastingId && !tasting) {
			setLoading(true)

			dispatch(entityThunks.fetchTastingById(currentTastingId)).then((response) => {
				setLoading(false)
				Cookies.set('currentTasting', currentTastingId)
			})
		} else if (currentTastingId && tasting && !Cookies.get('currentTasting')) {
			Cookies.set('currentTasting', currentTastingId)
		}
	}, [currentTastingId]) */

	useEffect(() => {
		pusherSubscribe()

		return () => {
			pusherUnsubscribe()
		}
	}, [])

	const pusherSubscribe = () => {
		pusher = new Pusher(process.env.PUSHER_API_KEY, {
			cluster: process.env.PUSHER_CLUSTER
		})

		const channel = pusher.subscribe('entities')
		let events = {}
		channel.bind_global(async (event, data) => {
			if (event.match(/^(?!pusher).+/)) {
				if (!events.hasOwnProperty(data.id)) {
					events[data.id] = { chunks: [], receivedFinal: false }
				}
				let ev = events[data.id]
				ev.chunks[data.index] = data.chunk
				console.log(`RECEIVED CHUNK: ${data.index}`)
				if (data.final) ev.receivedFinal = true
				if (ev.receivedFinal && ev.chunks.length === Object.keys(ev.chunks).length) {
					console.log('RECEIVED FINAL CHUNK')
					const entities = JSON.parse(ev.chunks.join(''))
					Object.entries(entities).map(([name, subEntities]) => {
						Object.entries(subEntities).map(([id, entity]) => {
							//console.log('Dispatching pusher thunk!')
							//dispatch(pusherThunk({ entity: name, data: entity }))
						})
					})
					delete events[data.id]
				}
			}
		})
	}

	const pusherUnsubscribe = () => {
		if (pusher) {
			pusher.unsubscribe('entities')
		}
	}

	return (
		<LumenoxContent.Provider
			value={{
				selectors,
				entityThunks,
				actions,
				user,
				lumenoxUser,
				userHasRole,
				userNotAdminHasRole,
				userIncludesRole,
				dispatch,
				breakpointUp,
				breakpointDown,
				theme,
				customToken
			}}
		>
			{isLoaded && children}
		</LumenoxContent.Provider>
	)
}
