import React, { PureComponent, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Badge, Container, Form, H1, H3, Hr, InputGroup, InputGroupAddon } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Textarea, Label } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { Formik, Form as FormikForm } from 'formik'
import { Checkbox, Input, MultipleInputArray, Select } from '~/components/bootstrap/components/Form'
import * as Yup from 'yup'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { callApi } from '~/services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StageForm = ({ setIsOpen, currentValues }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const stages = useSelector((state) => selectors.selectAllOpportunityStageTypes(state))

	const slugify = (string) => {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
		const p = new RegExp(a.split('').join('|'), 'g')

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') // Trim - from end of text
	}

	const onSubmit = (values) => {
		const data = {
			...values,
			slug: slugify(values.name),
			//MultipleArrayInput can add elements to array with initial values, filter out ones we don't actually use
			nextV2: values.nextV2.filter((next) => next.stage !== '')
		}
		if (currentValues) {
			dispatch(entityThunks.updateOpportunityStageType({ data, id: values.id })).then((response) => {
				setIsOpen(false)
			})
		} else {
			dispatch(entityThunks.addOpportunityStageType({ data })).then(() => {
				setIsOpen(false)
			})
		}
	}

	const defaultValues = {
		name: '',
		slug: '',
		percentage: '',
		next: [],
		nextV2: [],
		color: '',
		icon: '',
		archived: false
	}

	const initialValues = {
		...defaultValues,
		...currentValues
	}

	const validationSchema = Yup.object({
		name: Yup.string().required('Required'),
		percentage: Yup.number().required('Required')
	})

	const stageOptions = stages.map((stage) => ({ label: stage.name, value: stage.id }))
	const colorOptions = [
		{ label: 'default', value: 'default' },
		{ label: 'primary', value: 'primary' },
		{ label: 'success', value: 'success' },
		{ label: 'info', value: 'info' },
		{ label: 'warning', value: 'warning' },
		{ label: 'danger', value: 'danger' }
	]
	const iconOptions = Object.keys(solid).map((icon) => ({ label: icon, value: icon }))

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				return (
					<Form tag={FormikForm}>
						<Row className="align-items-xxxs-center">
							<Col xxxs>
								<Row className="mb-xxxs-3">
									<Col xxxs>
										<Label>Name:</Label>
										<Input name="name" />
									</Col>
									<Col xxxs>
										<Label>Percentage</Label>
										<Input type="number" name="percentage" />
									</Col>
								</Row>
								<Row>
									<Col xxxs>
										<Label>Color</Label>
										<Select name="color" options={colorOptions} />
									</Col>
									<Col xxxs>
										<Label>Icon</Label>
										{/*Need to adjust widths here*/}
										<InputGroup>
											<Select name="icon" options={iconOptions} />
											<InputGroupAddon>{solid[values.icon] && <FontAwesomeIcon icon={solid[values.icon]} />}</InputGroupAddon>
										</InputGroup>
									</Col>
								</Row>
								<Row className="mt-xxxs-3">
									<Col xxxs>
										<Checkbox name="archived" />
										<Label>Archive</Label>
									</Col>
								</Row>
							</Col>
						</Row>
						<Hr />
						<Label>Next Stages</Label>
						<MultipleInputArray name="nextV2" values={values.nextV2} initialValues={{ stage: '', dependencies: [] }}>
							{({ i }) => (
								<React.Fragment>
									<Label>Stage:</Label>
									<Select name={`nextV2.${i}.stage`} options={stageOptions} />
									<Label>Dependencies:</Label>
									<Select isMulti name={`nextV2.${i}.dependencies`} options={stageOptions} />
								</React.Fragment>
							)}
						</MultipleInputArray>

						<Hr />
						<Row className="justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit">Save</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default StageForm
