import React, { PureComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row, Form, FormGroup, H5, Hr, H6, Input as BsInput } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import RichTextEditor from 'react-rte'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbtack as faThumbtackSolid } from '@fortawesome/pro-solid-svg-icons'
import { faThumbtack as faThumbtackRegular } from '@fortawesome/pro-regular-svg-icons'

const NoteForm = ({ initialValues, onSubmit, onSuccess }) => {
	const { entityThunks, dispatch } = useLumenox()
	//const { value, setValue } = useState(RichTextEditor.createValueFromString(initialValues.content, 'markdown'))
	const initialValue = RichTextEditor.createValueFromString(initialValues.content, 'markdown') //need to do it like this for some reason
	const [value, setValue] = useState(initialValue)

	const defaultInitValues = {
		content: '',
		pinned: false
	}

	const formInitialValues = {
		...defaultInitValues,
		...initialValues
	}

	const validationSchema = Yup.object().shape({
		content: Yup.string().required('Required')
	})

	const handleSubmit = (values, helpers) => {
		helpers.setSubmitting(true)
		onSubmit(values, helpers, (successArgs) => {
			helpers.setSubmitting(false)
			if (onSuccess) onSuccess(successArgs)
		})
	}

	const handleOnChange = (value, setFieldValue) => {
		setValue(value)
		setFieldValue('content', value.toString('markdown'))
	}

	const toolbarConfig = {
		// Optionally specify the groups to display (displayed in the order listed).
		display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
		INLINE_STYLE_BUTTONS: [
			{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
			{ label: 'Italic', style: 'ITALIC' },
			{ label: 'Underline', style: 'UNDERLINE' }
		],
		BLOCK_TYPE_DROPDOWN: [
			{ label: 'Normal', style: 'unstyled' },
			{ label: 'Heading Large', style: 'header-one' },
			{ label: 'Heading Medium', style: 'header-two' },
			{ label: 'Heading Small', style: 'header-three' }
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: 'UL', style: 'unordered-list-item' },
			{ label: 'OL', style: 'ordered-list-item' }
		]
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<div className="mb-xxxs-3">
							<RichTextEditor
								toolbarConfig={toolbarConfig}
								value={value}
								onChange={(value) => handleOnChange(value, setFieldValue)}
								editorClassName="note-form"
								autoFocus
							/>
						</div>
						<Row className="align-items-xxxs-center">
							<Col xxxs className="pr-xxxs-0">
								<Button type="submit" color="success" block submitting={isSubmitting}>
									Save
								</Button>
							</Col>
							<Col xxxs="auto" className="mr-xxxs-2">
								<FontAwesomeIcon
									icon={values.pinned ? faThumbtackSolid : faThumbtackRegular}
									className="fa-rotate-by"
									style={{ '--fa-rotate-angle': '45deg', cursor: 'pointer' }}
									onClick={() => setFieldValue('pinned', !values.pinned)}
								/>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default NoteForm
