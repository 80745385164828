import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
	Row,
	Form,
	P,
	InputGroup,
	Hr,
	H4,
	Card,
	CardHeader,
	CardBlock,
	H6,
	Collapse,
	Thead,
	Th,
	Td,
	Badge,
	H3,
	H5,
	Small,
	ButtonGroup,
	Img,
	Ul,
	InputGroupAddon,
	Ol,
	Li,
	Tbody,
	Tr
} from '@bootstrap-styled/v4'
import { Col, Button, ActionButton, Table, ConfirmationButton } from '~/components/bootstrap'
import { Formik, Form as FormikForm, useField, FieldArray, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { round, truncate } from 'lodash'
import { Typeahead, FormGroup, Select, Label, Input, MultipleInputArray, DropzoneAreaModal, Textarea, Checkbox } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faArrowRight,
	faChevronDown,
	faChevronRight,
	faChevronUp,
	faFilePdf,
	faPencilPaintbrush,
	faPlus,
	faSearch,
	faTimes,
	faUpload
} from '@fortawesome/pro-regular-svg-icons'
import { useTheme } from 'styled-components'
import { faEdit, faImage, faImages, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { useRouteMatch } from 'react-router'
import * as markerjs2 from 'markerjs2'
import { PenMarker } from '~/markerjs/PenMarker'
import fixRotation from 'fix-image-rotation'
import loadImage from 'blueimp-load-image'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Page as RenderPage, Text, View, Document as RenderDocument, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import { StartMarker } from '~/markerjs/StartMarker'
import { EndMarker } from '~/markerjs/EndMarker'
import { v4 as uuid } from 'uuid'
import { Link } from 'react-router-dom'
import { callApi } from '~/services/api'
import PrivateQuotePDF from '../QuotePDF'
import quoteLocation from '~/state/entities/quoteLocation'
import { FormatCurrency } from '~/components/common/Text'
import TermForm from '~/views/Terms/components/TermForm'
import { Term } from '~/views/Terms'
import { CollapsableCard } from '~/components/common/CollapsableCard'
import equal from 'deep-equal'
import { ConditionalActionButton, ConditionalConfirmationButton } from '~/components/bootstrap/components/Button'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import RichTextEditor from 'react-rte'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
//import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const QuoteForm = (props) => {
	const { initialValues, onSubmit, dirty, setDirty, formRef, locations = [], mode = 'basic' } = props
	const { dispatch, entityThunks, selectors, user, lumenoxUser, userHasRole } = useLumenox()
	const [locationOptions, setLocationOptions] = useState(locations)
	const [downloadLoading, setDownloadLoading] = useState(false)
	const { url, params } = useRouteMatch({
		path: '/leads/:id/quotes/:quoteId',
		strict: false
	})
	const [valuesAtStart, setValuesAtStart] = useState(null)

	const innerSubmit = (values, other) => {
		onSubmit(values, other).then((result) => {
			//clear out values at start so that they get set to the new values after the save
			setValuesAtStart(null)
		})
	}

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, params.id))
	const quote = useSelector((state) => selectors.selectNormalizedQuoteFormValuesById(state, { quote: params.quoteId }))
	const contact = useSelector((state) => selectors.selectContactById(state, opportunity.contacts[0])) //can improve later

	const validationSchema = Yup.object({
		name: Yup.string().required('Required'),
		unitsPerFoot: Yup.number().required('Required'),
		approved: Yup.bool().required('Required'),
		files: Yup.array().of(Yup.mixed()),
		runs: Yup.array()
			.of(
				Yup.object({
					type: Yup.string().required(),
					start: Yup.lazy((value) => {
						switch (typeof value) {
							case 'object':
								return Yup.object({
									label: Yup.string().required('Required'),
									value: Yup.string().required('Required')
								}).required('Required')
							case 'string':
							default:
								return Yup.string().required('Required')
						}
					}),
					end: Yup.lazy((value) => {
						switch (typeof value) {
							case 'object':
								return Yup.object({
									label: Yup.string().required('Required'),
									value: Yup.string().required('Required')
								}).required('Required')
							case 'string':
							default:
								return Yup.string().required('Required')
						}
					}),
					segments: Yup.array()
						.of(
							Yup.object({
								length: Yup.number().required('Required'),
								color: Yup.string().required('Required').default('Black'),
								images: Yup.array().of(Yup.mixed())
							})
						)
						.min(1)
				})
			)
			.min(1)
	})

	/* useEffect(() => {
		dispatch(entityThunks.fetchUsersByRole('sales'))
	}, []) */

	const addLocation = (option) => {
		const temp = locationOptions.slice()
		temp.push(option)
		setLocationOptions(temp)
	}

	const slugify = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, '')
			.replace(/[\s_-]+/g, '-')
			.replace(/^-+|-+$/g, '')

	const downloadPdf = () => {
		setDownloadLoading(true)
		callApi({
			endpoint: `quotes/pdf/${params.id}/${params.quoteId}`,
			headers: {
				'Content-Type': 'application/pdf'
			}
		})
			.then((result) => {
				const linkSource = `data:application/pdf;base64,${result.data}`
				const link = document.createElement('a')
				link.href = linkSource
				link.setAttribute(
					'download',
					`lumenox-quote-${slugify(contact.name.first + ' ' + contact.name.last)}${quote.quotationNumber ? `-${quote.quotationNumber}` : ''}.pdf`
				)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
			.then(() => {
				setDownloadLoading(false)
			})
	}

	return (
		<Formik
			initialStatus={null}
			validateOnChange={false}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={innerSubmit}
			innerRef={formRef}
		>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				useEffect(() => {
					if (valuesAtStart === null) {
						setValuesAtStart(values)
					}
					//console.log('setting dirty to:', !equal(values, valuesAtStart))
					setDirty(!equal(values, valuesAtStart))
				}, [values, valuesAtStart])
				return (
					<Form tag={FormikForm}>
						<FormGroup row className="align-items-xxxs-center">
							<Col xxxs={12} lg={5}>
								<Label>Location Name:</Label>
								<Input name="name" placeholder="House, garage, shed, etc" />
							</Col>
							<Col xxxs={12} lg>
								<Label>Units Per Foot:</Label>
								<Input type="text" name="unitsPerFoot" placeholder="1" className="text-xxxs-center" />
							</Col>
							<Col xxxs={6} lg>
								<Label>Pixel Rate</Label>
								<InputGroup>
									<InputGroupAddon>$</InputGroupAddon>
									<Input type="number" name="pixelsRate" />
								</InputGroup>
							</Col>
							<Col xxxs={6} lg>
								<Label>Discount</Label>
								<InputGroup>
									<Input type="number" name="discount" />
									<InputGroupAddon>%</InputGroupAddon>
								</InputGroup>
							</Col>
							<Col xxxs={3} lg>
								<Label>GST</Label>
								<InputGroup>
									<Input type="number" name="taxRate.GST" />
									<InputGroupAddon>%</InputGroupAddon>
								</InputGroup>
							</Col>
							<Col xxxs={3} lg>
								<Label>PST</Label>
								<InputGroup>
									<Input type="number" name="taxRate.HST" />
									<InputGroupAddon>%</InputGroupAddon>
								</InputGroup>
							</Col>
						</FormGroup>
						<Hr />
						<Row>
							<Col xxxs={12} lg={8}>
								<Row className="mb-xxxs-3">
									<Col xxxs>
										<LineItems values={values} />
									</Col>
								</Row>
								<Row className="mb-xxxs-3">
									<Col xxxs>
										<TermsAndConditions />
									</Col>
								</Row>
								<Row>
									<Col xxxs>
										<AdditionalNotes initialValue={values.additionalNotes} setFieldValue={setFieldValue} />
									</Col>
								</Row>
							</Col>
							<Col xxxs={12} lg={4}>
								<H4 className="mb-xxxs-3">Summary:</H4>
								<LineItemsSummarry quote={values} />
							</Col>
						</Row>
						<Hr />
						<Row className="mb-xxxs-3">
							<Col xxxs={12} lg={8}>
								<Runs locations={locationOptions} handleCreateLocation={addLocation} values={values} />
							</Col>
							<Col xxxs={12} lg={4}>
								<H4>Footage:</H4>
								<Summary values={values} />
								<Hr />
								<Files values={values} />
							</Col>
						</Row>
						<Hr />
						<div className="d-xxxs-flex justify-content-xxxs-end">
							{values.quotationNumber && (
								<React.Fragment>
									<ConditionalActionButton
										className="mr-xxxs-3"
										condition={!dirty}
										button="Preview"
										title="Preview PDF"
										icon={faFilePdf}
										color="info"
										confirmButton="Preview"
										confirmTitle="Unsaved Changes"
										description="There are unsaved changes that won't appear in the pdf, preview anyways?"
									>
										{(setIsOpen, isShown) => <PrivateQuotePDF setIsOpen={setIsOpen} />}
									</ConditionalActionButton>
									<ConditionalConfirmationButton
										className="mr-xxxs-3"
										handleConfirmation={downloadPdf}
										condition={dirty}
										color="info"
										to={`${url}/pdf`}
										icon={faFilePdf}
										submitting={downloadLoading}
										button="Download"
										description="There are unsaved changes that won't appear in the PDF. Download anyways?"
									/>
								</React.Fragment>
							)}
							{params.quoteId !== 'new' && (
								<ApproveButton className="mr-xxxs-3" name="approved" quoteId={params.quoteId} opportunityId={params.id} />
							)}
							<Button type="submit" color="primary" disabled={isSubmitting} submitting={isSubmitting} noBorders>
								Save Quote
							</Button>
						</div>

						<Row>
							<Col xxxs={12} lg={6}>
								{/* <ErrorAlert isSubmitting={isSubmitting} isValid={isValid}>
											<Strong>Alert!</Strong> Please fix the submission errors above.
										</ErrorAlert> */}
							</Col>
						</Row>

						{/* <FormConfirmatonModal isOpen={status !== null} title={status ? 'Success!' : 'Error!'} handleModalClose={() => setStatus(null)}>
									{status ? (
										<Fragment>
											<P>Your information has been received.</P>
											<P>We will be in contact with you shortly.</P>
										</Fragment>
									) : (
										<Fragment>
											<P>Something went wrong!</P>
											<P>There was an error submitting your request. Please try again.</P>
										</Fragment>
									)}
								</FormConfirmatonModal> */}
					</Form>
				)
			}}
		</Formik>
	)
}

const Files = ({ values }) => {
	const [fileModalIsOpen, setFileModalIsOpen] = useState(false)
	const [dropzoneFiles, setDropzoneFiles] = useState([])
	const [field, meta, { setValue, setTouched }] = useField(`files`)
	const { url, params } = useRouteMatch({
		path: '/leads/:id/quotes/:quoteId',
		strict: false
	})

	const handleOnChange = (data) => {
		setDropzoneFiles(data)
	}

	const handleOnSave = () => {
		const imgPromises = dropzoneFiles.map((file, i) => {
			console.log(file)
			if (file.type.startsWith('image')) {
				return loadImage(file, {
					canvas: true,
					orientation: true
				}).then((img) => {
					const { image } = img
					const smaller = document.createElement('canvas')
					const smallerCtx = smaller.getContext('2d')
					const multiplier = image.width > 2560 || image.height > 2560 ? (image.width > image.height ? 2560 / image.width : 2560 / image.height) : 1

					smaller.width = image.width * multiplier
					smaller.height = image.height * multiplier

					smallerCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * multiplier, image.height * multiplier)

					return new Promise((resolve) => smaller.toBlob(resolve, 'image/jpeg', 0.85))
				})
			} else {
				return new Promise((resolve) => resolve(new Blob([file], { type: file.type })))
			}
		})

		Promise.all(imgPromises).then((images) => {
			const files = images.map((blob, i) => {
				console.log(blob)
				const dzFile = dropzoneFiles[i]
				const newFile = new File([blob], dzFile.name, {
					lastModified: dzFile.lastModified,
					type: dzFile.type
				})

				return {
					url: newFile
				}
			})

			const value = field.value ? field.value.slice().concat(files) : files
			setFileModalIsOpen(false)
			setValue(value)
			setTouched(true)
		})
	}

	const handleOnClose = () => {
		setTouched(true)
		setDropzoneFiles([])
		setFileModalIsOpen(false)
	}

	const handleOnDelete = (index, closeModal) => {
		const images = field.value.slice()
		images.splice(index, 1)

		if (images.length === 0) {
			closeModal(false)
		}
		setValue(images)
	}

	return (
		<Fragment>
			<Row>
				<Col xxxs>
					<H4>Files:</H4>
				</Col>
				<Col xxxs="auto">
					<Button size="sm" color="dark" onClick={() => setFileModalIsOpen(true)} icon={faUpload}>
						Upload
					</Button>
				</Col>
			</Row>

			<FieldArray
				name="files"
				render={(arrayHelpers) => (
					<Fragment>
						<Table className="mb-xxxs-0" bordered size="sm">
							<Tbody>
								{values.files &&
									values.files.map((file, index) => {
										console.log(file)
										console.log(params)
										let src
										let type
										if (file.url instanceof File) {
											src = URL.createObjectURL(file.url)
											type = file.url.type.startsWith('image') ? 'image' : 'pdf'
										} else {
											src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/quotes/files/${file.url}`
											console.log(src)
											const ext = file.url.split('.').pop()
											type = ext.toLowerCase() === 'pdf' ? 'pdf' : 'image'
										}

										return (
											<Tr key={index}>
												<Td width="90%">{file.url instanceof File ? file.url.name : file.url}</Td>
												<Td>
													<ButtonGroup size="sm">
														<ActionButton color="info" modalSize="lg" icon={faSearch} title="View Document">
															{(setIsOpen, isShown) => {
																if (type === 'pdf') {
																	return <PdfViewer url={src} fileName={file.url.name ? file.url.name : file.url} />
																} else {
																	return <Img fluid src={src} />
																}
															}}
														</ActionButton>
														<Button color="danger" icon={faTrash} onClick={() => arrayHelpers.remove(index)} />
													</ButtonGroup>
												</Td>
											</Tr>
										)
									})}
							</Tbody>
						</Table>

						{fileModalIsOpen && (
							<DropzoneAreaModal
								isOpen={fileModalIsOpen}
								onClose={handleOnClose}
								onSave={handleOnSave}
								onChange={handleOnChange}
								/* acceptedFiles={['image/*']} */
								filesLimit={10}
								maxFileSize={1024 * 1024 * 10}
							/>
						)}
					</Fragment>
				)}
			/>
		</Fragment>
	)
}

const PdfViewer = ({ url, fileName }) => {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
	}

	return (
		<Fragment>
			<StyledDocument
				file={{
					url,
					httpHeaders: {
						'Access-Control-Allow-Origin': '*'
					}
				}}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<Page pageNumber={pageNumber} scale={3} />
			</StyledDocument>
			<Row className="justify-content-xxxs-end">
				<Col xxxs="auto">
					<Button color="info" tag="a" href={url} download={fileName} target="_blank">
						Download
					</Button>
				</Col>
			</Row>
		</Fragment>
	)
}

const StyledDocument = styled(({ children, className, ...rest }) => (
	<Document className={className} {...rest}>
		{children}
	</Document>
))`
	> div {
		> canvas,
		> div {
			width: 100% !important;
			height: auto !important;
		}
	}
`

export const LineItems = ({ values }) => {
	const { setFieldValue } = useFormikContext()

	const onDragEnd = ({ draggableId, source, destination }) => {
		const lineItemsCopy = [...values.lineItems]
		const lineItem = lineItemsCopy.splice(source.index, 1)[0]
		lineItemsCopy.splice(destination.index, 0, lineItem)
		setFieldValue('lineItems', lineItemsCopy)
	}

	return (
		<CollapsableCard
			openByDefault
			customHeader={(ToggleChevron) => (
				<Row className="align-items-xxxs-center">
					<Col xxxs>
						<H4 className="mb-xxxs-0">Line Items:</H4>
					</Col>
					<Col xxxs="auto">
						<ActionButton color="success" modalSize="lg" button="Add Line Item" icon={faPlus} title="Add Line Item">
							{(setIsOpen, isShown) => <LineItemForm setIsOpen={setIsOpen} index={values.lineItems ? values.lineItems.length : 0} />}
						</ActionButton>
					</Col>
					<Col xxxs="auto">
						<ToggleChevron />
					</Col>
				</Row>
			)}
		>
			<React.Fragment>
				<Table responsive>
					<Thead>
						<Tr>
							<Th>Name</Th>
							<Th>Type</Th>
							<Th>Rate</Th>
							<Th>Discount</Th>
							<Th>Quantity</Th>
							<Th className="text-xxxs-right">Total</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="lineItems">
							{(provided) => (
								<tbody {...provided.droppableProps} ref={provided.innerRef}>
									{values.lineItems &&
										values.lineItems.map((lineItem, i) => (
											<LineItemRow key={lineItem._id} quoteValues={values} values={lineItem} index={i} />
										))}
									{provided.placeholder}
								</tbody>
							)}
						</Droppable>
					</DragDropContext>
				</Table>
			</React.Fragment>
		</CollapsableCard>
	)
}

const LineItemsSummarry = ({ quote }) => {
	const roundTo2 = (number) => Math.round((number + Number.EPSILON) * 100) / 100

	const totalTaxRate = quote.taxRate ? (quote.taxRate.GST ? quote.taxRate.GST : 0) + (quote.taxRate.HST ? quote.taxRate.HST : 0) : 0
	const { subTotal, discount, discountFromSalesCommission } = quote.lineItems.reduce(
		({ subTotal, discount, discountFromSalesCommission }, lineItem) => {
			if (lineItem.type === 'Sales Discount') {
				return lineItem.takenFromCommission
					? { subTotal, discount, discountFromSalesCommission: discountFromSalesCommission - lineItem.quantity * lineItem.rate }
					: { subTotal, discount: discount - lineItem.quantity * lineItem.rate, discountFromSalesCommission } // - since rate is -ve
			}
			const qty =
				lineItem.type === 'Pixels'
					? Math.floor(lineItem.quantity) + (lineItem.quantity % 1 !== 0 ? (lineItem.quantity % 1 > 0.5 ? 1 : 0.5) : 0)
					: lineItem.quantity
			const itemTotal = roundTo2(qty * lineItem.rate)
			const st = subTotal + itemTotal
			const d = discount + (lineItem.discount > 0 ? roundTo2((itemTotal * lineItem.discount) / 100) : 0)
			return { subTotal: st, discount: d, discountFromSalesCommission }
		},
		{ subTotal: 0, discount: 0, discountFromSalesCommission: 0 }
	)
	const discountTotal = discount + discountFromSalesCommission
	const taxTotal = roundTo2(((subTotal - discountTotal) * totalTaxRate) / 100)
	const overallTotal = roundTo2(subTotal - discountTotal + taxTotal)

	return (
		<Table bordered>
			<Tbody>
				<Tr>
					<Td>Sub Total</Td>
					<Td className="text-xxxs-right">
						<FormatCurrency amount={subTotal} decimalPlaces={2} />
					</Td>
				</Tr>
				<Tr>
					<Td>Discount</Td>
					<Td className="text-xxxs-right">
						(<FormatCurrency amount={discount} decimalPlaces={2} />)
					</Td>
				</Tr>
				{discountFromSalesCommission !== 0 && (
					<Tr>
						<Td>Discount From Sales Commission</Td>
						<Td className="text-xxxs-right">
							(<FormatCurrency amount={discountFromSalesCommission} decimalPlaces={2} />)
						</Td>
					</Tr>
				)}
				<Tr>
					<Td>Subtotal After Discount</Td>
					<Td className="text-xxxs-right">
						<FormatCurrency amount={subTotal - discountTotal} decimalPlaces={2} />
					</Td>
				</Tr>
				<Tr>
					<Td>Tax</Td>
					<Td className="text-xxxs-right">
						<FormatCurrency amount={taxTotal} decimalPlaces={2} />
					</Td>
				</Tr>
				<Tr>
					<Th>Total (CAD)</Th>
					<Th className="text-xxxs-right">
						<FormatCurrency amount={overallTotal} decimalPlaces={2} />
					</Th>
				</Tr>
			</Tbody>
		</Table>
	)
}

const LineItemForm = ({ currentValues, index, setIsOpen }) => {
	const { values: quoteValues, setFieldValue: setQuoteFieldValue } = useFormikContext()
	const nameRef = useRef(null)
	const rateRef = useRef(null)

	useEffect(() => {
		if (nameRef && nameRef.current) {
			nameRef.current.focus()
		}
	}, [nameRef])

	const onSubmit = (v) => {
		if (!v._id) v._id = uuid()
		if (!quoteValues.lineItems) {
			setQuoteFieldValue('lineItems', [v])
		} else if (index < quoteValues.lineItems.length) {
			const lineItemsCopy = [...quoteValues.lineItems]
			lineItemsCopy[index] = v
			setQuoteFieldValue('lineItems', lineItemsCopy)
		} else {
			setQuoteFieldValue('lineItems', [...quoteValues.lineItems, v])
		}
		setIsOpen(false)
	}

	const onChangeType = (option, values, setFieldValue) => {
		setFieldValue('type', option.value)
		if (option.value === 'Pixels') {
			if ((values.rate === 0 || values.rate === '') && quoteValues.pixelsRate) setFieldValue('rate', quoteValues.pixelsRate)
			setFieldValue('quantity', 0)
			if (quoteValues.discount) setFieldValue('discount', quoteValues.discount)
		} else if (option.value === 'Control Box') {
			if (values.rate === 0 || values.rate === '') setFieldValue('rate', 199)
			if (values.name === '') setFieldValue('name', 'Control Box')
			if (quoteValues.lineItems && quoteValues.lineItems.filter((li) => li.type === 'Control Box').length === 1) {
				setFieldValue('discount', 100)
				setFieldValue('description', 'Second control box included at no cost')
			}
		} else if (option.value === 'Sales Discount') {
			if (values.name === '') setFieldValue('name', 'Sales Discount')
			setFieldValue('quantity', 1)
			setFieldValue('takenFromCommission', true)
			if (rateRef && rateRef.current) {
				rateRef.current.focus()
			}
		}
	}

	const defaultValues = {
		type: '',
		name: '',
		rate: '',
		discount: 0,
		quantity: 1,
		description: '',
		images: [],
		takenFromCommission: null
	}

	const initialValues = {
		...defaultValues,
		...currentValues
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={null} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				return (
					<Form tag={FormikForm}>
						<FormGroup row>
							<Col xxxs>
								<Label>Name:</Label>
								<Input name="name" type="text" autoFocus ref={nameRef} />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs={6}>
								<Label>Type:</Label>
								<Select
									name={'type'}
									creatable
									options={[
										{ label: 'Pixels', value: 'Pixels' },
										{ label: 'Control Box', value: 'Control Box' },
										{ label: 'Specialty Channel', value: 'Specialty Channel' },
										{ label: 'Sales Discount', value: 'Sales Discount' },
										{ label: 'Other', value: 'Other' }
									]}
									onChange={(option) => onChangeType(option, values, setFieldValue)}
								/>
							</Col>
							<Col xxxs>
								<Label>Rate:</Label>
								<Input name="rate" type="number" ref={rateRef} />
							</Col>
							<Col xxxs>
								<Label>Discount %</Label>
								<Input name="discount" type="number" disabled={values.type === 'Sales Discount'} />
							</Col>
							<Col xxxs>
								<Label>Quantity:</Label>
								<Input name="quantity" type="number" disabled={values.type === 'Pixels' || values.type === 'Sales Discount'} />
							</Col>
							<Col xxxs>
								<Label>Total</Label>
								<Input name="total" disabled value={values.rate * values.quantity * (1 - values.discount / 100)} />
							</Col>
						</FormGroup>
						{values.type === 'Sales Discount' && (
							<FormGroup row className="align-items-xxxs-center">
								<Col xxxs="auto" className="pr-xxxs-0">
									<Label>Taken From Commission:</Label>
								</Col>
								<Col xxxs="auto">
									<Checkbox name="takenFromCommission" />
								</Col>
							</FormGroup>
						)}
						<FormGroup row className="align-items-xxxs-center">
							<Col xxxs>
								<Label>Description:</Label>
								<Textarea name="description" />
							</Col>
							<Col xxxs="auto">
								<Label className="mr-xxxs-3">Images:</Label>
								<ImageInput name="images" index={index} />
							</Col>
						</FormGroup>
						<FormGroup row className="justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit" color="success">
									Save
								</Button>
							</Col>
						</FormGroup>
					</Form>
				)
			}}
		</Formik>
	)
}

const LineItemRow = ({ values, index }) => {
	const { values: quoteValues, setFieldValue: setQuoteFieldValue } = useFormikContext()

	const deleteLineItem = () => {
		setQuoteFieldValue(
			'lineItems',
			quoteValues.lineItems.filter((li) => li._id != values._id)
		)
	}

	const qty =
		values.type === 'Pixels' ? Math.floor(values.quantity) + (values.quantity % 1 !== 0 ? (values.quantity % 1 > 0.5 ? 1 : 0.5) : 0) : values.quantity
	const taxlessTotal = qty * values.rate * (1 - values.discount / 100)

	return (
		<React.Fragment>
			<Draggable draggableId={values._id} index={index}>
				{(draggableProvided, snapshot) => {
					if (snapshot.isDragging) {
						//idk why but without this it offsets while dragging
						draggableProvided.draggableProps.style.left = undefined
						draggableProvided.draggableProps.style.right = undefined
						draggableProvided.draggableProps.style.top = undefined
						draggableProvided.draggableProps.style.bottom = undefined
					}
					return (
						<tr {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps} ref={draggableProvided.innerRef}>
							<Td>
								{values.name}
								{values.description && (
									<React.Fragment>
										<br />
										<Small>{values.description}</Small>
									</React.Fragment>
								)}
							</Td>
							<Td style={{ verticalAlign: 'top' }}>{values.type}</Td>
							<Td style={{ verticalAlign: 'top' }}>${typeof values.rate === 'Number' ? values.rate.toFixed(2) : values.rate}</Td>
							<Td style={{ verticalAlign: 'top' }}>{values.discount}%</Td>
							<Td style={{ verticalAlign: 'top' }}>{qty}</Td>
							<Td style={{ verticalAlign: 'top' }} className="text-xxxs-right">
								<FormatCurrency amount={taxlessTotal} decimalPlaces={2} />
							</Td>
							<Td>
								<ButtonGroup size="sm">
									<ActionButton color="info" modalSize="lg" icon={faEdit} title="Edit Line Item">
										{(setIsOpen, isShown) => (
											<LineItemForm quoteValues={quoteValues} currentValues={values} setIsOpen={setIsOpen} index={index} />
										)}
									</ActionButton>
									<ConfirmationButton
										icon={faTrash}
										handleConfirmation={deleteLineItem}
										description="Are you sure you want to delete this line item?"
									/>
									{/*Shouldn't need this check but better safe than sorry*/}
									{values.images && values.images.length > 0 && <ImageButton images={values.images} index={index} />}
								</ButtonGroup>
							</Td>
						</tr>
					)
				}}
			</Draggable>
		</React.Fragment>
	)
}

const TermsAndConditions = () => {
	const { values, setFieldValue } = useFormikContext()
	const { selectors, dispatch, entityThunks } = useLumenox()
	const defaultTerms = useSelector((state) => selectors.selectDefaultTermsSorted(state))

	useEffect(() => {
		dispatch(entityThunks.fetchTerms({ includeArchived: false })).then((response) => {
			// nothing
		})
	}, [])

	useEffect(() => {
		if (!values.terms || values.terms.length === 0) {
			setFieldValue(
				'terms',
				defaultTerms.map((term) => term.id)
			)
		}
	}, [defaultTerms])

	return (
		<CollapsableCard
			customHeader={(ToggleChevron) => (
				<Row className="align-items-xxxs-center">
					<Col xxxs>
						<H5 className="mb-xxxs-0">Terms and Conditions</H5>
					</Col>
					<Col xxxs="auto">
						<ActionButton button="Select Terms" title="Select Terms">
							{(setIsOpen) => <ChooseTermForm setIsOpen={setIsOpen} />}
						</ActionButton>
					</Col>
					<Col xxxs="auto">
						<ToggleChevron />
					</Col>
				</Row>
			)}
		>
			<React.Fragment>
				<Row>
					<Col xxxs>
						<Ol type="numbered">
							{values.terms.map((term) => (
								<Li key={term}>
									<Term id={term} />
								</Li>
							))}
						</Ol>
					</Col>
				</Row>
			</React.Fragment>
		</CollapsableCard>
	)
}

const ChooseTermForm = ({ setIsOpen }) => {
	const { selectors } = useLumenox()
	const unarchivedTerms = useSelector((state) => selectors.selectUnarchivedTerms(state))
	const { values, setFieldValue } = useFormikContext()
	const [selectedTerms, setSelectedTerms] = useState([])
	const [availableTerms, setAvailableTerms] = useState([])

	useEffect(() => {
		if (selectedTerms.length === 0 && availableTerms.length === 0) {
			// initially set selected terms to whatever's selected on the quote
			const newSelectedTerms = []
			const newAvailableTerms = []
			// do this first to preserve the order
			values.terms.forEach((term) => {
				newSelectedTerms.push(term)
			})
			unarchivedTerms.forEach((term) => {
				if (!newSelectedTerms.includes(term.id)) {
					newAvailableTerms.push(term.id)
				}
			})
			setSelectedTerms(newSelectedTerms)
			setAvailableTerms(newAvailableTerms)
		} else {
			// if a new term is added, put it in selected terms
			const newSelectedTerms = [...selectedTerms]
			//could probably just check the last term for this but this is safer
			unarchivedTerms.forEach((term) => {
				if (!selectedTerms.includes(term.id) && !availableTerms.includes(term.id)) {
					newSelectedTerms.push(term.id)
				}
			})
			setSelectedTerms(newSelectedTerms)
		}
	}, [unarchivedTerms])

	const save = () => {
		setFieldValue('terms', selectedTerms)
		setIsOpen(false)
	}

	const onDragEnd = ({ draggableId, source, destination }) => {
		if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) return
		const newAvailableTerms = [...availableTerms]
		const newSelectedTerms = [...selectedTerms]
		//cut it from source
		if (source.droppableId === 'available') {
			newAvailableTerms.splice(source.index, 1)
		} else {
			newSelectedTerms.splice(source.index, 1)
		}
		//add it to destination
		if (destination.droppableId === 'available') {
			newAvailableTerms.splice(destination.index, 0, draggableId)
		} else {
			newSelectedTerms.splice(destination.index, 0, draggableId)
		}
		//set changes
		setAvailableTerms(newAvailableTerms)
		setSelectedTerms(newSelectedTerms)
	}

	return (
		<React.Fragment>
			<DragDropContext onDragEnd={onDragEnd}>
				<Row>
					<Col xxxs>
						<Droppable droppableId="available">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									<H5>Available Terms</H5>
									<Ul>
										{availableTerms.map((term, i) => (
											<Draggable key={term} draggableId={term} index={i}>
												{(draggableProvided, snapshot) => {
													if (snapshot.isDragging) {
														//idk why but without this it offsets while dragging
														draggableProvided.draggableProps.style.left = undefined
														draggableProvided.draggableProps.style.right = undefined
														draggableProvided.draggableProps.style.top = undefined
														draggableProvided.draggableProps.style.bottom = undefined
													}
													return (
														<div
															{...draggableProvided.draggableProps}
															{
																...draggableProvided.dragHandleProps /*Can move this to icon to use icon to drag*/
															}
															ref={draggableProvided.innerRef}
														>
															<Li key={term}>
																<Term id={term} />
															</Li>
														</div>
													)
												}}
											</Draggable>
										))}
										{provided.placeholder}
									</Ul>
								</div>
							)}
						</Droppable>
					</Col>
					<Col xxxs>
						<Droppable droppableId="selected">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									<H5>Selected Terms</H5>
									<Ol>
										{selectedTerms.map((term, i) => (
											<Draggable key={term} draggableId={term} index={i}>
												{(draggableProvided, snapshot) => {
													if (snapshot.isDragging) {
														//idk why but without this it offsets while dragging
														draggableProvided.draggableProps.style.left = undefined
														draggableProvided.draggableProps.style.right = undefined
														draggableProvided.draggableProps.style.top = undefined
														draggableProvided.draggableProps.style.bottom = undefined
													}
													return (
														<div
															{...draggableProvided.draggableProps}
															{...draggableProvided.dragHandleProps}
															ref={draggableProvided.innerRef}
														>
															<Li key={term}>
																<Term id={term} />
															</Li>
														</div>
													)
												}}
											</Draggable>
										))}
										{provided.placeholder}
									</Ol>
								</div>
							)}
						</Droppable>
					</Col>
				</Row>
			</DragDropContext>
			<Hr />
			<Row className="justify-content-xxxs-end">
				<Col xxxs>
					<ActionButton button="Add New Term" color="success">
						{(setIsOpen) => <TermForm setIsOpen={setIsOpen} />}
					</ActionButton>
				</Col>
				<Col xxxs="auto">
					<Button onClick={save}>Save</Button>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const AdditionalNotes = ({ initialValue, setFieldValue }) => {
	const contentState = convertFromRaw(markdownToDraft(initialValue))
	const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

	const toolbarOptions = {
		options: ['inline', 'blockType', 'list', 'history']
	}

	const handleEditorChange = (state) => {
		const markdown = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
		console.log('md', markdown)
		setFieldValue('additionalNotes', markdown)
		setEditorState(state)
	}

	return (
		<CollapsableCard title="Additional Notes">
			<Row className="mb-xxxs-3">
				<Col xxxs>
					<NoteWrapper>
						<Editor editorState={editorState} onEditorStateChange={handleEditorChange} toolbar={toolbarOptions} />
					</NoteWrapper>
				</Col>
			</Row>
		</CollapsableCard>
	)
}

const NoteWrapper = styled.div`
	.additional-notes-form {
		height: 8rem;
	}
`

const ApproveButton = ({ className, name, quoteId, opportunityId }) => {
	const { selectors } = useLumenox()
	const [field, meta, { setValue, setTouched }] = useField(name)
	const quotes = useSelector((state) => selectors.selectQuotesByOpportunity(state, opportunityId))
	const cantApprove = quotes.some((quote) => quote.id !== quoteId && quote.approved)
	return field.value ? (
		<Button className={className} color="danger" onClick={() => setValue(false)}>
			Unapprove Quote
		</Button>
	) : (
		<Button className={className} color="success" onClick={() => setValue(true)} disabled={cantApprove}>
			Approve Quote
		</Button>
	)
}

const calculateRunLength = (run, unitsPerFoot) => {
	const { segments } = run
	let sum = 0

	if (segments && segments.length > 0) {
		segments.forEach((segment) => {
			sum += segment.length ? parseFloat(segment.length) / unitsPerFoot : 0
		})
	}
	return sum
}

const Summary = ({ values }) => {
	const { unitsPerFoot, runs } = values
	const [totals, setTotals] = useState(0)
	const { selectors } = useLumenox()
	const runTypes = useSelector((state) => selectors.selectAllRunTypes(state))

	const calculateTotalFeet = () => {
		const totals = runTypes.slice().reduce((obj, runType) => {
			obj[runType.id] = { ...runType, totalFeet: 0, totalCost: 0 }
			return obj
		}, {})

		if (unitsPerFoot && runs.length > 0) {
			runs.forEach((run) => {
				if (run.type && run.segments.length > 0) {
					totals[run.type.value].totalFeet += calculateRunLength(run, unitsPerFoot)
				}
			})

			Object.entries(totals).forEach(([key, runType]) => {
				if (runType.unitCost) {
					runType.totalCost = runType.totalFeet * runType.unitCost
				}
			})
		}

		setTotals(totals)
	}

	useEffect(() => {
		calculateTotalFeet()
	}, [values])

	return (
		<Fragment>
			<Table size="sm" bordered>
				{Object.entries(totals).map(([id, runType]) => {
					if (runType.totalFeet === 0) {
						return null
					}
					return (
						<Fragment key={id}>
							<Tbody>
								<Tr>
									<Th>{runType.name}</Th>
									<Td className="text-xxxs-center">{round(runType.totalFeet, 2)}ft</Td>
								</Tr>
								{runType.slug === 'pixel-string' && (
									<Fragment>
										<Tr>
											<Th colSpan="2" className="text-xxxs-center">
												Channel Pieces
											</Th>
										</Tr>
										<Tr>
											<Th>9.5 ft</Th>
											<Td>{Math.ceil((runType.totalFeet * 1.15) / 9.5)} pcs</Td>
										</Tr>
										<Tr>
											<Th>5 ft</Th>
											<Td>{Math.ceil((runType.totalFeet * 1.15) / 5)} pcs</Td>
										</Tr>
									</Fragment>
								)}
							</Tbody>
						</Fragment>
					)
				})}
			</Table>
		</Fragment>
	)
}

const Runs = ({ mode, values, ...rest }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const runTypes = useSelector((state) => selectors.selectAllRunTypes(state))
	const [jbNames, setJbNames] = useState(
		values.runs
			? values.runs.reduce((arr, run) => {
					if (run.start && run.start.label && run.start.label.startsWith('Junction Box') && !arr.includes(run.start.name)) {
						arr.push(run.start.label)
					}
					return arr
			  }, [])
			: []
	)
	const [rearranging, setRearranging] = useState(false)
	const { setFieldValue } = useFormikContext()
	const [field, meta] = useField(`runs`)
	const { url, params } = useRouteMatch({
		path: '/leads/:id/quotes/:quoteId',
		strict: false
	})
	const [isOpen, setIsOpen] = useState(values.runs.map((run, i) => meta.initialValue[i] === undefined || params.quoteId === 'new'))

	const runTypeOptions = runTypes.map((runType) => {
		return {
			label: runType.name,
			slug: runType.slug,
			value: runType.id
		}
	})

	const initialValues = {
		type: '' /* runTypeOptions.find((option) => option.slug === 'pixel-string') */,
		start: '',
		end: '',
		segments: [
			{
				length: '',
				images: [],
				color: 'Black'
			}
		],
		lineItem: ''
	}

	useEffect(() => {
		dispatch(entityThunks.fetchRunTypes())
	}, [])

	const toggleRearranging = () => {
		setRearranging(!rearranging)
	}

	const onRunAdded = (i) => {
		const isOpenCopy = [...isOpen]
		isOpenCopy.splice(i, 0, true)
		setIsOpen(isOpenCopy)
	}

	const onRunRemoved = (i) => {
		const isOpenCopy = [...isOpen]
		isOpenCopy.splice(i, 1)
		setIsOpen(isOpenCopy)
	}

	const setRunOpen = (i, open) => {
		const isOpenCopy = [...isOpen]
		isOpenCopy[i] = open
		setIsOpen(isOpenCopy)
	}

	const collapseOrExpandAll = () => {
		const open = shouldExpandAll()
		setIsOpen(isOpen.map(() => open))
	}

	const onDragEnd = ({ draggableId, source, destination }) => {
		if (source.index === destination.index) return
		const runsCopy = [...values.runs]
		const run = runsCopy.splice(source.index, 1)[0]
		runsCopy.splice(destination.index, 0, run)
		setFieldValue('runs', runsCopy)
		//shift isOpen the same way
		const isOpenCopy = [...isOpen]
		const open = isOpenCopy.splice(source.index, 1)[0]
		isOpenCopy.splice(destination.index, 0, open)
		setIsOpen(isOpenCopy)
	}

	const shouldExpandAll = () => {
		for (const open of isOpen) {
			if (open) return false
		}
		return true
	}

	return (
		<React.Fragment>
			<Row className="mb-xxxs-3 align-items-xxxs-center">
				<Col xxxs>
					<H4>Runs:</H4>
				</Col>
				<Col xxxs="auto">
					<Button onClick={collapseOrExpandAll} color="info">
						{shouldExpandAll() ? 'Expand ' : 'Collapse '}All
					</Button>
				</Col>
				<Col xxxs="auto">
					<Button onClick={toggleRearranging} color="info">
						{rearranging ? 'End rearranging' : 'Rearrange'}
					</Button>
				</Col>
			</Row>
			<Row>
				<Col xxxs>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="runs">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									<MultipleInputArray
										name="runs"
										initialValues={initialValues}
										{...rest}
										values={values.runs}
										customButtons
										confirmRemoval
										onAdd={onRunAdded}
										onRemove={onRunRemoved}
										removalDescription="Are you sure you want to remove this run?"
									>
										{(inputProps) => {
											//might want different draggableId but also this could be fine
											return (
												<Draggable
													key={inputProps.i}
													draggableId={'' + inputProps.i}
													index={inputProps.i}
													isDragDisabled={!rearranging}
												>
													{(draggableProvided, snapshot) => {
														if (snapshot.isDragging) {
															//idk why but without this it offsets while dragging
															draggableProvided.draggableProps.style.left = undefined
															draggableProvided.draggableProps.style.right = undefined
															draggableProvided.draggableProps.style.top = undefined
															draggableProvided.draggableProps.style.bottom = undefined
														}
														return (
															<div
																{...draggableProvided.draggableProps}
																{
																	...draggableProvided.dragHandleProps /*Can move this to icon to use icon to drag*/
																}
																ref={draggableProvided.innerRef}
															>
																<Run
																	{...inputProps}
																	{...rest}
																	values={values}
																	jbNames={jbNames}
																	setJbNames={setJbNames}
																	rearranging={rearranging}
																	isOpen={isOpen[inputProps.i]}
																	setIsOpen={(open) => setRunOpen(inputProps.i, open)}
																/>
															</div>
														)
													}}
												</Draggable>
											)
										}}
									</MultipleInputArray>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const Run = ({ i, values, locations, handleCreateLocation, renderButtons, jbNames, setJbNames, rearranging, isOpen, setIsOpen, ...rest }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { setFieldValue } = useFormikContext()
	const { url, params } = useRouteMatch({
		path: '/leads/:id/quotes/:quoteId',
		strict: false
	})
	const [field, meta] = useField(`runs.${i}`)
	const locationOptions = locations.slice().reverse()
	const runValues = values.runs[i]
	const runTypeEntities = useSelector((state) => selectors.selectRunTypeEntities(state))
	const runTypes = useSelector((state) => selectors.selectAllRunTypes(state))

	useEffect(() => {
		if (!values.lineItems) return
		updatePixelQuantities(values, setFieldValue, runTypes)
	}, [runValues.type, runValues.lineItem, runValues.segments])

	useEffect(() => {
		// actually kinda useless cuz they don't use Junction Box name when quoting :(
		if (runValues.start && runValues.start.label && runValues.start.label.startsWith('Junction Box')) {
			if (!jbNames.includes(runValues.start.label)) {
				//new junction box
				setJbNames([...jbNames, runValues.start.label])
				const lineItem = {
					_id: uuid(),
					type: 'Control Box',
					name: 'Control Box',
					rate: '225',
					discount: 0,
					quantity: 1,
					description: '',
					images: []
				}

				if (!values.lineItems) {
					lineItem.discount = 100
					lineItem.description = 'First control box included at no cost'
					setFieldValue('lineItems', [lineItem])
				} else {
					if (!values.lineItems.some((li) => li.type === 'Control Box')) {
						lineItem.discount = 100
						lineItem.description = 'First control box included at no cost'
					}
					setFieldValue('lineItems', [...values.lineItems, lineItem])
				}
			}
		}
	}, [runValues.start.label])

	const updatePixelQuantities = (quoteValues, setFieldValue, runTypes) => {
		if (!quoteValues.lineItems) return
		quoteValues.lineItems.forEach((lineItem, i) => {
			if (lineItem.type === 'Pixels') {
				const pixelRunType = runTypes.find((rt) => rt.slug === 'pixel-string')
				if (!pixelRunType) return //should never happen but just in case
				let quantity = 0
				quoteValues.runs.forEach((run) => {
					if (run.lineItem === lineItem._id && run.type.value === pixelRunType.id) quantity += calculateRunLength(run, quoteValues.unitsPerFoot)
				})
				setFieldValue(`lineItems.${i}.quantity`, quantity)
			}
		})
	}

	const runTypeOptions = runTypes.map((runType) => {
		return {
			label: runType.name,
			slug: runType.slug,
			value: runType.id
		}
	})

	const lineItemOptions = values.lineItems
		? values.lineItems.filter((lineItem) => lineItem.type === 'Pixels').map((li) => ({ label: li.name, value: li._id }))
		: []

	const onTypeChanged = (option, action) => {
		setFieldValue(`runs.${i}.type`, { value: option.value })
		if (option.slug === 'pixel-string' && lineItemOptions.length === 1) {
			setFieldValue(`runs.${i}.lineItem`, lineItemOptions[0].value)
		}
	}

	const totalLength = runValues ? calculateRunLength(runValues, values.unitsPerFoot) : 0
	const selectedLineItem = values.lineItems.find((li) => li._id === runValues.lineItem)
	const pixelsRate = runValues.type?.slug === 'pixel-string' && selectedLineItem ? selectedLineItem.rate * (1 - selectedLineItem.discount / 100) : 0
	const totalCost = totalLength * pixelsRate

	return (
		<Card key={i} className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs={12} lg className="mb-xxxs-3 mb-lg-0">
						<Row className="align-items-xxxs-center">
							<Col xxxs="auto">
								<FontAwesomeIcon
									icon={isOpen ? faChevronUp : faChevronDown}
									fixedWidth
									onClick={() => setIsOpen(!isOpen)}
									className="pointer"
									size="lg"
								/>
								#{i + 1}
							</Col>
							{runValues && runValues.start && runValues.end && (
								<Col xxxs>
									<Small>
										{runValues.start.label}
										<FontAwesomeIcon icon={faArrowRight} fixedWidth className="mx-xxxs-2" />
										{runValues.end.label}
									</Small>
									<H5 className="mb-xxxs-0">
										<Badge color="primary">{round(totalLength, 2)}ft</Badge>
										{totalCost !== 0 && (
											<React.Fragment>
												{' - '}
												<Badge color="info">${round(totalCost, 2)}</Badge>
											</React.Fragment>
										)}
									</H5>
								</Col>
							)}
						</Row>
					</Col>
					<Col xxxs lg={3}>
						<Select
							name={`runs.${i}.type.value`}
							options={runTypeOptions}
							placeholder="Run Type"
							defaultValue={runTypeOptions.find((option) => option.slug === 'pixel-string')}
							onChange={onTypeChanged}
						/>
					</Col>

					{runValues && runValues.type && runTypeEntities[runValues.type.value].slug === 'pixel-string' && (
						<Col xxxs lg={3} className="mt-xxxs-3 mt-lg-0">
							<Select name={`runs.${i}.lineItem`} options={lineItemOptions} placeholder="Line Item" />
						</Col>
					)}

					<Col xxxs="auto">{renderButtons(i)}</Col>
				</Row>
			</CardHeader>

			<Collapse isOpen={isOpen && !rearranging}>
				<CardBlock>
					<FormGroup row>
						<Col xxxs={12} lg={6} className="mb-xxxs-3 mb-lg-0">
							<LocationSelect
								name={`runs.${i}.start`}
								options={locationOptions}
								onCreateOption={handleCreateLocation}
								placeholder="Starting Location"
							/>
						</Col>
						<Col xxxs={12} lg={6}>
							<LocationSelect
								name={`runs.${i}.end`}
								options={locationOptions}
								onCreateOption={handleCreateLocation}
								placeholder="Ending Location"
							/>
						</Col>
					</FormGroup>
					<FormGroup>
						<Label>Segments:</Label>
						<Segments runIndex={i} values={values.runs.length > 0 && values.runs[i].segments} />
					</FormGroup>
				</CardBlock>
			</Collapse>
		</Card>
	)
}

const Segments = ({ runIndex, values, ...rest }) => {
	const initialValues = {
		length: '',
		color: 'Black',
		images: []
	}

	return (
		<Table size="sm">
			<Thead>
				<Tr>
					<Th>#</Th>
					<Th width="30%">Length</Th>
					<Th width="30%">Color</Th>
					<Th width="30%">Images</Th>
					<Th></Th>
				</Tr>
			</Thead>
			<Tbody>
				<MultipleInputArray name={`runs.${runIndex}.segments`} initialValues={initialValues} values={values} {...rest} customButtons>
					{(segmentProps) => (
						<Segment
							key={segmentProps.i}
							{...segmentProps}
							values={values[segmentProps.i]}
							runIndex={runIndex}
							name={`runs.${runIndex}.segments`}
						/>
					)}
				</MultipleInputArray>
			</Tbody>
		</Table>
	)
}

const Segment = ({ i, name, setElRef, values, renderButtons, ...rest }) => {
	const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
	const [dropzoneFiles, setDropzoneFiles] = useState([])
	const [field, meta, { setValue, setTouched }] = useField(`${name}.${i}.images`)
	const colorOptions = [
		{ label: 'Black', value: 'Black' },
		{ label: 'White', value: 'White' }
	]

	const handleOnChange = (data) => {
		setDropzoneFiles(data)
	}

	const handleOnSave = () => {
		const imgPromises = dropzoneFiles.map((file, i) => {
			return loadImage(file, {
				canvas: true,
				orientation: true
			}).then((img) => {
				const { image } = img
				const smaller = document.createElement('canvas')
				const smallerCtx = smaller.getContext('2d')
				const multiplier = image.width > 2560 || image.height > 2560 ? (image.width > image.height ? 2560 / image.width : 2560 / image.height) : 1

				smaller.width = image.width * multiplier
				smaller.height = image.height * multiplier

				smallerCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * multiplier, image.height * multiplier)

				return new Promise((resolve) => smaller.toBlob(resolve, 'image/jpeg', 0.85))
			})
		})

		Promise.all(imgPromises).then((images) => {
			const files = images.map((blob, i) => {
				console.log(blob)
				const dzFile = dropzoneFiles[i]
				const newFile = new File([blob], dzFile.name, {
					lastModified: dzFile.lastModified,
					type: dzFile.type
				})

				return {
					url: newFile
				}
			})

			const value = field.value ? field.value.slice().concat(files) : files
			setImageModalIsOpen(false)
			setValue(value)
			setTouched(true)
		})
	}

	const handleOnClose = () => {
		setTouched(true)
		setDropzoneFiles([])
		setImageModalIsOpen(false)
	}

	const handleOnDelete = (index, closeModal) => {
		const images = field.value.slice()
		images.splice(index, 1)

		if (images.length === 0) {
			closeModal(false)
		}
		setValue(images)
	}

	return (
		<Tr>
			<Td>{i + 1}</Td>
			<Td>
				<Input name={`${name}.${i}.length`} type="text" inputMode="decimal" pattern="[0-9.]*" autoFocus placeholder="" />
			</Td>
			<Td>
				<Select name={`${name}.${i}.color`} options={colorOptions} />
			</Td>
			<Td>
				<ButtonGroup size="sm">
					<Button size="sm" color="dark" onClick={() => setImageModalIsOpen(true)} icon={faUpload} />
					{field.value && field.value.length > 0 && (
						<ActionButton color="info" title={`View Image${field.value && field.value.length > 1 ? 's' : ''}`} icon={faSearch} modalSize="xl">
							{(closeModal, isShown) => (
								<Fragment>
									{field.value.map((image, j) => (
										<SegmentImage
											key={j}
											handleDelete={() => handleOnDelete(j, closeModal)}
											image={image}
											index={j}
											name={`${name}.${i}.images.${j}`}
										/>
									))}
									<Row className="justify-content-xxxs-end">
										<Col xxxs="auto">
											<Button color="dark" onClick={() => closeModal(false)}>
												Close
											</Button>
										</Col>
									</Row>
								</Fragment>
							)}
						</ActionButton>
					)}
				</ButtonGroup>

				{imageModalIsOpen && (
					<DropzoneAreaModal
						isOpen={imageModalIsOpen}
						onClose={handleOnClose}
						onSave={handleOnSave}
						onChange={handleOnChange}
						acceptedFiles={['image/*']}
						filesLimit={10}
						maxFileSize={1024 * 1024 * 10}
					/>
				)}
			</Td>
			<Td>{renderButtons(i, 'sm')}</Td>
		</Tr>
	)
}

const SegmentImage = ({ image, handleDelete, index, name }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [field, meta, { setValue, setTouched }] = useField(name)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()
	const editing = useRef(false)

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (field.value.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [PenMarker, StartMarker, EndMarker, markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setValue({
					...field.value,
					annotation: state
				})
				setIsEditing(false)
				editing.current = false
				setShouldRender(true)
			})

			markerArea.current.addCloseEventListener(() => {
				if (editing.current) {
					editing.current = false
					setIsEditing(false)
					setShouldRender(true)
				}
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		if (field.value.annotation) {
			markerArea.current.restoreState(field.value.annotation)
		}

		setTimeout(() => {
			markerArea.current.render().then((dataUrl) => {
				imageRef.current.src = dataUrl
				markerArea.current.close()
			})
		}, 500)
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/quotes/${image.url}?c=${new Date()}`
	}

	const showMarkerArea = () => {
		setIsEditing(true)
		editing.current = true
		if (markerArea.current) {
			imageRef.current.src = src

			markerArea.current.show()
			if (field.value.annotation) {
				markerArea.current.restoreState(field.value.annotation)
			}
			const bounds = imageRef.current.getBoundingClientRect()
			const toolbarHeight = markerArea.current.toolbar.uiContainer
			markerArea.current.coverDiv.style.zIndex = 2000
			markerArea.current.coverDiv.style.top = `${bounds.top - toolbarHeight.offsetHeight}px`
			markerArea.current.coverDiv.style.left = `${bounds.left}px`
		}
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
					<Col xxxs="auto">
						<ButtonGroup size="sm">
							{!isEditing && (
								<Fragment>
									<Button color="info" icon={faPencilPaintbrush} onClick={showMarkerArea} />
									<ConfirmationButton
										color="danger"
										handleConfirmation={handleDelete}
										description="Are you sure you want to remove this image?"
										icon={faTrash}
									/>
									{/* <Button color="danger" icon={faTrash} onClick={handleDelete} /> */}
								</Fragment>
							)}
						</ButtonGroup>
					</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<SegmentImg src={src} ref={imageRef} crossOrigin={image.url instanceof File ? '' : 'anonymous'} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const SegmentImg = React.forwardRef((props, ref) => (
	<img crossOrigin="anonymous" ref={ref} {...props} style={{ maxWidth: '100%', width: '125vh', imageOrientation: 'none' }} />
))

const LocationSelect = ({ name, options, onCreateOption, ...rest }) => {
	const [{ value }, meta, { setValue, setTouched }] = useField(name)

	const handleCreateLocation = (value) => {
		const option = {
			label: value,
			value: `new-${value.toLowerCase().replace(/\W/g, '')}`
		}
		onCreateOption(option)
		setValue(option)
		setTouched(true)
	}

	const handleOnChange = (option, action) => {
		switch (action.action) {
			case 'clear':
				setValue('')
				break
			case 'select-option':
				setValue(option)
				break
		}
		setTouched(true)
	}

	return (
		<Select
			creatable
			name={name}
			options={options}
			onCreateOption={handleCreateLocation}
			handleOnChange={handleOnChange}
			{...rest}
			value={value.value}
			autoCapitalize="words"
		/>
	)
}

//all 3 of these directly copied from Site Visit Checklist Form (and slightly modified), should really just make a common component for it

const ImageInput = ({ name, index }) => {
	const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
	const [dropzoneFiles, setDropzoneFiles] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [field, meta, { setValue, setTouched }] = useField(name)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})

	const handleOnChange = (data) => {
		setDropzoneFiles(data)
	}

	const handleOnSave = () => {
		setIsSubmitting(true)
		const imgPromises = dropzoneFiles.map((file, i) => {
			return loadImage(file, {
				canvas: true,
				orientation: true
			}).then((img) => {
				const { image } = img
				const smaller = document.createElement('canvas')
				const smallerCtx = smaller.getContext('2d')
				const multiplier = image.width > 2560 || image.height > 2560 ? (image.width > image.height ? 2560 / image.width : 2560 / image.height) : 1

				smaller.width = image.width * multiplier
				smaller.height = image.height * multiplier

				smallerCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * multiplier, image.height * multiplier)

				return new Promise((resolve) => smaller.toBlob(resolve, 'image/jpeg', 0.85))
			})
		})

		Promise.all(imgPromises).then((images) => {
			const files = images.map((blob, i) => {
				//console.log(blob)
				const dzFile = dropzoneFiles[i]
				const newFile = new File([blob], dzFile.name, {
					lastModified: dzFile.lastModified,
					type: dzFile.type
				})

				return {
					url: newFile
				}
			})

			const value = field.value ? field.value.slice().concat(files) : files
			setImageModalIsOpen(false)
			setValue(value)
			setTouched(true)

			// const formData = new FormData()
			// files.forEach((image, i) => {
			// 	formData.append('siteVisit', image.url, `images.${i}`)
			// })
			// //not a thunk since it doesn't change state
			// return callApi({
			// 	endpoint: `opportunities/${params.id}/upload-images`,
			// 	method: 'POST',
			// 	headers: {
			// 		'Content-Type': 'multipart/form-data'
			// 	},
			// 	data: formData
			// }).then((response) => {
			// 	const filesToSave = files.map((image, i) => {
			// 		return { url: response.data[`images.${i}`] }
			// 	})
			// 	const value = field.value ? field.value.slice().concat(filesToSave) : filesToSave
			setIsSubmitting(false)
			// 	setImageModalIsOpen(false)
			// 	setValue(value)
			// 	setTouched(true)
			// })
		})
	}

	const handleOnClose = () => {
		setTouched(true)
		setDropzoneFiles([])
		setImageModalIsOpen(false)
	}

	const handleOnDelete = (index, closeModal) => {
		const images = field.value.slice()
		images.splice(index, 1)

		if (images.length === 0) {
			closeModal(false)
		}
		setValue(images)
	}

	const defaultColor = ['red', 'blue', 'green', 'yellow'][index]

	return (
		<React.Fragment>
			<ButtonGroup size="sm">
				<Button size="sm" color="dark" onClick={() => setImageModalIsOpen(true)} icon={faUpload} />
				{field.value && field.value.length > 0 && (
					<ActionButton color="info" title={`View Image${field.value && field.value.length > 1 ? 's' : ''}`} icon={faSearch} modalSize="xl">
						{(closeModal, isShown) => (
							<Fragment>
								{field.value.map((image, j) => (
									<ImageView
										key={j}
										handleDelete={() => handleOnDelete(j, closeModal)}
										image={image}
										index={j}
										name={`${name}.${j}`}
										colorIndex={index}
										defaultColor={defaultColor}
									/>
								))}
								<Row className="justify-content-xxxs-end">
									<Col xxxs="auto">
										<Button color="dark" onClick={() => closeModal(false)}>
											Close
										</Button>
									</Col>
								</Row>
							</Fragment>
						)}
					</ActionButton>
				)}
			</ButtonGroup>

			{imageModalIsOpen && (
				<DropzoneAreaModal
					isOpen={imageModalIsOpen}
					onClose={handleOnClose}
					onSave={handleOnSave}
					onChange={handleOnChange}
					acceptedFiles={['image/*']}
					filesLimit={10}
					maxFileSize={1024 * 1024 * 10}
					submitting={isSubmitting}
				/>
			)}
		</React.Fragment>
	)
}

const ImageButton = ({ images, index }) => {
	const defaultColor = ['red', 'blue', 'green', 'yellow'][index]

	return (
		<ActionButton color="info" title={`View Image${images.length > 1 ? 's' : ''}`} icon={images.length > 1 ? faImages : faImage} modalSize="xl" size="sm">
			{(closeModal, isShown) => (
				<React.Fragment>
					{images.map((image, i) => (
						<ReadOnlyImageView key={i} image={image} index={i} defaultColor={defaultColor} />
					))}
					<Row className="justify-content-xxxs-end">
						<Col xxxs="auto">
							<Button color="dark" onClick={() => closeModal(false)}>
								Close
							</Button>
						</Col>
					</Row>
				</React.Fragment>
			)}
		</ActionButton>
	)
}

const ImageView = ({ image, handleDelete, index, name, defaultColor }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [field, meta, { setValue, setTouched }] = useField(name)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()
	const editing = useRef(false)

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (field.value.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [PenMarker, StartMarker, EndMarker, markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]
			if (defaultColor) {
				markerArea.current.settings.defaultColor = defaultColor
			}

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setValue({
					...field.value,
					annotation: state
				})
				setIsEditing(false)
				editing.current = false
				setShouldRender(true)
			})

			markerArea.current.addCloseEventListener(() => {
				if (editing.current) {
					editing.current = false
					setIsEditing(false)
					setShouldRender(true)
				}
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		//console.log('field.value:', field.value)
		if (field.value.annotation) {
			//doesn't properly show annotations here
			//console.log('restoring annotations')
			markerArea.current.restoreState(field.value.annotation)
		}

		setTimeout(() => {
			markerArea.current.render().then((dataUrl) => {
				imageRef.current.src = dataUrl
				markerArea.current.close()
			})
		}, 500)
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		//same as segment images, should be fine
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/quotes/${image.url}?c=${new Date()}`
	}

	const showMarkerArea = () => {
		setIsEditing(true)
		editing.current = true
		if (markerArea.current) {
			imageRef.current.src = src

			markerArea.current.show()
			if (field.value.annotation) {
				markerArea.current.restoreState(field.value.annotation)
			}
			const bounds = imageRef.current.getBoundingClientRect()
			const toolbarHeight = markerArea.current.toolbar.uiContainer
			markerArea.current.coverDiv.style.zIndex = 2000
			markerArea.current.coverDiv.style.top = `${bounds.top - toolbarHeight.offsetHeight}px`
			markerArea.current.coverDiv.style.left = `${bounds.left}px`
		}
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
					<Col xxxs="auto">
						<ButtonGroup size="sm">
							{!isEditing && (
								<Fragment>
									<Button color="info" icon={faPencilPaintbrush} onClick={showMarkerArea} />
									<ConfirmationButton
										color="danger"
										handleConfirmation={handleDelete}
										description="Are you sure you want to remove this image?"
										icon={faTrash}
									/>
									{/* <Button color="danger" icon={faTrash} onClick={handleDelete} /> */}
								</Fragment>
							)}
						</ButtonGroup>
					</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<LineItemImg src={src} ref={imageRef} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const ReadOnlyImageView = ({ image, index, defaultColor }) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [img, setImg] = useState(image)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (img.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [PenMarker, StartMarker, EndMarker, markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]
			if (defaultColor) {
				markerArea.current.settings.defaultColor = defaultColor
			}

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setImg({
					...img,
					annotation: state
				})
				setShouldRender(true)
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		if (img.annotation) {
			//console.log('restoring annotations')
			//was working here before but now it's not...
			markerArea.current.restoreState(img.annotation)
		}

		setTimeout(() => {
			markerArea.current.render().then((dataUrl) => {
				imageRef.current.src = dataUrl
				markerArea.current.close()
			})
		}, 500)
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		//same as segment images, should be fine
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/quotes/${image.url}?c=${new Date()}`
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<LineItemImg src={src} ref={imageRef} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const LineItemImg = React.forwardRef((props, ref) => (
	<img crossOrigin="anonymous" ref={ref} {...props} style={{ maxWidth: '100%', width: '125vh', imageOrientation: 'none' }} />
))

export default QuoteForm
