import React, { useState, useEffect } from 'react'
import axios from 'axios'
//import { getTokenSilently } from '~/services/auth0'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useLumenox } from './lumenox'

//

const useAPIData = (params) => {
	const [data, setData] = useState()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	//const apiParams = params

	const handleApiCall = (apiParams) => {
		const { callback, ...rest } = apiParams

		callApi({ ...rest })
			.then((response) => {
				console.log(response)
				setData(response.data)
				if (callback) {
					callback(response.data)
				}
				setError(false)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err.response)
				setError(true)
				setLoading(false)
			})
	}

	useEffect(() => {
		console.log('use effect')
		handleApiCall(params)
	}, [])

	return [data, loading, error]
}

const getUrlToken = () => {
	const params = new URL(document.location).searchParams

	return params.get('token') ?? false
}

export const callApi = (params) => {
	const auth = firebase.auth()

	const customToken = getUrlToken()

	return Promise.resolve()
		.then(() => {
			if (customToken) {
				return customToken
			} else {
				return auth.currentUser.getIdToken()
			}
		})
		.then((token) => {
			const { endpoint, headers, ...rest } = params

			const defaultParams = {
				url: `${process.env.API_BASE_URL}${endpoint}`,
				method: 'get',
				headers: {
					Authorization: `Bearer ${token}`,
					...headers
				}
			}

			let requestParams = {
				...defaultParams,
				...rest
			}

			//console.log(requestParams)

			return axios.request(requestParams)
		})
}

export const callApiWithToken = (params, token) => {
	const { endpoint, headers, ...rest } = params

	const defaultParams = {
		url: `${process.env.API_BASE_URL}${endpoint}`,
		method: 'get',
		headers: {
			Authorization: `Bearer ${token}`,
			...headers
		}
	}

	let requestParams = {
		...defaultParams,
		...rest
	}

	return axios.request(requestParams)
}

export const callPublicApi = (params) => {
	const { endpoint, ...rest } = params

	const defaultParams = {
		url: `${process.env.PUBLIC_API_BASE_URL}${endpoint}`,
		method: 'get'
	}

	let requestParams = {
		...defaultParams,
		...rest
	}

	return axios.request(requestParams)
}

export default useAPIData
