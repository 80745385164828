import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, Badge, CardHeader } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import ContactCard from '~/components/common/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faSignal1, faSignal2, faSignal3, faSignal4, faSparkles, faAward } from '@fortawesome/pro-duotone-svg-icons'

const Stage = ({ id, renderTimestamp }) => {
	const { selectors, theme } = useLumenox()

	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	const stage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, activity.stage))

	if (!activity || !stage) {
		return null
	}

	let signalIcon
	switch (true) {
		case stage.percentage < 10:
			signalIcon = faSparkles
			break
		case stage.percentage < 20:
			signalIcon = faSignal1
			break
		case stage.percentage < 40:
			signalIcon = faSignal2
			break
		case stage.percentage < 60:
			signalIcon = faSignal3
			break
		case stage.percentage < 75:
			signalIcon = faSignal4
			break
		case stage.percentage < 100:
			signalIcon = faSignal
			break
		default:
			signalIcon = faAward
			break
	}

	return (
		<CardHeader>
			<Row>
				<Col xxxs="auto">
					<FontAwesomeIcon icon={signalIcon} color={theme['$brand-info']} fixedWidth />
				</Col>
				<Col xxxs>{stage.name}</Col>
				<Col xxxs="auto">{renderTimestamp()}</Col>
			</Row>
		</CardHeader>
	)
}

export default Stage
