import React, { PureComponent, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row, Form, FormGroup, H5, Hr, H6 } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'

const InviteUserForm = ({ initialValues, onSubmit, onSuccess }) => {
	const { entityThunks, dispatch, selectors } = useLumenox()
	const userRoles = useSelector((state) => selectors.selectAllUserRoles(state))

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
	}, [])

	const defaultInitValues = {
		name: '',
		email: '',
		roles: []
	}

	const formInitialValues = {
		...defaultInitValues,
		...initialValues
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Required'),
		email: Yup.string()
			.email('Invalid email')

			.required('Required'),
		roles: Yup.array().of(Yup.string().required('Required')).min(1).required('Required')
	})

	const userRoleOptions = userRoles.map((row) => {
		return {
			label: row.name,
			value: row.id
		}
	})

	const handleOnSubmit = (values, helpers) => {
		onSubmit(values, helpers, onSuccess)
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<FormGroup>
							<Input type="text" name={`name`} placeholder="Full Name" />
						</FormGroup>
						<FormGroup>
							<Input type="email" name={`email`} placeholder="Email" />
						</FormGroup>
						<Hr />
						<H6>Roles:</H6>
						<CheckboxGroup name={`roles`} options={userRoleOptions} values={values.roles} />

						<Button type="submit" color="success" block>
							Send Invite
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

export default InviteUserForm
