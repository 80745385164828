import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Container, Row, H1, H2, Hr } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { Redirect } from 'react-router-dom'

const Home = () => {
	return <Redirect to="/leads" />
}

export default Home
