import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi, callPublicApi } from '~/services/api'
import { actions } from '~/state/entities'

export const fetchOpportunities = createAsyncThunk('opportunities/fetch', (params, { getState, dispatch }) => {
	const {
		entities: {
			opportunity: { loadData }
		}
	} = getState()

	if (loadData) {
		return callApi({
			endpoint: `opportunities`,
			params: params
		}).then((response) => {
			return response.data
		})
	} else {
		dispatch(actions.opportunity.resetLoading())
		return []
	}
})

export const migrateSegmentImages = createAsyncThunk('opportunities/migrateImages', () => {
	return callApi({
		endpoint: `opportunities/migrate-images`
	}).then((response) => {
		console.log(response)
		return response.data
	})
})

export const fetchOpportunityById = createAsyncThunk('opportunities/fetchById', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`
	}).then((response) => {
		return response.data
	})
})

export const fetchPublicOpportunityById = createAsyncThunk('opportunities/fetchById', (id, { getState, requestId }) => {
	return callPublicApi({
		endpoint: `opportunities/${id}`
	}).then((response) => {
		return response.data
	})
})

export const fetchOpportunitiesByStage = createAsyncThunk('opportunities/fetchByStage', ({ stageId, assignedOnly = false }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/by-stage/${stageId}`,
		params: {
			assignedOnly
		}
	}).then((response) => {
		return response.data
	})
})

export const fetchOpportunitiesByTag = createAsyncThunk('opportunities/fetchByTag', ({ tagId, assignedOnly = false }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/by-tag/${tagId}`,
		params: {
			assignedOnly
		}
	}).then((response) => {
		return response.data
	})
})

export const addOpportunity = createAsyncThunk('opportunities/add', (values, { getState, requestId }) => {
	console.log(values)
	return callApi({
		endpoint: `opportunities`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const duplicateOpportunity = createAsyncThunk('opportunities/duplicate', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}/duplicate`,
		method: 'POST'
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityStage = createAsyncThunk('opportunities/updateStage', ({ id, newStageId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}/stage`,
		method: 'PUT',
		data: { stage: newStageId }
	}).then((response) => {
		return response.data
	})
})

export const validateOpportunityStageChange = createAsyncThunk('opportunities/validateStageChange', ({ id, newStageId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/stages/validate/${id}`,
		method: 'GET',
		params: { newStageId }
	}).then((response) => {
		return response.data
	})
})

export const undoOpportunityStage = createAsyncThunk('opportunities/undoStage', ({ id }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PATCH'
	}).then((response) => {
		return response.data
	})
})

export const addOpportunityFollowUp = createAsyncThunk('opportunity/followup', ({ id, values, parent }, { getState, requestId }) => {
	console.log(values)
	return callApi({
		endpoint: `opportunities/${id}/activity`,
		method: 'POST',
		data: {
			__t: 'FollowUp',
			...values,
			parent
		}
	}).then((response) => {
		return response.data
	})
})

export const addOpportunityActivityNote = createAsyncThunk('opportunityAcitivity/addNote', ({ id, values }, { getState, requestId }) => {
	console.log(values)
	return callApi({
		endpoint: `opportunities/${id}/activity`,
		method: 'POST',
		data: {
			__t: 'Note',
			...values
		}
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityActivity = createAsyncThunk('opportunities/updateNote', ({ opportunityId, id, values }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}/activity/${id}`,
		method: 'PUT',
		data: { ...values }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityAssignedTo = createAsyncThunk('opportunities/updateAssignedTo', ({ id, userId, assignMessage }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { assignedTo: userId, assignMessage }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityCSR = createAsyncThunk('opportunities/updateCSR', ({ id, userId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { csr: userId }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityInstaller = createAsyncThunk('opportunities/updateInstaller', ({ id, userId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { installer: userId }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunitySalesperson = createAsyncThunk('opportunities/updateSalesperson', ({ id, userId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { salesperson: userId }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityInstallDetails = createAsyncThunk('opportunities/updateInstallDetails', ({ id, installDetails }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { installDetails }
	}).then((response) => {
		return response.data
	})
})

export const addOpportunitySiteVisit = createAsyncThunk('opportunities/addSiteVisit', ({ id, values }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}/site-visit`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunitySiteVisit = createAsyncThunk('opportunities/updateSiteVisit', ({ id, values }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}/site-visit`,
		method: 'PUT',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityTags = createAsyncThunk('opportunities/updateInstaller', ({ id, tags }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { tags }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityInstallDate = createAsyncThunk('opportunities/updateInstallDate', ({ id, installDate }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { installDate }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityReferral = createAsyncThunk('opportunities/updateReferral', ({ id, referral }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { referral }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityReferralByCode = createAsyncThunk('opportunities/updateReferralByCode', ({ id, code }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { referralCode: code }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityLightsGen = createAsyncThunk('opportunities/updateLightsGen', ({ id, lightsGen }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { lightsGen }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityAppGuideSent = createAsyncThunk('opportunities/updateAppGuideSent', ({ id, appGuideSent }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { appGuideSent }
	}).then((response) => {
		return response.data
	})
})

export const updateOpportunityRequestDetails = createAsyncThunk('opportunities/request-details', ({ id, requestDetails }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${id}`,
		method: 'PUT',
		data: { requestDetails }
	}).then((response) => {
		return response.data
	})
})

export const createHoverCaptureRequest = createAsyncThunk('opportunities/hover/capture-request', ({ id, userId = null }, { getState, requestId }) => {
	return callApi({
		endpoint: `hover/capture-request/${id}`,
		method: 'POST',
		data: {
			userId
		}
	}).then((response) => {
		return response.data
	})
})

export const createHoverJob = createAsyncThunk('opportunities/hover/job', ({ id, userId = null }, { getState, requestId }) => {
	return callApi({
		endpoint: `hover/job/${id}`,
		method: 'POST',
		data: {
			userId
		}
	}).then((response) => {
		return response.data
	})
})

export const addHoverJobToOpportunity = createAsyncThunk('opportunities/hover/add-job', ({ opportunityId, jobId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}`,
		method: 'PUT',
		data: { hoverJob: jobId }
	}).then((response) => {
		return response.data
	})
})

export const removeHoverJobFromOpportunity = createAsyncThunk('opportunities/hover/remove-job', ({ opportunityId, jobId }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/${opportunityId}`,
		method: 'PUT',
		data: { hoverJobToDelete: jobId }
	}).then((response) => {
		return response.data
	})
})
