import React, { Fragment, PureComponent, useState, useEffect } from 'react'
import withWidth, { isWidthUp, isWidthDown } from 'bootstrap-styled/lib/withWidth'
import { useSelector } from 'react-redux'
import { H3, Img } from '@bootstrap-styled/v4'

import { BackLink } from '~/components/common/Text'
/* import LeadForm from '../components/LeadForm' */

import { Redirect, useRouteMatch } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'
import { useFirebase } from '~/services/firebase'
import WiringDiagramForm from '../WiringDiagramForm' //will change

const WiringDiagram = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const [redirect, setRedirect] = useState(null)
	const { url, params } = useRouteMatch({
		path: '/leads/:id/wiring-diagram/:quoteId',
		strict: false
	})

	const quote = useSelector((state) => selectors.selectNormalizedQuoteFormValuesById(state, { quote: params.quoteId }))

	const [wiringDiagram, setWiringDiagram] = useState({})

	useEffect(() => {
		console.log('using effect')
		dispatch(entityThunks.getWiringDiagram(params.quoteId)).then((result) => {
			console.log('result:', result)
			setWiringDiagram(result.payload)
		})
	}, [])

	return (
		<Fragment>
			{redirect && <Redirect to={`${redirect}`} />}
			{Object.entries(wiringDiagram).length > 0 && <WiringDiagramForm values={{ ...quote }} wiringDiagram={wiringDiagram} />}
		</Fragment>
	)
}

export default WiringDiagram
