import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { H1, Row, Hr, H3, Card, CardBlock, ButtonToolbar, ButtonGroup, CardHeader, H4, H5, H6, Badge, P, Tbody, Tr, Th, Td, Form } from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import { DatePicker } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useField, FieldArray } from 'formik'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { ActionButton, Button, Col } from '~/components/bootstrap'
import InstallDetailsForm from '../../../InstallDetails/InstallDetailsForm'
import InstallDetailsView from '../../../InstallDetails/InstallDetailsView'
import { format } from 'date-fns'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'

const InstallDetails = ({ opportunityId }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()

	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, opportunityId))
	const installDetails = useSelector((state) => selectors.selectInstallDetailsPopulated(state, opportunity.installDetails))

	const copyDetails = () => {
		if (!installDetails) return
		const installersText = installDetails.installers.reduce((text, installer, i) => {
			if (!installer) return text
			text += installer.name
			if (i !== installDetails.installers.length - 1) {
				text += ', '
			}
			return text
		}, '')
		const text = `${
			installDetails.channel?.length > 0
				? installDetails.channel.reduce(
						(str, channel) =>
							str +
							`<b>Channel Type -</b> ${channel.type}<br/>
		<b>Channel Color -</b> ${channel.color}<br/>
		<b>Channel Quantity -</b> ${channel.quantity}<br/>`,
						''
				  )
				: `<b>Channel Color -</b> ${installDetails.channelColor}<br/>
        <b>Channel Quantity -</b> ${installDetails.channelQuantity}<br/>`
		}
        <b>Box Location -</b> ${installDetails.boxLocation}<br/>
        <b>Lights Location -</b> ${installDetails.lightsLocation}<br/>
        <b>Plug locations -</b> ${installDetails.plugLocations}<br/>
        <b>Site Details (ie. Lift required) -</b>\n${installDetails.siteDetails.replaceAll('\n', '<br/>')}<br/>
        <b>Install Date - ${format(new Date(installDetails.date), 'MMMM d, y')}<br/>
        Installer - ${installersText}<br/>
        Site visit date -${format(new Date(installDetails.siteVisitDate), 'MMMM d, y')}<br/>
        Site visit completed by - ${installDetails.siteVisitor ? installDetails.siteVisitor.name : ''}<br/>
        Homeowner confirmed they will be present - ${installDetails.homeownerPresent ? 'yes' : 'no'}<br/>
        Date confirmed by homeowner - ${installDetails.dateConfirmed ? 'yes' : 'no'}</b>`
		console.log('text:', text)
		navigator.clipboard.write([new ClipboardItem({ 'text/html': new Blob([text], { type: 'text/html' }) })])
	}

	return (
		<Card className="mb-xxxs-3">
			<CardHeader>
				<Row className="align-items-xxxs-center">
					<Col xxxs>
						<H5 className="mb-xxxs-0">Install Details</H5>
					</Col>
					<Col xxxs="auto">
						<ButtonGroup>
							<Button onClick={copyDetails} icon={faCopy} color="warning" />
							<ActionButton icon={faEdit} title="Edit Install Details" color="info">
								{(closeModal) => <InstallDetailsForm closeModal={closeModal} id={opportunity.installDetails} opportunityId={opportunityId} />}
							</ActionButton>
						</ButtonGroup>
					</Col>
				</Row>
			</CardHeader>
			<InstallDetailsView id={opportunity.installDetails} />
		</Card>
	)
}

export default InstallDetails
