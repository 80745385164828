import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, CenteredModal, Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Hr, ModalBody, ModalHeader, P, Form, Row, Small } from '@bootstrap-styled/v4'
import { Input, Label, Select, Textarea, UncontrolledSelect, Checkbox } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import DefaultStageButton from './default'

const SiteVisitScheduledStageButton = ({ opportunity, currentStage, nextStage, ...rest }) => {
	return currentStage.slug === 'qualified' || currentStage.slug === 'rough-quote-sent' ? (
		<NormalSiteVisitScheduledStageButton
			opportunity={opportunity}
			currentStage={currentStage}
			nextStage={nextStage}
			{...rest}
			roleOptionsSlugs={['estimator']}
			assignMessage="Assign the lead to the estimator who will complete the site visit:"
		/>
	) : (
		<DefaultStageButton opportunity={opportunity} currentStage={currentStage} nextStage={nextStage} {...rest} />
	)
}

const NormalSiteVisitScheduledStageButton = ({ opportunity, currentStage, nextStage, roleOptionsSlugs, assignMessage, ...rest }) => {
	const { selectors, dispatch, entityThunks, user: lumenoxUser } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const [assignModalOpen, setAssignModalOpen] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const allRoles = useSelector((state) => selectors.selectAllUserRoles(state))
	const roleOptionsIds = allRoles.filter((role) => roleOptionsSlugs.includes(role.slug)).map((role) => role.id)
	const allUsers = useSelector((state) => selectors.selectAllUsers(state))
	const currentUser = allUsers.find((user) => user.id === lumenoxUser.uid) //lumenoxUser doesn't have hoverId
	const assignedToOptions = allUsers
		.filter((user) => {
			for (const role of user.roles) {
				if (roleOptionsIds.includes(role)) return true
			}
			return false
		})
		.map((user) => ({ label: user.name, value: user.id }))
	const currentAssignedTo = useSelector((state) => selectors.selectUserById(state, opportunity.assignedTo))

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	const updateStageAndAssignedTo = async (values) => {
		setIsSubmitting(true)
		const selectedUser = allUsers.find((user) => user.id === values.selectedAssignedTo)
		if (values.makeHoverJob) {
			await dispatch(entityThunks.createHoverJob({ id: opportunity.id, userId: selectedUser?.hoverId ? selectedUser.id : currentUser.id }))
		}
		//update assignedTo even if it's already assigned to that person so that message is sent
		dispatch(entityThunks.updateOpportunityAssignedTo({ id: opportunity.id, userId: values.selectedAssignedTo, assignMessage: values.message })).then(
			(response) => {
				dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then(() => {
					setIsSubmitting(false)
				})
			}
		)
	}

	const initialValues = {
		selectedAssignedTo: null,
		message: '',
		makeHoverJob: true
	}

	if (!dependenciesMet) return null

	return (
		<React.Fragment>
			<Button color={nextStage.color} icon={solid[nextStage.icon]} onClick={() => setAssignModalOpen(true)}>
				{nextStage.name}
			</Button>
			<CenteredModal isOpen={assignModalOpen} size="sm">
				<ModalHeader toggle={() => setAssignModalOpen(false)}>{'Assign Lead'}</ModalHeader>
				<ModalBody>
					<Formik
						initialStatus={null}
						validateOnChange={false}
						initialValues={initialValues}
						validationSchema={null}
						onSubmit={updateStageAndAssignedTo}
					>
						{({ values, setFieldValue, errors }) => {
							useEffect(() => {
								if (!currentAssignedTo || !currentAssignedTo.roles) return
								if (currentAssignedTo.roles.some((role) => roleOptionsIds.includes(role))) {
									setFieldValue('selectedAssignedTo', currentAssignedTo.id)
								} else {
									setFieldValue('selectedAssignedTo', null)
								}
							}, [currentAssignedTo])
							const selectedUser = allUsers.find((user) => user.id === values.selectedAssignedTo)

							return (
								<Form tag={FormikForm}>
									<P>Assign the lead to a new user</P>
									<Select name="selectedAssignedTo" options={assignedToOptions} />
									{values.makeHoverJob && !selectedUser?.hoverId && (
										<Small>{`Selected user isn't associated with a Hover user. ${
											currentUser.hoverId
												? 'The hover job will be assigned to you instead.'
												: 'Link their crm user with their hover user, link your crm account to your hover account, or select another user.'
										}`}</Small>
									)}
									<P className="mt-xxxs-3">Message to who you are assigning to:</P>
									<Textarea name="message" />
									<Checkbox name="makeHoverJob" className="mt-xxxs-3">
										Make Hover Job?
									</Checkbox>
									<Hr />

									<Button
										type="submit"
										disabled={values.selectedAssignedTo === null || (values.makeHoverJob && !selectedUser?.hoverId && !currentUser.hoverId)}
										color="success"
										submitting={isSubmitting}
										block
									>
										Continue
									</Button>
								</Form>
							)
						}}
					</Formik>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

export default SiteVisitScheduledStageButton
