import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchContactById = createAsyncThunk('contacts/fetchById', ({ tastingId, id }, { getState, requestId }) => {
	return callApi({
		endpoint: `tastings/${tastingId}/contacts/${id}`
	}).then((response) => {
		return response.data
	})
})

export const searchContacts = createAsyncThunk('beers/searchLocal', (query) => {
	return callApi({
		endpoint: `contacts/search`,
		method: 'GET',
		params: { q: query }
	}).then((response) => {
		return response.data
	})
})

export const addContact = createAsyncThunk('contacts/add', ({ values }, { getState, requestId }) => {
	return callApi({
		endpoint: `contacts`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const updateContact = createAsyncThunk('contacts/update', ({ id, values }, { getState, requestId }) => {
	return callApi({
		endpoint: `contacts/${id}`,
		method: 'PUT',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const addCompensationProgramToContact = createAsyncThunk('contacts/addCompensationProgram', ({ id, data }, { getState, requestId }) => {
	return callApi({
		endpoint: `contacts/${id}/compensation-programs`,
		method: 'POST',
		data
	}).then((response) => {
		return response.data
	})
})

export const fetchOpportunityReferralContact = createAsyncThunk('contacts/fetchOpportunityReferral', ({ id }) => {
	return callApi({
		endpoint: `contacts/by-opportunity-referral/${id}`,
		method: 'GET'
	}).then((response) => {
		if (response.data.success === false)
			return {
				entities: {},
				result: []
			}
		return response.data
	})
})
