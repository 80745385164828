import React, { PureComponent, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Form, FormGroup, H5, Hr, H6 } from '@bootstrap-styled/v4'
import { Col, Row, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray, DatePicker, Select } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'

const AddProductForm = ({ onSuccess }) => {
	const { entityThunks, dispatch } = useLumenox()

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Required'),
		rate: Yup.object().shape({
			currencies: Yup.array()
				.of(
					Yup.object().shape({
						value: Yup.number().required('Required'),
						symbol: Yup.string().required('Required')
					})
				)
				.min(1),
			effectiveDate: Yup.date().default(() => new Date().toISOString().split('T')[0]) //make default today
		}),
		slug: Yup.string()
	})

	const slugify = (string) => {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
		const p = new RegExp(a.split('').join('|'), 'g')

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') // Trim - from end of text
	}

	const handleSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
		validationSchema
			.validate(values)
			.then((values) => {
				values.slug = slugify(values.name)
				return values
			})
			.then((values) => {
				dispatch(entityThunks.addProduct(values)).then((response) => {
					//in this for contacts values was in an object like { values }, not sure why though it doesn't seem to line up with the back end, I'll ask Taylor about it
					console.log('success', response)

					// todo error handling
					setSubmitting(false)
					resetForm()
					if (onSuccess && response.meta.requestStatus === 'fulfilled') {
						onSuccess()
					}
				})
			})
	}

	return (
		<Formik
			initialStatus={null}
			validateOnChange={false}
			initialValues={{
				name: '',
				rate: {
					currencies: [{ symbol: '', value: '' }],
					effectiveDate: new Date()
				},
				slug: ''
			}}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				return (
					<Form tag={FormikForm}>
						<H6>Name:</H6>
						<FormGroup>
							<Input type="text" name={`name`} placeholder="Product Name" />
						</FormGroup>
						<H6>Rate:</H6>
						<MultipleInputArray name="rate.currencies" values={values.rate.currencies} initialValues={{ symbol: '', value: '' }}>
							{({ i, setElRef }) => (
								<Row>
									<Col xxxs={12} md={9} className="mb-xxxs-3 mb-lg-0">
										<Input type="number" name={`rate.currencies.${i}.value`} ref={(n) => setElRef && setElRef(n, i)} placeholder="Value" />
									</Col>
									<Col xxxs={12} md={3} className="mb-xxxs-3 mb-lg-0">
										<Select
											name={`rate.currencies.${i}.symbol`}
											options={[
												{ label: 'CAD', value: 'CAD' },
												{ label: 'USD', value: 'USD' }
											]}
											defaultValue="CAD"
										></Select>
										{/* <Input type="text" name={`rate.currencies.${i}.symbol`} placeholder="Symbol" /> */}
									</Col>
								</Row>
							)}
						</MultipleInputArray>
						<H6>Effective Date:</H6>
						<FormGroup>
							<DatePicker name="rate.effectiveDate" />
						</FormGroup>
						<Button type="submit" color="success" block>
							Save
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

export default AddProductForm
