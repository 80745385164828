import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import { useFirebase } from '~/services/firebase'
import { Container } from '@bootstrap-styled/v4'
import PrivateRoute from '~/components/common/PrivateRoute'

import Home from '~/views/Home'
import Auth from '~/views/Auth'
import Leads from '~/views/Leads'
import Products from '~/views/Products'
import Users from '~/views/Users'
import ScrollToTop from './common/ScrollToTop'
import { PublicQuotePDF } from '~/views/Leads/View/components/Quotes/QuotePDF'
import Terms from '~/views/Terms'
import Stages from '~/views/Stages'
import CompensationPrograms from '~/views/CompensationPrograms'
import Channel from '~/views/Channel'
import Hover from '~/views/Hover'

const Routes = () => {
	const { loading } = useFirebase()

	return (
		<Wrapper className="px-xxxs-0">
			<ScrollToTop />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route path="/auth" component={Auth} />
				<Route path="/public/leads/:opportunityId/quotes/:quoteId/pdf" component={PublicQuotePDF} />
				<PrivateRoute path="/leads" component={Leads} />
				<PrivateRoute path="/quotes" component={Leads} />
				<PrivateRoute path="/production" component={Leads} />
				<PrivateRoute path="/installs" component={Leads} />
				<PrivateRoute path="/users" component={Users} />
				<PrivateRoute path="/products" component={Products} />
				<PrivateRoute path="/terms" component={Terms} />
				<PrivateRoute path="/stages" component={Stages} />
				<PrivateRoute path="/compensation-programs" component={CompensationPrograms} />
				<PrivateRoute path="/channel" component={Channel} />
				<PrivateRoute path="/hover" component={Hover} />
			</Switch>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default Routes
