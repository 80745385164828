import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import {
	H1,
	Row,
	Hr,
	H3,
	Card,
	CardBlock,
	ButtonToolbar,
	ButtonGroup,
	CardHeader,
	H4,
	H5,
	H6,
	Badge,
	P,
	Tbody,
	Tr,
	Th,
	Td,
	Form,
	InputGroup,
	Collapse,
	InputGroupAddon
} from '@bootstrap-styled/v4'
import { useLumenox } from '~/services/lumenox'
import * as Yup from 'yup'
import { Formik, Form as FormikForm, useField, FieldArray } from 'formik'
import {
	BooleanButtons,
	Checkbox,
	DatePicker,
	DateTimePicker,
	DropzoneAreaModal,
	FormGroup,
	Input,
	Label,
	MultipleInputArray,
	Select,
	Textarea
} from '~/components/bootstrap/components/Form'
import { ActionButton, Button, Col, ConfirmationButton } from '~/components/bootstrap'
import { InstallerSelect, SiteVisitorSelect } from '~/components/common/Forms'
import { faChevronDown, faChevronUp, faPencilPaintbrush, faSearch, faTrash, faUpload } from '@fortawesome/pro-regular-svg-icons'
import loadImage from 'blueimp-load-image'
import { Redirect, useRouteMatch } from 'react-router-dom'
import * as markerjs2 from 'markerjs2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { callApi } from '~/services/api'
import Cookies from 'js-cookie'

const SiteVisitChecklistForm = ({ currentSiteVisit }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()
	const [colourSamplesOpen, setColourSamplesOpen] = useState(true)
	const [boxLocationsOpen, setBoxLocationsOpen] = useState(true)
	const [specialtyChannelOpen, setSpecialtyChannelOpen] = useState(true)
	const [obstaclesOpen, setObstaclesOpen] = useState(true)
	const [notesOpen, setNotesOpen] = useState(true)
	const [hoverOpen, setHoverOpen] = useState(true)
	const [redirect, setRedirect] = useState(null)
	const { url, params } = useRouteMatch({
		path: '/leads/:id/site-visit-checklist',
		strict: false
	})
	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, params.id))
	const currentStage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, opportunity?.stage))
	const siteVisitCompletedStage = useSelector((state) => selectors.selectOpportunityStageTypeBySlug(state, 'site-visit-completed'))

	const defaultValues = {
		soffitSameAsFascia: null,
		hasOtherColourSamples: null,
		soffitColourSample: { name: 'Soffit', colour: '', images: [], notes: '' },
		fasciaColourSample: { name: 'Fascia', colour: '', images: [], notes: '' },
		otherColourSamples: [{ name: '', colour: '', images: [], notes: '' }],
		boxLocations: [{ location: '', mountingSameAsPlug: null, plugLocations: [], mountingLocation: [], notes: '' }],
		specialtyChannelRequired: {
			upperFront: null,
			lowerFront: null,
			upperBack: null,
			lowerBack: null,
			elsewhere: null
		},
		specialtyChannel: {
			upperFront: { name: 'Upper Front', type: '', fasciaDepth: '', notes: '', images: [] },
			lowerFront: { name: 'Lower Front', type: '', fasciaDepth: '', notes: '', images: [] },
			upperBack: { name: 'Upper Back', type: '', fasciaDepth: '', notes: '', images: [] },
			lowerBack: { name: 'Lower Back', type: '', fasciaDepth: '', notes: '', images: [] },
			elsewhere: [{ name: '', type: '', notes: '', fasciaDepth: '', images: [] }]
		},
		hasObstacles: {
			lift: null,
			bayWindows: null,
			chimneys: null,
			decorativeTrusses: null,
			potLights: null,
			securityCameras: null,
			other: null
		},
		obstacles: {
			lift: { name: 'Lift', location: '', notes: '', amount: '', images: [] },
			bayWindows: { name: 'Bay Windows', location: '', notes: '', amount: '', images: [] },
			chimneys: { name: 'Chimneys', location: '', notes: '', amount: '', images: [] },
			decorativeTrusses: { name: 'Decorative Trusses', location: '', notes: '', amount: '', images: [] },
			potLights: { name: 'Pot Lights', location: '', notes: '', amount: '', images: [] },
			securityCameras: { name: 'Security Cameras', location: '', notes: '', amount: '', images: [] },
			other: [{ name: '', location: '', notes: '', amount: '', images: [] }]
		},
		hasNonStandardElevations: null,
		nonStandardElevations: [{ name: '', measurements: [], notes: '', images: [] }],
		hoverMeasurements: {
			garageDoor: { type: 'Garage Door', width: '', height: '', notes: '' },
			frontDoor: { type: 'Front Door', width: '', height: '', notes: '' }
		},
		hoverMovedToBuilding: null,
		notes: ''
	}

	const currentSiteVisitReconstructed = reconstructSiteVisitValues(currentSiteVisit)

	// Don't allow more than 3 cookies to be saved
	const allCookies = Cookies.get()
	const svcCookies = Object.keys(allCookies).reduce((obj, key) => {
		if (key.startsWith('siteVisit')) {
			obj[key] = allCookies[key]
		}
		return obj
	}, {})
	if (Object.keys(svcCookies).length > 3) {
		//remove oldest
		let min = new Date().getTime() + 1000000 //guaranteed bigger than any savedAt
		let minKey = null
		for (const [key, value] of Object.entries(svcCookies)) {
			const savedAt = JSON.parse(value).savedAt
			if (savedAt < min) {
				min = savedAt
				minKey = key
			}
		}
		Cookies.remove(minKey)
	}
	const savedCookie = Cookies.get(`siteVisit/${params.id}`)
	const savedValues = savedCookie ? JSON.parse(savedCookie) : null
	const initialValues = {
		...defaultValues,
		...currentSiteVisitReconstructed,
		...savedValues
	}

	const validationSchema = Yup.object({
		soffitColourSample: Yup.object({
			name: Yup.string(),
			colour: Yup.string(),
			images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
			notes: Yup.string()
		}),
		fasciaColourSample: Yup.object({
			name: Yup.string(),
			colour: Yup.string(),
			images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
			notes: Yup.string()
		}),
		otherColourSamples: Yup.array().of(
			Yup.object({
				name: Yup.string(),
				colour: Yup.string(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			})
		),
		boxLocations: Yup.array().of(
			Yup.object({
				location: Yup.string(),
				plugLocations: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				mountingLocation: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			})
		),
		specialtyChannel: Yup.object({
			upperFront: Yup.object({
				name: Yup.string(),
				type: Yup.string(),
				fasciaDepth: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			lowerFront: Yup.object({
				name: Yup.string(),
				type: Yup.string(),
				fasciaDepth: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			upperBack: Yup.object({
				name: Yup.string(),
				type: Yup.string(),
				fasciaDepth: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			lowerBack: Yup.object({
				name: Yup.string(),
				type: Yup.string(),
				fasciaDepth: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			elsewhere: Yup.array().of(
				Yup.object({
					name: Yup.string(),
					type: Yup.string(),
					fasciaDepth: Yup.number(),
					images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
					notes: Yup.string()
				})
			)
		}),
		obstacles: Yup.object({
			lift: Yup.object({
				name: Yup.string(),
				location: Yup.string(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			bayWindows: Yup.object({
				name: Yup.string(),
				location: Yup.string(),
				amount: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			chimneys: Yup.object({
				name: Yup.string(),
				location: Yup.string(),
				amount: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			decorativeTrusses: Yup.object({
				name: Yup.string(),
				location: Yup.string(),
				amount: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			potLights: Yup.object({
				name: Yup.string(),
				location: Yup.string(),
				amount: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			securityCameras: Yup.object({
				name: Yup.string(),
				location: Yup.string(),
				amount: Yup.number(),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			}),
			other: Yup.array().of(
				Yup.object({
					name: Yup.string(),
					location: Yup.string(),
					amount: Yup.number(),
					images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
					notes: Yup.string()
				})
			)
		}),
		nonStandardElevations: Yup.array().of(
			Yup.object({
				name: Yup.string(),
				measurements: Yup.array().of(Yup.number()),
				images: Yup.array().of(Yup.object({ url: Yup.string(), annotation: Yup.object() })),
				notes: Yup.string()
			})
		),
		hoverMeasurements: Yup.object({
			garageDoor: Yup.object({
				type: Yup.string(),
				width: Yup.number(),
				height: Yup.number(),
				notes: Yup.string()
			}),
			frontDoor: Yup.object({
				type: Yup.string(),
				width: Yup.number(),
				height: Yup.number(),
				notes: Yup.string()
			})
		}),
		notes: Yup.string()
	})

	const readyForOtherColourSamples = (values) => {
		return values.soffitSameAsFascia
			? values.soffitColourSample.colour !== ''
			: values.soffitColourSample.colour !== '' && values.fasciaColourSample.colour !== ''
	}

	const readyForControlBox = (values) => {
		if (!readyForOtherColourSamples(values)) return false
		if (values.hasOtherColourSamples === null) return false
		if (values.hasOtherColourSamples === false) return true
		for (let colourSample of values.otherColourSamples) {
			if (colourSample.name === '' || colourSample.colour === '') return false
		}
		return true
	}

	const readyForSpecialtyChannel = (values) => {
		if (!readyForControlBox(values)) return false
		for (let boxLocation of values.boxLocations) {
			if (
				boxLocation.location === '' ||
				boxLocation.mountingLocation.length === 0 ||
				(boxLocation.plugLocations.length === 0 && !boxLocation.mountingSameAsPlug)
			)
				return false
		}
		return true
	}

	const specialtyChannelAreas = ['upperFront', 'lowerFront', 'upperBack', 'lowerBack']

	const readyForNextSpecialtyChannelArea = (values, currentArea) => {
		if (!readyForSpecialtyChannel(values)) return false
		const index = specialtyChannelAreas.indexOf(currentArea)
		if (index > 0 && !readyForNextSpecialtyChannelArea(values, specialtyChannelAreas[index - 1])) return false
		if (values.specialtyChannelRequired[currentArea] === false) return true
		const channel = values.specialtyChannel[currentArea]
		if (channel.type === '' || channel.images.length === 0) return false
		return true
	}

	const readyForObstacles = (values) => {
		if (!readyForNextSpecialtyChannelArea(values, 'lowerBack')) return false
		if (values.specialtyChannelRequired.elsewhere === false) return true
		for (const elsewhere of values.specialtyChannel.elsewhere) {
			if (elsewhere.name === '' || elsewhere.type === '' || elsewhere.images.length === 0) return false
		}
		return true
	}

	const obstacles = ['lift', 'bayWindows', 'chimneys', 'decorativeTrusses', 'potLights', 'securityCameras']

	const readyForNextObstacle = (values, currentObstacle, needsLocation = false) => {
		if (!readyForObstacles(values)) return false
		if (currentObstacle === 'bayWindows' && !readyForNormalObstacles(values)) return false
		const index = obstacles.indexOf(currentObstacle)
		if (index > 0 && !readyForNextObstacle(values, obstacles[index - 1], index === 1)) return false //only lift needs location
		if (values.hasObstacles[currentObstacle] === false) return true
		const obstacle = values.obstacles[currentObstacle]
		if (obstacle.images.length === 0) return false
		if (currentObstacle === 'lift') {
			//needs location
			if (obstacle.location === '') return false
		} else {
			//needs amount
			//could also check that amount === images.length to make sure they take a picture of each
			if (obstacle.amount === '') return false
		}
		return true
	}

	const readyForNormalObstacles = (values) => {
		if (!readyForNextObstacle(values, 'lift', true)) return false
		if (values.hasNonStandardElevations === false) return true
		for (const elevation of values.nonStandardElevations) {
			if (elevation.name === '' || elevation.images.length === 0) return false
			for (const measurement of elevation.measurements) {
				if (measurement === undefined || measurement === null || measurement === '') return false
			}
		}
		return true
	}

	const readyForHover = (values) => {
		if (!readyForNextObstacle(values, 'securityCameras')) return false
		if (values.hasObstacles.other === false) return true
		for (const obstacle of values.obstacles.other) {
			if (obstacle.name === '' || obstacle.location === '' || obstacle.images.length === 0) return false
		}
		return true
	}

	const readyForHoverBuilding = (values) => {
		if (!readyForHover(values)) return false
		if (
			(values.hoverMeasurements.garageDoor.width === '' || values.hoverMeasurements.garageDoor.height === '') &&
			(values.hoverMeasurements.frontDoor.width === '' || values.hoverMeasurements.frontDoor.height === '')
		)
			return false
		return true
	}

	const onSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
		setSubmitting(true)
		if (currentSiteVisit) {
			dispatch(entityThunks.updateOpportunitySiteVisit({ id: params.id, values })).then((response) => {
				console.log('success', response)
				setSubmitting(false)
				Cookies.remove(`siteVisit/${params.id}`)
				setRedirect(true)
			})
		} else {
			dispatch(entityThunks.addOpportunitySiteVisit({ id: params.id, values }))
				.then((response) => {
					console.log('success', response)
					setSubmitting(false)
					Cookies.remove(`siteVisit/${params.id}`)
					setRedirect(true)
				})
				.then(() => {
					if (currentStage?.slug === 'site-visit-scheduled') {
						dispatch(entityThunks.updateOpportunityStage({ id: params.id, newStageId: siteVisitCompletedStage.id }))
					}
				})
		}
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm }) => {
				if (Object.keys(errors).length > 0) console.log('errors:', errors)
				//console.log('values', values)
				return (
					<Form tag={FormikForm}>
						<CookieSaver values={values} />
						{redirect && <Redirect to={`/leads/${params.id}`} />}
						<Card className="mb-xxxs-3">
							<CardHeader>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										<H5>Colour Samples</H5>
									</Col>
									<Col xxxs="auto">
										<FontAwesomeIcon
											onClick={() => {
												setColourSamplesOpen(!colourSamplesOpen)
											}}
											icon={colourSamplesOpen ? faChevronUp : faChevronDown}
											size="lg"
											fixedWidth
										/>
									</Col>
								</Row>
							</CardHeader>
							<Collapse isOpen={colourSamplesOpen}>
								<CardBlock>
									<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
										<Col xxxs>
											<Label>Is the soffit the same colour as the fascia?</Label>
										</Col>
										<Col xxxs="auto">
											<BooleanButtons name="soffitSameAsFascia" />
										</Col>
									</FormGroup>
									<Collapse isOpen={values.soffitSameAsFascia !== null}>
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Row>
													<Col xxxs>
														<Label>Soffit colour hex code:</Label>
														<InputGroup>
															<HexDisplay hex={values.soffitColourSample.colour}>&nbsp;&nbsp;&nbsp;</HexDisplay>
															<Input name={`soffitColourSample.colour`} />
														</InputGroup>
													</Col>
												</Row>
												<Row>
													<Col xxxs>
														<Label className="mr-xxxs-2">Images:</Label>
														<ImageInput name={`soffitColourSample.images`} />
													</Col>
												</Row>
												<Row>
													<Col xxxs>
														<Label>Notes:</Label>
														<Textarea name={`soffitColourSample.notes`} />
													</Col>
												</Row>
											</Col>
											{values.soffitSameAsFascia === false && (
												<Col xxxs>
													<Row>
														<Col xxxs>
															<Label>Fascia colour hex code:</Label>
															<InputGroup>
																<HexDisplay hex={values.fasciaColourSample.colour}>&nbsp;&nbsp;&nbsp;</HexDisplay>
																<Input name={`fasciaColourSample.colour`} />
															</InputGroup>
														</Col>
													</Row>
													<Row>
														<Col xxxs>
															<Label className="mr-xxxs-2">Images:</Label>
															<ImageInput name={`fasciaColourSample.images`} />
														</Col>
													</Row>
													<Row>
														<Col xxxs>
															<Label>Notes:</Label>
															<Textarea name={`fasciaColourSample.notes`} />
														</Col>
													</Row>
												</Col>
											)}
										</FormGroup>
									</Collapse>
									<Collapse isOpen={readyForOtherColourSamples(values)}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Do any other areas need a different channel colour?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasOtherColourSamples" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasOtherColourSamples}>
											<MultipleInputArray
												name="otherColourSamples"
												values={values.otherColourSamples}
												initialValues={{ name: '', colour: '', images: [], notes: '' }}
											>
												{({ i }) => (
													<React.Fragment>
														<FormGroup className="mb-xxxs-3 align-items-xxxs-center">
															<Row>
																<Col xxxs>
																	<Label>Area Name:</Label>
																	<Input name={`otherColourSamples.${i}.name`} />
																</Col>
																<Col xxxs>
																	<Label>Colour Hex Code:</Label>
																	<InputGroup>
																		<HexDisplay hex={values.otherColourSamples[i].colour}>&nbsp;&nbsp;&nbsp;</HexDisplay>
																		<Input name={`otherColourSamples.${i}.colour`} />
																	</InputGroup>
																</Col>
															</Row>
															<Row>
																<Col xxxs>
																	<Label className="mr-xxxs-2">Images:</Label>
																	<ImageInput name={`otherColourSamples.${i}.images`} />
																</Col>
															</Row>
															<Row>
																<Col xxxs>
																	<Label>Notes:</Label>
																	<Textarea name={`otherColourSamples.${i}.notes`} />
																</Col>
															</Row>
														</FormGroup>
													</React.Fragment>
												)}
											</MultipleInputArray>
										</Collapse>
									</Collapse>
								</CardBlock>
							</Collapse>
						</Card>
						<Card className="mb-xxxs-3">
							<CardHeader>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										<H5>Control Box Locations:</H5>
									</Col>
									{readyForControlBox(values) && (
										<Col xxxs="auto">
											<FontAwesomeIcon
												onClick={() => {
													setBoxLocationsOpen(!boxLocationsOpen)
												}}
												icon={boxLocationsOpen ? faChevronUp : faChevronDown}
												size="lg"
												fixedWidth
											/>
										</Col>
									)}
								</Row>
							</CardHeader>
							<Collapse isOpen={readyForControlBox(values) && boxLocationsOpen}>
								<CardBlock>
									<Label>Add all possible control box locations, including in the garage if accessible</Label>
									<MultipleInputArray
										name={'boxLocations'}
										values={values.boxLocations}
										initialValues={{ location: '', plugLocations: [], mountingLocation: [], notes: '' }}
									>
										{({ i }) => (
											<React.Fragment>
												<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
													<Col xxxs>
														<Label>Location:</Label>
														<Input
															name={`boxLocations.${i}.location`}
															onFocus={() => {
																if (i === 0) setColourSamplesOpen(false)
															}}
														/>
													</Col>
												</FormGroup>
												<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
													<Col xxxs>
														<Label>Is the mounting location the same as the plug location?</Label>
													</Col>
													<Col xxxs="auto">
														<BooleanButtons name={`boxLocations.${i}.mountingSameAsPlug`} />
													</Col>
												</FormGroup>
												<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
													<Col xxxs>
														<Label className="mr-xxxs-2">Mounting Location:</Label>
														<ImageInput name={`boxLocations.${i}.mountingLocation`} />
													</Col>
													{!values.boxLocations[i].mountingSameAsPlug && (
														<Col xxxs>
															<Label className="mr-xxxs-2">Plug locations:</Label>
															<ImageInput name={`boxLocations.${i}.plugLocations`} />
														</Col>
													)}
												</FormGroup>
												<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
													<Col xxxs>
														<Label>Notes:</Label>
														<Textarea name={`boxLocations.${i}.notes`} />
													</Col>
												</FormGroup>
											</React.Fragment>
										)}
									</MultipleInputArray>
								</CardBlock>
							</Collapse>
						</Card>
						<Card className="mb-xxxs-3">
							<CardHeader>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										<H5>Specialty Channel:</H5>
									</Col>
									{readyForSpecialtyChannel(values) && (
										<Col xxxs="auto">
											<FontAwesomeIcon
												onClick={() => {
													setSpecialtyChannelOpen(!specialtyChannelOpen)
												}}
												icon={specialtyChannelOpen ? faChevronUp : faChevronDown}
												size="lg"
												fixedWidth
											/>
										</Col>
									)}
								</Row>
							</CardHeader>
							<Collapse isOpen={readyForSpecialtyChannel(values) && specialtyChannelOpen}>
								<CardBlock>
									<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
										<Col xxxs>
											<Label>Is specialty channel required for the Upper Front?</Label>
										</Col>
										<Col xxxs="auto">
											<BooleanButtons name="specialtyChannelRequired.upperFront" onClick={() => setBoxLocationsOpen(false)} />
										</Col>
									</FormGroup>
									<Collapse isOpen={values.specialtyChannelRequired.upperFront}>
										<SpecialtyChannelInput name="specialtyChannel.upperFront" />
									</Collapse>
									<Collapse isOpen={readyForNextSpecialtyChannelArea(values, 'upperFront')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Is specialty channel required for the Lower Front?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="specialtyChannelRequired.lowerFront" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.specialtyChannelRequired.lowerFront}>
											<SpecialtyChannelInput name="specialtyChannel.lowerFront" />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextSpecialtyChannelArea(values, 'lowerFront')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Is specialty channel required for the Upper Back?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="specialtyChannelRequired.upperBack" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.specialtyChannelRequired.upperBack}>
											<SpecialtyChannelInput name="specialtyChannel.upperBack" />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextSpecialtyChannelArea(values, 'upperBack')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Is specialty channel required for the Lower Back?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="specialtyChannelRequired.lowerBack" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.specialtyChannelRequired.lowerBack}>
											<SpecialtyChannelInput name="specialtyChannel.lowerBack" />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextSpecialtyChannelArea(values, 'lowerBack')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Is specialty channel required anywhere else?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="specialtyChannelRequired.elsewhere" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.specialtyChannelRequired.elsewhere}>
											<MultipleInputArray
												name={'specialtyChannel.elsewhere'}
												values={values.specialtyChannel.elsewhere}
												initialValues={{ name: '', type: '', notes: '', fasciaDepth: '', images: [] }}
											>
												{({ i }) => (
													<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
														<Col xxxs>
															<SpecialtyChannelInput name={`specialtyChannel.elsewhere.${i}`} needsName />
														</Col>
													</FormGroup>
												)}
											</MultipleInputArray>
										</Collapse>
									</Collapse>
								</CardBlock>
							</Collapse>
						</Card>
						<Card className="mb-xxxs-3">
							<CardHeader>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										<H5>Installation Obstacles:</H5>
									</Col>
									{readyForObstacles(values) && (
										<Col xxxs="auto">
											<FontAwesomeIcon
												onClick={() => {
													setObstaclesOpen(!obstaclesOpen)
												}}
												icon={obstaclesOpen ? faChevronUp : faChevronDown}
												size="lg"
												fixedWidth
											/>
										</Col>
									)}
								</Row>
							</CardHeader>
							<Collapse isOpen={readyForObstacles(values) && obstaclesOpen}>
								<CardBlock>
									<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
										<Col xxxs>
											<Label>Is a lift required for installation?</Label>
										</Col>
										<Col xxxs="auto">
											<BooleanButtons name="hasObstacles.lift" onClick={() => setSpecialtyChannelOpen(false)} />
										</Col>
									</FormGroup>
									<Collapse isOpen={values.hasObstacles.lift}>
										<ObstacleInput name="obstacles.lift" needsLocation />
									</Collapse>
									<Collapse isOpen={readyForNextObstacle(values, 'lift', true)}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>
													Are there any nonstandard areas that could require lights (walkout basement, porches, decks, etc...)?
												</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasNonStandardElevations" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasNonStandardElevations}>
											<MultipleInputArray
												name="nonStandardElevations"
												values={values.nonStandardElevations}
												initialValues={{ name: '', measurements: [], notes: '', images: [] }}
											>
												{({ i }) => (
													<FormGroup className="mb-xxxs-0 align-items-xxxs-center">
														<Row className="align-items-xxxs-end">
															<Col xxxs>
																<Label>Name:</Label>
																<Input name={`nonStandardElevations.${i}.name`} />
															</Col>
															<Col xxxs="auto" className="pl-xxxs-0">
																<Label className="mr-xxxs-2">Images:</Label>
																<ImageInput name={`nonStandardElevations.${i}.images`} />
															</Col>
														</Row>
														<Row>
															<Col xxxs>
																<Label>Measurements (ft):</Label>
																<MultipleInputArray
																	name={`nonStandardElevations.${i}.measurements`}
																	values={values.nonStandardElevations[i].measurements}
																>
																	{({ i: j }) => (
																		<Row>
																			<Col xxxs>
																				<InputGroup>
																					<Input
																						type="number"
																						inputMode="decimal"
																						name={`nonStandardElevations.${i}.measurements.${j}`}
																					/>
																					<InputGroupAddon>'</InputGroupAddon>
																				</InputGroup>
																			</Col>
																		</Row>
																	)}
																</MultipleInputArray>
															</Col>
															<Col xxxs>
																<Label>Notes:</Label>
																<Textarea name={`nonStandardElevations.${i}.notes}`} />
															</Col>
														</Row>
													</FormGroup>
												)}
											</MultipleInputArray>
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNormalObstacles(values)}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Are there any bay windows that would interfere with installation?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasObstacles.bayWindows" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasObstacles.bayWindows}>
											<ObstacleInput name="obstacles.bayWindows" needsAmount />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextObstacle(values, 'bayWindows')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Are there any chimneys that would interfere with installation?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasObstacles.chimneys" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasObstacles.chimneys}>
											<ObstacleInput name="obstacles.chimneys" needsAmount />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextObstacle(values, 'chimneys')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Are there any decorative trusses that would interfere with installation?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasObstacles.decorativeTrusses" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasObstacles.decorativeTrusses}>
											<ObstacleInput name="obstacles.decorativeTrusses" needsAmount />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextObstacle(values, 'decorativeTrusses')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Are there any pot lights that would interfere with installation?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasObstacles.potLights" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasObstacles.potLights}>
											<ObstacleInput name="obstacles.potLights" needsAmount />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextObstacle(values, 'potLights')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Are there any security cameras that would interfere with installation?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasObstacles.securityCameras" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasObstacles.securityCameras}>
											<ObstacleInput name="obstacles.securityCameras" needsAmount />
										</Collapse>
									</Collapse>
									<Collapse isOpen={readyForNextObstacle(values, 'securityCameras')}>
										<hr />
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Are there any other obstacles that would interfere with installation?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hasObstacles.other" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hasObstacles.other}>
											<MultipleInputArray
												name="obstacles.other"
												values={values.obstacles.other}
												initialValues={{ name: '', location: '', notes: '', amount: '', images: [] }}
											>
												{({ i }) => <ObstacleInput name={`obstacles.other.${i}`} needsName needsLocation needsAmount />}
											</MultipleInputArray>
										</Collapse>
									</Collapse>
								</CardBlock>
							</Collapse>
						</Card>
						<Card className="mb-xxxs-3">
							<CardHeader>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										<H5>Hover</H5>
									</Col>
									{readyForHover(values) && (
										<Col xxxs="auto">
											<FontAwesomeIcon
												onClick={() => {
													setHoverOpen(!hoverOpen)
												}}
												icon={hoverOpen ? faChevronUp : faChevronDown}
												size="lg"
												fixedWidth
											/>
										</Col>
									)}
								</Row>
							</CardHeader>
							<Collapse isOpen={readyForHover(values) && hoverOpen}>
								<CardBlock>
									<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
										<Col xxxs>
											<Row>
												<Col xxxs>
													<Label>Garage Door Width (in):</Label>
													<InputGroup>
														<Input
															type="number"
															inputMode="decimal"
															name="hoverMeasurements.garageDoor.width"
															onFocus={() => setObstaclesOpen(false)}
														/>
														<InputGroupAddon>"</InputGroupAddon>
													</InputGroup>
												</Col>
											</Row>
											<Row>
												<Col xxxs>
													<Label>Garage Door Height (in):</Label>
													<InputGroup>
														<Input
															type="number"
															inputMode="decimal"
															name="hoverMeasurements.garageDoor.height"
															onFocus={() => setObstaclesOpen(false)}
														/>
														<InputGroupAddon>"</InputGroupAddon>
													</InputGroup>
												</Col>
											</Row>
											<Row>
												<Col xxxs>
													<Label>Notes:</Label>
													<Textarea name="hoverMeasurements.garageDoor.notes" />
												</Col>
											</Row>
										</Col>
										<Col xxxs>
											<Row>
												<Col xxxs>
													<Label>Front Door Width (in):</Label>
													<InputGroup>
														<Input
															type="number"
															inputMode="decimal"
															name="hoverMeasurements.frontDoor.width"
															onFocus={() => setObstaclesOpen(false)}
														/>
														<InputGroupAddon>"</InputGroupAddon>
													</InputGroup>
												</Col>
											</Row>
											<Row>
												<Col xxxs>
													<Label>Front Door Height (in):</Label>
													<InputGroup>
														<Input
															type="number"
															inputMode="decimal"
															name="hoverMeasurements.frontDoor.height"
															onFocus={() => setObstaclesOpen(false)}
														/>
														<InputGroupAddon>"</InputGroupAddon>
													</InputGroup>
												</Col>
											</Row>
											<Row>
												<Col xxxs>
													<Label>Notes:</Label>
													<Textarea name="hoverMeasurements.frontDoor.notes" />
												</Col>
											</Row>
										</Col>
									</FormGroup>
									<Collapse isOpen={readyForHoverBuilding(values)}>
										<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
											<Col xxxs>
												<Label>Has the Hover request moved to the building stage?</Label>
											</Col>
											<Col xxxs="auto">
												<BooleanButtons name="hoverMovedToBuilding" />
											</Col>
										</FormGroup>
										<Collapse isOpen={values.hoverMovedToBuilding === false}>
											<Label>Wait until hover moves to building before leaving the property</Label>
										</Collapse>
									</Collapse>
								</CardBlock>
							</Collapse>
						</Card>
						<Card className="mb-xxxs-3">
							<CardHeader>
								<Row className="align-items-xxxs-center">
									<Col xxxs>
										<H5>Notes</H5>
									</Col>
									{readyForHoverBuilding(values) && values.hoverMovedToBuilding && (
										<Col xxxs="auto">
											<FontAwesomeIcon
												onClick={() => {
													setNotesOpen(!notesOpen)
												}}
												icon={notesOpen ? faChevronUp : faChevronDown}
												size="lg"
												fixedWidth
											/>
										</Col>
									)}
								</Row>
							</CardHeader>
							<Collapse isOpen={readyForHoverBuilding(values) && values.hoverMovedToBuilding && notesOpen}>
								<CardBlock>
									<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
										<Col xxxs>
											<Label>Add any general notes here</Label>
											<Textarea name="notes" onFocus={() => setHoverOpen(false)} />
										</Col>
									</FormGroup>
								</CardBlock>
							</Collapse>
						</Card>
						<Collapse isOpen={readyForHoverBuilding(values) && values.hoverMovedToBuilding}>
							<Button
								type="submit"
								color="success"
								block
								disabled={!(readyForHoverBuilding(values) && values.hoverMovedToBuilding)}
								submitting={isSubmitting}
							>
								Submit
							</Button>
						</Collapse>
					</Form>
				)
			}}
		</Formik>
	)
}

const SpecialtyChannelInput = ({ name, needsName }) => {
	const channelOptions = [
		{ label: 'J-Channel', value: 'J-Channel' },
		{ label: 'Front Fascia', value: 'Front Fascia' },
		{ label: 'Back Fascia', value: 'Back Fascia' },
		{ label: 'Deep', value: 'Deep' },
		{ label: 'Side Holes', value: 'Side Holes' },
		{ label: 'Curved - Vertical', value: 'Curved - Vertical' },
		{ label: 'Curved - Horizontal', value: 'Curved - Horizontal' },
		{ label: 'Sloped', value: 'Sloped' }
	]

	return (
		<React.Fragment>
			<FormGroup className="mb-xxxs-3 align-items-xxxs-center">
				<Row className="align-items-xxxs-end">
					{needsName && (
						<Col xxxs>
							<Label>Location:</Label>
							<Input name={`${name}.name`} />
						</Col>
					)}
					<Col xxxs>
						<Label>Channel Type:</Label>
						<Select name={`${name}.type`} options={channelOptions} isSearchable={false} />
					</Col>
					<Col xxxs>
						<Label>Fascia depth (in)</Label>
						<InputGroup>
							<Input type="number" inputMode="decimal" name={`${name}.fasciaDepth`} autoFill={false} />
							<InputGroupAddon>"</InputGroupAddon>
						</InputGroup>
					</Col>
					<Col xxxs="auto" className="pl-xxxs-0">
						<Label className="mr-xxxs-2">Images:</Label>
						<ImageInput name={`${name}.images`} />
					</Col>
				</Row>
			</FormGroup>
			<FormGroup row className="mb-xxxs-3 align-items-xxxs-center">
				<Col xxxs>
					<Label>Notes:</Label>
					<Textarea name={`${name}.notes`} />
				</Col>
			</FormGroup>
		</React.Fragment>
	)
}

const ObstacleInput = ({ name, needsLocation, needsName, needsAmount }) => {
	return (
		<FormGroup className="mb-xxxs-3 align-items-xxxs-center">
			<Row className="align-items-xxxs-end">
				{needsName && (
					<Col xxxs>
						<Label>Name:</Label>
						<Input name={`${name}.name`} />
					</Col>
				)}
				{needsLocation && (
					<Col xxxs>
						<Label>Location:</Label>
						<Input name={`${name}.location`} />
					</Col>
				)}
				{needsAmount && !(needsLocation && needsName) && (
					<Col xxxs>
						<Label>Amount:</Label>
						<Input type="number" inputMode="decimal" name={`${name}.amount`} />
					</Col>
				)}
				{!(needsAmount && needsLocation && needsName) && (
					<Col xxxs="auto">
						<Label className="mr-xxxs-2">Images:</Label>
						<ImageInput name={`${name}.images`} />
					</Col>
				)}
			</Row>
			{needsAmount && needsLocation && needsName && (
				<Row className="align-items-xxxs-end">
					<Col xxxs>
						<Label>Amount:</Label>
						<Input type="number" inputMode="decimal" name={`${name}.amount`} />
					</Col>
					<Col xxxs="auto">
						<Label className="mr-xxxs-2">Images:</Label>
						<ImageInput name={`${name}.images`} />
					</Col>
				</Row>
			)}
			<Row>
				<Col xxxs>
					<Label>Notes:</Label>
					<Textarea name={`${name}.notes`} />
				</Col>
			</Row>
		</FormGroup>
	)
}

const ImageInput = ({ name }) => {
	const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
	const [dropzoneFiles, setDropzoneFiles] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [field, meta, { setValue, setTouched }] = useField(name)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})

	const handleOnChange = (data) => {
		setDropzoneFiles(data)
	}

	const handleOnSave = () => {
		setIsSubmitting(true)
		const imgPromises = dropzoneFiles.map((file, i) => {
			return loadImage(file, {
				canvas: true,
				orientation: true
			}).then((img) => {
				const { image } = img
				const smaller = document.createElement('canvas')
				const smallerCtx = smaller.getContext('2d')
				const multiplier = image.width > 2560 || image.height > 2560 ? (image.width > image.height ? 2560 / image.width : 2560 / image.height) : 1

				smaller.width = image.width * multiplier
				smaller.height = image.height * multiplier

				smallerCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * multiplier, image.height * multiplier)

				return new Promise((resolve) => smaller.toBlob(resolve, 'image/jpeg', 0.7))
			})
		})

		Promise.all(imgPromises).then((images) => {
			const files = images.map((blob, i) => {
				//console.log(blob)
				const dzFile = dropzoneFiles[i]
				const newFile = new File([blob], dzFile.name, {
					lastModified: dzFile.lastModified,
					type: dzFile.type
				})

				return {
					url: newFile
				}
			})

			const formData = new FormData()
			files.forEach((image, i) => {
				formData.append('siteVisit', image.url, `images.${i}`)
			})
			//not a thunk since it doesn't change state
			return callApi({
				endpoint: `opportunities/${params.id}/upload-images`,
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data: formData
			}).then((response) => {
				const filesToSave = files.map((image, i) => {
					return { url: response.data[`images.${i}`] }
				})
				const value = field.value ? field.value.slice().concat(filesToSave) : filesToSave
				setIsSubmitting(false)
				setImageModalIsOpen(false)
				setValue(value)
				setTouched(true)
			})
		})
	}

	const handleOnClose = () => {
		setTouched(true)
		setDropzoneFiles([])
		setImageModalIsOpen(false)
	}

	const handleOnDelete = (index, closeModal) => {
		const images = field.value.slice()
		images.splice(index, 1)

		if (images.length === 0) {
			closeModal(false)
		}
		setValue(images)
	}

	return (
		<React.Fragment>
			<ButtonGroup size="sm">
				<Button size="sm" color="dark" onClick={() => setImageModalIsOpen(true)} icon={faUpload} />
				{field.value && field.value.length > 0 && (
					<ActionButton color="info" title={`View Image${field.value && field.value.length > 1 ? 's' : ''}`} icon={faSearch} modalSize="xl">
						{(closeModal, isShown) => (
							<Fragment>
								{field.value.map((image, j) => (
									<ImageView key={j} handleDelete={() => handleOnDelete(j, closeModal)} image={image} index={j} name={`${name}.${j}`} />
								))}
								<Row className="justify-content-xxxs-end">
									<Col xxxs="auto">
										<Button color="dark" onClick={() => closeModal(false)}>
											Close
										</Button>
									</Col>
								</Row>
							</Fragment>
						)}
					</ActionButton>
				)}
			</ButtonGroup>

			{imageModalIsOpen && (
				<DropzoneAreaModal
					isOpen={imageModalIsOpen}
					onClose={handleOnClose}
					onSave={handleOnSave}
					onChange={handleOnChange}
					acceptedFiles={['image/*']}
					filesLimit={10}
					maxFileSize={1024 * 1024 * 10}
					submitting={isSubmitting}
				/>
			)}
		</React.Fragment>
	)
}

const reconstructSiteVisitValues = (currentSiteVisit) => {
	if (!currentSiteVisit) return currentSiteVisit
	const siteVisitValues = {}
	//colour samples
	siteVisitValues.soffitColourSample = currentSiteVisit.colourSamples.find((colourSample) => colourSample.name === 'Soffit') //has to exist
	const fasciaColourSample = currentSiteVisit.colourSamples.find((colourSample) => colourSample.name === 'Fascia')
	if (fasciaColourSample) {
		siteVisitValues.soffitSameAsFascia = false
		siteVisitValues.fasciaColourSample = fasciaColourSample
	} else {
		siteVisitValues.soffitSameAsFascia = true
		siteVisitValues.fasciaColourSample = { name: 'Fascia', colour: '', images: [], notes: '' }
	}
	const otherColourSamples = currentSiteVisit.colourSamples.filter((colourSample) => colourSample.name !== 'Soffit' && colourSample.name !== 'Fascia')
	if (otherColourSamples.length > 0) {
		siteVisitValues.hasOtherColourSamples = true
		siteVisitValues.otherColourSamples = otherColourSamples
	} else {
		siteVisitValues.hasOtherColourSamples = false
		siteVisitValues.otherColourSamples = [{ name: '', colour: '', images: [], notes: '' }]
	}
	//box locations
	siteVisitValues.boxLocations = currentSiteVisit.boxLocations.map((bl) => ({
		...bl,
		mountingSameAsPlug: bl.plugLocations.length === 0
	}))
	//specialty channel
	siteVisitValues.specialtyChannelRequired = {}
	siteVisitValues.specialtyChannel = {}
	const specialtyChannelOptions = [
		{ name: 'Upper Front', var: 'upperFront' },
		{ name: 'Lower Front', var: 'lowerFront' },
		{ name: 'Upper Back', var: 'upperBack' },
		{ name: 'Lower Back', var: 'lowerBack' }
	]
	specialtyChannelOptions.forEach((option) => {
		const specialtyChannel = currentSiteVisit.specialtyChannel.find((sc) => sc.name === option.name)
		if (specialtyChannel) {
			siteVisitValues.specialtyChannelRequired[option.var] = true
			siteVisitValues.specialtyChannel[option.var] = specialtyChannel
		} else {
			siteVisitValues.specialtyChannelRequired[option.var] = false
			siteVisitValues.specialtyChannel[option.var] = { name: option.name, type: '', fasciaDepth: '', notes: '', images: [] }
		}
	})
	const elsewhere = currentSiteVisit.specialtyChannel.filter((specialtyChannel) => {
		for (const option of specialtyChannelOptions) {
			if (specialtyChannel.name === option.name) return false
		}
		return true
	})
	if (elsewhere.length > 0) {
		siteVisitValues.specialtyChannelRequired.elsewhere = true
		siteVisitValues.specialtyChannel.elsewhere = elsewhere
	} else {
		siteVisitValues.specialtyChannelRequired.elsewhere = false
		siteVisitValues.specialtyChannel.elsewhere = [{ name: '', type: '', notes: '', fasciaDepth: '', images: [] }]
	}
	//obstacles
	siteVisitValues.hasObstacles = {}
	siteVisitValues.obstacles = {}
	const obstacleOptions = [
		{ name: 'Lift', var: 'lift' },
		{ name: 'Bay Windows', var: 'bayWindows' },
		{ name: 'Chimneys', var: 'chimneys' },
		{ name: 'Decorative Trusses', var: 'decorativeTrusses' },
		{ name: 'Pot Lights', var: 'potLights' },
		{ name: 'Security Cameras', var: 'securityCameras' }
	]
	obstacleOptions.forEach((option) => {
		const obstacle = currentSiteVisit.obstacles.find((o) => o.name === option.name)
		if (obstacle) {
			siteVisitValues.hasObstacles[option.var] = true
			siteVisitValues.obstacles[option.var] = obstacle
		} else {
			siteVisitValues.hasObstacles[option.var] = false
			siteVisitValues.obstacles[option.var] = { name: option.name, location: '', notes: '', amount: '', images: [] }
		}
	})
	const other = currentSiteVisit.obstacles.filter((obstacle) => {
		for (const option of obstacleOptions) {
			if (obstacle.name === option.name) return false
		}
		return true
	})
	if (other.length > 0) {
		siteVisitValues.hasObstacles.other = true
		siteVisitValues.obstacles.other = other
	} else {
		siteVisitValues.hasObstacles.other = false
		siteVisitValues.obstacles.other = [{ name: '', location: '', notes: '', amount: '', images: [] }]
	}
	//non standard elevations
	if (currentSiteVisit.nonStandardElevations.length > 0) {
		siteVisitValues.hasNonStandardElevations = true
		siteVisitValues.nonStandardElevations = currentSiteVisit.nonStandardElevations
	} else {
		siteVisitValues.hasNonStandardElevations = false
		siteVisitValues.nonStandardElevations = [{ name: '', measurements: [], notes: '', images: [] }]
	}
	//hover measurements
	siteVisitValues.hoverMeasurements = {}
	const garageDoor = currentSiteVisit.hoverMeasurements.find((hoverMeasurement) => hoverMeasurement.type === 'Garage Door')
	if (garageDoor) {
		siteVisitValues.hoverMeasurements.garageDoor = garageDoor
	} else {
		siteVisitValues.hoverMeasurements.garageDoor = { type: 'Garage Door', width: '', height: '', notes: '' }
	}
	const frontDoor = currentSiteVisit.hoverMeasurements.find((hoverMeasurement) => hoverMeasurement.type === 'Front Door')
	if (frontDoor) {
		siteVisitValues.hoverMeasurements.frontDoor = frontDoor
	} else {
		siteVisitValues.hoverMeasurements.frontDoor = { type: 'Front Door', width: '', height: '', notes: '' }
	}
	//hover moved to building
	siteVisitValues.hoverMovedToBuilding = true
	//notes
	siteVisitValues.notes = currentSiteVisit.notes

	return siteVisitValues
}

const ImageView = ({ image, handleDelete, index, name }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)
	const [shouldRender, setShouldRender] = useState(false)
	const [field, meta, { setValue, setTouched }] = useField(name)
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const imageRef = useRef()
	const markerArea = useRef()
	const editing = useRef(false)

	useEffect(() => {
		if (isLoaded && imageRef.current !== null && markerArea.current === undefined) {
			markerArea.current = new markerjs2.MarkerArea(imageRef.current)
			if (field.value.annotation) {
				setShouldRender(true)
			}
			markerArea.current.uiStyleSettings.zoomButtonVisible = true
			markerArea.current.uiStyleSettings.zoomOutButtonVisible = true
			markerArea.current.settings.defaultColorsFollowCurrentColors = true
			markerArea.current.settings.defaultStrokeWidth = 5
			markerArea.current.availableMarkerTypes = [markerjs2.LineMarker, ...markerArea.current.BASIC_MARKER_TYPES]

			markerArea.current.addRenderEventListener((dataUrl, state) => {
				setValue({
					...field.value,
					annotation: state
				})
				setIsEditing(false)
				editing.current = false
				setShouldRender(true)
			})

			markerArea.current.addCloseEventListener(() => {
				if (editing.current) {
					editing.current = false
					setIsEditing(false)
					setShouldRender(true)
				}
			})
		}
	}, [imageRef.current, isLoaded])

	useEffect(() => {
		if (shouldRender) {
			setShouldRender(false)
			renderExisting()
		}
	}, [shouldRender])

	const renderExisting = () => {
		markerArea.current.show()
		if (field.value.annotation) {
			markerArea.current.restoreState(field.value.annotation)
		}

		markerArea.current.render().then((dataUrl) => {
			imageRef.current.src = dataUrl
			markerArea.current.close()
		})
	}

	var src
	const getImageUrl = (image) => {
		return URL.createObjectURL(image)
	}

	if (image.url instanceof File) {
		src = getImageUrl(image.url)
	} else {
		src = `https://lumenox-dev.s3.ca-central-1.amazonaws.com/opportunities/${params.id}/site-visit/${image.url}` //change
	}

	const showMarkerArea = () => {
		setIsEditing(true)
		editing.current = true
		if (markerArea.current) {
			imageRef.current.src = src

			markerArea.current.show()
			if (field.value.annotation) {
				markerArea.current.restoreState(field.value.annotation)
			}
			const bounds = imageRef.current.getBoundingClientRect()
			const toolbarHeight = markerArea.current.toolbar.uiContainer
			markerArea.current.coverDiv.style.zIndex = 2000
			markerArea.current.coverDiv.style.top = `${bounds.top - toolbarHeight.offsetHeight}px`
			markerArea.current.coverDiv.style.left = `${bounds.left}px`
		}
	}

	return (
		<Card className={`mb-xxxs-3`}>
			<CardHeader>
				<Row>
					<Col xxxs>#{index + 1}</Col>
					<Col xxxs="auto">
						<ButtonGroup size="sm">
							{!isEditing && (
								<Fragment>
									<Button color="info" icon={faPencilPaintbrush} onClick={showMarkerArea} />
									<ConfirmationButton
										color="danger"
										handleConfirmation={handleDelete}
										description="Are you sure you want to remove this image?"
										icon={faTrash}
									/>
									{/* <Button color="danger" icon={faTrash} onClick={handleDelete} /> */}
								</Fragment>
							)}
						</ButtonGroup>
					</Col>
				</Row>
			</CardHeader>
			<div className="text-xxxs-center">
				<Img src={src} ref={imageRef} onLoad={() => setIsLoaded(true)} />
			</div>
		</Card>
	)
}

const Img = React.forwardRef((props, ref) => (
	<img crossOrigin="anonymous" ref={ref} {...props} style={{ maxWidth: '100%', width: '125vh', imageOrientation: 'none' }} />
))

const HexDisplay = styled(InputGroupAddon)`
	background: ${(props) =>
		props.hex.startsWith('#') ? (props.hex.length === 7 ? props.hex : 'white') : props.hex.length === 6 ? '#' + props.hex : 'white'} !important;
`

const CookieSaver = ({ values }) => {
	const { url, params } = useRouteMatch({
		path: '/leads/:id/site-visit-checklist',
		strict: false
	})
	useEffect(() => {
		//probably don't need this check but no harm in leaving it
		if (values) Cookies.set(`siteVisit/${params.id}`, JSON.stringify({ ...values, savedAt: new Date().getTime() }), { expires: 1 })
	}, [values])
	return null
}

export default SiteVisitChecklistForm
