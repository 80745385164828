import React from 'react'
import { useSelector } from 'react-redux'
import { CardBlock, Row } from '@bootstrap-styled/v4'

import { useLumenox } from '~/services/lumenox'
import { Col } from '~/components/bootstrap'
import ReactAudioPlayer from 'react-audio-player'

const Call = ({ id, renderChildren }) => {
	const { selectors } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	if (activity.missed && !activity.voicemail) return null

	return (
		<CardBlock>
			<Col xxxs>
				{activity.media &&
					activity.media.length > 0 &&
					activity.media.map((media) => {
						return (
							<Row key={media._id}>
								<ReactAudioPlayer style={{ width: '100%' }} src={media.url} controls />
							</Row>
						)
					})}
				{activity.voicemail && (
					<Row key={activity.voicemail._id}>
						<ReactAudioPlayer style={{ width: '100%' }} src={activity.voicemail.url} controls />
					</Row>
				)}
			</Col>
			{renderChildren()}
		</CardBlock>
	)
}

export default Call
