import React, { PureComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row, Form, FormGroup, H5, Hr, H6 } from '@bootstrap-styled/v4'
import { Col, Button, Badge } from '~/components/bootstrap'
import { Formik, Form as FormikForm, Field, useField } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray, Select } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'
import { callApi } from '~/services/api'
import { components } from 'react-select'

const UserForm = ({ initialValues, onSubmit, onSuccess }) => {
	const { entityThunks, dispatch, selectors } = useLumenox()
	const userRoles = useSelector((state) => selectors.selectAllUserRoles(state))

	useEffect(() => {
		dispatch(entityThunks.fetchUserRoles())
	}, [])

	const defaultInitValues = {
		name: '',
		roles: []
	}

	const formInitialValues = {
		...defaultInitValues,
		...initialValues
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Required'),
		roles: Yup.array().of(Yup.string().required('Required')).min(1).required('Required')
	})

	const userRoleOptions = userRoles.map((row) => {
		return {
			label: row.name,
			value: row.id
		}
	})

	const handleOnSubmit = (values, helpers) => {
		onSubmit(values, helpers, onSuccess)
	}

	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<FormGroup>
							<Input type="text" name={`name`} placeholder="Full Name" />
						</FormGroup>
						<Hr />
						<H6>Roles:</H6>
						<CheckboxGroup className="mb-xxxs-1" name={`roles`} options={userRoleOptions} values={values.roles} />
						<Hr />
						<Input className="mb-xxxs-3" type="text" name={`openPhoneId`} placeholder="OpenPhone id" />
						<Hr />
						<HoverUserSelect name="hoverId" values={values} setFieldValue={setFieldValue} />
						<Hr />
						<Button type="submit" color="success" block>
							Save
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

const HoverUserSelect = ({ name }) => {
	const [options, setOptions] = useState([])

	useEffect(() => {
		callApi({
			endpoint: 'hover/users'
		}).then((response) => {
			//will eventually need to mess with pagination stuff here
			console.log('hover users:', response.data)
			setOptions(response.data.map((user) => ({ label: user.first_name + ' ' + user.last_name, value: user.id, email: user.email })))
		})
	}, [])

	return <Select name={name} options={options} CustomOption={HoverUserOption} isClearable />
}

const HoverUserOption = (props) => {
	return (
		<components.Option {...props}>
			<Row noGutters className="align-xxxs-start">
				<Col xxxs>{props.label}</Col>
				<Col xxxs="auto" className="text-xxxs-right">
					<Badge color="info">{props.data.email}</Badge>
				</Col>
			</Row>
		</components.Option>
	)
}

export default UserForm
