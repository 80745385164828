import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, CenteredModal, ConfirmationButton } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { H5, Hr, ModalBody, ModalHeader, P } from '@bootstrap-styled/v4'
import { UncontrolledSelect } from '~/components/bootstrap/components/Form'

const PackageProducedStageButton = ({ opportunity, currentStage, nextStage }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const [dependenciesMet, setDependenciesMet] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [alreadyVisited, setAlreadyVisited] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const previousStageActivities = useSelector((state) =>
		selectors.selectOpportunityActivitiesSortedByType(state, { opportunity: opportunity.id, type: 'Stage', direction: 'desc' })
	)

	//just manually add option for new generations
	const genOptions = [
		{
			label: 'Gen 1',
			value: 'Gen 1'
		},
		{
			label: 'Gen 2',
			value: 'Gen 2'
		},
		{
			label: 'Gen 3',
			value: 'Gen 3'
		},
		{
			label: 'Gen 4',
			value: 'Gen 4'
		}
	]

	const [selectedGen, setSelectedGen] = useState(opportunity.lightsGen ? genOptions.find((option) => option.value === opportunity.lightsGen) : null)

	useEffect(() => {
		dispatch(entityThunks.validateOpportunityStageChange({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
			setDependenciesMet(response.payload.dependenciesMet)
		})
	}, [currentStage])

	useEffect(() => {
		// use state variable and useEffect so we don't need to run check on every render
		setAlreadyVisited(previousStageActivities.some((activity) => activity.stage === nextStage.id))
	}, [previousStageActivities, nextStage])

	const updateStage = () => {
		setIsSubmitting(true)
		dispatch(entityThunks.updateOpportunityLightsGen({ id: opportunity.id, lightsGen: selectedGen.value })).then(() => {
			dispatch(entityThunks.updateOpportunityStage({ id: opportunity.id, newStageId: nextStage.id })).then((response) => {
				setIsSubmitting(false)
			})
		})
	}

	if (!dependenciesMet || alreadyVisited) return null

	return (
		<React.Fragment>
			<Button color={nextStage.color} icon={solid[nextStage.icon]} onClick={() => setModalOpen(true)}>
				{nextStage.name}
			</Button>
			<CenteredModal isOpen={modalOpen} size="sm">
				<ModalHeader toggle={() => setModalOpen(false)}>Set Lights Gen</ModalHeader>
				<ModalBody>
					<P>Which generation of lights were used in this package?</P>
					<UncontrolledSelect value={selectedGen} onChange={(value) => setSelectedGen(value)} options={genOptions} />
					<Hr />
					<Button color="success" onClick={updateStage} submitting={isSubmitting} block disabled={selectedGen === null}>
						Continue
					</Button>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

export default PackageProducedStageButton
