import React, { PureComponent, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Form, FormGroup, H5, Hr, H6 } from '@bootstrap-styled/v4'
import { Col, Row, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Input, SectionTitle, CheckboxGroup, MultipleInputArray, InputArray, Select, DatePicker } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'

const UpdateProductForm = ({ onSuccess, product }) => {
	const { entityThunks, dispatch, selectors } = useLumenox()

	const currentRate = useSelector((state) => selectors.selectCurrentRateByProduct(state, product.id))

	const validationSchema = Yup.object().shape({
		rate: Yup.object().shape({
			currencies: Yup.array()
				.of(
					Yup.object().shape({
						value: Yup.number().required('Required'),
						symbol: Yup.string().required('Required')
					})
				)
				.min(1),
			effectiveDate: Yup.date().default(() => new Date()) //make default today
		})
	})

	const handleSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
		validationSchema.validate(values).then((values) => {
			dispatch(entityThunks.updateProduct({ values, productId: product.id })).then((response) => {
				console.log('success', response)

				// todo error handling
				setSubmitting(false)
				resetForm()
				if (onSuccess && response.meta.requestStatus === 'fulfilled') {
					onSuccess()
				}
			})
		})
	}

	return (
		<Formik
			initialStatus={null}
			validateOnChange={false}
			initialValues={{
				rate: {
					currencies: currentRate.currencies.map(({ _id, ...rest }) => rest),
					effectiveDate: new Date()
				}
			}}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				console.log(errors)
				console.log(values)
				return (
					<Form tag={FormikForm}>
						<H6>Currencies:</H6>
						<MultipleInputArray name="rate.currencies" values={values.rate.currencies} initialValues={{ value: '', symbol: '' }}>
							{({ i, setElRef }) => (
								<Row>
									<Col xxxs={12} md={9} className="mb-xxxs-3 mb-lg-0">
										<Input type="number" name={`rate.currencies.${i}.value`} ref={(n) => setElRef && setElRef(n, i)} placeholder="Value" />
									</Col>
									<Col xxxs={12} md={3} className="mb-xxxs-3 mb-lg-0">
										<Select
											name={`rate.currencies.${i}.symbol`}
											options={[
												{ label: 'CAD', value: 'CAD' },
												{ label: 'USD', value: 'USD' }
											]}
										></Select>
									</Col>
								</Row>
							)}
						</MultipleInputArray>
						<H6>Effective Date:</H6>
						<FormGroup>
							<DatePicker name="rate.effectiveDate" />
						</FormGroup>
						<Button type="submit" color="success" block>
							Save
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

export default UpdateProductForm
