import React from 'react'
import styled from 'styled-components'
import { A, Badge } from '@bootstrap-styled/v4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'
import { readableColor } from 'polished'
import { useSelector } from 'react-redux'
import { useLumenox } from '~/services/lumenox'

import { differenceInBusinessDays, formatRelative } from 'date-fns'
import { enCA } from 'date-fns/esm/locale'
import { Fragment } from 'react'

export const Address = (props) => {
	const { format, address } = props
	//console.log(format)

	const regex = /(c|P|C)/g

	return (
		<React.Fragment>
			{format.replace(regex, (match, tag, char) => {
				switch (tag) {
					case 'c':
						return address.find((row) => row.types.find((type) => type === 'locality')).short_name
					case 'P':
						return address.find((row) => row.types.find((type) => type === 'administrative_area_level_1')).short_name
					default:
						return ''
				}
			})}
		</React.Fragment>
	)
}

export const BackLink = (props) => {
	const { children, ...rest } = props

	return (
		<BlockLink tag={Link} {...rest}>
			<FontAwesomeIcon icon={faAngleDoubleLeft} /> {children}
		</BlockLink>
	)
}

const BlockLink = styled(A)`
	display: block;
	margin-bottom: ${({ theme }) => theme['$spacers'][3]};
`

export const TeamName = ({ team: teamId }) => {
	const { selectors } = useLumenox()
	const team = useSelector((state) => selectors.selectTournamentTeamById(state, teamId))
	return <TeamColor color={team.color}>{team.name}</TeamColor>
}

export const TeamColor = styled.span`
	color: ${({ color }) => color} !important;
`

export const TeamBadge = styled(({ color, children, ...rest }) => <Badge {...rest}>{children}</Badge>)`
	background: ${({ color }) => color} !important;
	color: ${({ color, theme }) => readableColor(color, theme['$gray-dark'], theme['$gray-light'])};
`

export const Points = ({ children }) => {
	return <React.Fragment>{Number.isInteger(children) ? children : `${children > 1 ? Math.floor(children) : ''}½`}</React.Fragment>
}

const formatRelativeLocale = {
	lastWeek: "eeee'<br />at' p",
	yesterday: "'Yesterday<br />at' p",
	today: "'Today<br />at' p",
	tomorrow: "'Tomorrow<br />at' p",
	nextWeek: "'next' eeee'<br />at' p",
	other: "PP'<br />'p"
}

const locale = {
	...enCA,
	formatRelative: (token) => formatRelativeLocale[token]
}

export const RelativeDate = styled(({ date, ...rest }) => (
	<span {...rest} dangerouslySetInnerHTML={{ __html: formatRelative(date, new Date(), { locale }) }} />
))`
	display: inline-block;
	line-height: 1.2;
	font-size: 0.8rem;
`

export const DaysSince = styled(({ date, threshold, suffix = true, ...rest }) => {
	const days = Math.abs(differenceInBusinessDays(date, new Date()))
	if (days === 0) {
		return null
	}
	return (
		<Badge color={threshold && days > threshold ? 'danger' : 'info'} {...rest}>
			{days}
			{suffix ? ` Day${days > 1 ? 's' : ''} Ago` : ''}
		</Badge>
	)
})`
	display: inline-block;
	line-height: 1.2;
	font-size: 0.8rem;
`

export const FormatCurrency = ({ amount, decimalPlaces }) => {
	const formatter = new Intl.NumberFormat('en-CA', {
		style: 'currency',
		currency: 'CAD',

		// These options are needed to round to whole numbers if that's what you want.
		minimumFractionDigits: decimalPlaces, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		maximumFractionDigits: decimalPlaces // (causes 2500.99 to be printed as $2,501)
	})

	const formatted = formatter.format(amount)
	const split = formatted.split('.')

	return (
		<Fragment>
			{split[0]}
			{split.length > 1 ? (
				<Fragment>
					.<TopSup>{split[1]}</TopSup>
				</Fragment>
			) : (
				''
			)}
		</Fragment>
	)
}

const TopSup = styled('sup')`
	vertical-align: top;
`
