import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { Container, Row, H1, H3, Hr } from '@bootstrap-styled/v4'
import { Col, Button, Badge } from '~/components/bootstrap'
import { format, formatRelative } from 'date-fns'

import { Link } from 'react-router-dom'
import { useLumenox } from '~/services/lumenox'
import { DaysSince, RelativeDate } from '~/components/common/Text'
import { capitalize } from 'lodash'

export const ContactName = ({ id }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const contact = useSelector((state) => selectors.selectContactById(state, id))

	if (!contact) {
		return null
	}
	return (
		<Fragment>
			{contact.name.first} {contact.name.last}
		</Fragment>
	)
}

export const StageName = ({ id }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const stage = useSelector((state) => selectors.selectOpportunityStageTypeById(state, id))

	if (!stage) {
		return null
	}
	return <Fragment>{stage.name}</Fragment>
}

export const TagName = ({ id }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const tag = useSelector((state) => selectors.selectOpportunityTagById(state, id))

	if (!tag) {
		return null
	}
	return <Fragment>{tag.name}</Fragment>
}

export const AssignedName = ({ id }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const user = useSelector((state) => selectors.selectUserById(state, id))

	if (!user) {
		return null
	}
	return <Fragment>{user.name}</Fragment>
}

export const UserName = ({ id }) => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const user = useSelector((state) => selectors.selectUserById(state, id))

	if (!user) {
		return null
	}
	return <Fragment>{user.name}</Fragment>
}

export const LastActivity = ({ id }) => {
	const { selectors } = useLumenox()
	const lastActivity = useSelector((state) => selectors.selectOpportunityLastActivity(state, { opportunity: id }))

	if (!lastActivity) {
		return null
	}

	return <RelativeDate date={new Date(lastActivity.createdAt)} />
}

export const LastFollowUpDaysSince = ({ id }) => {
	const { selectors } = useLumenox()
	const lastFollowUp = useSelector((state) => selectors.selectOpportunityLastFollowUp(state, { opportunity: id }))

	if (!lastFollowUp) {
		return null
	}

	return (
		<Fragment>
			<RelativeDate date={new Date(lastFollowUp.createdAt)} />
			<br />
			<DaysSince date={new Date(lastFollowUp.createdAt)} threshold={2} />
		</Fragment>
	)
}

export const SourceInfo = ({ id }) => {
	const { selectors } = useLumenox()
	const lead = useSelector((state) => selectors.selectOpportunityById(state, id))
	if (lead.source && lead.source.medium && lead.source.source) {
		return (
			<Fragment>
				<Badge color="danger">{capitalize(lead.source.medium)}</Badge>
				<br />
				<Badge color="info">{capitalize(lead.source.source)}</Badge>
			</Fragment>
		)
	} else {
		return null
	}
}

export const StageDate = ({ id, stage }) => {
	const { selectors } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityStage(state, { opportunity: id, stage: stage }))
	if (!activity) {
		return null
	}

	return <RelativeDate date={new Date(activity.createdAt)} />
}

export const StageDateDaysSince = ({ id, stage }) => {
	const { selectors } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityStage(state, { opportunity: id, stage: stage }))

	if (!activity) {
		return null
	}

	return (
		<Fragment>
			<RelativeDate date={new Date(activity.createdAt)} />
			<br />
			<DaysSince date={new Date(activity.createdAt)} threshold={2} />
		</Fragment>
	)
}

export const LastQuoteSentDate = ({ id }) => {
	const { selectors } = useLumenox()
	const lastQuoteSentActivity = useSelector((state) => selectors.selectOpportunityQuoteSentActivity(state, { opportunity: id }))

	if (!lastQuoteSentActivity) {
		return null
	}

	return (
		<Fragment>
			<Row className="align-items-xxxs-center justify-content-xxxs-between" noGutters>
				<Col xxxs="auto">
					<RelativeDate date={new Date(lastQuoteSentActivity.createdAt)} />
				</Col>
				<Col xxxs="auto">
					<Badge color="info">
						<DaysSince date={new Date(lastQuoteSentActivity.createdAt)} />
					</Badge>
				</Col>
			</Row>
		</Fragment>
	)
}
