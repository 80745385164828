import React from 'react'
import { Row, H2, Form, FormGroup, P, Alert, A, Hr, Card, CardBlock, H4, H5 } from '@bootstrap-styled/v4'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'
import { Button, Col, Input } from '~/components/bootstrap'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { useFirebase } from '~/services/firebase'
import Cookies from 'js-cookie'

const Register = (props) => {
	const { signUpWithEmailAndPassword, signInWithGoogle, user } = useFirebase()
	const invite = Cookies.getJSON('invite')
	const inviteName = invite && invite.name ? invite.name.split(' ') : false
	const validationSchema = Yup.object({
		firstName: Yup.string().max(25, 'Must be 15 characters or less').required('Required'),
		lastName: Yup.string().max(35, 'Must be 25 characters or less').required('Required'),
		password: Yup.string()
			.min(8, 'Must be at least 8 characters')
			.matches(
				/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])).{8,}$/,
				'Contain at least 3 of the following 4 types of characters: * lower case letters (a-z) * upper case letters (A-Z) * numbers (i.e. 0-9) * special characters (e.g. !@#$%^&*)'
			)
			.required('Required'),
		email: Yup.string().email('Invalid email address').required('Required')
	})

	const onUserRegister = (values, { setSubmitting, setStatus, resetForm }) => {
		setStatus(null)
		signUpWithEmailAndPassword(values).catch((e) => {
			setStatus({ description: e.message })
			setSubmitting(false)
		})
	}

	if (!invite) {
		return (
			<Card color="warning">
				<CardBlock className="text-xxxs-center">
					<H5>You must be invited to register</H5>
				</CardBlock>
			</Card>
		)
	}

	return user ? (
		<Redirect to="/leads" />
	) : (
		<React.Fragment>
			<H2>Register</H2>

			<P>
				<React.Fragment>Please use this form to register. Already have an account? </React.Fragment>
				<A tag={Link} to="/auth/login">
					Login
				</A>
			</P>
			<Formik
				initialValues={{
					email: invite && invite.email ? invite.email : '',
					password: '',
					firstName: inviteName && inviteName[0] ? inviteName[0] : '',
					lastName: inviteName && inviteName[1] ? inviteName[1] : ''
				}}
				onSubmit={onUserRegister}
				validationSchema={validationSchema}
			>
				{({ isSubmitting, status }) => {
					return (
						<Form tag={FormikForm}>
							<FormGroup row>
								<Col xxxs={12} xl={6}>
									<Input placeholder="First Name" name="firstName" type="text" />
								</Col>
								<Col xxxs={12} xl={6}>
									<Input placeholder="Last Name" name="lastName" type="text" />
								</Col>
							</FormGroup>
							<FormGroup>
								<Input placeholder="Email" name="email" type="email" />
							</FormGroup>
							<FormGroup>
								<Input placeholder="Password" name="password" type="password" />
							</FormGroup>
							{status && <Alert color="danger">{status.policy ? status.policy : status.description}</Alert>}

							<div className="d-xxxs-flex justify-content-xxxs-between align-items-xxxs-center pt-xxxs-4">
								<A tag={Link} to="/auth/forgot-password">
									Forgot Password
								</A>
								<Button type="submit" color="primary" disabled={isSubmitting} submitting={isSubmitting}>
									Register
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
			<Hr />
			<div className="d-xxxs-flex justify-content-xxxs-between align-items-xxxs-center">
				{/* <Button color="warning" onClick={() => onSocialLogin('facebook')}>
					Register with <FontAwesomeIcon icon={faFacebook} />
				</Button> */}
				<Button color="warning" onClick={() => signInWithGoogle()}>
					Register with <FontAwesomeIcon icon={faGoogle} />
				</Button>
			</div>
		</React.Fragment>
	)
}

export default Register
