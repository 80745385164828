import { Tbody, Td, Th, Thead, Tr, Form } from '@bootstrap-styled/v4'
import React, { useEffect, useState } from 'react'
import { Badge, Table } from '~/components/bootstrap'
import { Checkbox, StyledCheckbox } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm } from 'formik'
import { callApi } from '~/services/api'
import { ConfirmationCheckbox } from '~/components/bootstrap/components/Button'
import { useLumenox } from '~/services/lumenox'
import { FormatCurrency } from '~/components/common/Text'
import { faDoorClosed, faDoorOpen } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SalespersonStatsTable = () => {
	const { dispatch, entityThunks, userHasRole } = useLumenox()
	const [rows, setRows] = useState([])

	useEffect(() => {
		callApi({
			endpoint: 'compensation-programs/salesperson-stats'
		}).then((response) => {
			setRows(response.data)
		})
	}, [])

	return (
		<Table striped responsive>
			<Thead>
				<Tr>
					<Th>Salesperson</Th>
					<Th>Number of Sales</Th>
					<Th>Total Sales Price</Th>
					<Th>Paid Commission</Th>
					<Th>Unpaid Commission</Th>
					<Th>Pending Commission</Th>
				</Tr>
			</Thead>
			<Tbody>
				{rows.map((row) => (
					<Tr key={row.name}>
						<Td>{row.name}</Td>
						<Td>
							<Badge color="success" size="lg">
								{row.numSales.closed} <FontAwesomeIcon icon={faDoorClosed} size="lg" />
							</Badge>
							{row.numSales.closed && row.numSales.pending ? <br /> : null}
							{row.numSales.pending ? (
								<Badge color="warning">
									{row.numSales.pending} <FontAwesomeIcon icon={faDoorOpen} size="lg" />
								</Badge>
							) : null}
						</Td>
						<Td>
							<FormatCurrency amount={row.totalPrice} decimalPlaces={2} />
						</Td>
						<Td>
							<FormatCurrency amount={row.commission.paid.cash} decimalPlaces={2} />
							{row.commission.paid.cash && row.commission.paid.giftCard ? <br /> : null}
							{row.commission.paid.giftCard ? (
								<React.Fragment>
									<FormatCurrency amount={row.commission.paid.giftCard} />
									{' (Gift Card)'}
								</React.Fragment>
							) : null}
						</Td>
						<Td>
							<FormatCurrency amount={row.commission.unpaid.cash} decimalPlaunpaidces={2} />
							{row.commission.unpaid.cash && row.commission.unpaid.giftCard ? <br /> : null}
							{row.commission.unpaid.giftCard ? (
								<React.Fragment>
									<FormatCurrency amount={row.commission.unpaid.giftCard} />
									{' (Gift Card)'}
								</React.Fragment>
							) : null}
						</Td>
						<Td>
							<FormatCurrency amount={row.commission.pending.cash} decimalPlaces={2} />
							{row.commission.pending.cash && row.commission.pending.giftCard ? <br /> : null}
							{row.commission.pending.giftCard ? (
								<React.Fragment>
									<FormatCurrency amount={row.commission.pending.giftCard} />
									{' (Gift Card)'}
								</React.Fragment>
							) : null}
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	)
}

export default SalespersonStatsTable
