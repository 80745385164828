export { entityThunks, createParamSelector, createCachedParamSelector } from './adapter'
import * as contact from '~/state/entities/contact'
import * as dealer from '~/state/entities/dealer'
import * as opportunity from '~/state/entities/opportunity'
import * as opportunityStageType from '~/state/entities/opportunityStageType'
import * as opportunityActivity from '~/state/entities/opportunityActivity'
import * as user from '~/state/entities/user'
import * as userRole from '~/state/entities/userRole'
import * as userInvite from '~/state/entities/userInvite'
import * as quote from '~/state/entities/quote'
import * as quoteRun from '~/state/entities/quoteRun'
import * as quoteRunSegment from '~/state/entities/quoteRunSegment'
import * as quoteLocation from '~/state/entities/quoteLocation'
import * as runType from '~/state/entities/runType'
import * as product from '~/state/entities/product'
import * as rate from '~/state/entities/rate'
import * as controller from '~/state/entities/controller'
import * as installDetails from '~/state/entities/installDetails'
import * as openPhoneNumber from '~/state/entities/openPhoneNumber'
import * as siteVisit from '~/state/entities/siteVisit'
import * as quoteTerm from '~/state/entities/quoteTerm'
import * as quoteLineItem from '~/state/entities/quoteLineItem'
import * as opportunityTag from '~/state/entities/opportunityTag'
import * as compensationProgram from '~/state/entities/compensationProgram'
import * as compensationProgramEnrollment from '~/state/entities/compensationProgramEnrollment'
/* import * as tasting from '~/state/entities/tasting'
import * as tastingUser from '~/state/entities/tastingUser'
import * as flight from '~/state/entities/flight'
import * as flightRanking from '~/state/entities/flightRanking'
import * as brewery from '~/state/entities/brewery' */

export const selectors = {
	...contact.selectors,
	...dealer.selectors,
	...opportunity.selectors,
	...opportunityStageType.selectors,
	...opportunityActivity.selectors,
	...user.selectors,
	...userRole.selectors,
	...userInvite.selectors,
	...quote.selectors,
	...quoteLocation.selectors,
	...quoteRun.selectors,
	...quoteRunSegment.selectors,
	...runType.selectors,
	...product.selectors,
	...rate.selectors,
	...controller.selectors,
	...installDetails.selectors,
	...openPhoneNumber.selectors,
	...siteVisit.selectors,
	...quoteTerm.selectors,
	...quoteLineItem.selectors,
	...opportunityTag.selectors,
	...compensationProgram.selectors,
	...compensationProgramEnrollment.selectors
	/* ...tasting.selectors,
	...tastingUser.selectors,
	...flight.selectors,
	...flightRanking.selectors,
	...brewery.selectors */
}

export const actions = {
	contact: { ...contact.actions },
	dealer: { ...dealer.actions },
	opportunity: { ...opportunity.actions },
	opportunityStageType: { ...opportunityStageType.actions },
	opportunityActivity: { ...opportunityActivity.actions },
	user: { ...user.actions },
	userRole: { ...userRole.actions },
	userInvite: { ...userInvite.actions },
	quote: { ...quote.actions },
	quoteLocation: { ...quoteLocation.actions },
	quoteRun: { ...quoteRun.actions },
	quoteRunSegment: { ...quoteRunSegment.actions },
	runType: { ...runType.actions },
	product: { ...product.actions },
	rate: { ...rate.actions },
	controller: { ...controller.actions },
	installDetails: { ...installDetails.actions },
	openPhoneNumber: { ...openPhoneNumber.actions },
	siteVisit: { ...siteVisit.actions },
	quoteTerm: { ...quoteTerm.actions },
	quoteLineItem: { ...quoteLineItem.actions },
	opportunityTag: { ...opportunityTag.actions },
	compensationProgram: { ...compensationProgram.actions },
	compensationProgramEnrollment: { ...compensationProgramEnrollment.actions }
	/* tasting: { ...tasting.actions },
	tastingUser: { ...tastingUser.actions },
	flight: { ...flight.actions },
	flightRanking: { ...flightRanking.actions },
	brewery: { ...brewery.actions } */
}

//const getNameParam = createParameterSelector(params => params.name);
//const getTypeParam = createParameterSelector(params => params.type);
