import React, { PureComponent, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLumenox } from '~/services/lumenox'
import styled from 'styled-components'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { Button, CenteredModal, Col } from '~/components/bootstrap'
import { useFirebase } from '~/services/firebase'
import { Badge, ButtonGroup, Container, Hr, ModalBody, ModalHeader, Row, Small } from '@bootstrap-styled/v4'
import { upperFirst } from 'lodash'
import PrivateRoute from '~/components/common/PrivateRoute'

import Add from './Add'
import View from './View'

const Quotes = () => {
	const { dispatch, entityThunks, selectors } = useLumenox()
	const { url, params } = useRouteMatch({
		path: '/leads/:id',
		strict: false
	})
	const { params: nameParams } = useRouteMatch({
		path: '/leads/:id/quotes/:name',
		strict: false
	})
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(true)
	const quote = useSelector((state) => selectors.selectQuoteById(state, nameParams.name))
	const [dirty, setDirty] = useState(false)
	const [unsavedPopupOpen, setUnsavedPopupOpen] = useState(false)
	const [validationPopupOpen, setValidationPopupOpen] = useState(false)
	const formRef = useRef()

	const exit = () => {
		history.push(url)
	}

	const handleExit = () => {
		if (!dirty) exit()
		else setUnsavedPopupOpen(true)
		//else if (confirm('There are unsaved changes, exit?')) history.push(url)
	}

	//const handleAddSubmit =
	const save = () => {
		if (formRef.current) {
			formRef.current.submitForm().then((result) => {
				if (formRef.current.isValid) {
					exit()
				} else {
					setValidationPopupOpen(true)
				}
			})
		} else {
			console.log('formRef.current is not set')
		}
	}

	return (
		<React.Fragment>
			<CenteredModal isOpen={isOpen} size="xl">
				<ModalHeader toggle={handleExit}>
					{upperFirst(quote ? 'View' : nameParams.name)} Quote
					{quote && quote.quotationNumber && (
						<React.Fragment>
							<br />
							<Small>
								<Badge>#{quote.quotationNumber}</Badge>
							</Small>
						</React.Fragment>
					)}
				</ModalHeader>
				<ModalBody>
					<Switch>
						<Redirect exact from={`${url}/quotes`} to={`${url}`} />
						<Route path={`${url}/quotes/new`}>
							<Add dirty={dirty} setDirty={setDirty} formRef={formRef} />
						</Route>
						<Route exact path={`${url}/quotes/:id`}>
							<View dirty={dirty} setDirty={setDirty} formRef={formRef} />
						</Route>
					</Switch>
				</ModalBody>
			</CenteredModal>
			<CenteredModal isOpen={unsavedPopupOpen} size="sm">
				<ModalHeader toggle={() => setUnsavedPopupOpen(false)}>Confirm Exit</ModalHeader>
				<ModalBody>
					There are unsaved changes, do you still want to exit?
					<Hr />
					<Row className="justify-content-xxxs-end">
						<Col xxxs="auto">
							<ButtonGroup>
								<Button onClick={exit}>Discard Changes</Button>
								<Button onClick={save} color="success">
									Save
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</ModalBody>
			</CenteredModal>
			<CenteredModal isOpen={validationPopupOpen} size="sm">
				<ModalHeader toggle={() => setValidationPopupOpen(false)}>Validation Error</ModalHeader>
				<ModalBody>
					The quote has errors in it preventing it from being saved. Return and fix the errors or discard your changes to exit.
					<Hr />
					<Row className="justify-content-xxxs-end">
						<Col xxxs="auto">
							<ButtonGroup>
								<Button onClick={exit}>Discard Changes</Button>
								<Button
									onClick={() => {
										setValidationPopupOpen(false)
										setUnsavedPopupOpen(false)
									}}
									color="success"
								>
									Return
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

const Wrapper = styled(Container)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default Quotes
