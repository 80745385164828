import React, { PureComponent, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, H1, H2, H3, Hr, Input, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, Table } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { callApi } from '~/services/api'

const Channel = () => {
	const [customThreshold, setCustomThreshold] = useState(7)
	const [greatChannelColours, setGreatChannelColours] = useState({})
	const [goodChannelColours, setGoodChannelColours] = useState({})
	const [acceptableChannelColours, setAcceptableChannelColours] = useState({})
	const [customChannelColours, setCustomChannelColours] = useState({})
	const [channelNames, setChannelNames] = useState([])
	const [totalSamples, setTotalSamples] = useState(0)
	const [sorting, setSorting] = useState('great')
	const [originalChannelNames, setOriginalChannelNames] = useState([])
	// const testMatches = () => {
	// 	console.log('testing 123')
	// 	callApi({
	// 		endpoint: 'quotes/channel/matches/ff7700'
	// 	}).then((response) => {
	// 		console.log('response:', response)
	// 	})
	// }
	//console.log('channel Colours:', channelColours)

	useEffect(() => {
		callApi({
			endpoint: 'quotes/channel/stats',
			params: {
				threshold: 1.5,
				matchesPerSample: 10
			}
		})
			.then((response) => {
				console.log('great response:', response)
				setGreatChannelColours(response.data.colours)
				setTotalSamples(response.data.absoluteTotal)
				console.log('setting channel names to:', Object.keys(response.data.colours))
				setChannelNames(Object.keys(response.data.colours))
			})
			.then(() => {
				callApi({
					endpoint: 'quotes/channel/stats',
					params: {
						threshold: 3,
						matchesPerSample: 10
					}
				}).then((response) => {
					console.log('good response:', response)
					setGoodChannelColours(response.data.colours)
				})
			})
			.then(() => {
				callApi({
					endpoint: 'quotes/channel/stats',
					params: {
						threshold: 5,
						matchesPerSample: 10
					}
				}).then((response) => {
					console.log('acceptable response:', response)
					setAcceptableChannelColours(response.data.colours)
				})
			})
	}, [])

	useEffect(() => {
		if (customThreshold) {
			callApi({
				endpoint: 'quotes/channel/stats',
				params: {
					threshold: customThreshold,
					matchesPerSample: 10
				}
			}).then((response) => {
				console.log('custom response:', response)
				setCustomChannelColours(response.data.colours)
			})
		}
	}, [customThreshold])

	const getNames = () => {
		switch (sorting) {
			case 'great':
				return channelNames.sort((aName, bName) => {
					return greatChannelColours[aName]?.goodMatches.total > greatChannelColours[bName]?.goodMatches.total ? -1 : 1
				})
				break
			case 'good':
				return channelNames.sort((aName, bName) => {
					return goodChannelColours[aName]?.goodMatches.total > goodChannelColours[bName]?.goodMatches.total ? -1 : 1
				})
				break
			case 'acceptable':
				return channelNames.sort((aName, bName) => {
					return acceptableChannelColours[aName]?.goodMatches.total > acceptableChannelColours[bName]?.goodMatches.total ? -1 : 1
				})
				break
			case 'custom':
				return channelNames.sort((aName, bName) => {
					return customChannelColours[aName]?.goodMatches.total > customChannelColours[bName]?.goodMatches.total ? -1 : 1
				})
		}
	}

	console.log('names:', channelNames)
	console.log('names[0]', channelNames[0])

	return (
		<Wrapper className="p-xxxs-3">
			<Row className="justify-content-xxxs-end mb-xxxs-3">
				<Col xxxs>
					<H3>Channel</H3>
				</Col>
				<Col xxxs="auto">{totalSamples} total samples</Col>
			</Row>
			{/* <Button onClick={testMatches}>Test Matches</Button> */}
			{/* <Button onClick={testStats}>Test Stats</Button> */}
			<Table>
				<Thead>
					<Tr>
						<Th>Name</Th>
						<Th onClick={() => setSorting('great')}>Great Matches</Th>
						<Th onClick={() => setSorting('good')}>Good Matches</Th>
						<Th onClick={() => setSorting('acceptable')}>Acceptable Matches</Th>
						<Th onClick={() => setSorting('custom')}>
							Matches Below{' '}
							<Input type="number" value={customThreshold} onChange={(e) => setCustomThreshold(e.target.value)} style={{ maxWidth: '4rem' }} />
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{getNames().map((name, i) => {
						if (i === 0) console.log('first name:', name)
						return (
							<Tr key={i}>
								<Td>{name}</Td>
								<Td>
									{greatChannelColours[name]?.goodMatches.total} ({greatChannelColours[name]?.goodMatchesPercentage.toFixed(1)}%)
									<br />
									{greatChannelColours[name]?.goodMatches.paid} paid, {greatChannelColours[name]?.goodMatches.unpaid} unpaid
								</Td>
								<Td>
									{goodChannelColours[name]?.goodMatches.total} ({goodChannelColours[name]?.goodMatchesPercentage.toFixed(1)}%)
									<br />
									{goodChannelColours[name]?.goodMatches.paid} paid, {goodChannelColours[name]?.goodMatches.unpaid} unpaid
								</Td>
								<Td>
									{acceptableChannelColours[name]?.goodMatches.total} ({acceptableChannelColours[name]?.goodMatchesPercentage.toFixed(1)}%)
									<br />
									{acceptableChannelColours[name]?.goodMatches.paid} paid, {acceptableChannelColours[name]?.goodMatches.unpaid} unpaid
								</Td>
								<Td>
									{customChannelColours[name]?.goodMatches.total} ({customChannelColours[name]?.goodMatchesPercentage.toFixed(1)}%)
									<br />
									{customChannelColours[name]?.goodMatches.paid} paid, {customChannelColours[name]?.goodMatches.unpaid} unpaid
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${({ theme }) => theme['$gray-light']};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export default Channel
