import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Form, FormGroup, H5, Hr, H6 } from '@bootstrap-styled/v4'
import { Col, Button } from '~/components/bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Select, Checkbox } from '~/components/bootstrap/components/Form'
import { useLumenox } from '~/services/lumenox'

const UserCompensationProgramsForm = ({ userId, closeModal }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	useEffect(() => {
		//want to load in all active programs that they could join
		dispatch(entityThunks.fetchActiveCompensationPrograms()).then((result) => {})
	})

	const activeCompensationPrograms = useSelector((state) => selectors.selectActiveCompensationPrograms(state))
	const user = useSelector((state) => selectors.selectUserByIdCompensationProgramsPopulated(state, userId))

	//filter down to ones they're not already enrolled in
	const availableCompensationPrograms = activeCompensationPrograms
		.filter((program) => user.compensationPrograms.find((pgm) => pgm.program && pgm.program.id === program.id) === undefined)
		.map((program) => ({ label: program.name, value: program.id }))

	const initialValues = {
		program: '',
		sendWelcomeEmail: true,
		notifications: {
			new: true,
			paid: true
		}
	}

	const validationSchema = Yup.object().shape({
		program: Yup.string().required('Required'),
		sendWelcomeEmail: Yup.bool().required(),
		notifications: Yup.object({
			new: Yup.bool().required(),
			paid: Yup.bool().required()
		})
	})

	const handleSubmit = (values, helpers) => {
		helpers.setSubmitting(true)
		dispatch(entityThunks.addCompensationProgramToUser({ id: userId, data: values })).then((response) => {
			helpers.setSubmitting(false)
			closeModal(false)
		})
	}

	const removeProgram = (programId) => {
		console.log('removing')
	}

	return (
		<Formik validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				//console.log(errors)
				//console.log(values)
				return (
					<Form tag={FormikForm}>
						<FormGroup>
							<Select name={`program`} options={availableCompensationPrograms} isSearchable={false} />
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="sendWelcomeEmail">Send Enrollment Email?</Checkbox>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="notifications.new">Send New Referee Email Notifications?</Checkbox>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xxxs="auto">
								<Checkbox name="notifications.paid">Send Referee Paid Email Notifications?</Checkbox>
							</Col>
						</FormGroup>
						<Row className="align-items-xxxs-center justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit" color="success" submitting={isSubmitting}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default UserCompensationProgramsForm
