import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchOpportunityActivityById = createAsyncThunk('opportunityActivities/fetchById', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunities/activities/${id}`
	}).then((response) => {
		return response.data
	})
})

/* export const fetchContactById = createAsyncThunk('opportunitys/fetchById', ({ id }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunitys/${id}`
	}).then((response) => {
		return response.data
	})
})

export const addOpportunity = createAsyncThunk('opportunitys/add', ({ values }, { getState, requestId }) => {
	return callApi({
		endpoint: `opportunitys`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})
 */
