import React, { useEffect, useState } from 'react'
import { Card, CardBlock, CardHeader, Form, FormGroup, H5, Row, Tbody, Td, Th, Thead, Tr } from '@bootstrap-styled/v4'
import { ActionButton, Button, CenteredModal, Col, Table } from '~/components/bootstrap'
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useLumenox } from '~/services/lumenox'
import { useSelector } from 'react-redux'
import { Checkbox, DatePicker, Label, Select } from '~/components/bootstrap/components/Form'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { format } from 'date-fns'

const InstallDateCard = ({ id }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()
	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))

	return (
		<Card className="mb-xxxs-3">
			<CardHeader>
				<Row>
					<Col xxxs>
						<H5>Install Date</H5>
					</Col>
					<Col xxxs="auto">
						<ActionButton title="Update Install Date" icon={faEdit} color="info">
							{(setIsOpen) => <UpdateInstallDateModal id={id} setIsOpen={setIsOpen} installDate={opportunity.installDate} />}
						</ActionButton>
					</Col>
				</Row>
			</CardHeader>
			{opportunity?.installDate && (
				<Table>
					<Thead>
						<Tr>
							<Th>Tentative</Th>
							<Th>Confirmed</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td>{opportunity.installDate.tentative ? format(new Date(opportunity.installDate.tentative), 'MMMM d, y') : null}</Td>
							<Td>{opportunity.installDate.confirmed ? format(new Date(opportunity.installDate.confirmed), 'MMMM d, y') : null}</Td>
						</Tr>
					</Tbody>
				</Table>
			)}
		</Card>
	)
}

const UpdateInstallDateModal = ({ id, installDate, setIsOpen }) => {
	const { selectors, dispatch, entityThunks } = useLumenox()

	const validationSchema = Yup.object().shape({
		installDate: Yup.object({
			tentative: Yup.date().nullable(),
			confirmed: Yup.date().nullable()
		})
	})

	const handleSubmit = (values, { setSubmitting }) => {
		setSubmitting(true)
		dispatch(entityThunks.updateOpportunityInstallDate({ id, installDate: values.installDate })).then(() => {
			setSubmitting(false)
			setIsOpen(false)
		})
	}

	const initialValues = installDate ? { installDate } : { installDate: { tentative: null, confirmed: null } }

	return (
		<Formik validateOnChange={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ values, touched, setFieldTouched, isSubmitting, status, setStatus, isValid, errors, setFieldValue, validateForm, submitForm }) => {
				return (
					<Form tag={FormikForm}>
						<Label>Tentative Install Date:</Label>
						<Row>
							<Col xxxs="auto">
								<FormGroup>
									<DatePicker name={`installDate.tentative`} />
								</FormGroup>
							</Col>
							<Col xxxs="auto">
								<Button size="sm" type="button" onClick={() => setFieldValue('installDate.tentative', null)}>
									Clear
								</Button>
							</Col>
						</Row>
						<Label>Confirmed Install Date:</Label>
						<Row>
							<Col xxxs="auto">
								<FormGroup>
									<DatePicker name={`installDate.confirmed`} />
								</FormGroup>
							</Col>
							<Col xxxs="auto">
								<Button size="sm" type="button" onClick={() => setFieldValue('installDate.confirmed', null)}>
									Clear
								</Button>
							</Col>
						</Row>
						<Row className="align-items-xxxs-center justify-content-xxxs-end">
							<Col xxxs="auto">
								<Button type="submit" color="success" submitting={isSubmitting}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}}
		</Formik>
	)
}

export default InstallDateCard
