import React from 'react'
import { useSelector } from 'react-redux'
import { CardBlock, Row } from '@bootstrap-styled/v4'

import { useLumenox } from '~/services/lumenox'
import { Col } from '~/components/bootstrap'
import InstallDetailsView from '../../../InstallDetails/InstallDetailsView'

const InstallDetailsActivity = ({ id, renderChildren }) => {
	const { selectors } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))

	return (
		<CardBlock>
			<Col xxxs>
				<InstallDetailsView id={activity.installDetails.id} />
			</Col>
			{renderChildren()}
		</CardBlock>
	)
}

export default InstallDetailsActivity
