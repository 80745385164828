import React, { PureComponent, useEffect, useMemo, useState } from 'react'
import { Container, H1, H2, H3, Hr, P } from '@bootstrap-styled/v4'
import { Col, Row, Button, ActionButton, ConfirmationButton } from '~/components/bootstrap'
import styled from 'styled-components'
import { useLumenox } from '~/services/lumenox'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { callApi } from '~/services/api'

const Hover = () => {
	const { userHasRole } = useLumenox()
	const query = useQuery()
	const [currentWebhooks, setCurrentWebhooks] = useState([])
	const admin = userHasRole('admin')

	useEffect(() => {
		if (!query.code) return
		console.log('code:', query.code)
		console.log('making request to get access token')
		callApi({
			endpoint: 'hover/token',
			params: {
				code: query.code
			}
		}).then((response) => {
			console.log('auth response:', response)
			window.location.replace(`https://${process.env.HOST}/hover?success=${response.data.success}`)
		})
	}, [query])

	useEffect(() => {
		callApi({
			endpoint: 'hover/webhooks'
		}).then((response) => {
			console.log('webhooks response:', response)
			if (response.data.success !== false) {
				setCurrentWebhooks(response.data.results)
			}
		})
	}, [])

	const goToAuthPage = () => {
		window.location.replace(
			`${process.env.HOVER_BASE_URL}/oauth/authorize?response_type=code&client_id=${process.env.HOVER_CLIENT_ID}&redirect_uri=${process.env.HOVER_REDIRECT}`
		)
	}

	const createWebhook = () => {
		callApi({
			endpoint: 'hover/webhooks',
			method: 'POST'
		}).then((response) => {
			//don't need anything
			console.log('create webhooks response:', response)
		})
	}

	if (!admin) return null

	return (
		<Wrapper className="p-xxxs-3">
			<Row className="justify-content-xxxs-end mb-xxxs-3">
				<Col xxxs>
					<H3>Hover</H3>
				</Col>
				<Col xxxs="auto">
					<ConfirmationButton
						handleConfirmation={goToAuthPage}
						button="Reauthorize"
						title="Reauthorize"
						description="Are you sure you want to reauthorize hover? This should only need to be done once"
					/>
					{query.success !== undefined && <P>{query.success === 'true' ? 'success!' : 'failed :('}</P>}
				</Col>
			</Row>
			<Row>
				<Col xxxs>Maybe put stats and stuff here eventually (like jobs that are currently processing, how many we've done, stuff like that)</Col>
			</Row>
			{currentWebhooks.length === 0 && (
				<Row className="align-items-xxxs-center justify-content-xxxs-center my-xxxs-3">
					<Col xxxs="auto">
						<Button onClick={createWebhook}>Make webhook</Button>
					</Col>
				</Row>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${({ theme }) => theme['$gray-light']};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

const useQuery = () => {
	const { search } = useLocation()
	return useMemo(() => {
		const query = {}
		for (const [key, value] of new URLSearchParams(search).entries()) {
			query[key] = value
		}
		return query
	}, [search])
}

export default Hover
