import React from 'react'
import { Button, Col, CenteredModal } from '~/components/bootstrap'
import { Row, Hr, ModalHeader, ModalBody, P } from '@bootstrap-styled/v4'

const ErrorModal = ({ isOpen, close, description, title = 'Error' }) => {
	return (
		<CenteredModal isOpen={isOpen} size="sm">
			<ModalHeader toggle={close}>{title}</ModalHeader>
			<ModalBody>
				<P className="text-xxxs-center">{description}</P>
				<Hr />
				<Row className="justify-content-xxxs-end">
					<Col xxxs>
						<Button color="dark" onClick={close} block>
							Close
						</Button>
					</Col>
				</Row>
			</ModalBody>
		</CenteredModal>
	)
}

export default ErrorModal
