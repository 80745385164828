import { createAsyncThunk } from '@reduxjs/toolkit'
import { callPublicApi, callApi } from '~/services/api'

export const addUser = createAsyncThunk('users/add', () => {
	return callApi({
		endpoint: `users`,
		method: 'POST'
	}).then((response) => {
		return response.data
	})
})

export const updateUser = createAsyncThunk('users/update', ({ id, values }) => {
	return callApi({
		endpoint: `users/${id}`,
		method: 'PUT',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const fetchUserById = createAsyncThunk('users/fetchById', (id) => {
	return callApi({
		endpoint: `users/${id}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

export const fetchUsers = createAsyncThunk('users/fetch', (id) => {
	return callApi({
		endpoint: `users`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

export const fetchUsersByRole = createAsyncThunk('users/fetchByRole', (slug) => {
	return callApi({
		endpoint: `users/role/${slug}`,
		method: 'GET'
	}).then((response) => {
		return response.data
	})
})

export const addCompensationProgramToUser = createAsyncThunk('users/addCompensationProgram', ({ id, data }) => {
	return callApi({
		endpoint: `users/${id}/compensation-programs`,
		method: 'POST',
		data
	}).then((response) => {
		return response.data
	})
})

export const fetchOpportunityReferralUser = createAsyncThunk('users/fetchOpportunityReferral', ({ id }) => {
	return callApi({
		endpoint: `users/by-opportunity-referral/${id}`,
		method: 'GET'
	}).then((response) => {
		if (response.data.success === false)
			return {
				entities: {},
				result: []
			}
		return response.data
	})
})

/* export const fetchCourseById = createAsyncThunk('courses/fetchById', (id, { getState, requestId }) => {
	return callApi({
		endpoint: `courses/${id}`
	}).then((response) => {
		return response.data
	})
})

export const fetchCourses = createAsyncThunk('courses/fetch', () => {
	return callApi({
		endpoint: `courses`
	}).then((response) => {
		return response.data
	})
}) */
