import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Row } from '@bootstrap-styled/v4'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEnvelopeCircleCheck, faEnvelopeOpenText } from '@fortawesome/pro-duotone-svg-icons'
import { format } from 'date-fns'

const Email = ({ id, opportunityId }) => {
	const { selectors, theme } = useLumenox()
	const activity = useSelector((state) => selectors.selectOpportunityActivityById(state, id))
	//console.log('email activity:', activity)

	let time

	if (activity.events.length > 0) {
		let aDelivered = activity.events.find((ev) => ev.type === 'delivered')
		let aOpened = activity.events.find((ev) => ev.type === 'opened')
		time = aOpened ? aOpened.createdAt : aDelivered.createdAt
	} else {
		time = activity.createdAt
	}

	return <Fragment>{format(new Date(time), 'MMMM d, y - h:mma')}</Fragment>
}

export default Email
