import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import {
	H1,
	Row,
	Hr,
	H3,
	Card,
	CardBlock,
	ButtonToolbar,
	ButtonGroup,
	CardHeader,
	H4,
	H5,
	H6,
	Badge,
	P,
	Tbody,
	Tr,
	Th,
	Td,
	Strong,
	Form
} from '@bootstrap-styled/v4'
import { Button, Table } from '~/components/bootstrap'

import { BackLink } from '~/components/common/Text'
import { Col } from '~/components/bootstrap'
import { useLumenox } from '~/services/lumenox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { Checkbox, Select } from '~/components/bootstrap/components/Form'
import { AssignedToSelect, CSRField, DealerField, InstallerField, SalespersonField, TagField } from '~/components/common/Forms'
import DeviceDetails from './DeviceDetails'
import CompensationProgramCard from './CompensationProgramCard'
import ReferralCard from './ReferralCard'
import QuoteRequestCard from './QuoteRequestCard'
import { Formik, Form as FormikForm } from 'formik'

const Details = ({ id }) => {
	const { selectors, dispatch, entityThunks, userHasRole } = useLumenox()

	const sales = userHasRole('sales')
	const admin = userHasRole('admin')
	const opportunity = useSelector((state) => selectors.selectOpportunityById(state, id))

	const onChange = (option, action, setIsLoading) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				if (option.value !== opportunity.assignedTo) {
					setIsLoading(true)
					dispatch(entityThunks.updateOpportunityAssignedTo({ id, userId: option.value })).then((response) => {
						setIsLoading(false)
					})
				}
				break
		}
	}

	const onChangeCSR = (option, action, setIsLoading) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				if (option.value !== opportunity.csr) {
					setIsLoading(true)
					dispatch(entityThunks.updateOpportunityCSR({ id, userId: option.value })).then((response) => {
						setIsLoading(false)
					})
				}
				break
		}
	}

	const onChangeInstaller = (option, action, setIsLoading) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				if (option.value != opportunity.installer) {
					setIsLoading(true)
					dispatch(entityThunks.updateOpportunityInstaller({ id, userId: option.value })).then((response) => {
						setIsLoading(false)
					})
				}
				break
		}
	}

	const onChangeSalesperson = (option, action, setIsLoading) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				if (option.value != opportunity.salesperson) {
					setIsLoading(true)
					dispatch(entityThunks.updateOpportunitySalesperson({ id, userId: option.value })).then((response) => {
						setIsLoading(false)
					})
				}
				break
		}
	}

	const onChangeDealer = (option, action, setIsLoading) => {
		switch (action.action) {
			case 'clear':
				break
			case 'select-option':
				if (option.value !== opportunity.dealer.dealer) {
					setIsLoading(true)
					dispatch(entityThunks.updateOpportunityDealer({ id, dealerId: option.value })).then((response) => {
						setIsLoading(false)
					})
				}
				break
		}
	}

	const onChangeTags = (option, action, setIsLoading) => {
		switch (action.action) {
			case 'clear':
				setIsLoading(true)
				dispatch(entityThunks.updateOpportunityTags({ id, tags: [] })).then(() => {
					setIsLoading(false)
				})
				break
			case 'select-option':
			case 'remove-value':
				setIsLoading(true)
				dispatch(entityThunks.updateOpportunityTags({ id, tags: option ? option.map((o) => o.value.id) : [] })).then(() => {
					setIsLoading(false)
				})
				break
		}
	}

	const handleTagCreated = (value, setIsLoading) => {
		setIsLoading(true)
		dispatch(entityThunks.updateOpportunityTags({ id, tags: [...opportunity.tags, { name: value }] })).then(() => {
			setIsLoading(false)
		})
	}

	const onChangeAppGuideSent = () => {
		dispatch(entityThunks.updateOpportunityAppGuideSent({ id, appGuideSent: !opportunity.appGuideSent }))
	}

	if (!sales && !admin) {
		return null
	}
	//

	return (
		<React.Fragment>
			<Card className="mb-xxxs-3">
				<CardHeader>
					<H5>Main</H5>
				</CardHeader>
				<CardBlock>
					<H6>Currently Assigned To:</H6>
					<AssignedToSelect onChange={onChange} selected={opportunity.assignedTo} />
					<Hr />
					<H6>Customer Service Representative:</H6>
					<CSRField onChange={onChangeCSR} selected={opportunity.csr} />
					<Hr />
					<H6>Installer:</H6>
					<InstallerField onChange={onChangeInstaller} selected={opportunity.installer} />
					<Hr />
					<H6>Salesperson:</H6>
					<SalespersonField onChange={onChangeSalesperson} selected={opportunity.salesperson} />
					<Hr />
					<H6>Dealer:</H6>
					<DealerField onChange={onChangeDealer} selected={opportunity.dealer ? opportunity.dealer.dealer : null} />
					<Hr />
					<H6>Tags:</H6>
					<TagField onChange={onChangeTags} selected={opportunity.tags} handleTagCreated={handleTagCreated} />
					<Hr />
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<H6>App Guide Sent:</H6>
						<ControlledCheckbox value={opportunity.appGuideSent} onChange={onChangeAppGuideSent} />
					</div>
				</CardBlock>
			</Card>
			<QuoteRequestCard id={id} />
			{opportunity.source && (
				<Card className="mb-xxxs-3">
					<CardHeader>
						<H5>Lead Source</H5>
						{/*Just call it source?*/}
					</CardHeader>
					<Table className="mb-xxxs-0">
						<Tbody>
							<Tr>
								<Th>Medium:</Th>
								<Td>
									<Badge>{opportunity.source.medium}</Badge>
								</Td>
							</Tr>
							<Tr>
								<Th>Source:</Th>
								<Td>
									<Badge>{opportunity.source.source}</Badge>
								</Td>
							</Tr>
							<Tr>
								<Th>Campaign:</Th>
								<Td>
									<Badge>{opportunity.source.campaign}</Badge>
								</Td>
							</Tr>
						</Tbody>
					</Table>
				</Card>
			)}
			{/* {opportunity.controllers && <DeviceDetails id={id} controllers={opportunity.controllers} />} */}
			<ReferralCard id={id} />
			<CompensationProgramCard id={id} />
		</React.Fragment>
	)
}

const ControlledCheckbox = ({ value, onChange }) => {
	return (
		<Formik initialStatus={null} validateOnChange={false} initialValues={{ checked: '' }} validationSchema={null} onSubmit={null}>
			{() => {
				return (
					<Form tag={FormikForm}>
						<Checkbox name="checked" checked={value} onChange={onChange} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default Details
