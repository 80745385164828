import Color from '@bootstrap-styled/color'
import t from 'bootstrap-styled/lib/theme'
import SuperQuery from '@themgoncalves/super-query'

import unitUtils from '@bootstrap-styled/utils/lib/unitUtils'
const { detectUnit, rmUnit } = unitUtils

// Colors
const gray = '#919AA9'
t['$gray'] = gray
t['$gray-light'] = Color(gray).lighten(0.75).toString()
t['$gray-medium-light'] = Color(gray).lighten(0.5).toString()
t['$gray-medium'] = Color(gray).lighten(0.25).toString()
t['$gray-dark'] = Color(gray).darken(0.5).toString()

t['$white'] = '#EBEFED'
t['$yellow'] = '#E7BD58'
t['$blue'] = '#1D2A71'
t['$blue-dark'] = '#25354A'
t['$green'] = '#3AB176'
t['$red'] = '#CA323A'
t['$bronze'] = '#D1936F'
t['$purple'] = '#8532ca'
t['$pink'] = '#ca3290'
t['$orange'] = '#ca7e32'

t['$brand-primary'] = t['$red']
t['$brand-secondary'] = t['$white']
t['$brand-warning'] = t['$yellow']
t['$brand-danger'] = t['$red']
t['$brand-info'] = t['$blue']

t['$link-color'] = t['$blue-dark']
t['$link-hover-color'] = t['$blue-dark']
t['$link-decoration'] = 'none'
t['$link-hover-decoration'] = 'underline'

// Options
t['$enable-rounded'] = true

// Responsive
t['$grid-breakpoints'] = {
	xxxs: '0px',
	xxs: '375px',
	xs: '426px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1600px',
	xxxl: '1920px',
	xxxxl: '2560px',
	xxxxxl: '3840px'
}
t['$modal-md'] = '700px'
t['$modal-lg'] = '1200px'
t['$modal-xl'] = '1500px'
t['$zindex-modal-backdrop'] = '1040'
t['$zindex-modal'] = '1050'

const breakpoints = {}
const keys = Object.keys(t['$grid-breakpoints'])

keys.map(function (key) {
	breakpoints[key] = parseInt(t['$grid-breakpoints'][key])
})

console.log(breakpoints)

t['$breakpoints'] = keys

t.SuperQuery = SuperQuery(breakpoints)

t['$container-max-widths'] = {
	//xxs: '300px',
	//xs: '340px',
	sm: '700px',
	md: '720px',
	lg: '960px',
	xl: '1240px',
	xxl: '1440px',
	xxxl: '1600px'
}

// Spacers
const detectedUnit = detectUnit(t['$spacer'])
for (let x = 6, y = 4; x <= 25; x++, y++) {
	t['$spacers'][x] = rmUnit(t['$spacer']) * y + detectedUnit
}

// Fonts
t['$font-family-sans-serif'] = 'Quicksand, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
t['$font-family-serif'] = '"Roboto Slab", -apple-system, system-ui, BlinkMacSystemFont, serif'
t['$font-family-base'] = t['$font-family-sans-serif']

t['$navbar-light-color'] = Color(t['$black']).toString()
t['$navbar-inverse-color'] = Color(t['$white']).toString()
t['$navbar-inverse-active-color'] = t['$navbar-inverse-color']
t['$navbar-brand-padding-y'] = '0px'
/*t[
	'$navbar-inverse-toggler-bg'
] = `url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="${t['$blue-dark']}" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E')`*/

t['$body-color'] = t['$blue-dark']
t['$body-bg'] = t['$white']
t['$font-weight-base'] = t['$font-weight-normal']

t['$font-size-sm'] = '0.9rem'

// Text
t['$headings-font-weight'] = t['$font-weight-normal']
t['$headings-font-family'] = t['$font-family-serif']
t['$headings-color'] = t['$gray-dark']
t['$line-height-base'] = '1.5'
t['$font-size-h1'] = '3rem'
t['$font-size-h2'] = '2.5rem'

t['$font-weight-light'] = '300'
t['$font-weight-normal'] = '500'
t['$font-weight-bold'] = '800'

t['$btn-primary-bg'] = t['$brand-primary']
t['$btn-primary-border'] = Color(t['$brand-primary']).darken(0.2).toString()
t['$btn-primary-color'] = t['$white']

t['$btn-success-bg'] = t['$brand-success']
t['$btn-success-border'] = Color(t['$brand-success']).darken(0.2).toString()
t['$btn-success-color'] = t['$white']

t['$btn-warning-bg'] = t['$brand-warning']
t['$btn-warning-border'] = Color(t['$brand-warning']).darken(0.2).toString()
t['$btn-warning-color'] = t['$gray-light']

t['$btn-danger-bg'] = t['$brand-danger']
t['$btn-danger-border'] = Color(t['$brand-danger']).darken(0.2).toString()

t['$btn-info-bg'] = t['$brand-info']
t['$btn-info-border'] = Color(t['$brand-info']).darken(0.2).toString()

t['$btn-secondary-bg'] = t['$brand-secondary']
t['$btn-secondary-color'] = t['$gray-dark']
t['$btn-secondary-border'] = t['$gray-medium']

t['$hr-border-color'] = t['$gray-medium']

t['$badge-primary-bg'] = t['$brand-primary']
t['$badge-warning-bg'] = t['$brand-warning']
t['$badge-info-bg'] = t['$brand-info']
t['$badge-color'] = t['$white']

//t['$btn-padding-x'] = '1.875rem'
t['$btn-padding-y'] = '.4rem'
t['$btn-padding-x'] = '.75rem'
t['$btn-padding-x-sm'] = '0.5rem'
t['$btn-padding-y-sm'] = '0.25rem'
t['$btn-font-weight'] = t['$font-weight-normal']

t['$input-bg'] = 'transparent'
t['$input-color'] = t['$gray-dark']
t['$input-color-placeholder'] = t['$gray-medium']
t['$input-border-color'] = t['$gray-medium']
t['$input-border-focus'] = t['$brand-primary']
t['$input-transition'] = 'border-color ease-in-out .25s, box-shadow ease-in-out .25s, background ease-in-out .25s'

t['$state-danger-bg'] = Color(t['$brand-danger']).lighten(0.7).toString()
t['$state-danger-border'] = t['$brand-danger']
t['$state-danger-text'] = Color(t['$brand-danger']).darken(0.2).toString()

t['$alert-danger-bg'] = t['$state-danger-bg']
t['$alert-danger-border'] = t['$state-danger-border']
t['$alert-danger-text'] = t['$state-danger-text']

t['$input-group-addon-bg'] = 'transparent'
t['$input-group-addon-border-color'] = t['$input-border-color']

t['$custom-control-indicator-size'] = '2rem'
t['$custom-control-gutter'] = '2.5rem'
t['$custom-control-indicator-bg-size'] = '75% 75%'
t['$custom-control-indicator-bg'] = t['$white']
t['$custom-control-checked-indicator-bg'] = t['$white']
t['$custom-control-focus-indicator-box-shadow'] = `0 0 0 1px ${t['$body-bg']}, 0 0 0 3px ${t['$brand-primary']}`
t['$custom-control-active-indicator-bg'] = t['$white']

t['$form-group-margin-bottom'] = t['$spacers'][4]

t['$tooltip-font-size'] = '0.75rem'
t['$tooltip-line-height'] = 1
t['$tooltip-bg'] = t['$gray-dark']
t['$tooltip-arrow-color'] = t['$gray-dark']
t['$zindex-tooltip'] = '2000'

t['$card-spacer-x'] = t['$spacer']

t[
	'$custom-checkbox-checked-icon'
] = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='${encodeURIComponent(
	t['$brand-primary']
)}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")`

t[
	'$custom-radio-checked-icon'
] = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='${encodeURIComponent(
	t['$brand-primary']
)}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")`

export default t
