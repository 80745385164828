import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '~/services/api'

export const fetchTerms = createAsyncThunk('terms/fetch', ({ includeArchived }) => {
	return callApi({
		endpoint: `quotes/terms`,
		params: { includeArchived }
	}).then((response) => {
		return response.data
	})
})

export const addTerm = createAsyncThunk('terms/add', ({ values }, { getState, requestId }) => {
	return callApi({
		endpoint: `quotes/terms`,
		method: 'POST',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const updateTerm = createAsyncThunk('terms/update', ({ values, termId }, { getState, requestId }) => {
	return callApi({
		endpoint: `quotes/terms/${termId}`,
		method: 'PUT',
		data: values
	}).then((response) => {
		return response.data
	})
})

export const removeTerm = createAsyncThunk('terms/remove', ({ termId }, { getState, requestId }) => {
	return callApi({
		endpoint: `quotes/terms/${termId}`,
		method: 'DELETE'
	}).then((response) => {
		return response.data
	})
})

export const reorderTerms = createAsyncThunk('terms/reorder', ({ movedTermId, oldIndex, newIndex }, { getState, requestId }) => {
	return callApi({
		endpoint: `quotes/terms/reorder`,
		method: 'POST',
		data: { movedTermId, oldIndex, newIndex }
	}).then((response) => {
		return response.data
	})
})
