import React, { PureComponent, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import BootstrapButton from './source/Button'
import unitUtils from '@bootstrap-styled/utils/lib/unitUtils'
const { detectUnit, rmUnit } = unitUtils
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDown, faChevronUp, faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip, ModalBody, ModalHeader, Hr, Row, ButtonGroup, Form } from '@bootstrap-styled/v4'
import { CenteredModal, Col } from '~/components/bootstrap'
import { v4 } from 'uuid'
import P from '@bootstrap-styled/v4/lib/P'
import { Fragment } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { Checkbox } from '../Form'

export default class Button extends PureComponent {
	state = {
		width: null,
		tooltipIsOpen: false,
		id: v4()
	}

	constructor(props) {
		super(props)

		this.ref = React.createRef()
	}

	componentDidMount = () => {
		const width = this.ref.current.offsetWidth

		this.setState({ width: width })
	}

	render() {
		const { children, className, submitting, disabled, tooltip, icon, ...rest } = this.props
		const { width, tooltipIsOpen, id } = this.state

		return (
			<React.Fragment>
				<StyledButton
					outerRef={this.ref}
					id={id}
					className={className}
					{...rest}
					disabled={submitting || disabled}
					initWidth={width}
					submitting={submitting}
				>
					{submitting ? <FontAwesomeIcon icon={faSpinner} fixedWidth spin /> : children}
					{icon && <FontAwesomeIcon fixedWidth icon={icon} className={children ? 'ml-xxxs-2' : ''} />}
				</StyledButton>
				{tooltip && (
					<Tooltip placement="top" target={id} isOpen={tooltipIsOpen} toggle={() => this.setState({ tooltipIsOpen: !tooltipIsOpen })}>
						{tooltip}
					</Tooltip>
				)}
			</React.Fragment>
		)
	}

	static defaultProps = {
		submitting: false,
		disabled: false
	}
}

export const ActionButton = (props) => {
	const { button, title, children, modalSize, onClick, onClose, ...rest } = props
	const [isOpen, setIsOpen] = useState(false)
	const [isShown, setIsShown] = useState(false)

	let modalAttr = {}
	if (modalSize) {
		modalAttr.size = modalSize
	}

	const showModal = (e) => {
		if (onClick) onClick()
		e.stopPropagation()
		setIsOpen(true)
	}

	const onShown = () => {
		setIsShown(true)
	}

	const onHidden = () => {
		setIsShown(false)
	}

	const toggle = () => {
		if (onClose) onClose()
		setIsOpen(false)
	}

	return (
		<React.Fragment>
			<Button onClick={showModal} {...rest}>
				{button}
			</Button>
			<CenteredModal autoFocus isOpen={isOpen} {...modalAttr} onOpened={onShown} onClosed={onHidden}>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>{children(setIsOpen, isShown)}</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

export const ConfirmationButton = (props) => {
	const { description, handleConfirmation, title = 'Confirm Action', confirmButton = 'Confirm', confirmButtonSubmitting = false, ...rest } = props

	return (
		<ActionButton title={title} {...rest} modalSize="sm">
			{(closeModal) => (
				<Fragment>
					<P className="text-xxxs-center">{description}</P>
					<Hr />
					<Row className="justify-content-xxxs-end">
						<Col xxxs="auto">
							<ButtonGroup>
								<Button color="dark" onClick={() => closeModal(false)}>
									Cancel
								</Button>
								<Button color="danger" onClick={() => handleConfirmation(closeModal)} submitting={confirmButtonSubmitting}>
									{confirmButton}
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</Fragment>
			)}
		</ActionButton>
	)
}

export const ConditionalConfirmationButton = ({ condition, handleConfirmation, button, ...props }) => {
	return condition ? (
		<ConfirmationButton {...props} handleConfirmation={handleConfirmation} button={button} />
	) : (
		<Button {...props} onClick={handleConfirmation}>
			{button}
		</Button>
	)
}

export const ConditionalActionButton = ({
	condition,
	button,
	title,
	children,
	modalSize,
	description,
	onClick,
	confirmButton = 'Confirm',
	confirmTitle = 'Confirm Action',
	...rest
}) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
	const [isShown, setIsShown] = useState(false)

	let modalAttr = {}
	if (modalSize) {
		modalAttr.size = modalSize
	}

	const showModal = (e) => {
		if (onClick) onClick()
		e.stopPropagation()
		setModalOpen(true)
	}

	const onShown = () => {
		setIsShown(true)
	}

	const onHidden = () => {
		setIsShown(false)
	}

	return (
		<React.Fragment>
			<Button onClick={(e) => (condition ? showModal(e) : setConfirmationModalOpen(true))} {...rest}>
				{button}
			</Button>

			<CenteredModal autoFocus isOpen={confirmationModalOpen} size="sm">
				<ModalHeader toggle={() => setConfirmationModalOpen(false)}>{confirmTitle}</ModalHeader>
				<ModalBody>
					<Fragment>
						<P className="text-xxxs-center">{description}</P>
						<Hr />
						<Row className="justify-content-xxxs-end">
							<Col xxxs="auto">
								<ButtonGroup>
									<Button color="dark" onClick={() => setConfirmationModalOpen(false)}>
										Cancel
									</Button>
									<Button
										color="danger"
										onClick={(e) => {
											setConfirmationModalOpen(false)
											showModal(e)
										}}
									>
										{confirmButton}
									</Button>
								</ButtonGroup>
							</Col>
						</Row>
					</Fragment>
				</ModalBody>
			</CenteredModal>
			<CenteredModal autoFocus isOpen={modalOpen} {...modalAttr} onOpened={onShown} onClosed={onHidden}>
				<ModalHeader toggle={() => setModalOpen(false)}>{title}</ModalHeader>
				<ModalBody>{children(setModalOpen, isShown)}</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

export const ConfirmationCheckbox = ({
	value,
	handleConfirmation,
	titleIfTrue,
	titleIfFalse,
	descriptionIfTrue,
	descriptionIfFalse,
	children,
	onClick,
	onClose,
	confirmButton = 'Confirm',
	disabled = false,
	...rest
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isShown, setIsShown] = useState(false)

	const showModal = (e) => {
		if (disabled) return
		if (onClick) onClick()
		e.stopPropagation()
		setIsOpen(true)
	}

	const onShown = () => {
		setIsShown(true)
	}

	const onHidden = () => {
		setIsShown(false)
	}

	const toggle = () => {
		if (onClose) onClose()
		setIsOpen(false)
	}

	return (
		<React.Fragment>
			<Formik initialStatus={null} validateOnChange={false} initialValues={{ value }} validationSchema={null} onSubmit={null}>
				{({ setFieldValue }) => {
					//feels ridiculous but this is the only way I could get it to update properly
					useEffect(() => {
						setFieldValue('value', value)
					}, [value])
					return (
						<Form tag={FormikForm}>
							<Checkbox name="value" onChange={showModal} />
						</Form>
					)
				}}
			</Formik>
			<CenteredModal autoFocus isOpen={isOpen} size="sm" onOpened={onShown} onClosed={onHidden}>
				<ModalHeader toggle={toggle}>{value ? titleIfTrue : titleIfFalse}</ModalHeader>
				<ModalBody>
					<P className="text-xxxs-center">{value ? descriptionIfTrue : descriptionIfFalse}</P>
					<Hr />
					<Row className="justify-content-xxxs-end">
						<Col xxxs="auto">
							<ButtonGroup>
								<Button color="dark" onClick={() => setIsOpen(false)}>
									Cancel
								</Button>
								<Button color="danger" onClick={() => handleConfirmation(setIsOpen)}>
									{confirmButton}
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</ModalBody>
			</CenteredModal>
		</React.Fragment>
	)
}

/* export class ActionButton extends PureComponent {
	
	state = {
		modalOpen: false
	}

	openModal = () => {
		this.setState({ modalOpen: true })
	}

	closeModal = () => {
		this.setState({ modalOpen: false })
	}

	render() {
		const { button, title, children, modalSize, ...rest } = this.props
		const { modalOpen } = this.state

		let modalAttr = {}
		if (modalSize) {
			modalAttr.size = modalSize
		}

		return (
			<React.Fragment>
				<Button onClick={() => this.openModal()} {...rest}>
					{button}
				</Button>
				<CenteredModal isOpen={modalOpen} {...modalAttr}>
					<ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
					<ModalBody>{children(this.closeModal)}</ModalBody>
				</CenteredModal>
			</React.Fragment>
		)
	}
} */

export const Toggle = ({ isOpen, ...rest }) => {
	return (
		<Button color="medium" outline {...rest}>
			<FontAwesomeIcon icon={!isOpen ? faPlus : faMinus} />
		</Button>
	)
}

const StyledButton = styled(({ color, initWidth, children, noBorders, outerRef, submitting, ...rest }) => {
	return (
		<BootstrapButton ref={outerRef} color={color} {...rest}>
			{children}
		</BootstrapButton>
	)
})`
	text-transform: uppercase;
	text-decoration: none !important;
	margin: 0 !important;
	font-size: ${({ theme }) => theme['$font-size-sm']} !important;

	${({ submitting, initWidth }) =>
		submitting &&
		css`
			width: ${initWidth}px;
		`}

	${({ noBorders }) =>
		noBorders &&
		css`
			border: none !important;
		`}
`
